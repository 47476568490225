<template>
  <div class="content main">
    <Slider />
    <Banner />
    <Map />
    <Info v-if="false" />
    <News />
    <Forum v-if="forum.visible === 'true'" />
  </div>
</template>

<script>
import Slider from '@/components/views/home/Slider.vue'
import Banner from '@/components/views/home/Banner.vue'
import Map from '@/components/views/home/Map.vue'
import Info from '@/components/views/home/Info.vue'
import News from '@/components/views/home/News.vue'
import Forum from "@/components/views/home/Forum.vue"
import { mapState } from "vuex";

export default {
  name: 'HomeView',
  components: {
    Slider,
    Banner,
    Map,
    Info,
    News,
    Forum,
  },
  computed: {
    ...mapState('home', [
      'forum'
    ])
  },
  methods: {
  }
}
</script>

<style>
.content.main {
  padding-top: 0;
}
</style>