<template>
  <div class="tag" @click="$emit('click')">
    {{label}}
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: String,
  }
}
</script>

<style lang="scss" scoped>
.tag{
  @include box(null, null, relative, null, null, null, null);
  @include flex(center, center, row);
  flex-shrink: 0;
  border: 0.04vw solid #E9D7C1;
  color: rgba(44, 42, 41, 0.7);
  padding: 0.5vw 0.8vw;
  font-size: 0.8vw;
  border-radius: 0.6vw;
}
@media only screen and (max-width: 900px) {
  .button{
    padding: 1vw 2vw;
    font-size: 1.5vw;
    border-radius: 0.4vw;
  }
}
@media only screen and (max-width: 600px) {
  .button{
    border: 0.08vw solid #E04E39;
    padding: 3vw 4vw;
    font-size: 3.5vw;
    border-radius: 0.8vw;
  }
}
</style>
