<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 5C12.1445 5 9 8.14453 9 12C9 14.3789 10.2109 16.4844 12.0312 17.75C7.92578 19.3516 5 23.3516 5 28H7C7 23.6016 10.1914 19.9258 14.375 19.1562L15 21H17L17.625 19.1562C21.8086 19.9258 25 23.6016 25 28H27C27 23.3516 24.0742 19.3516 19.9688 17.75C21.7891 16.4844 23 14.3789 23 12C23 8.14453 19.8555 5 16 5ZM16 7C18.7734 7 21 9.22656 21 12C21 14.7734 18.7734 17 16 17C13.2266 17 11 14.7734 11 12C11 9.22656 13.2266 7 16 7ZM15 22L14 28H18L17 22H15Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Bisnessman"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
