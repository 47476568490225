<template>
    <NotifyList title="Уведомления" url="/profile/notify/notifications" description-as-html />
</template>

<script>
import NotifyList from '../../../components/views/mfcCabinet/notify/NotifyList'

export default {
    name: 'MfcCabinetNotifications',
    components: { NotifyList },
};
</script>

<style scoped>
</style>