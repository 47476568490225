<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M27.2188 4.375L23.8125 8.625L20.5938 6.1875L19.8125 5.625L19.2188 6.375L15.7188 10.75L12.4375 9.09375L11.6562 8.71875L11.1562 9.4375L7.75 14.5625L4.59375 12.1875L3.40625 13.8125L7.40625 16.8125L8.25 17.4375L8.84375 16.5625L12.3438 11.3125L15.5625 12.9062L16.2812 13.25L16.7812 12.625L20.1875 8.375L23.4062 10.8125L24.1875 11.375L24.7812 10.625L28.7812 5.625L27.2188 4.375ZM27 12V28H29V12H27ZM19 15V28H21V15H19ZM11 16V28H13V16H11ZM23 17V28H25V17H23ZM3 18V28H5V18H3ZM15 18V28H17V18H15ZM7 21V28H9V21H7Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Chart"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
