<template>
  <div class="banner">
    <div class="container" @click="route(bottom_banner.link)">
      <img :src="bottom_banner.desktop" class="desc" />
      <img :src="bottom_banner.mid" class="mid" />
      <img :src="bottom_banner.mobile" class="mob" />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Banner',
  computed: {
    ...mapState('home', [
        'bottom_banner'
    ])
  },
  methods:{
    route(link){
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  padding: 2vw;
  padding-bottom: 0;
  img{
    @include box(100%, auto, relative, null, null, null, null);
    &.desc{
      display: flex;
    }
    &.mid{
      display: none;
    }
    &.mob{
      display: none;
    }
  }
  .container{
    cursor: pointer;
  }
}
@media only screen and (max-width: 900px) {
  .banner{
    padding: 4vw 2vw;
    img{
      &.desc{
        display: none;
      }
      &.mid{
        display: flex;
      }
      &.mob{
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .banner{
    padding: 6vw;
    img{
      &.desc{
        display: none;
      }
      &.mid{
        display: none;
      }
      &.mob{
        display: flex;
      }
    }
  }
}
</style>
