<template>
  <svg width="101" height="124" viewBox="0 0 101 124" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoBigIcon2">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M85.1078 0.46875H50.291V27.4729H69.6336C71.7704 27.4729 73.5026 29.2002 73.5026 31.3305V85.3382H50.291V112.342H100.582V15.8996C100.582 7.37757 93.6537 0.46875 85.1078 0.46875Z" fill="#C59368"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.2909 50.6189V0.46875L0 50.6189V123.916H50.2909V96.9121H27.08V50.6189H50.2909Z" fill="#ED5338"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoBigIcon2',
}
</script>

<style lang="scss" scoped>
.logoBigIcon2{
  @include box(12vw, auto, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoBigIcon2{
    @include box(17vw, auto, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoBigIcon2{
    @include box(24vw, auto, relative, null, null, null, null);
  }
}
</style>
