<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="noicon">
    <rect width="32" height="32" rx="4" fill="#E6DBD9"/>
    <path d="M30 2L3 30" stroke="#E04E39" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.noicon{
  @include box(2vw, 2vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .noicon{
    @include box(5vw, 5vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .noicon{
    @include box(8vw, 8vw, relative, null, null, null, null);
  }
}
</style>
