<template>
  <div class="practicesIndex">
    <PracticesBanner
      title="Портал Идей"
      description="Сервис обмена опытом и успешными решениями для&nbsp;совершенствования текущей и развития будущей деятельности Центров госуслуг «Мои документы»"
      :link="{text: 'Смотреть демо-ролик', href: 'http://mfc.economy.gov.ru/web/uploads/demo/mfc_v02_00.mp4'}"
      :count="getStats.count"
      :embed="getStats.embed"
      :regions="getStats.regions"
      src="https://i.ibb.co/cL4xxPh/banner.jpg"
    />
    <div class="container cont">
      <div class="row min">
        <Sidebar :sections="sections" :regions="regions" :categories="categories" />
      </div>
      <div class="row max">
        <SearchBar />
        <Items :items="getPageItems" />
        <Pagination
          :pages="totalCount"
          :current="page"
          @setpage="setPageAction"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PracticesBanner from '@/components/views/practices/index/PracticesBanner.vue'
import Sidebar from '@/components/views/practices/index/Sidebar.vue'
import Items from '@/components/views/practices/index/Items.vue'
import Pagination from '@/components/Pagination.vue'
import SearchBar from '@/components/views/practices/index/SearchBar/SearchBar.vue'

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PracticesIndex',
  components: {
    PracticesBanner,
    Sidebar,
    Items,
    Pagination,
    SearchBar,
  },
  computed: {
    ...mapGetters('practices', ['getStats', 'getPageItems']),
    ...mapState('practices', [
      'sections',
      'section',
      'items',
      'regions',
      'categories',
      'page',
      'totalCount',
      'perPage',
    ]),
  },
  methods: {
    ...mapActions('practices', [
      'setStats',
      'setSections',
      'setRegion',
      'setCategory',
      'getApi',
      'setPage',
      'getApiCategory',
      'getApiRegion',
    ]),
    setPageAction(page) {
      this.$router.push({ name: 'PracticesIndex', query: { page: page } })
      this.setPage(page)
      this.getApi({})
    },
    resetHashtagHandler() {
      this.$router.push(this.$route.path)
      this.getData()
    },
    getData() {
      this.setStats()
      this.setPage(this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1)
      this.getApi({})
      this.getApiRegion()
      this.getApiCategory()

    },
  },
  mounted() {
    this.getData()
  },
  beforeDestroy() {
    this.setCategory({ index: -1 })
    this.setRegion({ index: -1 })
  },
}
</script>

<style lang="scss" scoped>
.practicesIndex {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .container {
    @include flex(left, top, row);
    min-height: 20vw;
    padding-top: 36px;
    .row {
      &.min {
        min-width: 205px;
        @include box(22%, auto, relative, null, null, null, null);
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
        .pagination {
          padding: 2vw;
        }
        &.mobile {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .practicesIndex {
    padding-bottom: 0;
    .container {
      @include flex(left, top, column);
      padding: 0 3vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 0;
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .pagination {
            padding: 3vw 1vw;
          }
          &.mobile {
            display: flex;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .practicesIndex {
    .container {
      @include flex(left, top, column);
      padding: 0 6vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .pagination {
            padding: 4vw 2vw;
          }
        }
      }
    }
  }
}
</style>
