<template>
    <div class="contest-winners-index-page">
        <PageHeader
            class="page-header"
            :page-name="pageName"
            :page-title="pageTitle"
            :header-title="headerTitle"
            :route-path="pagePath"
        >
            <LogoBigIcon class="logo" />
        </PageHeader>
        <div class="container">
            <ContentSection>
                <Sidebar
                    :links="getLinksWithSelect"
                    :current="pageType"
                    slot="side"
                    @set-page="setRouterPage"
                />
                <div class="filters">
                    <Selectbox
                        class="filters__select"
                        :options="years"
                        @set="setYear"
                        default="Выберите год"
                        keydef="type"
                        :has-all-choice="true"
                    />
                    <Selectbox
                        class="filters__select"
                        :options="regions"
                        @set="setRegion"
                        default="Выберите регион"
                        keydef="type"
                        :has-all-choice="true"
                        :has-search="true"
                        all-choice-text="Все регионы"
                    />
                    <Selectbox
                        class="filters__select"
                        :options="filterNominations"
                        @set="setFilterNomination"
                        default="Выберите номинацию"
                        keydef="type"
                        :has-all-choice="true"
                        :has-search="true"
                        all-choice-text="Все номинации"
                    />
                </div>
                <Nominations :nominations="nominations" />
            </ContentSection>
            <ContentSection title="Другие разделы">
                <Others :links="getOthers" />
            </ContentSection>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/shared/PageHeader';
    import ContentSection from '@/components/shared/ContentSection';
    import Sidebar from '@/components/shared/Sidebar';
    import Others from '@/components/views/contest/Others';
    import LogoBigIcon from "@/components/icons/LogoBigIcon";
    import Selectbox from "@/components/elements/Selectbox";
    import {mapActions, mapGetters, mapState} from "vuex";
    import Nominations from "@/components/views/contest/Nominations";

    export default {
        name: 'ContestWinnersIndex',
        components: {
            Nominations,
            PageHeader,
            ContentSection,
            Sidebar,
            Others,
            LogoBigIcon,
            Selectbox,
        },
        data() {
            return {
                pageName: 'Конкурс «Лучший МФЦ»',
                pageTitle: 'Конкурс «Лучший МФЦ»',
                headerTitle: 'Победители Всероссийского конкурса «Лучший многофункциональный центр России»',
                pagePath: '/contest',
            }
        },
        watch: {},
        computed: {
            ...mapState('contestWinners', [
                'links',
                'pageType',
                'files',
                'nominations',
                'filterNominations',
                'regions',
                'years'
            ]),
            ...mapGetters('contestWinners', [
                'getOthers',
                'getLinksWithSelect',
            ]),
        },
        methods: {
            ...mapActions('contestWinners', [
                'getApi',
                'getApiRegion',
                'getApiYears',
                'getApiFilterNominations',
                'setRegion',
                'setYear',
                'setFilterNomination',
                'setRouterPage',
            ]),
        },
        mounted() {
            this.getApiRegion();
            this.getApiYears();
            this.getApiFilterNominations();
            this.getApi({});
        }
    }
</script>

<style lang="scss" scoped>
.contest-winners-index-page {
    @include contest-page-style;

    .filters {
        display: flex;
        @media only screen and (max-width: 900px) {
            flex-direction: column;
        }

        &__select {
            width: 25%;
            margin-right: 4%;
            @media only screen and (max-width: 900px) {
                width: 98%;
                margin: 1vw auto 2vw;
            }
        }
    }
}
</style>
