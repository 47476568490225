<template>
  <ElDialog
    class="mfc-modal"
    :visible="visible"
    :append-to-body="true"
    :modal-append-to-body="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    top="10%"
  >
    <div class="modal-first">
      <div class="modal-first__strong" @click="$emit('closeModal')">
        <svg>
          <use href="#close" />
        </svg>
      </div>
      <p class="modal-first__title">{{ title }}</p>
      <p class="modal-first__subtitle modal-first__subtitle-second">{{ lead }}</p>
      <div class="modal-check" v-for="(checkbox, idx) in checkboxes" :key="checkbox.name">
        <input v-model="checkbox.checked" type="checkbox" :id="'check' + idx" :name="'check' + idx" />
        <label :for="'check' + idx">
          <span>{{ checkbox.name }}</span>
        </label>
      </div>
      <button class="modal__accept" @click="accept" :disabled="!allRequiredChecked">{{ button }}</button>
    </div>
  </ElDialog>
</template>

<script>
export default {
  name: 'AgreementModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    lead: {
      type: String,
      required: true,
    },
    checkboxes: {
      type: Array,
      default: () => [],
    },
    button: {
      type: String,
      default: 'Согласен',
    },
  },
  computed: {
    allRequiredChecked() {
      return this.checkboxes.every(el => el.required ? el.checked : true)
    }
  },
  methods: {
    accept() {
      this.$emit('accept')
    }
  }
};
</script>

<style>
.modal-first input[type="checkbox"],
.modal-first input[type="radio"] {
  display: none;
}

.modal-first input[type="checkbox"] + label {
  position: relative;
}

.modal-first input[type="checkbox"] + label:before {
  content: "\2713";
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  height: 20px;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  transition: color ease 0.3s;
  border: 1px solid #e04e39;
  border-radius: 4px;
}

.modal-first input[type="checkbox"] + label span {
  display: block;
  padding-left: 30px;
}

.modal-first input[type="checkbox"]:checked + label:before {
  color: #fff;
  background: #e04e39;
}

.modal-check {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .modal-check {
    margin-bottom: 15px;
  }
}

.modal-check label {
  font-size: 14px;
  line-height: 18px;
  color: #2c2a29;
}

@media only screen and (max-width: 1170px) {
  .modal-check label {
    font-size: 13px;
    line-height: 15px;
  }
}

.modal__accept {
  cursor: pointer;
  width: 116px;
  height: 44px;
  background: #e6dbd9;
  border-radius: 4px;
  color: #fff;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: 0;
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
}

.modal__accept:hover:enabled {
  background: #e04e39;
}

@media only screen and (max-width: 767px) {
  .modal-first__subtitle-second {
    margin: 15px 0;
  }
}
</style>
