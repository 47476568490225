<template>
  <div class="other-events">
    <div class="min">
      <span class="arrows-text">Другие события</span>
      <div class="arrows">
        <arrow-left1/>
        <arrow-rigth/>
      </div>
    </div>
    <div class="row max">
      <router-link v-for="item in getOther" :key="item.link" :to="{ name: 'PosterOnce', params: { id: item.link }}" class="wrapper" :style="{backgroundImage: `url(${item.img})`}">
        <div class="cardText">
          <span class="absPos">{{item.sponsor}}</span>
          <div class="bottomCard">
            <span class="absPos">{{item.date}}</span>
            <h2 class="absPos">{{item.event}}</h2>
            <span class="absPos">{{item.city}}</span>
          </div>
        </div>
      </router-link>
      </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import ArrowLeft1 from "../../icons/ArrowLeft1";
  import ArrowRigth from "../../icons/ArrowRigth";

  export default {
    name: "OtherEvents",
    components: {
      ArrowLeft1,
      ArrowRigth
    },
    computed: {
      ...mapGetters('events', [
        'getOther'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .other-events {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    margin-top: 5.5vw;
    margin-bottom: 5.5vw;
    .row {
      @include flex(left, center, row);
      justify-content: space-between;
      .wrapper:last-child {
        margin-right: 0;
      }
      .cardText {
        margin: 0 1.25vw 0 1vw;
        padding: 1.45vw 0 1.45vw 0;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-size: 1vw;
        line-height: 1.4vw;
        .bottomCard span {
          font-size: 1vw;
          font-weight: 400;
          line-height: 1.4vw;
        }
        .bottomCard h2 {
          font-size: 1.25vw;
          line-height: 1.65vw;
          font-weight: 400;
          margin: 0.2vw 0 0.2vw 0;
        }
        .absPos {
          color: #fff;
          position: relative;
        }
      }
      .wrapper {
        background-color: #E04E39;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.3vw;
        width: 17vw;
        height: 22vw;
        margin-bottom: 1.7vw;
        margin-right: 1.7vw;
      }
    }
    .min {
      @include box(23.5%, auto, relative, null, null, null, null);
      margin-right: 1.4vw;
      .arrows {
        margin-top: 2vw;
        .icon {
          margin-left: .5vw;
          margin-right: 1.5vw;
        }
      }
      span {
        font-weight: bold;
        font-size: 1.5vw;
        line-height: 1.95vw;
      }
    }
    .max {
      @include box(74.5%, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 900px) {
    .other-events {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      margin-top: 5.5vw;
      margin-bottom: 5.5vw;
      .row {
        @include flex(left, center, row);
        justify-content: space-between;
        .wrapper:last-child {
          margin-right: 0;
        }
        .cardText span {
          line-height: 2.4vw;
          font-size: 1.6vw;
        }
        .cardText {
          margin: 0 1.5vw 0 1.5vw;
          padding: 1.5vw 0 1.5vw 0;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 2.4vw;
          .bottomCard span {
            font-size: 1.6vw;
            font-weight: 400;
            line-height: 2.4vw;
          }
          .bottomCard h2 {
            font-size: 2.3vw;
            line-height: 2.5vw;
            font-weight: 400;
            margin: 1vw 0 1vw 0;
          }
          .absPos {
            color: #fff;
            position: relative;
          }
        }
        .wrapper {
          width: 31.5%;
          height: 34vw;
          border-radius: 0.6vw;
          margin-bottom: 3vw;
        }
      }
      .min {
        @include box(100%, auto, relative, null, null, null, null);
        margin-right: 1.4vw;
        .arrows {
          margin-top: 3vw;
          margin-bottom: 3vw;
          .icon {
            margin-left: 1vw;
            margin-right: 2vw;
          }
        }
        span {
          font-weight: bold;
          font-size: 2.5vw;
          line-height: 2.5vw;
        }
      }
      .max {
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .other-events {
      @include box(95%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      margin-top: 7.5vw;
      margin-bottom: 5.5vw;
      overflow: hidden;
      .row {
        @include flex(left, center, row);
        justify-content: space-between;
        .wrapper:last-child {
          margin-right: 0;
        }
        .cardText span {
          line-height: 2.5vw;
          font-size: 4vw;
        }
        .cardText {
          margin: 0 3.5vw 0 3.5vw;
          padding: 3.5vw 0 3.5vw 0;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 4.4vw;
          .bottomCard span {
            font-size: 4.6vw;
            font-weight: 400;
            line-height: 4.4vw;
          }
          .bottomCard h2 {
            font-size: 4.3vw;
            line-height: 4.5vw;
            font-weight: 400;
            margin: 1vw 0 1vw 0;
          }
          .absPos {
            color: #fff;
            position: relative;
          }
        }
        .wrapper {
          flex-shrink: 0;
          width: 60%;
          height: 68vw;
          border-radius: 1.1vw;
          margin-bottom: 6vw;
          margin-right: 5vw;
        }
      }
      .min {
        @include box(100%, auto, relative, null, null, null, null);
        margin-right: 1.4vw;
        .arrows {
          margin-top: 5vw;
          margin-bottom: 7vw;
          .icon {
            margin-left: 3vw;
            margin-right: 5vw;
          }
        }
        span {
          font-weight: bold;
          font-size: 4.5vw;
          line-height: 4.5vw;
        }
      }
      .max {
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
</style>
