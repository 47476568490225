<template>
  <div class="modelsView" v-if="item">
    <Header :model="item" />
    <div class="container" id="side" ref="side">
      <div class="rows">
        <div class="row min" :class="{fixed: fixed}" :style="{top: `${top}px`}">
          <div class="sidebar">
            <Sidebar
              v-for="(res, i) in getJson(item.articles)"
              :key="'about' + i"
              :link="`model-article-${i}`"
              :name="res.title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <a
              @click="scrollToFiles"
              class="link"
              :class="{active: 'files' === type}"
            >Скачать документы</a>
          </div>
          <Selectbox
            :options="selects"
            default="Выберите раздел"
            keydef="type"
            @set="setSelect"
            class="select"
          />
        </div>
        <div class="row max" id="max">
          <Content :articles="getJson(item.articles)" />
        </div>
      </div>
    </div>
    <ContentSection class="files-container">
      <FilesList :files="getJson(item.files)" ref="files" />
    </ContentSection>
    <Other :items="items.filter(initem => initem.id !== item.id)" :width="width" />
  </div>
</template>

<script>
import Header from '@/components/views/models/view/Header.vue'
import Sidebar from '@/components/views/models/view/Sidebar.vue'
import Content from '@/components/views/models/view/Content.vue'
import Other from '@/components/views/models/view/Other.vue'
import Selectbox from '@/components/elements/Selectbox.vue'
import ContentSection from "@/components/shared/ContentSection";
import FilesList from '@/components/shared/FilesList';

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'ModelsView',
  components: {
    Header,
    Sidebar,
    Content,
    Other,
    Selectbox,
    ContentSection,
    FilesList,
  },
  computed: {
    ...mapGetters('models', [
      'getType',
    ]),
    ...mapState('models', [
      'items',
      'item'
    ]),
  },
  data() {
    return {
      selects: [],
      fixed: false,
      top: 0,
      fixTop: null,
      type: '',
      width: 0
    }
  },
  watch: {
    item(val) {
      if (val) {
        const json = this.getJson(val.articles)
        this.selects = Object.keys(json).map((key, i) => {
          return {
            name: json[key].title,
            type: `model-article-${i}`,
            select: false
          }
        })
      }
    },
    id() {
      this.getApiOnce(this.$route.params.id)
    }
  },
  methods: {
    setSelect(val) {
      this.selects = this.selects.map(vl => {
        vl.select = false
        if (vl.type === val.value) {
          vl.select = true
        }
        return vl
      })
      this.setLink(val.value)
    },
    getJson(json){
      return json ? JSON.parse(json) : {}
    },
    setLink(val) {
      const link = document.getElementById(val)
      if (link) {
        const pos = link.getBoundingClientRect()
        let offset = 200
        if (window.innerWidth > 3000) {
          offset = 400
        }
        this.type = val
        window.scrollTo({
          top: (window.scrollY + pos.top) - offset,
          behavior: "smooth"
        })
      }
    },
    ...mapActions('models', [
      'setType',
      'getApiOnce',
      'getApi'
    ]),
    setTypeMethods(val) {
      this.$router.push({ name: 'ModelsView', params: { type: val.value ? val.value : val } })
      this.setType(val)
    },
    setFixed() {
      const posMax = document.querySelector('.max').getBoundingClientRect()
      const posMin = document.querySelector('.min').getBoundingClientRect()
      if (window.innerWidth > 1000) {
        let offset = 50
        if (window.innerWidth > 3000) {
          offset = 100
        }
        if (window.scrollY > this.fixTop - offset) {
          if (window.scrollY < (posMax.height + this.fixTop) - posMin.height) {
            this.top = window.scrollY - this.fixTop + offset
          }
        } else {
          this.top = 0
        }
      } else {
        this.top = 0
        this.fixed = false
      }
    },
    scrollToFiles() {
      this.type = 'files'
      this.$refs.files.$el.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  },
  mounted() {
    this.setType(this.$route.params.type ? this.$route.params.type : '')
    this.getApiOnce(this.$route.params.id)
    this.getApi({})
    window.addEventListener('scroll', this.setFixed);
    this.$nextTick(() => {
      this.width = this.$refs.side?.querySelector('.max').clientWidth;
    });
  },
  updated() {
    if (this.item) {
      this.width = document.getElementById('max').clientWidth
      const pos = this.$refs.side.getBoundingClientRect()
      if (this.fixTop === null) {
        this.fixTop = pos.top
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modelsView {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .container {
    @include flex(left, top, column);
    padding: 2vw 0;
    .rows {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      .row {
        &:nth-child(1) {
          @include box(22%, auto, relative, null, null, null, null);
          padding-right: 2vw;
        }
        &:nth-child(2) {
          @include box(78%, auto, relative, null, null, null, null);
          padding-left: 2vw;
        }
        .select {
          display: none;
        }
        .link {
          font-size: 1vw;
          color: #e04e39;
          margin-bottom: 1vw;
          transition: 0.2s;
          cursor: pointer;
          &.active {
            color: #2c2a29;
          }
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .files-container {
    width: 75%;
    height: auto;
    position: relative;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 900px) {
  .modelsView {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .container {
      @include flex(left, top, column);
      padding: 2vw 0;
      .rows {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        .row {
          &:nth-child(1) {
            @include box(40%, auto, relative, null, null, null, null);
            padding-right: 0;
          }
          &:nth-child(2) {
            @include box(100%, auto, relative, null, null, null, null);
            padding-left: 0;
            padding-top: 2vw;
          }
          .select {
            @include box(100%, null, relative, 2vw, null, null, null);
            display: flex;
          }
          .link {
            display: none;
          }
        }
      }
    }
    .files-container {
      width: 100%;
      padding: 0 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .modelsView {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .container {
      @include flex(left, top, column);
      padding: 2vw 0;
      .rows {
        .row {
          &:nth-child(1) {
            @include box(100%, auto, relative, null, null, null, null);
          }
          &:nth-child(2) {
            @include box(100%, auto, relative, null, null, null, null);
          }
          .select {
            @include box(
              calc(100% - 12vw),
              null,
              relative,
              6vw,
              null,
              null,
              null
            );
            display: flex;
          }
          .link {
            display: none;
          }
        }
      }
    }
    .files-container {
      width: 100%;
      padding: 0 6vw;
    }
  }
}
</style>
