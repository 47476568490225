<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 3.90625L15.5938 4.09375L4.59375 9.09375L4 9.34375V13H7V24H5V26H4V28H28V26H27V24H25V13H28V9.34375L27.4062 9.09375L16.4062 4.09375L16 3.90625ZM16 6.09375L26 10.625V11H6V10.625L16 6.09375ZM9 13H11V24H9V13ZM13 13H15V24H13V13ZM17 13H19V24H17V13ZM21 13H23V24H21V13Z" fill="#E04E39"/>
    </svg>
</template>

<script>
    export default {
        name: "Law"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
