<template>
    <div class="container">
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__title">
                        <div class="row"><div class="text__title">МФЦ проверит соответствие заявителя установленным критериям</div></div>
                    </div>
                </div>
            </div>
            <div class="rows no-padding">
                <div class="row__name">
                    <div class="name__table">
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__table">
                        <div class="row"><div>
                            <Watch class="icon" /><br>
                            <div class="_title">3 рабочих дня</div>
                            <div class="discription">Включение в Единый федеральный реестр сведений о банкротстве (далее – ЕФРСБ) сведений о
                                <span class="red__text">возбуждении процедуры внесудебного банкротства</span>
                            </div>
                        </div></div>
                        <div class="row"><div>
                            <Calendar class="icon" /><br>
                            <div class="_title">6 месяцев</div>
                            <div class="discription">Процедура внесудебного банкротства</div>
                        </div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title">Что происходит во время процедуры внесудебного банкротства?</div>
        <div class="sub__title">Приостановление исполнения исполнительных документов</div>
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        Приостановление исполнения исполнительных документов
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__title">
                        <div class="row"><div class="text__title">За исключением требований</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row66"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">О возмещении вреда, причиненного жизни или здоровью,</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">О выплате заработной платы и выходного пособия, о возмещении морального вреда, о взыскании алиментов;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Которые должник не указал в прилагавшемся к его заявлению списке кредиторов.</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Судебный пристав не может продавать имущество,</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Банк не может списывать деньги со счетов,</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Прекращается начисление процентов и финансовых санкций по всем обязательствам (за исключением вышеуказанных требований).</div>
                        </div></div>
                        <div class="row33"><div class="description red__text">
                            <Law class="icon" /><br>
                            Нельзя получать кредиты, займы, выдавать поручительства, участвовать
                            в обеспечительных сделках<br><br>
                            <DocumentSearch class="icon" /><br>
                            Кредитор, указанный
                            в заявлении, может направить в регистрирующие права собственности ведомства запрос о наличии имущественных прав гражданина-должника
                        </div></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sections">
            <div class="sub__title">Прекращение внесудебной процедуры банкротства</div>
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        Прекращение внесудебной процедуры банкротства
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__title">
                        <div class="row"><div class="text__title">В связи с улучшением имущественного положения гражданина</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row66"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">Если в течение 6 месяцев после подачи заявления произойдет существенное изменение имущественного положения гражданина, позволяющее полностью или в значительной части исполнить свои обязательства перед кредиторами, он обязан в течение 5 рабочих дней уведомить об этом МФЦ.</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">3 рабочих дня: МФЦ внесет в ЕФРСБ сведения о прекращении процедуры внесудебного банкротства. Гражданин может инициировать судебную процедуру банкротства, чтобы применить, например, процедуру реструктуризации долгов.</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Повторное инициирование процедуры внесудебного банкротства возможно будет только через 10 лет после такого прекращения.</div>
                        </div></div>
                        <div class="row33"><div class="description red__text">
                            <DocumentWarning class="icon" /><br>
                            Если гражданин не направит указанное уведомление в МФЦ, то кредиторы смогут инициировать процедуру судебного банкротства
                        </div></div>
                    </div>
                </div>
            </div>

            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__title">
                        <div class="row"><div class="text__title">В связи с введением судебной процедуры банкротства</div></div>
                    </div>
                    <div class="rows__title">
                        <div class="row"><div class="description">Если в течение срока процедуры внесудебного банкротства в отношении гражданина будет введена судебная процедура банкротства, то внесудебная процедура будет прекращена путем внесения МФЦ сведений об этом в ЕФРСБ.</div></div>
                    </div>
                    <div class="rows__title">
                        <div class="row"><div class="description"><b>Кредитор</b> может инициировать судебную процедуру банкротства во время внесудебной, если он:</div></div>
                    </div>
                    <div class="rows__title">
                        <div class="row"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">Не указан в списке кредиторов;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Указан в списке кредиторов, но долг указан не в полном объеме;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Обнаружилось принадлежащее должнику имущество;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Вступило в законную силу решение суда по иску о признании сделки должника недействительной;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Должник не направил в МФЦ уведомление об улучшении своего имущественного положения.</div>
                        </div></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="title">Завершение внесудебной процедуры банкротства и списание долгов</div>

        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__title">
                        <div class="row"><div class="text__title">6 месяцев</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="description">
                            <List class="icon" /><br>
                            МФЦ включает сведения о гражданине в ЕФРСБ и гражданин освобождается от долгов.
                        </div></div>
                        <div class="row"><div class="description">
                            <Home class="icon" /><br>
                            Пристав не сможет продать имущество гражданина и банк не сможет списы- вать деньги со счета.
                        </div></div>
                    </div>

                    <div class="rows__title">
                        <div class="row"><div class="text__title">Часть долгов не списывается</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">По возмещению вреда, причиненного жизни или здоровью;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">По выплате заработной платы и выходного пособия;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">По возмещению морального вреда;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">По уплате алиментов;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">По субсидиарной ответственности как контролирующего лица;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">По возмещению убытков, причиненных юрлицу умышленно или по грубой неосторожности;</div>
                        </div></div>
                        <div class="row"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">По возмещению вреда имуществу, причиненного умышленно или по грубой неосторожности;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Не указанные в списке кредиторов при подаче заявления на внесудебное банкротство;</div>
                            <div class="icon-point"><Point /></div><div class="text_icon">Возникшие в период процедуры внесудебного банкротства.</div>
                        </div></div>
                    </div>

                    <div class="rows__title">
                        <div class="row"><div class="text__title">Надо будет оплатить долг, если</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">Вступил в законную силу судебный акт о привлечении гражданина к уголовной или административной ответственности за неправомерные действия при банкротстве, преднамеренное или фиктивное банкротство;</div>
                        </div></div>
                        <div class="row"><div class="description">
                            <div class="icon-point"><Point /></div><div class="text_icon">Доказано, что гражданин действовал незаконно, совершил мошенничество, скрыл или умышленно уничтожил имущество и т.п.</div>
                        </div></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Point from "../../icons/bankrutcy/Point";
    import Watch from "../../icons/bankrutcy/Watch";
    import Calendar from "../../icons/bankrutcy/Calendar";
    import Law from "../../icons/bankrutcy/Law";
    import DocumentSearch from "../../icons/bankrutcy/DocumentSearch";
    import DocumentWarning from "../../icons/bankrutcy/DocumentWarning";
    import List from "../../icons/bankrutcy/List";
    import Home from "../../icons/bankrutcy/Home"
    export default {
        name: "MainStep",
        components: {
            Home,
            List,
            DocumentWarning,
            DocumentSearch,
            Law,
            Calendar,
            Watch,
            Point,
        }
    }
</script>

<style lang="scss" scoped>
    .sub__title {
        display: none;
    }
    .icon-point {
        float: left;
        width: 28px;
    }
    .text_icon {
        float: left!important;
        padding-left: 28px;
        width: calc(100% - 28px);
        min-height: 40px;
        padding-bottom: 28px;
    }
    .no-padding {
        padding-top: 0!important;
    }
    .title {
        margin-top: 80px;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
    }
    .red__text {
        color: #E04E39!important;
    }
    .sections {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        .rows {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            padding-top: 40px;
            .row__name {
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
            .row__table {
                @include box(75%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                align-items: flex-start;
                padding-left: 15px;
                padding-right: 15px;
                .rows__title {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, row);
                    @include flex(left, top, row);
                    .row {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        .description {
                            text-align: left;
                            font-size: 18px;
                            line-height: 28px;
                            color: #2C2A29;
                            font-weight: 400;
                        }
                        .title__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: rgba(44, 42, 41, 0.7);
                            font-weight: bold;
                        }
                        .text__title {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                        .text__debts {
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 36px;
                            color: #2C2A29;
                        }
                        .text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                    }
                }
                .rows__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, row);
                    .row66 {
                        @include box(66%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        ._title {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 700;
                        }
                        .description {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 400;
                        }
                    }
                    .row33 {
                        @include box(33%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        ._title {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 700;
                        }
                        .description {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 400;
                        }
                    }
                    .row {
                        @include box(50%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        ._title {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 700;
                        }
                        .description {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 400;
                        }
                        .title__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: rgba(44, 42, 41, 0.7);
                            font-weight: bold;
                        }
                        .text__title {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                            color: #2C2A29;
                            .icon {
                                float: left;
                                width: 28px;
                            }
                            .text_icon {
                                float: left!important;
                                color: red!important;
                            }
                        }
                        .text__debts {
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 36px;
                            color: #2C2A29;
                        }
                        .text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .title {
            margin-top: 80px;
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            padding-left: 15px;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-left: 15px;
            padding-top: 40px;
            display: block;
        }
        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                padding-top: 24px;
                .row__name {
                    @include box(25%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    display: none!important;
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__title {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        @include flex(left, top, row);
                        padding-bottom: 24px;
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 0 15px;
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 28px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                            .title__row {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .text__title {
                                font-size: 19px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        .row66 {
                            @include box(66%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                        }
                        .row33 {
                            @include box(33%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                        }
                        .row {
                            @include box(50%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .text__title {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                                padding-left: 15px;
                                .icon {
                                    float: left;
                                    width: 28px;
                                }
                                .text_icon {
                                    float: left!important;
                                    color: red!important;
                                }
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .title {
            margin-top: 80px;
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            padding-left: 15px;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-left: 15px;
            padding-top: 40px;
            display: block;
        }
        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                padding-top: 24px;
                display: block!important;
                .row__name {
                    @include box(25%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    display: none!important;
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    display: block!important;
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__title {
                        display: block!important;
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        @include flex(left, top, row);
                        padding-bottom: 24px;
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 0 15px;
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 28px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                            .title__row {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .text__title {
                                font-size: 19px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        display: block!important;
                        .row66 {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                        }
                        .row33 {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                        }
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 15px;
                            ._title {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                            }
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .text__title {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                                padding-left: 15px;
                                .icon {
                                    float: left;
                                    width: 28px;
                                }
                                .text_icon {
                                    float: left!important;
                                    color: red!important;
                                }
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

