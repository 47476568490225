<template>
    <a @click="$emit('setlink', link)" class="link" :class="{active: link === type}">
      {{name}}
    </a>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    link: String,
    name: String,
    type: String,
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .link{
    font-size: 1vw;
    color: #E04E39;
    margin-bottom: 1vw;
    transition: 0.2s;
    cursor: pointer;
    &.active{
      color: #2C2A29;
    }
    &:hover{
      opacity: 0.6;
    }
  }
  .selects{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, column);
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .sidebar{
    padding: 0 2vw;
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, row);
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar{
    padding: 0 6vw;
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, column);
    }
  }
}
</style>
