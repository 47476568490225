import axios from "axios";
import {
  getContestDocumentsQuery
} from "@/axios/contest";
import {
  PAGE_TYPE_DOCUMENTS,
  PAGES
} from "@/store/modules/contest/const";
import { getLinksWithSelect, getOthers } from "@/store/modules/contest/getters";
import { setRouterPage } from "@/store/modules/contest/actions";

const state = {
  pageType: PAGE_TYPE_DOCUMENTS,
  files: [],
  links: PAGES,
  page: 1,
  totalCount: null,
  perPage: 100
}

const getters = {
  getOthers,
  getLinksWithSelect,
}

const actions = {
  getApi({ commit, state }) {
    axios.get(getContestDocumentsQuery(state.page, state.perPage))
      .then(response => {
        const { data } = response;
        commit('setFiles', data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  setPage({ commit, dispatch }, value) {
    commit('setPage', value);
    dispatch('getApi', {});
  },
  async setRouterPage({ state }, value) {
    await setRouterPage(state, value);
  },
}

// mutations
const mutations = {
  setFiles(state, value) {
    state.files = value;
  },
  setPage(state, value) {
    state.page = Number(value);
  },
  setTotalCount(state, value) {
    state.totalCount = Number(value);
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}