import axios from "axios";
import {
  getExpertPlatformsQuery
} from "@/axios/expertPlatforms";

const state = {
  items: [],
  page: 1,
  totalCount: null,
  perPage: 12
}

const getters = {

}

const actions = {
  getApi({ commit, state }) {
    axios.get(getExpertPlatformsQuery(state.page, state.perPage))
      .then(response => {
        const { data } = response;
        commit('setItems', data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  setPage({ commit, dispatch }, value) {
    commit('setPage', value);
    dispatch('getApi', {});
  },
}

// mutations
const mutations = {
  setItems(state, value) {
    state.items = value;
  },
  setPage(state, value) {
    state.page = Number(value);
  },
  setTotalCount(state, value) {
    state.totalCount = Number(value);
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}