<template>
  <div @click="toggleMenu" class="sort-select content__item-select sidebar-input">
    <div class="content__item-block">
      <p class="content__item-sort">Сортировать по</p>
      <p class="content__item-name">{{ selectedType.label }}</p>
    </div>
    <div class="content__item-icons">
      <img
        @click.stop="toggleSortOrder"
        :class="{ 'asc-order': isSortOrderASC }"
        class="sort-icon"
        src="@/assets/searchBar/sort.svg"
        alt
      />

      <img src="@/assets/undecomposed/arrow-down.svg" alt />
    </div>
    <ul v-if="isMenuShown" class="content__item-drop active">
      <li v-for="type in sortTypes" :key="type.id" :class="selectedTypeClasses(type)">
        <div @click="changeSortType(type)">{{ type.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SortSelect',
  computed: {
    selectedType() {
      return this.sortTypes.find(el => el.id === this.sortTypeId)
    },
    isSortOrderASC() {
      return this.sortOrder === 'ASC'
    },
    serializedSortType() {
      return { [this.selectedType.type]: this.sortOrder }
    }
  },
  data: () => ({
    sortTypeId: 1,
    isMenuShown: false,
    sortOrder: 'ASC',
    sortTypes: [
      {
        id: 1,
        label: 'Названию практик',
        type: 'title'
      },
      {
        id: 2,
        label: 'Кол-ву лайков',
        type: 'like'
      },
      {
        id: 3,
        label: 'Кол-ву внедрений',
        type: 'embedded_count'
      },
      {
        id: 4,
        label: 'Кол-ву комментариев',
        type: 'comment_count'
      },
      {
        id: 5,
        label: 'Дате внедрения',
        type: 'date'
      },
      {
        id: 6,
        label: 'Стоимости внедрения',
        type: 'price'
      },
      {
        id: 7,
        label: 'Кол-ву добавлений в закладки',
        type: 'selected_count'
      },
    ]
  }),
  methods: {
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    closeMenu(e) {
      if (!e.target.closest('.sort-select')) this.isMenuShown = false
    },
    changeSortType(value) {
      this.sortTypeId = value.id
      this.$emit('change', this.serializedSortType)
    },
    selectedTypeClasses(type) {
      return { 'content__item-drop--active': type.id === this.sortTypeId }
    },
    toggleSortOrder() {
      if (this.sortOrder === 'ASC') this.sortOrder = 'DESC'
      else this.sortOrder = 'ASC'
      this.$emit('change', this.serializedSortType)
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style>
.sort-icon {
  display: block;
  margin-right: 10px;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  transition: transform 0.3s;
}

.asc-order {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition: transform 0.3s;
}

.sort-select.content__item-select.sidebar-input {
  max-width: 100%;
  margin-bottom: 200px;
}
</style>