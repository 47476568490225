<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M3 6V26H29V6H3ZM5 8H27V24H23.5938C23.5156 23.8633 23.5508 23.6758 23.4375 23.5625C23.0586 23.1836 22.5234 23 22 23C21.4766 23 20.9414 23.1836 20.5625 23.5625C20.4492 23.6758 20.4844 23.8633 20.4062 24H11.5938C11.5156 23.8633 11.5508 23.6758 11.4375 23.5625C11.0586 23.1836 10.5234 23 10 23C9.47656 23 8.94141 23.1836 8.5625 23.5625C8.44922 23.6758 8.48438 23.8633 8.40625 24H5V8ZM12 10C9.80078 10 8 11.8008 8 14C8 15.1133 8.47656 16.1172 9.21875 16.8438C7.88672 17.7461 7 19.2852 7 21H9C9 19.3438 10.3438 18 12 18C13.6562 18 15 19.3438 15 21H17C17 19.2852 16.1133 17.7461 14.7812 16.8438C15.5234 16.1172 16 15.1133 16 14C16 11.8008 14.1992 10 12 10ZM12 12C13.1172 12 14 12.8828 14 14C14 15.1172 13.1172 16 12 16C10.8828 16 10 15.1172 10 14C10 12.8828 10.8828 12 12 12ZM19 13V15H25V13H19ZM19 17V19H25V17H19Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Passport"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
