<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="eachIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C6.738 0 3.84653 1.577 2.01953 4H2V4.02539C0.75 5.69439 0 7.759 0 10C0 15.514 4.486 20 10 20C10.338 20 10.671 19.9822 11 19.9492V18.2988V10H7V8H8C8.552 8 9 7.552 9 7V5H11C12.105 5 13 4.105 13 3V2.58984C15.928 3.77984 18 6.65 18 10C18 10.0652 17.9958 10.1295 17.9915 10.1937V10.1937C17.9887 10.2358 17.986 10.2779 17.9844 10.3203L19.7852 12.0547C19.9242 11.3907 20 10.704 20 10C20 4.486 15.514 0 10 0ZM20.2734 15L13 8V18L15.2734 15.8379L17.3066 20.5234L19.5059 19.5234L17.457 15H20.2734ZM4 10L2.20898 8.20898C2.07698 8.78598 2 9.384 2 10C2 14.072 5.06 17.4366 9 17.9316V16C7.895 16 7 15.105 7 14V13L4 10Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>
</template>

<script>
  export default {
    name: "List"
  }
</script>

<style lang="scss" scoped>
.eachIcon {
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .eachIcon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .eachIcon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
