<template>
    <div class="svg-box">
        <svg>
            <symbol id="ok" viewBox="0 0 14 14">
                <path
                    fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.09766 11.6982C10.3878 10.5445 11.1998 8.86732 11.1998 7.00045C11.1998 5.13358 10.3878 3.45637 9.09766 2.30273C11.1228 2.90444 12.5998 4.77996 12.5998 7.00045C12.5998 9.22094 11.1228 11.0965 9.09766 11.6982Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.9 2.09961C2.19381 2.09961 0 4.29341 0 6.99961C0 9.7058 2.19381 11.8996 4.9 11.8996C7.60619 11.8996 9.8 9.7058 9.8 6.99961C9.8 4.29341 7.60619 2.09961 4.9 2.09961ZM7.54456 4.89961L4.34257 8.09321L2.95544 6.70971L2.1 7.56291L4.34257 9.79961L8.4 5.75281L7.54456 4.89961Z"
                    fill="white"
                />
            </symbol>
            <symbol id="like" viewBox="0 0 14 14">
                <path
                    d="M6.30156 1.63281L5.93971 3.22786L4.67461 4.63333C4.52014 4.8046 4.4349 5.02715 4.4349 5.25768V9.56615C4.4349 10.0818 4.85256 10.4995 5.36823 10.4995H9.11615C9.46195 10.4995 9.77503 10.2959 9.91549 9.97995L11.3164 6.82721C11.3943 6.65268 11.4349 6.46347 11.4349 6.27214V5.83281C11.4349 5.31948 11.0149 4.89948 10.5016 4.89948H7.2349C7.2349 4.89948 7.70156 3.79314 7.70156 2.96901C7.70156 1.68941 6.30156 1.63281 6.30156 1.63281ZM2.10156 4.89948V10.4995H3.50156V4.89948H2.10156Z"
                    fill="white"
                />
            </symbol>
            <symbol id="comment" viewBox="0 0 14 14">
                <path
                    d="M3.01076 2.80078C2.51021 2.80078 2.10512 3.21028 2.10512 3.71078L2.10156 9.16989C2.10156 9.67267 2.50883 10.0808 3.01165 10.0808H3.92085V11.9008L5.74103 10.0808H10.2915C10.792 10.0808 11.2016 9.67128 11.2016 9.17078V3.71078C11.2016 3.21028 10.792 2.80078 10.2915 2.80078H3.01076Z"
                    fill="white"
                />
            </symbol>
            <symbol id="favorite" viewBox="0 0 14 14">
                <path
                    d="M4 2C3.93094 2 3.86394 2.00719 3.79883 2.02051C3.34304 2.11372 3 2.51656 3 3V12L7 10L11 12V3C11 2.93125 10.9929 2.86377 10.9795 2.79883C10.8996 2.40815 10.5918 2.1004 10.2012 2.02051C10.1361 2.00719 10.0691 2 10 2H4Z"
                    fill="white"
                />
            </symbol>
            <symbol id="close" viewBox="0 0 32 32">
                <path
                    d="M23.6095 10.2761C24.1302 9.75544 24.1302 8.91122 23.6095 8.39052C23.0888 7.86983 22.2446 7.86983 21.7239 8.39052L16 14.1144L10.2761 8.39052C9.75544 7.86983 8.91122 7.86983 8.39052 8.39052C7.86983 8.91122 7.86983 9.75544 8.39052 10.2761L14.1144 16L8.39052 21.7239C7.86983 22.2446 7.86983 23.0888 8.39052 23.6095C8.91122 24.1302 9.75544 24.1302 10.2761 23.6095L16 17.8856L21.7239 23.6095C22.2446 24.1302 23.0888 24.1302 23.6095 23.6095C24.1302 23.0888 24.1302 22.2446 23.6095 21.7239L17.8856 16L23.6095 10.2761Z"
                    fill="white"
                />
            </symbol>
            <symbol id="redmine" viewBox="0 0 24 24">
                <path
                    fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.7995 7.09316L8.00852 3.74396L10.7341 3H13.4045L15.8233 3.74396L13.4045 7.09316H10.7995ZM6.8854 9.50706L3.16406 7.46001L7.07147 4.29688L9.86247 7.46001L6.8854 9.50706ZM0.925781 13.0445L5.57745 14.1612L6.69417 10.2535L2.78645 8.20703L0.925781 13.0445ZM0 19.3714H5.20987L5.58201 14.9055L0.743958 13.7894L0 19.3714ZM23.9991 19.3714H18.7898L18.4171 14.9055L23.2551 13.7894L23.9991 19.3714ZM18.4208 14.1612L23.0725 13.0445L21.2118 8.20703L17.3047 10.2535L18.4208 14.1612ZM20.8465 7.46001L17.1252 9.50706L14.1484 7.46001L16.9394 4.29688L20.8465 7.46001Z"
                    fill="#E04E39"
                />
            </symbol>
            <symbol id="telegram" viewBox="0 0 24 24">
                <path
                    d="M20.3027 2.98601C20.0138 2.99858 19.7486 3.08169 19.5156 3.17351C19.3004 3.25827 18.5275 3.58311 17.2969 4.0993C16.0662 4.61548 14.4355 5.29987 12.6973 6.03094C9.22084 7.49306 5.31424 9.13776 3.3125 9.98015C3.24376 10.0088 2.96459 10.0943 2.65625 10.3298C2.3472 10.5658 2.00391 11.0781 2.00391 11.6384C2.00391 12.0903 2.22952 12.5506 2.50195 12.8141C2.77439 13.0777 3.05049 13.2009 3.28125 13.2927V13.2907C4.06128 13.6015 6.39069 14.5336 6.94531 14.7555C7.14204 15.3451 7.98659 17.8689 8.1875 18.5036H8.18555C8.32756 18.9528 8.46598 19.2456 8.6582 19.4899C8.75431 19.6121 8.86904 19.7228 9.00977 19.8102C9.06376 19.8438 9.12355 19.8698 9.18359 19.8942C9.19196 19.8978 9.20059 19.8986 9.20898 19.902L9.18555 19.8962C9.20296 19.9032 9.21858 19.9135 9.23633 19.9196C9.26524 19.9296 9.28529 19.9292 9.32422 19.9372C9.46122 19.9793 9.59798 20.0075 9.72461 20.0075C10.2682 20.0075 10.6016 19.7126 10.6016 19.7126L10.623 19.697L12.9707 17.7106L15.8457 20.3708C15.8982 20.4449 16.3096 21.0016 17.2617 21.0016C17.8298 21.0016 18.279 20.7204 18.5664 20.4255C18.8538 20.1305 19.0328 19.8287 19.1133 19.4196L19.1152 19.4177C19.1794 19.0875 21.9316 5.26726 21.9316 5.26726L21.9258 5.2907C22.0114 4.90836 22.0367 4.5386 21.9355 4.1618C21.8344 3.78499 21.5613 3.41726 21.2324 3.2243C20.9036 3.03133 20.5917 2.97344 20.3027 2.98601ZM19.9082 5.17547C19.7994 5.7215 17.334 18.1075 17.1816 18.8845L13.0293 15.0427L10.2227 17.4157L11 14.3766C11 14.3766 16.3625 8.9485 16.6855 8.6325C16.9455 8.3795 17 8.29081 17 8.20281C17 8.08581 16.9398 8.00164 16.8008 8.00164C16.6758 8.00164 16.506 8.12142 16.416 8.17742C15.2724 8.89042 10.4013 11.6663 8.00586 13.029C7.8617 12.9712 5.6974 12.1021 4.53125 11.6364C6.60551 10.7634 10.1612 9.26745 13.4727 7.87469C15.2106 7.14375 16.8408 6.45874 18.0703 5.94305C19.1082 5.50774 19.6495 5.28234 19.9082 5.17547ZM17.1523 19.027H17.1543C17.1543 19.0273 17.1524 19.0326 17.1523 19.0329C17.1536 19.0264 17.1514 19.0321 17.1523 19.027Z"
                    fill="#E04E39"
                />
            </symbol>
            <symbol id="plan" viewBox="0 0 24 24">
                <path
                    d="M6 1V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16V3H8V1H6ZM5 8H19V19H5V8ZM7 11V13H11V11H7ZM13 13V15H17V13H13ZM7 15V17H11V15H7Z"
                    fill="#E04E39"
                />
            </symbol>
            <symbol id="practice" viewBox="0 0 24 24">
                <path
                    d="M12 2C6.489 2 2 6.489 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM12 4C16.4301 4 20 7.56988 20 12C20 16.4301 16.4301 20 12 20C7.56988 20 4 16.4301 4 12C4 7.56988 7.56988 4 12 4ZM11 7V9H13V7H11ZM11 11V17H13V11H11Z"
                    fill="#E04E39"
                />
            </symbol>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'SvgStatic',
};
</script>

<style>
.svg-box {
    display: none
}
</style>