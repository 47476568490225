import { render, staticRenderFns } from "./ArrowRigth.vue?vue&type=template&id=3ab49e50&scoped=true&"
import script from "./ArrowRigth.vue?vue&type=script&lang=js&"
export * from "./ArrowRigth.vue?vue&type=script&lang=js&"
import style0 from "./ArrowRigth.vue?vue&type=style&index=0&id=3ab49e50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab49e50",
  null
  
)

export default component.exports