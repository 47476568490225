<template>
  <div class="items">
    <div class="title" v-if="items.length > 0">{{getCount}}</div>
    <div class="title" v-if="items.length === 0">Ничего не найдено, попробуйте изменить запрос.</div>
    <div class="block" v-for="item in items" :key="item.id">
      <div class="name">
        <router-link :to="item.link" class="link">{{item.name}}</router-link>
      </div>
      <div class="subtext">http://{{getHost}}{{item.link}}</div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';

export default {
  name: 'Items',
  props: {
    items: Array,
    totalCount: Number
  },
  computed: {
    getHost() {
      return window.location.hostname
    },
    getCount() {
      if (this.totalCount > 9 && this.totalCount < 20) {
        return `Найдено ${this.totalCount} результатов`
      } else {
        const last = String(this.totalCount).slice(-1)
        if (last == 0 || (last >= 5 && last <= 9)) {
          return `Найдено ${this.totalCount} результатов`
        } else if (last == 1) {
          return `Найден ${this.totalCount} результат`
        }else {
          return `Найдено ${this.totalCount} результата`
        }
      }
    }
  },
  methods: {
    ...mapActions('search', [
      'getApi',
      'getApiOnce',
    ])
  },
  mounted() {
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.items {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  min-height: 27vw;
  .title {
    font-size: 1vw;
    font-weight: 700;
    padding-bottom: 1.5vw;
  }
  .block {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding-bottom: 1vw;
    &:last-child {
      padding-bottom: 0;
    }
    .name {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      .link {
        font-size: 1.1vw;
        color: #e04e39;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .subtext {
      font-size: 0.8vw;
      margin-top: 0.5vw;
      color: rgba(44, 42, 41, 0.7);
    }
  }
}
@media only screen and (max-width: 900px) {
  .items {
    min-height: auto;
    .title {
      font-size: 2vw;
      padding-bottom: 3vw;
    }
    .block {
      padding-bottom: 2vw;
      &:last-child {
        padding-bottom: 0;
      }
      .name {
        .link {
          font-size: 2.1vw;
        }
      }
      .subtext {
        font-size: 1.8vw;
        margin-top: 1.5vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .items {
    .title {
      font-size: 4vw;
      padding: 6vw 0;
    }
    .block {
      padding-bottom: 6vw;
      &:last-child {
        padding-bottom: 0;
      }
      .name {
        .link {
          font-size: 4.5vw;
        }
      }
      .subtext {
        font-size: 3.5vw;
        margin-top: 2vw;
      }
    }
  }
}
</style>
