<template>
  <div class="sidebar">
    <div class="title">Новости</div>
  <!--  <router-link :to="{ name: 'NewsIndex', params: { type: group.type }}" class="link" v-for="group in groups" :key="group.name">
      {{group.name}}
    </router-link> -->
    <div class="selects">
      <!-- <div class="select left groups">
        <span>Выберите категорию</span>
        <ArrowDownIcon />
      </div> -->
      <Calendar class="calendar" @date="$emit('setdate', $event)" />
      <Selectbox :options="regions" default="Выберите регион" keydef="type" @set="$emit('setregion', $event)" class="selectA" />
    </div>
  </div>
</template>

<script>
// import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import Selectbox from '@/components/elements/Selectbox.vue'
import Calendar from '@/components/elements/Calendar.vue'

export default {
  name: 'Sidebar',
  components: {
    //ArrowDownIcon,
    Selectbox,
    Calendar
  },
  props: {
    groups: Array,
    regions: Array,
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-top: 1vw;
  .title{
    display: none;
  }
  .link{
    font-size: 1vw;
    color: #E04E39;
    margin-bottom: 1vw;
  }
  .selects{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, column);
    padding-bottom: 2vw;
    .calendar{
      margin-top: 1vw;
    }
    .selectA{
      margin-top: 1vw;
    }
    .picker{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      border: 0.04vw solid #D0C7C2;
      padding: 0.7vw 1vw;
      border-radius: 0.3vw;
      margin-top: 1vw;
      margin-bottom: 1vw;
      span{
        padding-left: 0.7vw;
        font-size: 0.8vw;
        color: rgba(44, 42, 41, 0.7);
      }
    }
    .select{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      border: 0.04vw solid #D0C7C2;
      padding: 0.7vw 1vw;
      border-radius: 0.3vw;
      margin-top: 1vw;
      span{
        @include box(100%, auto, relative, null, null, null, null);
        padding-right: 0.7vw;
        font-size: 0.8vw;
        color: rgba(44, 42, 41, 0.7);
      }
      &.groups{
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .sidebar{
    padding: 0 3vw;
    .title{
      font-size: 4vw;
      display: flex;
      padding-bottom: 2vw;
    }
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, row);
      .calendar{
        @include box(calc(33.3% - 2vw), auto, relative, null, null, null, null);
        margin-top: 1vw;
        margin-right: 1.5vw;
      }
      .selectA{
        @include box(calc(33.3% - 2vw), auto, relative, null, null, null, null);
        margin-top: 1vw;
        margin-left: 1.5vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar{
    padding: 0 6vw;
    .title{
      font-size: 8vw;
      display: flex;
      padding-bottom: 6vw;
    }
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, column);
      .calendar{
        @include box(100%, auto, relative, null, null, null, null);
        margin-top: 2vw;
        margin-right: 0;
      }
      .selectA{
        @include box(100%, auto, relative, null, null, null, null);
        margin-top: 4vw;
        margin-left: 0;
      }
    }
  }
}
</style>
