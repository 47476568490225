<template>
  <svg width="294" height="400" viewBox="0 0 294 400" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoBigIcon">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="147" width="147" height="253">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 147.426H146.902V399.268H0V147.426Z" fill="white"/>
    </mask>
    <g mask="url(#mask0)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.9372 253.892V147.426H-0.00195312V253.842C-0.00195312 291.35 20.0098 326.017 52.4866 344.74L146.901 399.268V302.363L94.4374 272.102C87.9545 268.322 83.9372 261.401 83.9372 253.892Z" fill="#C59368"/>
    </g>
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="146" y="0" width="148" height="358">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M146.898 0.519531H293.768V357.289H146.898V0.519531Z" fill="white"/>
    </mask>
    <g mask="url(#mask1)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M146.898 357.289L241.313 302.773C273.79 284.025 293.776 249.383 293.776 211.863V0.519531H146.898V84.4962H209.85V211.913C209.85 219.435 205.845 226.368 199.349 230.11L146.898 260.397V357.289Z" fill="#ED5338"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M146.898 0.523438L0.0078125 147.426H146.898V0.523438Z" fill="#562212"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoBigIcon',
}
</script>

<style lang="scss" scoped>
.logoBigIcon{
  @include box(12vw, auto, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoBigIcon{
    @include box(12vw, auto, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoBigIcon{
    @include box(24vw, auto, relative, null, null, null, null);
  }
}
</style>
