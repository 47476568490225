<template>
  <div class="contest-page">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'ContestPage',
}
</script>

<style lang="scss" scoped>
.contest-page {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
}
</style>
