<template>
    <div class="container">
        <div class="sub__title">Какие долги можно списать через процедуру внесудебного банкротства?</div>
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        Какие долги можно списать через процедуру внесудебного банкротства?
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__table">
                        <div class="row row__left"><div class="text__title"><div class="icon_title"><Money /></div>Общий размер долгов</div></div>
                        <div class="row row_debts"><div class="text__debts">от 50 000 руб. до 500 000 руб.</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row row__left"><div class="text__title"><div class="icon_title"><DebtsIcon /></div>Включаются долги</div></div>
                        <div class="row"><div class="text">- по займам и кредитам (включая сумму кредита и
                            начисленные проценты за пользование кредитом);<br><br>
                            - по алиментам;<br><br>
                            - по договорам поручительства;<br><br>
                            - по возмещению вреда жизни и здоровью;<br><br>
                            - по налогам, сборам и другим обязательным платежам в бюджет.</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row row__left"><div class="text__title"><Clip class="icon_title" />При этом не имеет значения</div></div>
                        <div class="row"><div class="text">- имеется просрочка или нет;<br><br>
                            - обращался кредитор в суд или нет.</div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Money from "../../icons/bankrutcy/Money";
    import DebtsIcon from "../../icons/bankrutcy/Debts";
    import Clip from "../../icons/bankrutcy/Clip";
    export default {
        name: "Debts",
        components: {
            Clip,
            Money,
            DebtsIcon,
        }
    }
</script>

<style lang="scss" scoped>
    .sub__title{
        display: none;
    }
    .sections {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding: 80px 0;
        .rows {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .row__name {
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
            .row__table {
                @include box(75%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                align-items: flex-start;
                padding-left: 15px;
                padding-right: 15px;
                .rows__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, row);
                    .row_debts {
                        padding-top: 0!important;
                    }
                    .row {
                        @include box(50%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        .title__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: rgba(44, 42, 41, 0.7);
                            font-weight: bold;
                        }
                        .icon_title {
                            float: left;
                            color: #C39367;
                            margin-right: 16px;
                        }
                        .text__title {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                            color: #2C2A29;

                        }
                        .text__debts {
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 36px;
                            color: #2C2A29;
                        }
                        .text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .container {
            padding: 0 15px;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-top: 80px;
            display: block;
        }

        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-top: 24px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__name {
                    display: none;
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        .row_debts {
                            padding-top: 0!important;
                        }
                        .row__left {
                            width: 33%!important;
                        }
                        .row {
                            @include box(66%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 0;
                            .title__row {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .icon_title {
                                float: left;
                                color: #C39367;
                                margin-right: 16px;
                            }
                            .text__title {
                                font-size: 19px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;

                            }
                            .text__debts {
                                padding-top: 4px;
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            padding: 0 15px;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-top: 80px;
            display: block;
        }

        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-top: 24px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__name {
                    display: none;
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        display: block!important;
                        .row_debts {
                            padding-top: 0!important;
                        }
                        .row__left {
                            width: 100%!important;
                        }
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 0;
                            .title__row {
                                text-align: left;
                                font-size: 18px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                            }
                            .icon_title {
                                float: left;
                                color: #C39367;
                                margin-right: 16px;
                            }
                            .text__title {
                                font-size: 19px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;

                            }
                            .text__debts {
                                padding-top: 4px;
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
