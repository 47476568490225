<template>
    <div class="standartizationIndex">
        <Header v-if="header" :header="header" />
        <Tabs :tabs="types" @set="setType" class="tabs" />
        <div class="container cont" v-for="item of items" v-bind:key="item.type">
            <div style="width: 100%;" v-if="getType === item.type">
                <ContentStand :content-prop="item.content" :file="item.files" />
            </div>
        </div>
    </div>
</template>

<script>
    import Tabs from "@/components/views/standardization/view/Tabs";
    import Header from '@/components/views/standardization/view/Header.vue';
    import ContentStand from "@/components/views/standardization/view/Content";

    import {mapActions, mapGetters, mapState} from 'vuex';

    export default {
        name: 'StandardizationIndex',
        components: {
            //Breadcrumbs,
            //Sidebar,
            //Items,
            //Pagination,
            Tabs,
            Header,
            ContentStand,
        },
        computed: {
            ...mapGetters('standardization', [
                'getType',
                'getFiles',
            ]),
            ...mapState('standardization', [
                'header',
                'items',
                'types',
                'perPage',
                'page',
                'totalCount'
            ])
        },
        methods: {
            ...mapActions('standardization', [
                'setType',
                'setPage',
                'getApi'
            ]),
            setPageAction(page) {
                this.$router.push({ name: 'DocumentsIndex', params: { type: this.getType.type }, query: { page: page }})
                this.setPage(page)
            }
        },
        mounted() {
            this.setPage(this.$route.query.page > 0 ? Number(this.$route.query.page) : 1);
            this.setType(this.$route.params.type);
            this.getApi({})
        }
    }
</script>

<style lang="scss" scoped>
.standartizationIndex {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding-top: 3vw;
    .breadcrumbs {
        padding: 2vw 0;
    }
    .container {
        @include flex(left, top, row);
        .row {
            &.min {
                @include box(22%, auto, relative, null, null, null, null);
            }
            &.max {
                @include box(78%, auto, relative, null, null, null, null);
                padding-top: 0.5vw;
                .top {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, column);
                    .title {
                        font-size: 2vw;
                        padding-left: 2vw;
                        font-weight: 700;
                    }
                }
                .pagination {
                    padding: 2vw 2vw;
                }
                &.mobile {
                    display: none;
                    .top {
                        display: flex;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    .standartizationIndex {
        padding-bottom: 0;
        padding-top: 0;
        .breadcrumbs {
            padding: 4vw 4.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 3vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        display: none;
                        .title {
                            font-size: 3vw;
                            padding-left: 1.5vw;
                            padding-bottom: 2vw;
                        }
                    }
                    .pagination {
                        padding: 4vw 3vw;
                    }
                    &.mobile {
                        display: flex;
                        .top {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .standartizationIndex {
        padding-top: 0;
        .breadcrumbs {
            padding: 6vw 6.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 6vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        .title {
                            font-size: 8vw;
                            padding-left: 1vw;
                        }
                    }
                    .pagination {
                        padding: 6vw 1vw;
                    }
                }
            }
        }
    }
}
</style>
