//page types
export const PAGE_TYPE_DOCUMENTS = 'contest_documents';
export const PAGE_TYPE_INFORMATION = 'contest_information';
export const PAGE_TYPE_NEWS = 'contest_news';
export const PAGE_TYPE_REGIONAL_NEWS = 'contest_regional_news';
export const PAGE_TYPE_WINNERS = 'contest_winners';

//page links
export const PAGES = [
  {link: '/contest', name: 'Информация', type: PAGE_TYPE_INFORMATION, icon: 'Info'},
  {link: '/contest/news', name: 'Новости', type: PAGE_TYPE_NEWS, icon: 'News'},
  {link: '/contest/documents', name: 'Документы', type: PAGE_TYPE_DOCUMENTS, icon: 'Documents'},
  {link: '/contest/winners', name: 'Победители', type: PAGE_TYPE_WINNERS, icon: 'Winners'},
  {link: '/contest/regional-news', name: 'Новости региональных конкурсов', type: PAGE_TYPE_REGIONAL_NEWS, icon: 'RegionalNews'},
];
