<template>
  <svg class="checkIcon" :style="{ backgroundColor: bgColor }" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 11.8341 27.5082 9.78308 26.6301 7.95253L14.0195 20.5632L5.54898 12.4007L7.63066 10.2404L13.98 16.359L25.0055 5.34576C22.442 2.09032 18.4651 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"/>
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: {
    fill: {type: String, default: '#E9D7C1'},
    bgColor: {type: String, default: ''},
  },
}
</script>

<style lang="scss" scoped>
.checkIcon {

}
/*@media only screen and (max-width: 900px) {
  .telegramIcon2 {
    @include box(7vw, 7vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .telegramIcon2 {
    @include box(10vw, 10vw, relative, null, null, null, null);
  }
}*/
</style>
