<template>
    <div class="tabs">
        <div class="container">
            <div class="block" v-for="(tab, i) in getTabs" :key="i" :class="{active: tab.select}">
                <router-link :to="{ name: 'StandardizationIndex', params: { type: tab.type } }" class="tab">
                    <span @click="$emit('set', tab.type)">{{tab.name}}</span>
                </router-link>
            </div>
        </div>
        <div class="select">
            <Selectbox :options="getTabs" default="Выберите раздел" keydef="type" @set="$emit('set', $event)" />
        </div>
    </div>
</template>

<script>
    import Selectbox from '@/components/elements/Selectbox.vue'

    export default {
        name: 'Tabs',
        components: {
            Selectbox
        },
        props: {
            tabs: Array,
        },
        computed: {
            excludedTabs() {
                return ['_form_partition_optimization'];
            },
            getTabs() {
                return this.tabs.filter(tab => !this.excludedTabs.includes(tab.type));
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tabs{
        @include box(100%, auto, fixed, null, 3vw, null, null);
        @include flex(left, top, row);
        background-color: #F6F2EC;
        box-shadow: 0 0.3vw 0.6vw rgba(0, 0, 0, 0.198864);
        z-index: 50;
        .container{
            @include flex(left, top, row);
            .block{
                @include box(auto, auto, relative, null, null, null, null);
                @include flex(center, center, row);
                padding: 1.2vw 0;
                margin-right: 2vw;
                .tab{
                    font-size: 0.85vw;
                    color: #623B2A;
                    transition: 0.2s;
                    &:hover{
                        color: #E04E39;
                    }
                }
                &.active{
                    &:after{
                        content: '';
                        @include box(100%, 0.2vw, absolute, 0, null, null, 0);
                        background-color: #E04E39;
                    }
                    .tab{
                        color: #E04E39;
                    }
                }
            }
        }
        .select{
            display: none;
        }
    }
    @media only screen and (max-width: 900px) {
        .tabs{
            position: absolute;
            background-color: transparent;
            box-shadow: none;
            .container{
                display: none;
            }
            .select{
                padding: 380px 7.5vw 0;
                display: block;
                @include box(100%, null, relative, null, null, null, null);
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .tabs{
            position: absolute;
            background-color: transparent;
            box-shadow: none;
            .container{
                display: none;
            }
            .select{
                padding: 160px 13.5vw 0;
                display: block;
                @include box(100%, null, relative, null, null, null, null);
            }
        }
    }
</style>
