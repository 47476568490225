import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from "vue-axios"
import axios from "axios"
import { Tooltip, Dialog } from 'element-ui'
import 'element-ui/lib/theme-chalk/tooltip.css'
import 'element-ui/lib/theme-chalk/dialog.css'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = '/api'

Vue.axios.defaults.headers.common['Access-Control-Expose-Headers'] = 'X-Pagination-Total-Count'

import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

Vue.use(Tooltip)
Vue.use(Dialog)

router.beforeEach((to, from, next) => {
  store.commit('setMenuSidebar', false)
  if (to.name !== from.name || (!('id' in to.params || 'id' in from.params) || to.params?.id !== from.params?.id)) {
    window.scrollTo(0, 0)
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
