<template>
  <div @click="toggleMenu" class="sort-select content__item-select">
    <div class="content__item-block">
      <p class="content__item-sort">Сортировать по</p>
      <p class="content__item-name">{{ selectedType.label }}</p>
    </div>
    <div class="content__item-icons">
      <img class="sort-icon" src="@/assets/searchBar/sort.svg" alt />
      <img src="@/assets/undecomposed/arrow-down.svg" alt />
    </div>
    <ul v-if="isMenuShown" class="content__item-drop">
      <li v-for="type in options" :key="type.id" :class="selectedTypeClasses(type)">
        <div @click="sortPractices(type)">{{ type.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SortSelect',
  computed: {
    selectedType() {
      return this.options.find(el => el.id === this.sortTypeId)
    }
  },
  props: {
    options: Array
  },
  data: () => ({
    sortTypeId: 1,
    isMenuShown: false,
    sortTypes: [
    ]
  }),
  methods: {
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    closeMenu(e) {
      if (!e.target.closest('.sort-select')) this.isMenuShown = false
    },
    sortPractices(type) {
      this.sortTypeId = type.id
      this.$emit('change', type)
    },
    selectedTypeClasses(type) {
      return { 'content__item-drop--active': type.id === this.sortTypeId }
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.content__item {
  @media only screen and (max-width: 1170px) {
    width: 575px;
  }

  @media only screen and (max-width: 861px) {
    width: 100%;
  }

  &-drop {
    position: absolute;
    top: 44px;
    right: 0;
    background: #ffffff;
    border: 1px solid #e9d7c1;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 50;
    padding: 20px;
    width: 100%;
    height: fit-content;
    display: block;

    @media only screen and (max-width: 861px) {
      width: 100%;
      padding: 10px;
    }

    li {
      margin: 0 0 12px;
      padding-left: 0;
      position: static;

      &:hover {
        a {
          color: #2c2a29;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        display: none;
      }

      a {
        font-size: 14px;
        transition: 300ms;
        color: #ea543a;
      }
    }

    &--active {
      a {
        color: #2c2a29 !important;
      }
    }
  }
}
.sort-icon {
  display: block;
  margin-right: 10px;
}
</style>