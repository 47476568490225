<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="phoneIcon">
    <path d="M5.512 0.976999C5.527 1.607 5.564 2.4 5.652 2.901C5.748 3.443 5.934 4.095 6.077 4.554C6.189 4.914 6.09 5.306 5.824 5.573L3.62 7.79C4.427 9.322 5.367 10.549 6.412 11.588C7.451 12.633 8.678 13.573 10.21 14.38L12.428 12.176C12.696 11.91 13.087 11.811 13.447 11.923C13.905 12.065 14.558 12.252 15.1 12.348C15.601 12.436 16.393 12.473 17.024 12.488C17.568 12.501 18 12.945 18 13.49V17C18 17.438 17.672 18 17 18C12.461 18 8.126 16.16 4.966 13.034C1.84 9.874 0 5.539 0 1C0 0.327999 0.562 0 1 0H4.51C5.055 0 5.499 0.431999 5.512 0.976999Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>
</template>

<script>
  export default {
    name: "List"
  }
</script>

<style lang="scss" scoped>
.phoneIcon {
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .phoneIcon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .phoneIcon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
