<template>
  <div class="other">
    <div class="container">
      <div class="row min">
        <div class="title">МФЦ рядом</div>
      </div>
      <div class="row max">
        <div class="item" v-for="item in getOther" :key="item.id">
          <div class="map">
            <yandex-map :settings="settings" :coords="[item.lat, item.lon]" :zoom="zoom" :controls="controls" class="ymap">
              <ymap-marker
                :marker-id="item.id"
                :coords="[item.lat, item.lon]"
                :icon="markerIcon"
              />
            </yandex-map>
          </div>
          <router-link :to="{ name: 'MfcView', params: {id: item.id} }" class="name">{{item.address}}</router-link>
          <div class="km">{{ item.distanceFromCurrent }} км</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'Other',
  props: {
    items: Array,
    currentPoint: Object,
  },
  components: {
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      settings: {
        apiKey: 'cdb58544-05a1-44c3-9951-1af68ea958ce',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      controls: ['zoomControl'],
      zoom: 12,
      markerIcon: {
        layout: 'islands#dotIcon',
      }
    }
  },
  computed: {
    ...mapGetters('mfc', [
      'getOther'
    ]),
    ...mapState('mfc', [
      'regions',
      'defaultRegion',
    ]),
    getPointRegion() {
      if (this.regions.length && this.currentPoint) {
        return this.regions.find(region => region.region === this.currentPoint.region).type;
      }
      return null;
    },
  },
  methods: {
    ...mapActions('mfc', [
      'setRegion',
    ]),
  },
  mounted() {
    if (this.getPointRegion) {
      this.setRegion({value: this.getPointRegion});
    }
  },
}
</script>

<style lang="scss" scoped>
.other{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .container{
    @include flex(left, top, row);
    .row{
      &.min{
        @include box(22%, auto, relative, null, null, null, null);
        @include flex(left, top, row);
        .title{
          font-size: 1.1vw;
          font-weight: 700;
        }
      }
      &.max{
        @include box(78%, auto, relative, null, null, null, null);
        @include flex(left, top, row);
        padding-left: 2vw;
        .item{
          @include box(33.3%, auto, relative, null, null, null, null);
          &:nth-child(1) {
            margin-right: 2vw;
          }
          &:nth-child(2) {
            margin: 0 1vw;
          }
          &:nth-child(3) {
            margin-left: 2vw;
          }
          .map{
            @include box(100%, 10vw, relative, null, null, null, null);
            .ymap{
              @include box(100%, 100%, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 1vw;
            padding: 1vw 0;
            color: #E04E39;
            display: flex;
          }
          .km{
            font-size: 0.8vw;
            color: rgba(44, 42, 41, 0.7);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .other{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .container{
      @include flex(left, top, column);
      padding: 0 4vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          .title{
            font-size: 3vw;
            font-weight: 700;
            padding-bottom: 4vw;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          padding-left: 0;
          .item{
            @include box(33.3%, auto, relative, null, null, null, null);
            &:nth-child(1) {
              margin-right: 2vw;
            }
            &:nth-child(2) {
              margin: 0 1vw;
            }
            &:nth-child(3) {
              margin-left: 2vw;
            }
            .map{
              @include box(100%, 20vw, relative, null, null, null, null);
              iframe{
                @include box(100%, 100%, relative, null, null, null, null);
              }
            }
            .name{
              font-size: 2vw;
              padding: 2vw 0;
              color: #E04E39;
            }
            .km{
              font-size: 2vw;
              color: rgba(44, 42, 41, 0.7);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .other{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .container{
      @include flex(left, top, column);
      padding: 0 6vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          .title{
            font-size: 5vw;
            font-weight: 700;
            padding-bottom: 6vw;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          padding-left: 0;
          .item{
            @include box(100%, auto, relative, null, null, null, null);
            &:nth-child(1) {
              margin-right: 0;
            }
            &:nth-child(2) {
              margin: 0 0;
            }
            &:nth-child(3) {
              margin-left: 0;
            }
            margin-bottom: 4vw;
            .map{
              @include box(100%, 40vw, relative, null, null, null, null);
              iframe{
                @include box(100%, 100%, relative, null, null, null, null);
              }
            }
            .name{
              font-size: 4vw;
              padding: 4vw 0;
              color: #E04E39;
            }
            .km{
              font-size: 4vw;
              color: rgba(44, 42, 41, 0.7);
            }
          }
        }
      }
    }
  }
}
</style>
