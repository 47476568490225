<template>
  <div class="content__sidebar-content" :id="'date-input-' + _uid">
    <div class="content__sidebar-select content__sidebar-select--first" @click="toggleMenu">
      <div data-info="CategoryInfoCaption" class="content__sidebar__select--block">
        <div>{{ label || 'Интервал дат' }}</div>
        <div>
          <b>{{ dateSelected }}</b>
        </div>
      </div>
      <img src="@/assets/undecomposed/arrow-down.svg" alt />
    </div>

    <div v-if="isMenuShown" class="content__sidebar-drop content__sidebar-drop--first active">
      <div class="content__sidebar-drop--header">
        <p class="content__sidebar-head" data-info="MFCOffices">Даты</p>
      </div>

      <div class="content__sidebar-block">
        <DateInput title-prefix="c " :value.sync="selectedDates.from" />
        <DateInput title-prefix="по " :value.sync="selectedDates.to" />
      </div>
      <div class="content__sidebar-nav">
        <button @click="apply" class="content__sidebar-accept">Применить</button>
        <button @click="resetDates" class="content__sidebar-reset">Сбросить фильтр</button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/elements/DateInput.vue'
import moment from 'moment'

export default {
  name: 'DateSelect',
  components: {
    DateInput,
  },
  computed: {
    dateSelected() {
      const from = this.selectedDates.from ? `с ${moment(this.selectedDates.from).format('DD.MM.YYYY')} ` : ''
      const to = this.selectedDates.to ? `по ${moment(this.selectedDates.to).format('DD.MM.YYYY')}` : ''
      return from + to
    },
  },
  data: () => ({
    isMenuShown: false,
    selectedDates: {
      from: '',
      to: '',
    },
  }),
  props: {
    label: String,
    dates: Object,
  },
  methods: {
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    apply() {
      this.updateValue()
      this.isMenuShown = false
    },
    updateValue() {
      this.$emit('update:value', this.selectedDates)
    },
    resetDates() {
      this.selectedDates.from = ''
      this.selectedDates.to = ''
      this.updateValue()
    },
    closeMenu(e) {
      if (!(e.target.closest('#date-input-' + this._uid) || e.target.closest('.date-picker'))) this.isMenuShown = false
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  },
}
</script>

<style lang="scss" scoped>
.select__search {
  width: 100%;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 44px;
  padding: 0 12px;
  font-size: 14px;

  @media only screen and (lt-width: 861px) {
    height: 30px;
    margin-bottom: 10px;
  }

  &::placeholder {
    color: rgba(44, 42, 41, 0.7);
  }
}

.content__sidebar-block {
  height: fit-content;
  margin-bottom: 0;
  padding-bottom: 0;
}

.select__range-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (lt-width: 861px) {
    display: block;
    margin-bottom: 10px;
  }

  &--second {
    margin-bottom: 0;
    padding: 0 20px;
  }

  input {
    width: 140px;
    height: 44px;
    background: rgba(44, 42, 41, 0.1);
    border: 1px solid #d0c7c2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 14px;
    color: #000;

    @media only screen and (lt-width: 861px) {
      width: 100%;
      margin-bottom: 10px;
      height: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::placeholder {
      color: rgba(44, 42, 41, 0.7);
    }
  }
}

.content__sidebar-drop--header {
  margin: 0 20px;
}
.content__sidebar-reset {
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: rgba(234, 84, 58, 1);
  margin: 2px 0;
}
</style>
