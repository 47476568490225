<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="print">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3V0H16V3H4ZM0 8C0 6.35 1.35 5 3 5H17C18.65 5 20 6.35 20 8V14H16V18H4V14H0V8ZM17 7C17.6 7 18 7.4 18 8C18 8.6 17.6 9 17 9C16.4 9 16 8.6 16 8C16 7.4 16.4 7 17 7ZM14 11H6V16H14V11Z" fill="#E04E39"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.print{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .print{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .print{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
