// states
import axios from 'axios'
import { getNewsQuery } from "@/axios/news";
import { getDatesListFromItems } from "@/helpers/date";

const state = {
  groups: [
    { name: 'Новости МФЦ', type: 'cat1' },
    { name: 'Категория 2', type: 'cat2' },
    { name: 'Категория новостей А', type: 'cat3' },
    { name: 'Новости Z', type: 'cat4' },
  ],
  news: [],
  item: null,
  regions: [],
  dates: [
    /*{ name: 'Декабрь 2020', type: 'date5', dateIn: 1606780800, dateOut: 1609459200, select: false },
    { name: 'Ноябрь 2020', type: 'date4', dateIn: 1604188800, dateOut: 1606780800, select: false },
    { name: 'Октябрь 2020', type: 'date3', dateIn: 1601510400, dateOut: 1604188800, select: false },
    { name: 'Сентябрь 2020', type: 'date2', dateIn: 1598918400, dateOut: 1601510400, select: false },
    { name: 'Август 2020', type: 'date1', dateIn: 1596240000, dateOut: 1598918400, select: false },*/
  ],
  dateIn: '',
  dateOut: '',
  page: 1,
  perPage: 10,
  totalCount: null,
  quiz: null,
}

// getters
const getters = {
  getNewsSortedByDate: state => {
    return [...state.news].sort((a, b) => (b.date && a.date) && b.date - a.date);
  },
}

// actions
const actions = {
  setRegion({ dispatch, state, commit }, value) {
    commit('setRegion', state.regions.map(item => {
      value = value.value ? value.value : value;
      item.select = item.type === value || item.name === value;
      return item;
    }));
    dispatch('getApi', {})
  },
  setDates({ dispatch, state, commit }, value) {
    commit('setDates', state.dates.map(item => {
      if (item.type === value.value.type) {
        item.select = true
        dispatch('setDate', { in: item.dateIn, out: item.dateOut })
      } else {
        item.select = false
      }
      return item
    }))
    if (!Object.keys(value.value).length) {
      dispatch('setDate', { in: '', out: '' })
    }
    dispatch('getApi', {})
  },
  getApi({ dispatch, commit, state }) {
    if (!state.dates.length) {
      dispatch('getAllDates');
    }
    const region = state.regions.filter(item => item.select)[0]?.name
    axios.get(getNewsQuery(region, state.dateIn, state.dateOut, state.page, state.perPage))
      .then(response => {
        commit('setNews', response.data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  getApiOnce({ dispatch, commit }, id) {
    axios.get('/news/' + id)
      .then(response => {
        commit('setItem', response.data);
        //когда появится параметр в ответе новости, поменять 1 на него
        const quizId = state.item.questionnaireId;
        if (quizId != null)
          dispatch('getQuiz', quizId);
      });
  },
  setDate({ commit }, value) {
    commit('setDate', value);
  },
  setPage({ dispatch, commit }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  },
  getApiRegion({ commit }) {
    axios.get('/mfc-find')
      .then(response => {
        commit('setRegion', response.data.map((item, i) => {
          item.type = i + 1
          item.select = false
          item.name = item.region
          return item
        }))
      })
  },
  getAllDates({ commit, state }) {
    const region = state.regions.filter(item => item.select)[0]?.name;
    axios.get(getNewsQuery(region))
      .then(response => {
        commit('setAllDates', response.data);
      });
  },
  getQuiz({ commit }, id) {
    axios.get('/questionnaire/' + id)
      .then(response => {
        commit('setQuiz', response.data);
      });
  },
  sendQuiz({ state }, payload) {
    const params = {
      userId: null,
      id: state.quiz.id,
      answers: payload,
    }
    axios.post('/questionnaire/save', params)
      .then(response => {
        //обработка ответа от сервера, после отправки запроса
        console.log(response);
      });
  },
}

// mutations
const mutations = {
  setRegion(state, value) {
    state.regions = value
  },
  setDate(state, value) {
    state.dateIn = value.in
    state.dateOut = value.out
  },
  setDates(state, value) {
    state.dates = value
  },
  setAllDates(state, value) {
    const rawDates = value.map(item => item.date).sort((a, b) => b - a);
    state.dates = getDatesListFromItems(rawDates);
  },
  setNews(state, value) {
    state.news = value
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setItem(state, value) {
    state.item = value
    state.item.types = value.lid
    /*state.item.types = [
      value.lid,
      value.city,
      'Новости МФЦ'
    ];*/
  },
  setPage(state, value) {
    state.page = value
  },
  setQuiz(state, value) {
    state.quiz = value
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
