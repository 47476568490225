<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M14 5C13.4766 5 12.9414 5.18359 12.5625 5.5625C12.1836 5.94141 12 6.47656 12 7V8H3V28H29V8H20V7C20 6.47656 19.8164 5.94141 19.4375 5.5625C19.0586 5.18359 18.5234 5 18 5H14ZM14 7H18V8H14V7ZM5 10H27V16C27 17.1914 26.1914 18 25 18H7C5.80859 18 5 17.1914 5 16V10ZM9 14V17H11V14H9ZM21 14V17H23V14H21ZM5 19.4375C5.58984 19.7773 6.26953 20 7 20H25C25.7305 20 26.4102 19.7773 27 19.4375V26H5V19.4375Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Bug"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(4vw, 4vw, relative, null, null, null, null);
        }
    }
</style>
