<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M7 5V19H9V7H23V23C23 24.1172 22.1172 25 21 25C19.8828 25 19 24.1172 19 23V20H3V23C3 25.1992 4.80078 27 7 27H21C23.1992 27 25 25.1992 25 23V5H7ZM11 11V13H13V11H11ZM15 11V13H21V11H15ZM11 15V17H13V15H11ZM15 15V17H21V15H15ZM5 22H17V23C17 23.7344 17.2148 24.4062 17.5625 25H7C5.88281 25 5 24.1172 5 23V22Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Debts"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
