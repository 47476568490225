<template>
  <div class="expert-platforms-view-page">
    <PageHeader
      :page-name="pageName"
      :page-title="pageTitle"
      :header-title="pageName"
      :page-sub-title="pageSubTitle"
      :route-path="pagePath"
      :white-text="false"
      :assets="'assets/expertPlatforms'"
    />
    <div class="container">
      <ContentSection>
        <TextContent :title="pageTitle" :content="content" />
        <ChannelsList :channels="items">
          <Telegram2 slot="icon" />
        </ChannelsList>
        <Pagination
          :pages="totalCount"
          :current="page"
          @setpage="setPageAction"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </ContentSection>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/shared/PageHeader'
import TextContent from '@/components/shared/TextContent'
import ChannelsList from '@/components/views/expertPlatforms/ChannelsList'
import Telegram2 from '@/components/icons/Telegram2'
import Pagination from '@/components/Pagination.vue'
import ContentSection from '@/components/shared/ContentSection'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ExpertPlatformsIndex',
  components: {
    Telegram2,
    PageHeader,
    TextContent,
    ChannelsList,
    Pagination,
    ContentSection,
  },
  data() {
    return {
      pageName: 'Экспертные площадки',
      pageTitle: '',
      pageSubTitle:
        'Данный раздел содержит сведения об экспертных площадках в социальных сетях и популярных мессенджерах, посвященных деятельности Центров госуслуг. <br>' +
        'Доступ к данном разделу возможен для авторизованных ЕСИА пользователей с соответствующим правом доступа.<br>' +
        'Для получения доcтупа для иных пользователей просьба обратиться по адресу электронной почты <a style="text-decoration: underline" href="mailto:mfc@csr.ru">mfc@csr.ru</a><br>' +
        'Для включения в перечень новой экспертной площадки в социальных сетях и популярных мессенджерах просьба обратиться по адресу электронной почты <a style="text-decoration: underline" href="mailto:mfc@csr.ru">mfc@csr.ru</a>',
      //pageSubTitle: 'Обеспечение открытости и доступности органов власти и иных организаций, создание для граждан и предпринимателей условий, способствующих повышению эффективности деятельности органов власти и иных организаций',
      pagePath: '/expert-platforms',

      //test
      content: '',
    }
  },
  watch: {},
  computed: {
    ...mapState('expertPlatforms', ['items', 'page', 'totalCount', 'perPage']),
  },
  methods: {
    ...mapActions('expertPlatforms', ['getApi', 'setPage']),
    setPageAction(page) {
      //this.$router.push({ name: 'PracticesIndex', query: { page: page }})
      this.setPage(page)
    },
  },
  mounted() {
    this.getApi({})
  },
}
</script>

<style lang="scss" scoped>
.expert-platforms-view-page {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .container {
    @include flex(left, top, column);
  }
  .pagination {
    padding-top: 1vw;
    padding-bottom: 4vw;
  }
}
@media only screen and (max-width: 900px) {
  .expert-platforms-view-page {
    .container {
      padding: 0 2vw;
    }
    .pagination {
      justify-content: center;
      padding-bottom: 8vw;
    }
  }
}
@media only screen and (max-width: 600px) {
}
</style>
