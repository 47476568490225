<template>
  <div class="map">
    <yandex-map :settings="settings" :coords="coords" :zoom="zoom" class="ymap" ref="ymap" @map-was-initialized="mapWasInitialized">
      <div :key="key">
        <ymap-marker
            v-for="point in points"
            :key="point.id"
            :marker-id="point.id"
            :coords="[point.lat, point.lon]"
            :icon="markerIcon"
        >
          <div slot="balloon">
            <h3>{{ point.name }}</h3>
            <p>{{ point.address }}<br>{{ point.phone }}</p>
            <router-link :to="{ name: 'MfcView', params: {id: point.id} }" class="link">
              {{ balloonLinkText }}
            </router-link>
          </div>
        </ymap-marker>
      </div>
    </yandex-map>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapGetters} from "vuex";

export default {
  name: "Map",
  components: {
    yandexMap,
    ymapMarker,
  },
  props: {
    points: Array,
    regions: Array
  },
  data() {
    return {
      key: 0,
      settings: {
        apiKey: 'cdb58544-05a1-44c3-9951-1af68ea958ce',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      coords: [55.67,37.76],
      zoom: 8,
      maxBoundsZoom: 18,
      currentPoints: [],
      markerIcon: {
        layout: 'islands#dotIcon',
      },
      mapLoaded: false,
      mapInstance: null,
      balloonLinkText: 'перейти на страницу МФЦ',
    }
  },
  watch: {
    points() {
      this.key++;
      this.setMapBounds();
    }
  },
  computed: {
    ...mapGetters('mfc', [
      'getActiveRegion'
    ])
  },
  methods: {
    mapWasInitialized(map) {
      if (map) {
        this.mapLoaded = true;
        this.mapInstance = map;
        this.getGeolocation();
        this.setMapBounds();
      }
    },
    setMapBounds() {
      if (!this.mapLoaded) { return; }
      const map = this.mapInstance;
      const pointsList = this.points.map(point => [point.lat, point.lon]);
      const bounds = window.ymaps.util.bounds.fromPoints(pointsList);
      map.setBounds(bounds).then(() => {
        if (map.getZoom() > this.maxBoundsZoom) {
          map.setZoom(this.maxBoundsZoom);
        }
      });
    },
    getGeolocation() {
      if (!this.mapLoaded || !!this.getActiveRegion) { return; }
      const geolocation = window.ymaps.geolocation;
      geolocation.get({
        provider: 'browser',
        mapStateAutoApply: true
      }).then((result) => {
        window.ymaps.geocode(result.geoObjects.position, {
          kind: 'locality',
          results: 1
        }).then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const administrativeArea = firstGeoObject.getAdministrativeAreas()[0].toLowerCase();
          const foundRegion = this.regions.find(item => {
            return item.name.toLowerCase().indexOf(administrativeArea) > -1;
          });
          if (foundRegion) {
            this.$emit('geolocation-success', {index: -1, value: foundRegion.type});
          }
        });
      });
    },
  },
  async mounted() {
    const settings = { lang: 'ru_RU' };
    await loadYmap(settings);
    //console.log(window.ymaps); // здесь доступен весь функционал ymaps
  },
}
</script>

<style lang="scss" scoped>
.map {
  @include box(75%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  margin: auto;
  margin-top: 1vw;
  .ymap {
    @include box(100%, 54vw, relative, null, null, null, null);
  }
  .link{
    color: #E04E39;
    transition: 0.2s;
    &:hover{
      color: #2C2A29;
    }
  }
}
@media only screen and (max-width: 900px) {
  .map {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    margin: auto;
    margin-top: 2vw;
    .ymap {
      @include box(100%, 54vw, relative, null, null, null, null);
    }
  }
}
@media only screen and (max-width: 600px) {
  .map {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    margin: auto;
    margin-top: 1vw;
    .ymap {
      @include box(100%, 131vw, relative, null, null, null, null);
    }
  }
}
</style>
