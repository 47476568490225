<template>
  <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path d="M4 0V3H22V5H4V8L0 4L4 0Z" fill="#E04E39"/>
  </svg>
</template>

<script>
  export default {
    name: "ArrowLeft1"
  }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(2vw, auto, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(3.5vw, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
      @include box(6.5vw, auto, relative, null, null, null, null);
    }
  }
</style>
