<template>
  <div class="event-description">
    <div class="description-row">
      <span class="description-title min">Описание</span>
      <p class="description-text max">
        Обеспечение комфортных для заявителей всех категорий и удобных для работы сотрудников МФЦ параметров здания, помещения и рабочих мест зон электронных услуг МФЦ (ЦМФЦ);
        <br>
        <br>
        Обеспечение технической возможности оказания консультационной поддержки сотрудниками МФЦ заявителей в решении их вопросов и проблем при работе в зоне электронных услуг МФЦ (ЦМФЦ);
        <br>
        <br>
        Обеспечение информационной поддержки деятельности зоны электронных услуг МФЦ и продвижение практики использования ЦМФЦ среди граждан, а также популяризация электронного способа предоставления государственных, муниципальных и других массовых социально значимых услуг
      </p>
    </div>
    <div class="description-row">
      <span class="description-title min">Участники</span>
      <p class="description-text max">Стив Джобс, <span class="colorText">Леонардо ДаВинчи</span>, Платон</p>
    </div>
    <div class="description-row">
      <span class="description-title min">Организатор</span>
      <p class="description-text max">МФЦ Басманного района</p>
    </div>
    <div class="description-row">
      <span class="description-title min">Место проведения</span>
      <p class="description-text max">Москва, Центросоюзный пер., 13с3, 105082</p>
    </div>
    <div class="description-row">
      <span class="description-title min">Скачать</span>
      <div class="description-text max">
        <a href="#" class="download">
          <doc-album/>
          <div class="download_item">
            <span class="colorText">Презентация</span>
            <span class="download_size">PPTX (17,6 МБ)</span>
          </div>
        </a>
        <hr>
        <a href="#" class="download">
          <doc-vertical/>
          <div class="download_item">
            <span class="colorText">Пресс-релиз</span>
            <span class="download_size">DOC (3,2 МБ)</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import DocAlbum from "../../icons/DocAlbum";
  import DocVertical from "../../icons/DocVertical";
  import { mapGetters } from "vuex";

  export default {
    name: "EventDescription",
    components: {
      DocAlbum,
      DocVertical
    },
    computed: {
      ...mapGetters('events', [
          'getEvents',
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .event-description {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .description-row {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      margin-top: 1.8vw;
      .download {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, center, row);
        svg {
          margin-right: 1vw;
        }
        .download_item {
          @include flex(left, top, column);
          .download_size {
            font-size: 1vw;
          }
        }
      }
      .description-title {
        font-weight: bold;
        font-size: 1.5vw;
        line-height: 1.95vw;
      }
      .description-text {
        margin: 0;
        font-size: 1.25vw;
        line-height: 1.95vw;
        .colorText {
          color: #E04E39;
        }
      }
    }
    .min {
      @include box(23.5%, auto, relative, null, null, null, null);
      margin-right: 1.4vw;
    }
    .max {
      @include box(74.5%, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 900px) {
    .event-description {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      .description-row {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        margin-top: 3.5vw;
        .download {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, center, row);
          margin-top: 1.5vw;
          svg {
            margin-right: 1vw;
          }
          .download_item {
            @include flex(left, top, column);
            .download_size {
              font-size: 2vw;
            }
          }
        }
        .description-title {
          font-weight: bold;
          font-size: 2.5vw;
          line-height: 2.5vw;
          margin-bottom: 1vw;
        }
        .description-text {
          margin: 0;
          font-size: 2vw;
          line-height: 2.5vw;
          .colorText {
            color: #E04E39;
            font-size: 2.5vw;
          }
        }
      }
      .min {
        @include box(100%, auto, relative, null, null, null, null);
        margin-right: 0;
      }
      .max {
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .event-description {
      @include box(95%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      .description-row {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        margin-top: 6.5vw;
        .download {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, center, row);
          margin-top: 0;
          svg {
            margin-right: 4vw;
          }
          .download_item {
            @include flex(left, top, column);
            .download_size {
              font-size: 3.5vw;
              line-height: 8vw;
            }
          }
        }
        .description-title {
          font-weight: bold;
          font-size: 5.5vw;
          line-height: 6vw;
          margin-bottom: 4vw;
        }
        .description-text {
          margin: 0;
          font-size: 5vw;
          line-height: 7vw;
          .colorText {
            color: #E04E39;
            font-size: 4.5vw;
          }
        }
      }
      .min {
        @include box(100%, auto, relative, null, null, null, null);
        margin-right: 0vw;
      }
      .max {
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
</style>
