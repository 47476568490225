export function getTime(date) {
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();

    return hours + ':' + minutes.substr(-2);
}

/**
 * Получение локализованного списка дат
 *
 * @param {Array} dates
 * @return {Object[]}
 */
export function getDatesListFromItems(dates) {
    const months = [];
    return dates.reduce((acc, date, i) => {
        const rawDate = new Date(date * 1000);
        const month = rawDate.getMonth();
        if (months.includes(month)) { return acc; }
        months.push(month);
        const name = rawDate.toLocaleDateString('ru', { year: 'numeric', month: 'long'}); //январь 2021 г.
        const firstDay = new Date(rawDate.getFullYear(), rawDate.getMonth(), 1).getTime() / 1000;
        const lastDay = new Date(rawDate.getFullYear(), rawDate.getMonth() + 1, 0).getTime() / 1000;
        acc.push({
            name: name.charAt(0).toUpperCase() + name.slice(1).replace(' г.', ''), //Январь 2021
            type: 'date' + i,
            dateIn: firstDay,
            dateOut: lastDay,
            select: false,
        });
        return acc;
    }, []);
}

/**
 * Преобразование даты из timestamp в привычный формат
 *
 * @param {Number} date - timestamp, пример: 1611752700
 * @return {String} - пример: "27 января 2021 г., 16:05"
 */
export function getFullDateFromTimestamp(date) {
  return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
}