<template>
  <div class="planning_container">
    <iframe
      src="https://outlook.office365.com/owa/calendar/Bookings2@csr.ru/bookings/"
      width="100%"
      height="2150px"
      scrolling="yes"
      style="border:0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'PlanningPanel',
}
</script>

<style scoped>
.planning_container {
  width: 830px;
}

@media screen and (max-width: 1170px) {
  .planning_container {
    width: 600px;
  }
}

@media screen and (max-width: 861px) {
  .planning_container {
    width: 100%;
  }
}
</style>
