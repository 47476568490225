<template>
  <svg width="63" height="82" viewBox="0 0 63 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 46H49V82L32 67L15 82V46Z" fill="#623B2A"/>
    <circle cx="31.5" cy="31.5" r="24.5" stroke="#C59368" stroke-width="14"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5 54C43.9264 54 54 43.9264 54 31.5C54 19.0736 43.9264 9 31.5 9C19.0736 9 9 19.0736 9 31.5C9 43.9264 19.0736 54 31.5 54ZM32.9511 14.9271C32.6517 14.0057 31.3483 14.0057 31.0489 14.927L27.9587 24.4377C27.8249 24.8497 27.4409 25.1287 27.0077 25.1287H17.0076C16.0389 25.1287 15.6361 26.3683 16.4198 26.9377L24.5101 32.8156C24.8605 33.0702 25.0072 33.5216 24.8733 33.9336L21.7831 43.4443C21.4838 44.3656 22.5383 45.1317 23.322 44.5623L31.4122 38.6844C31.7627 38.4298 32.2373 38.4298 32.5878 38.6844L40.678 44.5623C41.4617 45.1317 42.5162 44.3656 42.2169 43.4443L39.1267 33.9336C38.9928 33.5216 39.1395 33.0702 39.4899 32.8156L47.5802 26.9377C48.3639 26.3683 47.9611 25.1287 46.9924 25.1287H36.9923C36.5591 25.1287 36.1751 24.8497 36.0413 24.4377L32.9511 14.9271Z" fill="#EA543A"/>
  </svg>
</template>

<script>
export default {
  name: "Winners"
}
</script>

<style lang="scss" scoped>
.icon {
  @include box(63px, 82px, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .icon {
    @include box(63px, 82px, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .icon {
    @include box(63px, 82px, relative, null, null, null, null);
  }
}
</style>
