<template>
    <div class="container">
        <div class="title">Что такое процедура внесудебного банкротства?</div>
        <div class="sub__title">Федеральный закон от 26.10.2002 г. No 127-ФЗ «о несостоятельности (банкротстве)»</div>
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        Федеральный закон от 26.10.2002 г. No 127-ФЗ «о несостоятельности (банкротстве)»
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__table rows__title">
                        <div class="row"><div class="title__row">ПАРАМЕТР</div></div>
                        <div class="row"><div class="title__row">СУДЕБНАЯ ПРОЦЕДУРА</div></div>
                        <div class="row"><div class="title__row">ВНЕСУДЕБНАЯ ПРОЦЕДУРА</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="text">Место обращения</div></div>
                        <div class="row"><div class="text">Арбитражный суд</div></div>
                        <div class="row"><div class="text">Многофункциональный центр (далее – МФЦ)</div></div>
                    </div>
                    <div class="rows__table another__color">
                        <div class="row"><div class="text">Размер оплаты</div></div>
                        <div class="row"><div class="text">300 руб. – госпошлина,<br><br>
                            25 тыс. руб. — вознаграждение арбитражного управляющего, допрасходы</div></div>
                        <div class="row"><div class="text">Бесплатно</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="text">Ограничения по размеру долгов</div></div>
                        <div class="row"><div class="text">Для подачи заявления должником — нет</div></div>
                        <div class="row"><div class="text">Общий размер долгов — от 50 до 500 тыс. руб.</div></div>
                    </div>
                    <div class="rows__table another__color">
                        <div class="row"><div class="text">Необходимость наличия постановления об окончании исполнительного производства</div></div>
                        <div class="row"><div class="text">Нет</div></div>
                        <div class="row"><div class="text">Да</div></div>
                    </div>
                    <div class="rows__table">
                        <div class="row"><div class="text">Длительность процедуры</div></div>
                        <div class="row"><div class="text">От нескольких месяцев до нескольких лет</div></div>
                        <div class="row"><div class="text">Ровно 6 месяцев</div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style lang="scss" scoped>
    .sub__title {
        display: none;
    }
    .title {
        margin-top: 80px;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
    }
    .another__color {
        background-color: #F6F2EC;
        border-radius: 4px;
    }
    .sections {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding: 0;
        .rows {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .row__name {
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
            .row__table {
                @include box(75%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                align-items: flex-start;
                padding-left: 15px;
                padding-right: 15px;
                .rows__title {
                    .row {
                        padding-top: 4px!important;
                    }
                }
                .rows__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, row);
                    .row {
                        @include box(33%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        .title__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: rgba(44, 42, 41, 0.7);
                            font-weight: bold;
                        }
                        .text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .container {
            padding: 0 8px;
        }
        .title {
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            margin-top: 24px;
            padding-left: 7px;
        }
        .sub__title {
            display: block;
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-left: 7px;
        }
        .sections {
            .rows {
                .row__name {
                    display: none;
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__title {
                        .row {
                            padding-top: 4px!important;
                        }
                    }
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        .row {
                            @include box(33%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 0;
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                                padding-left: 7px;
                                padding-right: 7px;
                            }
                            .text {
                                padding-left: 7px;
                                padding-right: 7px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            padding: 0 8px;
        }
        .title {
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            margin-top: 24px;
            padding-left: 7px;
        }
        .sub__title {
            display: block;
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-left: 7px;
        }
        .sections {
            .rows {
                .row__name {
                    display: none;
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__title {
                        .row {
                            padding-top: 4px!important;
                        }
                    }
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        display: block!important;
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 8px 0;
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(44, 42, 41, 0.7);
                                font-weight: bold;
                                padding-left: 7px;
                                padding-right: 7px;
                            }
                            .text {
                                padding-left: 7px;
                                padding-right: 7px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
