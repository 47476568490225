<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="list">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 6C1.5 5.17157 2.17157 4.5 3 4.5C3.82843 4.5 4.5 5.17157 4.5 6C4.5 6.82843 3.82843 7.5 3 7.5C2.17157 7.5 1.5 6.82843 1.5 6ZM22 7H7V5H22V7ZM3 10.5C2.17157 10.5 1.5 11.1716 1.5 12C1.5 12.8284 2.17157 13.5 3 13.5C3.82843 13.5 4.5 12.8284 4.5 12C4.5 11.1716 3.82843 10.5 3 10.5ZM22 13H7V11H22V13ZM3 16.5C2.17157 16.5 1.5 17.1716 1.5 18C1.5 18.8284 2.17157 19.5 3 19.5C3.82843 19.5 4.5 18.8284 4.5 18C4.5 17.1716 3.82843 16.5 3 16.5ZM22 19H7V17H22V19Z" fill="#E04E39"/>
  </svg>
</template>

<script>
  export default {
    name: "List"
  }
</script>

<style lang="scss" scoped>
  .list {
  @include box(1vw, 1vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .list {
    @include box(2vw, 2vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .list {
    @include box(4vw, 4vw, relative, null, null, null, null);
    }
  }
</style>
