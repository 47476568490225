<template>
  <div class="button" @click="$emit('click')" :class="[{background: background, white: white, disabled}, size]">
    <List v-if="icon === 'list'" class="icon" />
    <MapIcon v-if="icon === 'map'" class="icon" />
    {{label}}
  </div>
</template>

<script>
import List from "../icons/List";
import MapIcon from "../icons/MapIcon";

export default {
  name: 'Button',
  props: {
    size: String,
    label: String,
    background: Boolean,
    icon: String,
    white: Boolean,
    disabled: Boolean
  },
  components: {
    List,
    MapIcon
  }
}
</script>

<style lang="scss" scoped>
.button{
  @include box(auto, auto, relative, null, null, null, null);
  @include flex(center, center, row);
  border: 0.04vw solid #E04E39;
  color: #E04E39;
  text-transform: uppercase;
  padding: 0.7vw 1vw;
  font-size: 0.8vw;
  border-radius: 0.2vw;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s;
  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &:hover{
    opacity: 0.9;
    transform: scale(0.95);
  }
  &.background{
    background-color: #E04E39;
    color: #FFF;
  }
  &.white{
    border: 0.04vw solid #FFF;
    color: #FFF;
  }
  &.normal {
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      padding: 0 24px;
  }
  .icon{
    margin-right: 1vw;
  }
}
@media only screen and (max-width: 900px) {
  .button{
    padding: 1vw 2vw;
    font-size: 1.5vw;
    border-radius: 0.4vw;
  }
}
@media only screen and (max-width: 600px) {
  .button{
    border: 0.08vw solid #E04E39;
    padding: 3vw 4vw;
    font-size: 3.5vw;
    border-radius: 0.8vw;
  }
}
</style>
