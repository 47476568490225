import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// import modules
import home from './modules/home'
import news from './modules/news'
import events from './modules/events'
import search from './modules/search'
import practices from './modules/practices'
import mfc from "./modules/mfc"
import documents from "./modules/documents"
import models from "./modules/models"
import forums from "./modules/forums"
import experience from "./modules/experience"
import journal from "./modules/journal"
import brand from "./modules/brand";
import standardization from "./modules/standardization";
import expertPlatforms from "./modules/expertPlatforms";
import contestInfo from "./modules/contest/contestInfo";
import contestDocuments from "@/store/modules/contest/contestDocuments";
import contestNews from "@/store/modules/contest/contestNews";
import contestRegionalNews from "@/store/modules/contest/contestRegionalNews";
import contestWinners from "@/store/modules/contest/contestWinners";
import mfcCabinet from './modules/mfcCabinet';

export default new Vuex.Store({
  // states
  state: {
    routes: [],
    menuSidebar: false,
  },
  // actions
  actions: {
    setRoutes({ commit }, routes) {
      commit('setRoutes', routes.filter(route => route.menu && route))
    },
    setMenuSidebar({ commit }, value) {
      commit('setMenuSidebar', value)
    }
  },
  // mutations
  mutations: {
    setRoutes(state, routes) {
      state.routes = routes
    },
    setMenuSidebar(state, value) {
      state.menuSidebar = value
    }
  },
  // modules
  modules: {
    home,
    news,
    events,
    search,
    practices,
    mfc,
    documents,
    models,
    forums,
    experience,
    standardization,
    journal,
    brand,
    expertPlatforms,
    contestInfo,
    contestDocuments,
    contestNews,
    contestRegionalNews,
    contestWinners,
      mfcCabinet
  }
})
