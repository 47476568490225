<template>
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="modelicon">
    <path d="M3 0V1V13.5859L0.707031 15.8789C0.254359 16.3316 0 16.946 0 17.5859V18.5859H0.417969C0.818615 19.3718 1.48072 20 2.41406 20H25.5859C26.5193 20 27.1814 19.3718 27.582 18.5859H28V17.5859C28 16.946 27.7456 16.3316 27.293 15.8789L25 13.5859V0H3ZM5 2H23V13H5V2ZM17.293 4.29297L13 8.58594L10.707 6.29297L9.29297 7.70703L13 11.4141L18.707 5.70703L17.293 4.29297ZM4.41406 15H23.5859L25.8789 17.293C25.9562 17.3703 26 17.4758 26 17.5859C26 17.8266 25.8266 18 25.5859 18H2.41406C2.1734 18 2 17.8266 2 17.5859C2 17.4758 2.04377 17.3703 2.12109 17.293L4.41406 15Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: "ModelIcon2"
}
</script>

<style lang="scss" scoped>
.modelicon {
  @include box(1.3vw, 1.3vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .modelicon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .modelicon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
