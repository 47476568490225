<template>
    <div class="container">
        <div class="sections">
            <div class="sub__title">Проверьте внимательно корректность сведений:</div>
            <div class="rows">
                <div class="row row__title">
                    <div class="title">
                        Проверьте внимательно корректность сведений:
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <Coins class="icon" /><br>
                        Обо всех кредиторах
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <Coins class="icon" /><br>
                        Налогах, сборах и иных обязательных платежах (включая их точное наименование)
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <Coins class="icon" /><br>
                        О правильном размере долга
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Coins from "../../icons/bankrutcy/Coins";

    export default {
        name: "CheckInfo",
        components: {
            Coins,
        }
    }
</script>

<style lang="scss" scoped>
    .sub__title {
        display: none;
    }
    .sections{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        background-color: #E04E39;
        color: #FFFFFF;
        margin-bottom: 40px;
        .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            padding: 40px 0;
            .row{
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .title {
                    text-align: left;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
                .text{
                    text-align: left;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .sub__title {
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            padding-left: 15px;
            display: block;
        }
        .sections{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            background-color: #E04E39;
            color: #FFFFFF;
            margin-bottom: 40px;
            padding: 40px 0;
            .rows{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__title {
                    display: none!important;
                }
                .row{
                    @include box(33%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 15px;
                    padding-right: 15px;
                    .title {
                        text-align: left;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .text{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .sections{
            padding: 4vw 0;
            .rows{
                padding: 0;
                display: block!important;
                .row{
                    @include box(100%, auto, relative, null, null, null, null);
                    .name{
                        font-size: 5vw;
                        padding-top: 4vw;
                    }
                }
            }
        }
    }

</style>



