<template>
  <div class="quiz" v-if="quiz.id && isAuthorized">
    <div class="container">
      <ContentSection :title="quizTitle" no-side-padding>
        <template v-if="done">
          <h4 class="done-text">{{ doneText }}</h4>
        </template>
        <template v-else>
          <div class="pages">
            <button
              class="pages__button"
              v-for="(item, i) in quiz.questions"
              :key="i"
              @click="currentQuestionPageHandler(item.id)"
              :disabled="!answerModel[item.id] && item.id !== currentQuestionId"
              :class="{ active: item.id === currentQuestionId }"
            >{{ i + 1 }}</button>
          </div>
          <div
            class="quiz-item"
            v-for="(question, i) in quiz.questions"
            :key="i"
            :class="{ visible: currentQuestionId === question.id }"
          >
            <div class="quiz-item__question">{{ question.question }}</div>
            <div class="quiz-item__answers quiz-answers">
              <label
                class="quiz-answers__item quiz-answer"
                v-for="(answer, i) in question.answers"
                :key="i"
              >
                <input
                  class="quiz-answer__input"
                  :type="getType(question.multiple)"
                  :name="question.multiple ? `checkbox-${question.id}-${answer.id}` : `radio-${question.id}`"
                  @input="checkHandler(question.id, answer.id, question.multiple)"
                />
                <div class="quiz-answer__text">
                  <div class="icon" :class="[question.multiple ? 'icon-checkbox' : 'icon-radio']"></div>
                  {{ answer.value }}
                </div>
              </label>
            </div>
            <Button
              v-if="i !== quiz.questions.length - 1"
              class="quiz-submit button background"
              :label="nextQuestionText"
              @click="nextQuestionHandler"
              :class="{ disabled: !answerModel[question.id] }"
            ></Button>
            <Button
              v-else
              class="quiz-submit button background"
              :label="submitText"
              @click="submitHandler"
              :class="{ disabled: !answerModel[question.id] }"
            ></Button>
          </div>
        </template>
      </ContentSection>
    </div>
  </div>
</template>

<script>
import ContentSection from '@/components/shared/ContentSection'
import Button from '@/components/elements/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'Quiz',
  components: {
    Button,
    ContentSection,
  },
  props: {
    quiz: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      answerModel: {},
      currentQuestionId: null,
      done: false,
      quizTitle: 'Пройдите опрос',
      doneText: 'Спасибо! Ваше мнение очень важно для нас.',
      nextQuestionText: 'Следующий вопрос',
      submitText: 'Завершить',
    }
  },
  computed: {
    ...mapGetters('mfcCabinet', ['isAuthorized']),
  },
  methods: {
    getType(multiple) {
      return multiple ? 'checkbox' : 'radio'
    },
    checkHandler(questionId, answerId, multiple) {
      if (!this.answerModel[questionId]) {
        this.$set(this.answerModel, questionId, [])
      }
      if (multiple) {
        if (this.answerModel[questionId].includes(answerId)) {
          this.answerModel[questionId].splice(this.answerModel[questionId].indexOf(answerId), 1)
        } else {
          this.answerModel[questionId].push(answerId)
        }
      } else {
        this.answerModel[questionId] = [answerId]
      }
    },
    currentQuestionPageHandler(page) {
      this.currentQuestionId = page
    },
    nextQuestionHandler() {
      if (!this.answerModel[this.currentQuestionId]) {
        return
      }
      const currentIndex = this.quiz.questions.findIndex(item => item.id === this.currentQuestionId)
      this.currentQuestionId = this.quiz.questions[currentIndex + 1].id
    },
    submitHandler() {
      this.done = true
      this.$emit('quiz-submit', this.answerModel)
    },
  },
  mounted() {
    this.currentQuestionId = this.quiz.questions[0].id
  },
}
</script>
<style lang="scss" scoped>
.quiz {
  width: 100%;
  padding-top: 1rem;
  background: #f6f2ec;
  .pages {
    margin-bottom: 1.5rem;
    &__button {
      cursor: pointer;
      border: 1px solid #e04e39;
      background: none;
      border-radius: 0.3rem;
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;
      color: #e04e39;
      &:not(:disabled):hover,
      &.active {
        background: #e04e39;
        color: #fff;
        outline: none;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .quiz-item {
    margin-bottom: 1rem;
    display: none;
    &.visible {
      display: block;
    }
  }
  .quiz-answers {
    border: 1px solid #e04e39;
    border-radius: 0.4rem;
    overflow: hidden;
    margin: 1.5rem 0;
  }
  .quiz-answer {
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #e04e39;
    &__input {
      display: none;
      &:checked {
        + .quiz-answer__text {
          background: #fff;
          .icon {
            background: #e04e39;
            border-color: #e04e39;
            &:after {
              display: block;
            }
          }
        }
      }
    }
    &__text {
      padding: 1rem 1.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .icon {
    flex-shrink: 0;
    position: relative;
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 1rem;
    transition: background 250ms;
    &:after {
      content: "";
      position: absolute;
      display: none;
      transition: background 250ms;
    }
  }
  .icon-radio {
    border: 1px solid #e04e39;
    border-radius: 2rem;
    &:after {
      left: 25%;
      top: 25%;
      width: 50%;
      height: 50%;
      border-radius: 2rem;
      background: #fff;
    }
  }
  .icon-checkbox {
    background: #fff;
    border: 1px solid #e04e39;
    border-radius: 0.307rem;
    &:after {
      left: 0.45rem;
      top: 0.13rem;
      width: 0.34rem;
      height: 0.68rem;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .quiz-submit {
    width: 30%;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .done-text {
    margin-top: 0;
  }
}
@media only screen and (max-width: 900px) {
  .container {
    padding: 0 3vw;
  }
}
@media only screen and (max-width: 600px) {
  .quiz {
    .pages {
      margin-top: 1rem;
    }
    .quiz-submit {
      width: 100%;
    }
  }
}
</style>
