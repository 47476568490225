<template>
  <div class="replication__content" data-link-content>
    <div class="replication__item">
      <div class="replication__header replication__header-foure">
        <span>{{ practice.comment.length }}</span>
        <p>Комментарии</p>
      </div>
      <form class="replication__form">
        <textarea
          v-model="textComment"
          name="comment"
          id="comment"
          cols="30"
          rows="10"
          placeholder="Поделитесь своим мнением о практике..."
        ></textarea>
        <BaseButton
          @click="sendComment"
          :disabled="!textComment"
          background
          size="normal"
          label="Отправить комментарий"
        />
      </form>
      <InfiniteScrolling :stop="stopper" @loadMore="loadMore">
        <div
          class="replication__comment"
          v-for="(comment, idx) in practice.comment.slice(0, end)"
          :key="idx"
        >
          <div class="replication__comment-header">
            <p>{{ comment.regionName }}</p>
            <span>{{ formatDate(comment.timestamp) }}</span>
          </div>
          <p class="replication__comment-text">{{ comment.description }}</p>
        </div>
      </InfiniteScrolling>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/elements/Button.vue'
import InfiniteScrolling from '@/components/elements/InfiniteScrolling/index.vue'
import axios from 'axios'
import formatDate from '@/utils/formatDate'
import { mapActions } from 'vuex'

export default {
  name: 'ReplicationComments',
  components: {
    BaseButton,
    InfiniteScrolling,
  },
  props: {
    practice: Object,
  },
  data: () => ({
    textComment: '',
    end: 5,
  }),
  computed: {
    stopper() {
      return this.practice.comment.length <= this.end
    },
  },
  methods: {
    ...mapActions('practices', [
      'getApiOnce',
    ]),
    formatDate,
    async sendComment() {
      if (this.textComment) {
        const body = {
          practice: this.practice.id,
          description: this.textComment,
        }
        const response = await axios.post('/practice/comment', body)
        if (response?.status === 200) {
          this.textComment = ''
          this.getApiOnce({ id: this.practice.id })
        }
      }
    },
    loadMore() {
      setTimeout(() => {
        this.end += 5
      }, 1000)
    },
  },
}
</script>
