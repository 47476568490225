<template>
  <div class="files-list" v-if="getFiles.length">
    <div v-for="(file, i) in getFiles" :key="`file-${i}`" class="file">
      <a class="file__icon" :href="getLink(file.link)" target="_blank">
        <DocVertical v-if="docFiles.includes(getFileType(file))"/>
        <DocAlbum v-else />
      </a>
      <div class="file__content">
        <div class="file__name"><a :href="getLink(file.link)" target="_blank">{{ file.name }}</a></div>
        <div class="file__link" v-if="showLink"><a :href="getLink(file.link)" target="_blank">{{ file.link }}</a></div>
        <div class="file__extra" v-if="showTypeAndSize">
          <span class="file__extra-type">{{ getFileType(file).toUpperCase() }} </span>
          <span class="file__extra-size" v-if="file.size">({{ file.size }})</span>
        </div>
        <div class="file__description" v-if="showDescription" v-html="getFileDescription(file)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import DocAlbum from '@/components/icons/DocAlbum.vue';
import DocVertical from '@/components/icons/DocVertical.vue';

export default {
  name: 'FilesList',
  components: {
    DocAlbum,
    DocVertical,
  },
  props: {
    files: {type: Array, default: () => ([])},
    showLink: {type: Boolean, default: false},
    showTypeAndSize: {type: Boolean, default: true},
    showDescription: {type: Boolean, default: false},
  },
  data() {
    return {
      docFiles: ['docs', 'doc', 'docx', 'rtf', 'txt']
    }
  },
  computed: {
    getFiles() {
      return this.files.filter(file => file.link.length && file.name.length);
    },
  },
  methods: {
    getLink(link) {
      return (link.indexOf('://') === -1) ? 'http://' + link : link;
    },
    getFileType(file) {
      return file.type || this.getTypeFromLink(file.link);
    },
    getTypeFromLink(link) {
      const regex = /\.([^./?#]+)($|\?|#)/;
      let ext;
      try {
        ext = link.match(regex)[1];
      } catch (e) {
        console.warn('Не удалось обработать ссылку', e, link, this.files);
        ext = '';
      }
      return ext.toLowerCase();
    },
    //так, пока бэк не определится с выбором
    getFileDescription(file) {
      return file.description || file.about;
    },
  },
}
</script>
<style lang="scss" scoped>
$spacing: 1vw;
$border-color: #e9d7c1;
$link-color: #e04e39;
$link-color--hover: #2C2A29;
$icon-size: 3.5vw;
$text-color: rgba(44, 42, 41, 0.7);

.files-list {
  .file {
    display: flex;
    border-bottom: 1px solid $border-color; // цвет поменять
    padding: $spacing 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
    }
    &__icon {
      display: flex;
      flex-shrink: 0;
      width: $icon-size;
      height: $icon-size;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      border-radius: 0.5vw;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
    &__content {
      margin-left: $spacing;
    }
    &__name {
      font-size: 0.9vw;
      color: $link-color;
      a {
        color: $link-color;
        transition: color 0.2s;
      }
      :hover {
        color: $link-color--hover;
      }
    }
    &__link {
      font-size: 0.7vw;
      color: $text-color;
      margin-top: 0.5vw;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.7;
      }
    }
    &__description {
      font-size: 0.8vw;
      color: $text-color;
      margin-top: 0.5vw;
    }
    &__extra {
      font-size: 0.7vw;
      color: $text-color;
      margin-top: 0.5vw;
    }
  }
}
@media only screen and (max-width: 900px) {
  .files-list {
    .file {
      padding: $spacing * 2 0;
      &__icon {
        width: $icon-size * 2;
        height: $icon-size * 2;
        border-radius: 1vw;
      }
      &__content {
        margin-left: $spacing * 2;
      }
      &__name {
        font-size: 2vw;
      }
      &__description {
        margin-top: 1vw;
      }
      &__extra {
        font-size: 1.7vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .files-list {
    .file {
      padding: $spacing * 3 0;
      &__icon {
        width: $icon-size * 3;
        height: $icon-size * 3;
        border-radius: 1.5vw;
      }
      &__content {
        margin-left: $spacing * 3;
      }
      &__name {
        font-size: 4vw;
      }
      &__description {
        margin-top: 1vw;
      }
      &__extra {
        font-size: 2.7vw;
      }
    }
  }
}
</style>
