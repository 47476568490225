<template>
  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="menuicon">
    <rect y="5" width="22" height="2" fill="white" fill-opacity="0.3"/>
    <rect y="10" width="22" height="2" fill="white" fill-opacity="0.3"/>
    <rect width="22" height="2" fill="white" fill-opacity="0.3"/>
  </svg>
</template>

<script>
export default {
  name: 'MenuIcon',
}
</script>

<style lang="scss" scoped>
.menuicon{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .menuicon{
    @include box(3.1vw, 3.1vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .menuicon {
    @include box(6.4vw, 6.4vw, relative, null, null, null, null);
  }
}
</style>
