<template>
  <div class="slider" id="slider">
    <div class="rows" :style="{left: `-${size * count}px`}">
      <div class="item" v-for="(item, i) in items" :key="i" :style="{width: `${size}px`}">
        <a :href="'/models/view/' + item.id" class="block">
          <div class="image">
            <LogoBigIcon2 class="icon" v-if="!item.image_url" />
            <img :src="item.image_url" />
          </div>
          <div class="name">{{item.name}}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBigIcon2 from '@/components/icons/LogoBigIcon2.vue'

export default {
  name: 'Items',
  components: {
    LogoBigIcon2,
  },
  props: {
    items: Array,
    count: Number,
    width: Number
  },
  watch: {
    width(val) {
      if (window.innerWidth < 600) {
        this.size = (val / 1.3) / 1
      } else if (window.innerWidth < 900) {
        this.size = val / 3
      } else {
        this.size = val / 3
      }
    }
  },
  data() {
    return {
      size: 0
    }
  },
  mounted() {
    if (window.innerWidth < 600) {
      this.size = (this.width / 1.3) / 1
    } else if (window.innerWidth < 900) {
      this.size = this.width / 3
    } else {
      this.size = this.width / 3
    }
  },
}
</script>

<style lang="scss" scoped>
.slider{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  padding: 1vw 0;
  user-select: none;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: nowrap;
    transition: 0.3s;
    .item{
      @include box(auto, auto, relative, null, null, null, null);
      flex-shrink: 0;
      padding: 1vw;
      .block{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        transition: 0.2s;
        border-radius: 0.4vw;
        padding: 0.5vw;
        .image{
          @include box(100%, 10vw, relative, null, null, null, null);
          background-color: #F6F2EC;
          @include flex(center, center, row);
          border-radius: 0.3vw;
          overflow: hidden;
          .icon{
            @include box(6vw, auto, relative, null, null, null, null);
          }
          .img{
            @include box(100%, auto, relative, null, null, null, null);
          }
          img{
            max-height: 100%;
          }
        }
        .name{
          font-size: 1vw;
          padding-top: 1vw;
          color: #E04E39;
          transition: 0.2s;
        }
        &:hover{
          transform: scale(1.05);
          box-shadow: 0 0.3vw 0.6vw rgba(44, 42, 41, 0.302885);
          .name{
            color: #2C2A29;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .slider{
    padding-top: 2vw;
    .rows{
      padding: 0;
      .item{
        @include box(33.3%, auto, relative, null, null, null, null);
        padding: 1.5vw;
        .block{
          .image{
            @include box(100%, 14vw, relative, null, null, null, null);
            border-radius: 0.3vw;
            .icon{
              @include box(8vw, auto, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 2vw;
            padding-top: 2vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .slider{
    padding-top: 4vw;
    .rows{
      .item{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        padding-right: 6vw;
        .block{
          .image{
            @include box(100%, 45vw, relative, null, null, null, null);
            border-radius: 2vw;
            .icon{
              @include box(25vw, auto, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 5vw;
            padding-top: 4vw;
          }
          &:hover {
            transform: scale(1);
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
