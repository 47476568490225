<template>
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowleft">
    <path d="M7 0.929688L0.929688 7L7 13.0703L8.5 11.5703L3.92969 7L8.5 2.42969L7 0.929688Z" fill="#E04E39"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeft',
}
</script>

<style lang="scss" scoped>
.arrowleft{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowleft{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowleft{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
