<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M21 4C19.6094 4 18.2422 4.53906 17.1875 5.59375L7.8125 14.9688C4.83984 17.9414 4.83984 22.7773 7.8125 25.75C10.7852 28.7227 15.6211 28.7227 18.5938 25.75L24.8438 19.5L23.4375 18.0938L17.1875 24.3438C14.9805 26.5508 11.4258 26.5508 9.21875 24.3438C7.01172 22.1367 7.01172 18.582 9.21875 16.375L18.5938 7C19.9375 5.65625 22.0938 5.65625 23.4375 7C24.7812 8.34375 24.7812 10.5 23.4375 11.8438L14.0625 21.2188C13.582 21.6992 12.8242 21.6992 12.3438 21.2188C11.8633 20.7383 11.8633 19.9805 12.3438 19.5L20.9375 10.9062L19.5312 9.5L10.9375 18.0938C9.69141 19.3398 9.69141 21.3789 10.9375 22.625C12.1836 23.8711 14.2227 23.8711 15.4688 22.625L24.8438 13.25C26.9531 11.1406 26.9531 7.70312 24.8438 5.59375C23.7891 4.53906 22.3906 4 21 4Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Clip"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
