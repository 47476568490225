<template>
  <ElDialog
    class="mfc-modal"
    :visible="visible"
    :append-to-body="true"
    :modal-append-to-body="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    top="0"
    @update:visible="$emit('update:visible', $event)"
    @close="closeModal"
  >
    <div class="modal-first">
      <div class="modal-first__strong" @click="closeModal">
        <svg>
          <use href="#close" />
        </svg>
      </div>
      <template v-if="modalMode === 'entrance'">
        <p class="modal-first__title">Вход для МФЦ</p>
        <p class="modal-first__subtitle">Используйте логин и пароль от Redmine</p>
        <div>
          <div class="modal-first__inputs">
            <input v-model="userData.username" type="text" placeholder="Логин" />
            <input v-model="userData.password" type="password" placeholder="Пароль" />
          </div>
          <div class="modal-first__link" @click="switchMode('restorePassword')">Забыли пароль?</div>
          <div v-if="authError" class="auth-error">{{ 'Ошибка: ' + authError }}</div>
          <button class="modal-first__btn" @click="submit" :disabled="isLoginButtonDisabled">ВОЙТИ</button>
        </div>
      </template>
      <template v-else-if="modalMode === 'restorePassword'">
        <p class="modal-first__title">Забыли пароль?</p>
        <p class="modal-first__subtitle modal-first__subtitle-second">
          Обратитесь к администратору
          <a href="mailto:mfc@csr.ru">mfc@csr.ru</a> для восстановления пароля
        </p>
        <div class="modal-second__btns">
          <div class="modal-second__btn" @click="switchMode('entrance')">Отменить</div>
          <a href="mailto:mfc@csr.ru" class="modal-second__btn">Написать</a>
        </div>
      </template>
    </div>
  </ElDialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MfcCabinetEntranceModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalMode: 'entrance',
      userData: {
        username: '',
        password: '',
      },
      pendingLogin: false,
      authError: null
    }
  },
  computed: {
    isLoginButtonDisabled() {
      return !this.userData.username || !this.userData.password || this.pendingLogin
    }
  },
  watch: {
    visible(value, oldValue) {
      if (value && !oldValue) {
        this.modalMode = 'entrance'
      }
    },
  },
  methods: {
    ...mapActions('mfcCabinet', ['login']),
    switchMode(mode) {
      this.modalMode = mode
    },
    closeModal() {
      this.clearUserData()
      this.$emit('update:visible', false)
    },
    clearUserData() {
      this.userData.username = ''
      this.userData.password = ''
    },
    submit() {
      this.pendingLogin = true
      this.authError = null
      this.login(this.userData).then( response => {
        if (response.status === 200) {
          this.clearUserData()
          this.closeModal()
          this.$router.push({ name: 'hosted-practices' })
        } else {
          this.authError = 'ошибка сервера'
        }
      }).catch((err) => {
        if (err.toString().includes("401") !== false )
          this.authError = 'неправильный логин или пароль'
        else
          this.authError = 'сервер недоступен'

      }).finally(() => {
        this.pendingLogin = false
      })
    },
  },
}
</script>

<style>
.modal-first {
  width: 730px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e9d7c1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 24px;
  position: relative;
}

@media only screen and (max-width: 1170px) {
  .modal-first {
    width: 600px;
  }
}

@media only screen and (max-width: 861px) {
  .modal-first {
    width: 100%;
    transform: translateY(-20%);
  }
}

.modal-first__strong {
  position: absolute;
  top: 0;
  right: -36px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 861px) {
  .modal-first__strong {
    right: 20px;
    top: -40px;
  }
}

.modal-first__title {
  font-size: 30px;
  margin-bottom: 4px;
  color: #2c2a29;
  font-weight: 700;
}

@media only screen and (max-width: 560px) {
  .modal-first__title {
    font-size: 24px;
  }
}

.modal-first__subtitle {
  font-size: 18px;
  color: #2c2a29;
  margin-bottom: 32px;
}

@media only screen and (max-width: 560px) {
  .modal-first__subtitle {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.modal-first__subtitle-second {
  margin: 30px 0 24px 0;
}

.modal-first__subtitle-second a {
  color: #e04e39;
}

.modal-first__inputs {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

@media only screen and (max-width: 560px) {
  .modal-first__inputs {
    display: block;
  }
}

.modal-first__inputs input {
  height: 44px;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  color: #000;
}

.modal-first__inputs input::-moz-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.modal-first__inputs input::-ms-input-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.modal-first__inputs input::placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.modal-first__inputs input:first-child {
  margin-right: 30px;
}

@media only screen and (max-width: 560px) {
  .modal-first__inputs input:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.modal-first__link {
  font-size: 14px;
  color: #e04e39;
  display: block;
  width: 47%;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
}

@media only screen and (max-width: 560px) {
  .modal-first__link {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.modal-first__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 44px;
  background: #e04e39;
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  transition: 300ms;
}

@media only screen and (max-width: 560px) {
  .modal-first__btn {
    width: 100%;
  }
}

.modal-first__btn:hover {
  background: #be4330;
}

.modal-first__btn:disabled {
  background: #e6dbd9;
}

.modal-second__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-second__btn {
  padding: 12px 24px;
  border: 1px solid #e04e39;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #e04e39;
  transition: 300ms;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .modal-second__btn {
    padding: 10px 16px;
    font-size: 12px;
  }
}

.modal-second__btn:first-child {
  margin-right: 24px;
  color: #e04e39;
}

@media only screen and (max-width: 767px) {
  .modal-second__btn:first-child {
    margin-right: 12px;
  }
}

.modal-second__btn:first-child:hover {
  color: #fff;
  background: #e04e39;
}

.modal-second__btn:last-child {
  color: #fff;
  background: #e04e39;
}

.modal-second__btn:last-child:hover {
  background: #993627;
}

.auth-error {
  color: #e04e39;
}
</style>
