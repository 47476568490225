<template>
  <div class="channels-list">
    <div v-for="channel in channels" :key="channel.id" class="channel">
      <a class="channel__icon" :href="getLink(channel.link)" target="_blank">
        <slot name="icon"/>
      </a>
      <div class="channel__content">
        <div class="channel__name"><a :href="getLink(channel.link)" target="_blank">{{ channel.name }}</a></div>
        <div class="channel__link" v-if="false">{{ channel.link }}</div>
        <div class="channel__description" v-html="channel.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChannelsList',
  props: {
    channels: {type: Array, required: true},
  },
  computed: {

  },
  methods: {
    getLink(link) {
      return (link.indexOf('://') === -1) ? 'http://' + link : link;
    },
  },
}
</script>
<style lang="scss" scoped>
$spacing: 1.5vw;
$border-color: #e9d7c1;
$link-color: #e04e39;
$link-color--hover: #2C2A29;
$icon-size: 3.5vw;

.channels-list {
  margin-top: 2vw;
  .channel {
    display: flex;
    border-bottom: 1px solid $border-color; // цвет поменять
    padding: $spacing 0;
    &:last-child {
      border: none;
    }
    &__icon {
      display: flex;
      flex-shrink: 0;
      width: $icon-size;
      height: $icon-size;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 0.5vw;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
    &__content {
      margin-left: $spacing;
    }
    &__name {
      font-size: 1vw;
      color: $link-color;
      a {
        color: $link-color;
        transition: 0.2s;
      }
      :hover {
        color: $link-color--hover;
      }
    }
    &__link {

    }
    &__description {
      margin-top: 0.5vw;
    }
  }
}
@media only screen and (max-width: 900px) {
  .channels-list {
    .channel {
      padding: $spacing * 2 0;
      &__icon {
        width: $icon-size * 2;
        height: $icon-size * 2;
        border-radius: 1vw;
      }
      &__content {
        margin-left: $spacing * 2;
      }
      &__name {
        font-size: 2vw;
      }
      &__description {
        margin-top: 1vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .channels-list {
    .channel {
      padding: $spacing * 3 0;
      &__icon {
        width: $icon-size * 3;
        height: $icon-size * 3;
        border-radius: 1.5vw;
      }
      &__content {
        margin-left: $spacing * 3;
      }
      &__name {
        font-size: 4vw;
      }
      &__description {
        margin-top: 1vw;
      }
    }
  }
}
</style>
