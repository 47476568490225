<template>
  <div class="nominations">
    <div class="nomination" v-for="(nomination, nominationIndex) in nominations" :key="nominationIndex">
      <h2 v-show="nomination.winners.length > 0">{{ nomination.title }}</h2>
      <div class="winner" v-for="(winner, winnerIndex) in nomination.winners" :key="winnerIndex">
        <div class="winner__title">
          <h3>{{winner.title}}</h3>
          <button class="winner__collapse desktop" @click="collapseHandler(`winner_${nomination.id}_${winner.id}`)">
            {{ getCollapseText(`winner_${nomination.id}_${winner.id}`) }}
          </button>
        </div>
        <div class="winner__tags">
          <button class="tag" @click="setRegion(winner)">{{ winner.region }}</button>
          <button class="tag" @click="setYear(winner)">{{ winner.year }}</button>
          <button class="winner__collapse mobile" @click="collapseHandler(`winner_${nomination.id}_${winner.id}`)">
            {{ getCollapseText(`winner_${nomination.id}_${winner.id}`) }}
          </button>
        </div>
        <transition name="fade">
          <div class="winner__content" v-if="collapsed.includes(`winner_${nomination.id}_${winner.id}`)">
            <div class="winner__text" v-html="winner.content"></div>
            <div class="winner__images" v-if="winner.images && winner.images.length">
              <a class="image" :href="image" target="_blank" v-for="(image, imageIndex) in winner.images" :key="imageIndex">
                <img :src="image"/>
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nominations',
  components: {
  },
  props: {
    nominations: {type: Array, required: true}
  },
  data() {
    return {
      collapsed: [],
    }
  },
  computed: {

  },
  methods: {
    getCollapseText(winnerId) {
      return this.collapsed.includes(winnerId) ? 'Свернуть' : 'Подробнее';
    },
    collapseHandler(winnerId) {
      if (this.collapsed.includes(winnerId)) {
        this.collapsed.splice(this.collapsed.indexOf(winnerId), 1);
      } else {
        this.collapsed.push(winnerId);
      }
    },
    setRegion(winner) {
      console.log(winner);
    },
    setYear(winner) {
      console.log(winner);
    }
  },
}
</script>
<style lang="scss" scoped>
.nominations {
  .nomination {

  }
  .winner {
    padding-bottom: 1rem;
    border-bottom: 1px solid #E9D7C1;
    &__tags {
      display: flex;
      flex-direction: row;
      .tag {
        cursor: pointer;
        font-family: inherit;
        margin-right: 1rem;
        background: #fff;
        border: 0.04rem solid #E9D7C1;
        padding: 0.3rem 0.7rem;
        border-radius: 0.4rem;
        font-size: 1rem;
        color: rgba(44, 42, 41, 0.7);
        &:hover {
          color: #333;
        }
      }
    }
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      h3 {
        width: 70%;
      }
    }
    &__collapse {
      cursor: pointer;
      font-family: inherit;
      font-size: 1vw;
      background: none;
      border: none;
      color: #E04E39;
      outline: none;
      &:hover {
        color: #333;
      }
    }
    &__text {
      margin: 1rem 0 1.5rem;
    }
    &__images {
      display: flex;
      flex-direction: row;
      .image {
        display: block;
        max-width: calc(48% - 2rem);
        margin-right: 2rem;
        height: 20rem;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .nominations {
    .winner {
      &__collapse {
        font-size: 2vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .nominations {
    .desktop {
      display: none;
    }
    .winner {
      &__tags {
        justify-content: space-between;
      }
      &__collapse {
        &.mobile {
          font-size: 3vw;
          display: block;
          flex-grow: 1;
          text-align: right;
        }
      }
      &__images {
        flex-direction: column;
        .image {
          max-width: 100%;
          margin: 0 0 6vw;
        }
      }
    }
  }
}
</style>
