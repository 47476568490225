<template>
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="modelicon">
    <path d="M9.5 0C7.57812 0 6 1.57812 6 3.5C6 5.42188 7.57812 7 9.5 7C11.4219 7 13 5.42188 13 3.5C13 1.57812 11.4219 0 9.5 0ZM18.5 0C16.5781 0 15 1.57812 15 3.5C15 5.42188 16.5781 7 18.5 7C20.4219 7 22 5.42188 22 3.5C22 1.57812 20.4219 0 18.5 0ZM9.5 2C10.3398 2 11 2.66016 11 3.5C11 4.33984 10.3398 5 9.5 5C8.66016 5 8 4.33984 8 3.5C8 2.66016 8.66016 2 9.5 2ZM18.5 2C19.3398 2 20 2.66016 20 3.5C20 4.33984 19.3398 5 18.5 5C17.6602 5 17 4.33984 17 3.5C17 2.66016 17.6602 2 18.5 2ZM5 6C2.80078 6 1 7.80078 1 10C1 11.1133 1.47656 12.1172 2.21875 12.8438C0.886719 13.7461 0 15.2812 0 17H2C2 15.332 3.33203 14 5 14C6.66797 14 8 15.332 8 17H10C10 15.2812 9.11328 13.7461 7.78125 12.8438C8.52344 12.1172 9 11.1133 9 10C9 7.80078 7.19922 6 5 6ZM10 17C9.375 17.8359 9 18.8867 9 20H11C11 18.332 12.332 17 14 17C15.668 17 17 18.332 17 20H19C19 18.8867 18.625 17.8359 18 17C17.6602 16.5469 17.25 16.1602 16.7812 15.8438C17.5234 15.1172 18 14.1133 18 13C18 10.8008 16.1992 9 14 9C11.8008 9 10 10.8008 10 13C10 14.1133 10.4766 15.1172 11.2188 15.8438C10.75 16.1602 10.3398 16.5469 10 17ZM18 17H20C20 15.332 21.332 14 23 14C24.668 14 26 15.332 26 17H28C28 15.2812 27.1133 13.7461 25.7812 12.8438C26.5234 12.1172 27 11.1133 27 10C27 7.80078 25.1992 6 23 6C20.8008 6 19 7.80078 19 10C19 11.1133 19.4766 12.1172 20.2188 12.8438C18.8867 13.7461 18 15.2812 18 17ZM5 8C6.11719 8 7 8.88281 7 10C7 11.1172 6.11719 12 5 12C3.88281 12 3 11.1172 3 10C3 8.88281 3.88281 8 5 8ZM23 8C24.1172 8 25 8.88281 25 10C25 11.1172 24.1172 12 23 12C21.8828 12 21 11.1172 21 10C21 8.88281 21.8828 8 23 8ZM14 11C15.1172 11 16 11.8828 16 13C16 14.1172 15.1172 15 14 15C12.8828 15 12 14.1172 12 13C12 11.8828 12.8828 11 14 11Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: "ModelIcon1"
}
</script>

<style lang="scss" scoped>
.modelicon {
  @include box(1.3vw, 1.3vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .modelicon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .modelicon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
