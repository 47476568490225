<template>
    <div class="items" v-if="getCurrentPartition">
        <div class="rows">
          <transition name="fade">
            <div v-if="show" :id="`${getCurrentPartition.type}`" class="block">
                <div class="name">{{getCurrentPartition.header}}</div>
                <div class="text contentModels" v-html="getCurrentPartition.content"></div>
                <Documents :items="getCurrentPartition.files" />
            </div>
          </transition>
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import Documents from "../index/Documents";

export default {
    name: 'Items',
    components: {
        Documents
    },
    props: {
        header: String,
        content: [String, Array],
        items: Array,
        padding: Boolean,
    },
    data() {
        return {
            show: true,
        }
    },
    watch: {
        subType() {
          //todo: сделать нормально
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
          });
        }
    },
    computed: {
      ...mapState('standardization', [
        'subType',
      ]),
      getCurrentPartition() {
        return this.content.find(item => item.type === this.subType);
      },
    },
    methods: {
        getImgUrl(pic) {
            return require('@/assets/poster/' + pic)
        },
        getDate(date) {
            return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
        }
    },
}
</script>

<style lang="scss" scoped>
    .items{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-left: 2vw;
            .item{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                padding: 1.5vw 0;
                border-top: 0.1vw solid #E9D7C1;
                .lft{
                    flex-shrink: 0;
                }
                .rgt{
                    @include box(100%, auto, relative, null, null, null, null);
                    padding-left: 1.5vw;
                    padding-right: 4vw;
                    .link{
                        font-size: 1vw;
                        color: #E04E39;
                        transition: 0.2s;
                        &:hover{
                            color: #2C2A29;
                        }
                    }
                    .sub{
                        font-size: 0.8vw;
                        padding-top: 0.5vw;
                        color: rgba(44, 42, 41, 0.7);
                    }
                }
                &:first-child{
                    border-top: 0;
                }
            }
            .name{
                font-size: 2vw;
            }
            .block{
                margin-bottom: 1vw;
                .name{
                    font-size: 1.1vw;
                    font-weight: 700;
                    text-align: justify;
                }
                .href{
                    padding-top: 1vw;
                    font-size: 1vw;
                    a{
                        color: #E04E39;
                    }
                }
                .text{
                    padding-top: 1vw;
                    font-size: 1vw;
                }
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .items{
            padding-top: 1vw;
            padding-left: 2vw;
            padding-right: 2vw;
            .rows{
                padding: 0;
                .item{
                    padding: 2vw 0;
                    border-top: 0.2vw solid #E9D7C1;
                    .rgt{
                        padding-left: 3vw;
                        padding-right: 0;
                        .link{
                            font-size: 2vw;
                        }
                        .sub{
                            font-size: 2vw;
                            padding-top: 1vw;
                        }
                    }
                }
                .block{
                    margin-bottom: 2vw;
                    .name{
                        font-size: 3vw;
                        font-weight: 700;
                    }
                    .href{
                        padding-top: 2vw;
                        font-size: 2vw;
                        a{
                            color: #E04E39;
                        }
                    }
                    .text{
                        padding-top: 2vw;
                        font-size: 3vw;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .items{
            padding-top: 4vw;
            .rows{
                .item{
                    padding: 3vw 0;
                    border-top: 0.4vw solid #E9D7C1;
                    .lft{
                        padding-left: 0;
                        padding-right: 4vw;
                    }
                    .rgt{
                        padding-left: 0;
                        padding-right: 0;
                        .link{
                            font-size: 4vw;
                        }
                        .sub{
                            font-size: 3.5vw;
                            padding-top: 1vw;
                        }
                    }
                }
                .block{
                    margin-bottom: 4vw;
                    .name{
                        font-size: 5vw;
                        font-weight: 700;
                    }
                    .href{
                        padding-top: 4vw;
                        font-size: 4vw;
                        a{
                            color: #E04E39;
                        }
                    }
                    .text{
                        padding-top: 4vw;
                        font-size: 4vw;
                    }
                }
            }
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
