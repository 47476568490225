<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M9.5 6C7.57812 6 6 7.57812 6 9.5V12.5C6 14.4219 7.57812 16 9.5 16C11.4219 16 13 14.4219 13 12.5V9.5C13 7.57812 11.4219 6 9.5 6ZM20.75 6L8.75 26H11.25L23.25 6H20.75ZM9.5 8C10.3398 8 11 8.66016 11 9.5V12.5C11 13.3398 10.3398 14 9.5 14C8.66016 14 8 13.3398 8 12.5V9.5C8 8.66016 8.66016 8 9.5 8ZM22.5 16C20.5781 16 19 17.5781 19 19.5V22.5C19 24.4219 20.5781 26 22.5 26C24.4219 26 26 24.4219 26 22.5V19.5C26 17.5781 24.4219 16 22.5 16ZM22.5 18C23.3398 18 24 18.6602 24 19.5V22.5C24 23.3398 23.3398 24 22.5 24C21.6602 24 21 23.3398 21 22.5V19.5C21 18.6602 21.6602 18 22.5 18Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Percent"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
