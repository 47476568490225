<template>
  <div class="replication__content" data-link-content>
    <div class="replication__item">
      <div class="replication__header">
        <span>{{ practice.review.length }}</span>
        <p>Отзывы о внедрении</p>
      </div>
      <InfiniteScrolling :stop="stopper" @loadMore="loadMore">
        <div
          class="replication__comment"
          v-for="(review, idx) in practice.review.slice(0, end)"
          :key="idx"
        >
          <div class="replication__comment-header">
            <p>{{ review.regionName }}</p>
            <span>{{ formatDate(review.timestamp) }}</span>
          </div>
          <p class="replication__comment-text">{{ review.description }}</p>
        </div>
      </InfiniteScrolling>
    </div>
  </div>
</template>

<script>
import InfiniteScrolling from '@/components/elements/InfiniteScrolling/index.vue'
import formatDate from '@/utils/formatDate'

export default {
  name: 'ReplicationReviews',
  components: {
    InfiniteScrolling,
  },
  props: {
    practice: Object
  },
  data: () => ({
    end: 5
  }),
  computed: {
    stopper() {
      return this.practice?.review?.length <= this.end
    }
  },
  methods: {
    formatDate,
    loadMore() {
      setTimeout(() => {
        this.end += 5
      }, 1000)
    },
  }
}
</script>
