<template>
  <div class="replication__content" data-link-content>
    <div class="replication__contact">
      <p class="replication__contact-title">Контакты</p>
      <div class="replication__contact-block">
        <p>Регион, разместивший практику</p>
        <span>{{ practice.subject }}</span>
      </div>
      <div class="replication__contact-block">
        <p>Дата размещения практики</p>
        <span>{{ date }}</span>
      </div>
      <div v-for="(item, idx) in contacts" :key="idx" class="replication__contact-block--person">
        <div class="replication__contact-block">
          <p>{{ item.position }}</p>
          <span>{{ item.name }}</span>
        </div>
        <div class="replication__contact-block">
          <p>Email</p>
          <a :href="`mailto:${item.mail}`">{{ item.mail }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ReplicationContacts',
  props: {
    practice: Object
  },
  computed: {
    contacts() {
      return JSON.parse(this.practice.contacts).map(el => ({
        ...el,
        name: el.contact.slice(0, el.contact.indexOf(',')),
        position: el.contact.slice(el.contact.indexOf(',') + 1)
      })) || []
    },
    date() {
      return moment(this.practice.date * 1000).format('DD.MM.YYYY')
    }
  }
}
</script>

<style>
.replication__contact-block--person {
  margin-bottom: 20px;
}
</style>
