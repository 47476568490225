<template>
  <div class="event-title">
    <span class="mobile_title">{{getEvents[0].event}}</span>
    <img :src="getEvents[0].img" alt="Изображение события" class="event-img min">
    <div class="event-name">
      <span class="title">{{getEvents[0].event}}</span>
      <span>{{getEvents[0].sponsor}} • {{getEvents[0].city}}</span>
      <span>{{getEvents[0].date}}</span>
      <Button label="Зарегистрироваться"/>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Button from "../../Button";

  export default {
    name: "EventTitle",
    components: {
      Button
    },
    computed: {
      ...mapGetters('events', [
        'getEvents'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .event-title {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    .mobile_title {
      display: none;
    }
    .event-img {
      @include box(100%, auto, relative, null, null, null, null);
      max-height: 21vw;
      border-radius: 0.3vw;
    }
    .event-name {
      @include flex(left, top, column);
      color: #2C2A29;
      span {
        font-size: 1.25vw;
        line-height: 1.5vw;
        margin-bottom: 1vw;
      }
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 3vw;
        line-height: 3.6vw;
        margin-bottom: 2vw;
      }
      .button {
        @include box(17vw, auto, relative, null, null, null, null);
        color: #fff;
        margin-top: 1.7vw;
        background-color: #E04E39;
        letter-spacing: .035vw;
      }
    }
    .min {
      @include box(23.5%, auto, relative, null, null, null, null);
      margin-right: 2vw;
    }
    .max {
      @include box(74.5%, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 900px) {
    .event-title{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      .mobile_title {
        display: none;
      }
      .event-img {
        @include box(100%, auto, relative, null, null, null, null);
        max-height: none;
        border-radius: 0.3vw;
      }
      .event-name {
        @include flex(left, top, column);
        color: #2C2A29;
        span {
          font-size: 2vw;
          line-height: 2.5vw;
        }
        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 3.6vw;
          line-height: 3.6vw;
          margin-bottom: 4vw;
        }
        .button {
          @include box(28vw, auto, relative, null, null, null, null);
          color: #fff;
          margin-top: 3.4vw;
          background-color: #E04E39;
          letter-spacing: .035vw;
        }
      }
      .min {
        @include box(23.5%, auto, relative, null, null, null, null);
        margin-right: 2vw;
      }
      .max {
        @include box(74.5%, auto, relative, null, null, null, null);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .event-title{
      @include flex(left, top, column);
      .mobile_title {
        @include box(95%, auto, relative, null, null, null, null);
        margin: auto;
        margin-bottom: 8vw;
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 10vw;
        line-height: 10vw;
      }
      .event-img {
        @include box(100%, auto, relative, null, null, null, null);
        max-height: 128vw;
        border-radius: 0;
      }
      .event-name {
        @include flex(left, top, column);
        @include box(95%, auto, relative, null, null, null, null);
        span {
          @include box(95%, auto, relative, null, null, null, null);
          margin: auto;
          font-size: 4vw;
          margin-top: 6vw;
        }
        .title {
          display: none;
        }
        .button {
          @include box(95%, auto, relative, null, null, null, null);
          margin: auto;
          color: #fff;
          margin-top: 6vw;
          background-color: #E04E39;
          letter-spacing: .035vw;
        }
      }
      .min {
        margin-right: 2vw;
      }
      .max {
      }
    }
  }
</style>
