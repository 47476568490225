<template>
  <div class="practicesView">
    <Tabs :tabs="pages" @set="setPages" class="tabs" />
    <Header v-if="item" :practice="item" />
    <div class="container cont" id="side">
      <div class="section" v-if="item && getPage">
        <div class="row min" :class="{ fixed: fixed }">
          <div class="sidebar" v-if="getPage.type === 'contacts'">
            <Sidebar
              v-for="(res, i) in getJson(item.contacts)"
              :key="res.title"
              :link="'contact' + i"
              :name="res.title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'resources'">
            <Sidebar
              v-for="(res, i) in getJson(item.resources)"
              :key="res.resource"
              :link="'resources' + i"
              :name="res.menu_title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'events'">
            <Sidebar
              v-for="(res, i) in getJson(item.events)"
              :key="res.event"
              :link="'events' + i"
              :name="res.menu_title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'documents'">
            <Sidebar
              link="docs1"
              name="Принятые документы"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <Sidebar
              link="docs2"
              name="Измененные документы"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <Sidebar
              link="docs3"
              name="Презентации практики"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <Sidebar
              link="docs4"
              name="Иные материалы(новости и др)"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'about'">
            <Sidebar
              link="subject"
              name="Субъект РФ"
              class="sidebar"
              @setlink="setLink"
              :type="type"
              v-if="item.subject"
            />
            <Sidebar
              v-for="(res, i) in getJson(item.about)"
              :key="'about' + i"
              :link="'about' + i"
              :name="res.title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'network'">
            <Sidebar
              link="network1"
              name="Система организации МФЦ"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <Sidebar
              link="network2"
              name="Общее количество объектов МФЦ"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <Sidebar
              link="network3"
              name="Общее количество участвующих объектов МФЦ"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'priority_target'">
            <Sidebar
              v-for="(res, i) in getJson(item.priority_target)"
              :key="'priority_target' + i"
              :link="'priority_target' + i"
              :name="res.menu_title || res.task || res.target"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
            <a
              @click="scrollToFiles"
              class="link"
              :class="{ active: 'files' === type }"
              v-if="false"
            >Скачать документы</a>
          </div>
          <div class="sidebar" v-if="getPage.type === 'results'">
            <Sidebar
              v-for="(res, i) in getJson(item.results)"
              :key="'results' + i"
              :link="'results' + i"
              :name="res.menu_title"
              class="sidebar"
              @setlink="setLink"
              :type="type"
            />
          </div>
          <div class="sidebar" v-if="getPage.type === 'timings'">
            <Sidebar link="timing1" name="Сроки" class="sidebar" @setlink="setLink" :type="type" />
          </div>
          <div class="sidebar" v-if="getPage.type === 'replication'">
            <SubPageNav />
          </div>
          <Selectbox
            :options="pages"
            default="Выберите раздел"
            keydef="type"
            @set="setPage"
            class="select"
          />
          <Selectbox
            v-if="getPage.type === 'replication'"
            :options="getPage.subPages"
            default="Подраздел"
            @set="setSubPage"
            class="select"
          />
          <Hashtags v-if="getPage.type !== 'replication'" :hashtags="item.hashtag" />
        </div>
        <div class="row max">
          <div class="contact page" v-if="getPage.type === 'about'">
            <div class="block" id="subject">
              <div v-show="false" class="name mobile-name">Субъект Российской Федерации:</div>
              <div class="text mobile-text" v-html="item.subject"></div>
            </div>
            <div class="block" v-for="(res, i) in getJson(item.about)" :key="i" :id="'about' + i">
              <div class="name">{{ res.title }}</div>
              <div class="text" v-html="res.article"></div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'network'">
            <div class="block" id="network1">
              <div class="name">Система организации МФЦ:</div>
              <div class="text">{{ item.system_type }}</div>
            </div>
            <div class="block" id="network2">
              <div class="name">Общее количество объектов сети МФЦ:</div>
              <div class="text">МФЦ: {{ item.mfc_count }}</div>
              <div class="text">ТОСП: {{ item.tocp_count }}</div>
              <div class="text">ОПО: {{ item.opo_count }}</div>
            </div>
            <div class="block" id="network3">
              <div class="name">Количество объектов сети МФЦ, участвующих в реализации практики:</div>
              <div class="text">МФЦ: {{ item.used_mfc_count }}</div>
              <div class="text">ТОСП: {{ item.used_tocp_count }}</div>
              <div class="text">ОПО: {{ item.used_opo_count }}</div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'contacts'">
            <div
              class="block"
              v-for="(res, i) in getJson(item.contacts)"
              :key="`${res.contact}-${i}`"
              :id="'contact' + i"
            >
              <div class="name">{{ res.contact }}</div>
              <div class="text">{{ res.mail }}</div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'priority_target'">
            <div
              class="block"
              v-for="(res, i) in getJson(item.priority_target)"
              :key="'t' + i"
              :id="'priority_target' + i"
            >
              <div class="name">{{ res.priority }}</div>
              <div class="href" v-if="false">
                <a :href="res.file_url">Материалы для ознакомления</a>
              </div>
              <div class="text" v-html="res.about"></div>
              <FilesList class="files-priority" :files="res.files" />
            </div>
            <FilesList
              class="files-priority"
              :files="getJson(item.priority_target_files)"
              ref="filesPriority"
              v-if="false"
            />
          </div>
          <div class="contact page" v-if="getPage.type === 'results'">
            <div
              class="block"
              v-for="(res, i) in getJson(item.results)"
              :key="'t' + i"
              :id="'results' + i"
            >
              <div class="name">{{ res.name }}</div>
              <div class="text" v-html="res.description"></div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'resources'">
            <div
              class="block"
              v-for="(res, i) in getJson(item.resources)"
              :key="res.resources"
              :id="'resources' + i"
            >
              <div class="name">{{ res.name }}</div>
              <div class="text" v-html="res.description"></div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'events'">
            <div
              class="block"
              v-for="(res, i) in getJson(item.events)"
              :key="res.event"
              :id="'events' + i"
            >
              <div class="name">{{ res.event }}</div>
              <div class="text">{{ res.period }}</div>
              <div class="text" v-html="res.result"></div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'documents'">
            <div class="block" id="docs1">
              <div class="name">Принятые документы:</div>
              <div
                class="item"
                v-for="(res, i) in getJson(item.documents_accepted)"
                :key="'doc' + i"
              >
                <div class="href">
                  <a :href="res.link" target="_blank">{{ res.name }}</a>
                </div>
              </div>
            </div>
            <div class="block" id="docs2">
              <div class="name">Измененные документы:</div>
              <div
                class="item"
                v-for="(res, i) in getJson(item.documents_changed)"
                :key="'doc' + i"
              >
                <div class="href">
                  <a :href="res.link" target="_blank">{{ res.name }}</a>
                </div>
              </div>
            </div>
            <div class="block" id="docs3">
              <div class="name">Презентации практики:</div>
              <div
                class="item"
                v-for="(res, i) in getJson(item.documents_presentational)"
                :key="'doc' + i"
              >
                <div class="href">
                  <a :href="res.link" target="_blank">{{ res.name }}</a>
                </div>
              </div>
            </div>
            <div class="block" id="docs4">
              <div class="name">Иные материалы(новости и др):</div>
              <div
                class="item"
                v-for="(res, i) in getJson(item.documents_additional)"
                :key="'doc' + i"
              >
                <div class="href">
                  <a :href="res.link" target="_blank">{{ res.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'timings'">
            <div class="block" id="timing1">
              <div class="text" v-html="item.timings"></div>
            </div>
          </div>
          <div class="contact page" v-if="getPage.type === 'replication'">
            <Replication :practice="item" />
          </div>
        </div>
      </div>
    </div>
    <Other :items="items.filter(initem => initem.id !== item.id)" v-if="item" />
  </div>
</template>

<script>
import Tabs from '@/components/views/practices/view/Tabs.vue'
import Header from '@/components/views/practices/view/Header.vue'
import Sidebar from '@/components/views/practices/view/Sidebar.vue'
import Selectbox from '@/components/elements/Selectbox.vue'
import Other from '@/components/views/practices/view/Other.vue'
import Hashtags from '@/components/views/practices/view/Hashtags.vue'
import FilesList from '@/components/shared/FilesList'
import Replication from '@/components/views/practices/index/Replication/Replication.vue'
import SubPageNav from '@/components/views/practices/view/SubPageNav.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'practicesView',
  components: {
    Tabs,
    Header,
    Sidebar,
    Selectbox,
    Other,
    Hashtags,
    FilesList,
    Replication,
    SubPageNav,
  },
  computed: {
    ...mapGetters('practices', ['getPage']),
    ...mapState('practices', ['items', 'regions', 'categories', 'pages', 'links', 'item']),
    id() {
      return this.$route.params.id
    },
  },
  data() {
    return {
      fixed: false,
      top: 0,
      fixTop: null,
      type: 'subject',
      isAgreementModalVisible: false,
    }
  },
  methods: {
    getJson(json) {
      return JSON.parse(json)
    },
    ...mapActions('practices', ['setPages', 'getApiOnce', 'getApi', 'selectCurrentSubPage']),
    setPage(val) {
      this.$router.push({ name: 'PracticesView', params: { page: val.value } })
      this.setPages(val.value)
    },
    setSubPage(val) {
      this.selectCurrentSubPage(val.value)
    },
    setLink(val) {
      const link = document.getElementById(val)
      if (link) {
        const pos = link.getBoundingClientRect()
        let offset = 150
        if (window.innerWidth > 3000) {
          offset = 400
        }
        this.type = val
        window.scrollTo({
          top: window.scrollY + pos.top - offset,
          behavior: 'smooth',
        })
      }
    },
    scrollToFiles() {
      this.type = 'files'
      //todo: check refs
      this.$refs.filesPriority.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
  },
  mounted() {
    this.setPages(this.$route.params.page)
    this.getApiOnce({id: this.$route.params.id})
    this.getApi({})
  },
  watch: {
    id() {
      this.getApiOnce({id: this.id})
    },
  },
}
</script>

<style lang="scss" scoped>
.practicesView {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-bottom: 2vw;
  padding-top: 3vw;
  .breadcrumbs {
    display: none;
    z-index: 20;
  }
  .cont {
    @include flex(left, top, row);
    padding: 2vw 0;
    .section {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
    }
    .row {
      &.min {
        @include box(22%, auto, sticky, null, null, null, null);
        @include flex(left, top, column);
        padding-top: 16px;
        .select {
          display: none;
        }
        .sidebar {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);

          .link {
            font-size: 1vw;
            margin-bottom: 1vw;
            color: #e04e39;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
            &.active {
              color: #2c2a29;
              cursor: pointer;
            }
          }
        }
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
        padding-left: 2vw;
        .pagination {
          padding: 1vw;
        }
        .page {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          .block {
            margin-bottom: 1vw;
            .name {
              font-size: 1.1vw;
              font-weight: 700;
            }
            .href {
              padding-top: 1vw;
              font-size: 1vw;
              a {
                color: #e04e39;
              }
            }
            .text {
              padding-top: 1vw;
              font-size: 1vw;
            }
          }
          #subject {
            margin-bottom: 2vw;
            .name {
              font-size: 1.1vw;
              font-weight: 700;
            }
            .text {
              font-size: 1.3vw;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .files-priority {
    margin-top: 3vw;
  }
}
@media only screen and (max-width: 900px) {
  .practicesView {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding-bottom: 2vw;
    padding-top: 0;
    .breadcrumbs {
      display: flex;
      padding: 2vw 4vw;
    }
    .tabs {
      display: none;
    }
    .cont {
      @include flex(left, top, column);
      margin-top: 30px;
      padding: 0 4vw;
      .section {
        @include flex(left, top, column);
      }
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 16px;
          .sidebar {
            display: none;
          }
          .select {
            display: flex;
          }
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          padding-left: 0;
          .pagination {
            padding: 1vw;
          }
          .page {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .block {
              margin-bottom: 2vw;
              .name {
                font-size: 3vw;
                font-weight: 700;
              }
              .href {
                padding-top: 2vw;
                font-size: 2vw;
                a {
                  color: #e04e39;
                }
              }
              .text {
                padding-top: 2vw;
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .mobile-name {
    font-size: 5vw !important;
  }
  .mobile-text {
    font-size: 5vw !important;
  }
  .practicesView {
    padding-bottom: 4vw;
    .breadcrumbs {
      padding: 6vw;
    }
    .cont {
      padding: 6vw 6vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 16px;
          .select {
            display: flex;
            width: 100%;
          }
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .pagination {
            padding: 1vw;
          }
          .page {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            margin-top: 0;
            padding-top: 0;
            .block {
              margin-bottom: 4vw;
              .name {
                font-size: 5vw;
                font-weight: 700;
              }
              .href {
                padding-top: 4vw;
                font-size: 4vw;
                a {
                  color: #e04e39;
                }
              }
              .text {
                padding-top: 4vw;
                font-size: 4vw;
              }
            }
          }
        }
      }
    }
  }
}
</style>
