<template>
  <div class="map">
    <yandex-map :settings="settings" :coords="coords" :zoom="zoom" class="ymap">
      <!--Markers-->
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap } from 'vue-yandex-maps'

export default {
  name: "Map",
  components: {
    yandexMap,
  },
  data() {
    return {
      settings: {
        apiKey: 'cdb58544-05a1-44c3-9951-1af68ea958ce',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      coords: [55.678022224242454,37.764915265625],
      zoom: 8,
    }
  }
}
</script>

<style lang="scss" scoped>
  .map {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    margin: auto;
    margin-top: 1vw;
    .ymap {
      @include box(100%, 54vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 900px) {
    .map {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      margin: auto;
      margin-top: 2vw;
      .ymap {
        @include box(100%, 54vw, relative, null, null, null, null);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .map {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      margin: auto;
      margin-top: 1vw;
      .ymap {
        @include box(100%, 131vw, relative, null, null, null, null);
      }
    }
  }
</style>
