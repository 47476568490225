export const getOthers = (state) => {
  return state.links.filter(link => link.type !== state.pageType);
}

export const getOtherNews = (state, getters) => {
  return getters.getNewsSortedByDate.filter(item => item.id !== state.newsPage?.id);
}

export const getLinksWithSelect = (state) => {
  return state.links.map(item => {
    return {
      ...item,
      select: item.type === state.pageType,
    }
  });
}