export default ({ filter, sort, hashtags }) => {
  const filterCategories = filter.categories?.options.map(el => el.name)
  const filterSubCategories = []
  filter.categories?.options?.forEach(el => {
    if (el.sub) {
      el.sub.forEach(s => {
        if (s.select) {
          filterSubCategories.push(s.name)
        }
      })
    }
  })
  const filterRegions = filter.regions?.options.map(el => el.name)
  const filterSystemType = filter.mfc?.options.map(el => el.name)
  const filterBestProject = filter.bestProject?.options.map(el => el.value)
  const body = {
    filter: {
      and: []
    },
    sort
  }

  if (filter.price.range.gt !== '' || filter.price.range.lt !== '') {
    const priceFilter = { price: {} }
    if (filter.price.range.gt !== '') priceFilter.price.gt = +filter.price.range.gt
    if (filter.price.range.lt !== '') priceFilter.price.lt = +filter.price.range.lt
    body.filter.and.push(priceFilter)
  }

  if (filter.mfc.range.gt !== '' || filter.mfc.range.lt !== '') {
    const mfcCountFilter = { mfc_count: {} }
    if (filter.mfc.range.gt !== '') mfcCountFilter.mfc_count.gt = +filter.mfc.range.gt
    if (filter.mfc.range.lt !== '') mfcCountFilter.mfc_count.lt = +filter.mfc.range.lt
    body.filter.and.push(mfcCountFilter)
  }

  if (filterCategories.length) {
    body.filter.and.push({ category: { in: filterCategories } })
  }

  if (filterSubCategories.length) {
    body.filter.and.push({ sub_category: { in: filterSubCategories } })
  }

  if (filterRegions.length) {
    body.filter.and.push({ subject: { in: filterRegions } })
  }

  if (filterSystemType.length) {
    body.filter.and.push({ system_type: { in: filterSystemType } })
  }

  if (hashtags.length) {
    body.filter.hashtag = hashtags
  }

  if (filterBestProject.length) {
    body.filter.best = filterBestProject
  }

  if (!body.filter.and.length) delete body.filter.and
  return body
}
