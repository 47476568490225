<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="infoIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12Z" fill="#E04E39"/>
    <rect x="11" y="11" width="2" height="6" fill="#E04E39"/>
    <circle cx="12" cy="8.5" r="1.5" fill="#E04E39"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.infoIcon{
  @include box(1.5vw, 1.5vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .infoIcon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .infoIcon{
    @include box(6vw, 6vw, relative, null, null, null, null);
  }
}
</style>
