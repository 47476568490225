<template>
  <div class="mfc-cabinet">
    <MfcCabinetHeader />
    <router-view :key="$route.path" />
    <AgreementModal
      :visible="!userAgreement && false"
      title="Размещенные практики"
      lead="Настоящим, даю свое согласие на: "
      :checkboxes="agreementCheckBoxes"
      @closeModal="logout"
      @accept="accept"
    />
  </div>
</template>

<script>
import MfcCabinetHeader from '../../components/views/mfcCabinet/index/MfcCabinetHeader';
import AgreementModal from '@/components/elements/AgreementModal.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MfcCabinetIndex',
  components: { AgreementModal, MfcCabinetHeader },
  data: () => ({
    agreementCheckBoxes: [
      {
        name: 'ведение сопроводительной деятельности при внедрении практик другими регионами',
        required: true,
        ckecked: false
      }
    ]
  }),
  computed: {
    ...mapGetters('mfcCabinet', [
      'userAgreement',
    ]),
  },
  methods: {
    ...mapActions('mfcCabinet', [
      'acceptProfileAgreement',
      'getProfile',
      'logout'
    ]),
    ...mapMutations('mfcCabinet', [
      'setUserAgreement',
    ]),
    accept() {
      this.acceptProfileAgreement()
    },
  },
  mounted() {
    this.getProfile()
  }
};
</script>

<style>
.mfc-cabinet {
  width: 100%;
}

.mfc-cabinet-container {
  max-width: 1110px;
  margin: 0 auto;
}

@media screen and (max-width: 1170px) {
  .mfc-cabinet-container {
    width: 840px;
  }
}

@media screen and (max-width: 861px) {
  .mfc-cabinet-container {
    width: 750px;
  }
}

@media screen and (max-width: 767px) {
  .mfc-cabinet-container {
    width: 100%;
    padding: 0 15px;
  }
}

.mfc-cabinet-container .other .container {
  width: 100%;
}
</style>
