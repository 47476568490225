<template>
  <div class="pagination">
    <div class="page click" v-for="page in start" :key="page" :class="{active: Number(getCurrPage()) === Number(page)}" @click="setPage(page)">
      {{page}}
    </div>
    <div class="page" v-if="center.length || (start.length && width < 601)">...</div>
    <div class="page click" v-for="page in center" :key="page" :class="{active: Number(getCurrPage()) === Number(page)}" @click="setPage(page)">
      {{page}}
    </div>
    <div class="page" v-if="end.length || viewEnd">...</div>
    <div class="page click" v-for="page in end" :key="page" :class="{active: Number(getCurrPage()) === Number(page)}" @click="setPage(page)">
      {{page}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pages: Number,
    current: [Number, String],
    perPage: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      start: [],
      center: [],
      end: [],
      viewEnd: false
    }
  },
  computed: {
    width() {
      return window.innerWidth
    }
  },
  watch: {
    pages() {
      this.setPage()
    }
  },
  methods: {
    setPage(val) {
      this.start = []
      this.center = []
      this.end = []
      this.viewEnd = false
      const page = val ? val : this.$route.query.page
      const total = Number(this.pages) > this.perPage ? Math.ceil(Number(this.pages) / this.perPage) : 1
      if (total > 5) {
        if (page && (page > 3)) {
          if ((total - 3) < page) {
            if (window.innerWidth > 600) this.start.push(1)
            this.end.push(total - 3)
            this.end.push(total - 2)
            this.end.push(total - 1)
            if (window.innerWidth > 600) this.end.push(total)
          } else {
            if (window.innerWidth > 600) this.start.push(1)
            this.center.push(page - 1)
            this.center.push(page)
            this.center.push(page + 1)
            if (window.innerWidth > 600) {
              this.end.push(total)
            } else {
              this.viewEnd = true
            }
          }
        } else {
          for (let i = 1; i <= total; i++) {
            if (i < 5) this.start.push(i)
            if (i === total && window.innerWidth > 600) this.end.push(i)
          }
        }
      } else {
        this.start = total
      }
      if (page) {
        this.$emit('setpage', page)
      }
    },
    getCurrPage() {
      return this.current === 0 ? 1 : Number(this.current);
    }
  },
  mounted() {
    this.setPage()
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  .page{
    @include flex(center, center, row);
    border: 0.04vw solid #E04E39;
    padding: 0.7vw 1vw;
    font-weight: 700;
    color: #E04E39;
    font-size: 0.9vw;
    margin-right: 1vw;
    border-radius: 0.3vw;
    &.active{
      border-color: #E04E39;
      color: #fff;
      background-color: #E04E39;
      cursor: default;
    }
    &.click:hover{
      cursor: pointer;
      border-color: #E04E39;
      background-color: #E04E39;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 900px) {
  .pagination {
    .page{
      @include flex(center, center, row);
      border: 0.04vw solid #E04E39;
      padding: 1.3vw 2vw;
      font-size: 2vw;
      margin-right: 2vw;
      border-radius: 0.4vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .pagination {
    @include flex(center, center, row);
    .page {
      @include flex(center, top, row);
      font-size: 4vw;
      padding: 3vw 4.5vw;
      border-radius: 0.8vw;
      border: 0.08vw solid #E04E39;
      margin-right: 4vw;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
