<template>
  <div class="social">
    <div class="links">
      <div class="title">Поделиться</div>
      <vue-yandex-share v-bind="options"></vue-yandex-share>
    </div>
    <!-- <Button label="Распечатать" icon="print" class="button" /> -->
  </div>
</template>

<script>
import VueYandexShare from '@alexlit/vue-yandex-share';

//import Button from '@/components/Button'

export default {
  name: 'Social',
  components: {
    VueYandexShare,
    //Button
  },
  data() {
    return {
      options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: 'last',
        description: this.item.lid ? this.item.lid.join(',') : null,
        direction: 'horizontal',
        hashtags: 'новости',
        image: this.item.anons_photo ? this.item.anons_photo : null,
        lang: 'ru',
        limit: 24,
        popupDirection: 'bottom',
        popupPosition: 'inner',
        size: 'm',
        title: this.item.title,
        url: this.getUrl,
        services: [
          'facebook',
          'odnoklassniki',
          'vkontakte',
        ],
      }
    }
  },
  props: {
    news: Array,
    groups: Array,
    item: Object
  },
  computed: {
    getNews() {
      return this.news.filter((item, i) => item.actual && i < 3)
    },
    getUrl() {
      return document.location.href
    }
  }
}
</script>

<style lang="scss" scoped>
.social{
  @include box(calc(100% - 22%), auto, relative, 22%, null, null, null);
  @include flex(left, center, row);
  margin: 1.5vw 0;
  border: 0.04vw solid #E9D7C1;
  padding: 0.8vw 1vw;
  border-radius: 0.2vw;
  .links{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    .title{
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.9vw;
      margin-right: 1vw;
    }
    .icon{
      margin-right: 0.5vw;
    }
  }
}
@media only screen and (max-width: 900px) {
  .social{
    @include box(100%, auto, relative, 0, null, null, null);
    margin: 3vw 0;
    border: 0.2vw solid #E9D7C1;
    padding: 1vw 2vw;
    border-radius: 0.6vw;
    .links{
      .title{
        font-size: 2vw;
        margin-right: 2vw;
      }
      .icon{
        margin-right: 1vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .social{
    margin: 4vw 0;
    border: 0.5vw solid #E9D7C1;
    padding: 3vw 4vw;
    border-radius: 0.6vw;
    .links{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      .title{
        font-size: 4vw;
        margin-right: 8vw;
      }
      .icon{
        margin-right: 3vw;
      }
    }
    .button{
      display: none;
    }
  }
}
</style>
