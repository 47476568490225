<template>
  <div class="menu">
    <div class="fixed" :class="{ 'bvi--mode': bviActive }">
      <div class="container">
        <router-link to="/" class="logo">
          <LogoIcon />
        </router-link>
        <div class="links">
          <div
            class="linkBlock"
            v-for="route in routes"
            :key="route.route"
            :class="{ active: route.name === getRouteName || route.child.indexOf(getRouteName) !== -1 }"
          >
            <router-link :to="route.route" class="link">{{ route.description }}</router-link>
          </div>
        </div>
        <Search @accessibility-mode-change="accessibilityModeChangeHandler" />
      </div>
      <div class="icon" @click="setMenuSidebar(true)">
        <MenuIcon />
      </div>
      <Sidebar :routes="routes" @close="setMenuSidebar" :menuSidebar="menuSidebar" />
    </div>
  </div>
</template>

<script>
import MenuIcon from '@/components/icons/MenuIcon.vue'
import LogoIcon from '@/components/icons/LogoIcon.vue'

import Search from '@/components/menu/Search.vue'
import Sidebar from '@/components/menu/Sidebar.vue'

import { mapState, mapActions } from 'vuex';

export default {
  name: 'MenuComponent',
  components: {
    MenuIcon,
    LogoIcon,
    Search,
    Sidebar
  },
  data() {
    return {
      bviActive: false
    }
  },
  computed: {
    getRouteName() {
      return this.$route.name
    },
    ...mapState([
      'routes',
      'menuSidebar'
    ])
  },
  methods: {
    ...mapActions([
      'setMenuSidebar'
    ]),
    accessibilityModeChangeHandler() {
      this.bviActive = !this.bviActive;
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  @include box(100%, 3vw, relative, null, null, null, null);
  .fixed {
    @include box(100%, auto, fixed, 0, 0, null, null);
    @include flex(left, center, row);
    background-color: #623b2a;
    z-index: 9999;
    .icon {
      @include box(4vw, 100%, absolute, 0, 0, null, null);
      @include flex(center, center, row);
      cursor: pointer;
    }
    .container {
      @include box(null, 100%, relative, null, null, null, null);
      @include flex(left, center, row);
      .logo {
        @include box(auto, auto, relative, null, null, null, null);
        @include flex(left, center, row);
        flex-shrink: 0;
        padding: 0.8vw 0;
      }
      .links {
        @include box(auto, 3.2vw, relative, null, null, null, null);
        @include flex(left, center, row);
        flex-shrink: 0;
        padding: 0 1.5vw;
        .linkBlock {
          @include box(auto, 100%, relative, null, null, null, null);
          @include flex(left, center, row);
          .link {
            font-size: 0.8vw;
            margin: 0 1vw;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 300;
            transition: 0.2s;
            &:hover {
              color: rgba(255, 255, 255, 1);
            }
          }
          &.active {
            &:after {
              content: "";
              @include box(100%, 0.2vw, absolute, 0, null, null, 0);
              background-color: #fff;
            }
            .link {
              color: rgba(255, 255, 255, 1);
              cursor: default;
            }
          }
        }
      }
    }
  }
  // режим для слабовидящих
  .bvi--mode {
    position: relative;
    /deep/ .eyeiconBlock {
      position: static;
    }
  }
}
@media only screen and (max-width: 900px) {
  .menu {
    @include box(100%, 11vw, relative, null, null, null, null);
    margin-bottom: -2px;
    .fixed {
      padding: 2vw 2vw;
      .icon {
        @include box(10vw, 100%, absolute, auto, 0, 0, null);
      }
      .container {
        .logo {
          flex-shrink: 0;
        }
        .links {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .menu {
    @include box(100%, 15vw, relative, null, null, null, null);
    .fixed {
      padding: 3vw 4vw;
      .icon {
        @include box(15vw, 100%, absolute, auto, 0, 0, null);
      }
      .container {
        .logo {
          flex-shrink: 0;
        }
        .links {
          display: none;
        }
      }
    }
  }
}
</style>
