<template>
    <div class="sidebar">
        <a v-for="(type, i) in types" :key="i" @click="$emit('settype', type.type)" class="link" :class="{active: type.type === subType}">
            <span>{{type.name}}</span>
        </a>
        <Selectbox :options="types" default="Выберите тип" keydef="type" @set="(data) => {$emit('settype', data.value)}" class="select" />
    </div>
</template>

<script>
    import Selectbox from '@/components/elements/Selectbox.vue'
    import {mapState} from "vuex";

    export default {
        name: 'Sidebar',
        components: {
            Selectbox
        },
        props: {
            types: Array,
        },
        computed: {
            ...mapState('standardization', [
                'subType',
            ]),
        }
    }
</script>

<style lang="scss" scoped>
    .sidebar{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding-top: 1vw;
        .link{
            font-size: 1vw;
            color: #E04E39;
            margin-bottom: 1vw;
            cursor: pointer;
            &.active{
                color: #2C2A29;
                cursor: pointer;
            }
            &:hover{
                opacity: 0.7;
            }
        }
        .select{
            margin-top: 1vw;
            display: none;
        }
    }
    @media only screen and (max-width: 900px) {
        .sidebar{
            @include flex(left, top, row);
            padding: 0 1.5vw;
            flex-wrap: wrap;
            .link{
                display: none;
            }
            .select{
                display: flex;
                @include box(100%, auto, relative, null, null, null, null);
                margin-left: 0;
                margin-top: 80px;
                margin-bottom: 80px;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .sidebar{
            @include flex(left, top, column);
            padding: 0 1.5vw;
            .link{
                display: none;
            }
            .select{
                @include box(100%, auto, relative, null, null, null, null);
                margin-left: 0;
                margin-top: 80px;
                margin-bottom: 40px;
            }
        }
    }
</style>
