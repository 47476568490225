<template>
  <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowLeftWhiteIcon">
    <path d="M18 0V3H0V5H18V8L22 4L18 0Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftWhiteIcon',
}
</script>

<style lang="scss" scoped>
.arrowLeftWhiteIcon{
  background-color: rgba(0,0,0,0) !important;
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowLeftWhiteIcon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowLeftWhiteIcon{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
