<template>
  <div class="journalIndex">
    <Breadcrumbs
      class="breadcrumbs"
      :pages="[{name: 'Журнал «Мои документы»', route: '/journal'}]"
    />
    <div class="container cont">
      <div class="title">Журнал «Мои документы»</div>
      <Items :items="items" />
      <Pagination
        :pages="totalCount"
        :current="page"
        :per-page="perPage"
        @setpage="setPageAction"
        class="pagination"
        v-if="totalCount > perPage"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Items from '@/components/views/journal/index/Items.vue'
import Pagination from "@/components/Pagination";

import {mapActions, mapState} from 'vuex';

export default {
  name: 'ForumsIndex',
  components: {
    Pagination,
    Breadcrumbs,
    Items
  },
  computed: {
    ...mapState('journal', [
      'items',
      'totalCount',
      'perPage',
      'page'
    ]),
  },
  methods: {
    ...mapActions('journal', [
      'setPage',
      'getApi'
    ]),
    setPageAction(page) {
      this.setPage(page)
      this.$router.push({ name: 'JournalIndex', query: { page: page }})
    }
  },
  mounted() {
    this.setPage(this.$route.query.page > 0 ? this.$route.query.page : 1)
    this.getApi({})
  }
}
</script>

<style lang="scss" scoped>
.journalIndex {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .breadcrumbs {
    padding: 2vw 0;
  }
  .title {
    font-size: 2vw;
    padding: 0 0;
  }
  .pagination {
    padding: 2vw 0;
  }
}
@media only screen and (max-width: 900px) {
  .journalIndex {
    .breadcrumbs {
      padding: 2vw 2vw;
    }
    .title {
      font-size: 3vw;
      padding: 0 2vw;
    }
    .pagination {
      padding: 3vw 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .journalIndex {
    .breadcrumbs {
      padding: 4vw 6vw;
    }
    .title {
      font-size: 8vw;
      padding: 0 6vw;
    }
    .pagination {
      padding: 4vw 0;
    }
  }
}
</style>
