<template>
  <div class="items">
    <div class="container">
      <div class="rows">
        <div class="item" v-for="(item, i) in items" :key="i">
          <router-link :to="{ name: 'ModelsView', params: { id: item.id } }" class="block">
            <div class="image">
              <LogoBigIcon2 class="icon" v-if="!item.image_url" />
              <img :src="item.image_url" />
            </div>
            <div class="name">{{item.name}}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBigIcon2 from '@/components/icons/LogoBigIcon2.vue'

export default {
  name: 'Items',
  components: {
    LogoBigIcon2
  },
  props: {
    items: Array,
  }
}
</script>

<style lang="scss" scoped>
.items{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-bottom: 2vw;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: wrap;
    .item{
      @include box(25%, auto, relative, null, null, null, null);
      padding: 1vw;
      .block{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        transition: 0.2s;
        border-radius: 0.4vw;
        padding: 0.5vw;
        .image{
          @include box(100%, 10vw, relative, null, null, null, null);
          background-color: #F6F2EC;
          @include flex(center, center, row);
          border-radius: 0.3vw;
          overflow: hidden;
          .icon{
            @include box(6vw, auto, relative, null, null, null, null);
          }
          .img{
            @include box(100%, auto, relative, null, null, null, null);
          }
          img{
            max-height: 100%;
          }
        }
        .name{
          font-size: 1.1vw;
          padding-top: 1vw;
          color: #E04E39;
          transition: 0.2s;
        }
        &:hover{
          transform: scale(1.05);
          box-shadow: 0 0.3vw 0.6vw rgba(44, 42, 41, 0.302885);
          .name{
            color: #2C2A29;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .items{
    padding-bottom: 2vw;
    .rows{
      .item{
        @include box(33%, auto, relative, null, null, null, null);
        padding: 2vw;
        .block{
          .image{
            @include box(100%, 14vw, relative, null, null, null, null);
            border-radius: 0.3vw;
            .icon{
              @include box(8vw, auto, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 2vw;
            padding-top: 2vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .items{
    padding-bottom: 2vw;
    .rows{
      .item{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 6vw;
        .block{
          .image{
            @include box(100%, 45vw, relative, null, null, null, null);
            border-radius: 2vw;
            .icon{
              @include box(25vw, auto, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 5vw;
            padding-top: 4vw;
          }
          &:hover {
            transform: scale(1);
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
