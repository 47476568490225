<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="closeIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00091 7.58578L1.92984 0.514709L0.515625 1.92892L7.58669 8.99999L0.515625 16.0711L1.92984 17.4853L9.00091 10.4142L16.072 17.4853L17.4862 16.0711L10.4151 8.99999L17.4862 1.92892L16.072 0.514709L9.00091 7.58578Z" fill="white" fill-opacity="0.3"/>
  </svg>
</template>

<script>
    export default {
        name: "Arms"
    }
</script>

<style lang="scss" scoped>
.closeIcon{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .closeIcon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .closeIcon{
    @include box(5vw, 5vw, relative, null, null, null, null);
  }
}
</style>
