<template>
  <svg width="62" height="82" viewBox="0 0 62 82" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoIcon3">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.574 75.0521V81.8924C30.2513 81.7465 30.0571 81.4986 29.8316 81.3008C26.4031 78.2939 22.9846 75.276 19.7849 72.0209C15.399 67.559 11.3585 62.8181 8.01352 57.5136C4.84463 52.4885 2.42563 47.1421 1.11879 41.3255C0.220547 37.3274 -0.183037 33.2797 0.0784558 29.1853C0.914418 16.0917 9.74741 4.89515 22.0479 1.22315C24.7107 0.428184 27.4333 0.0316421 30.2105 8.63098e-05C30.3274 -0.00106405 30.4444 0.00957717 30.5613 0.0145848C30.5616 5.53175 30.5618 11.0491 30.5621 16.5664C29.5061 16.6005 28.4511 16.669 27.4256 16.9363C22.021 18.3457 18.6418 21.8432 17.0443 27.1173C16.1944 29.9224 16.3977 32.7942 16.7581 35.6448C17.1611 38.8334 18.1771 41.8463 19.5796 44.7345C21.3248 48.3285 23.5887 51.5798 26.1178 54.6551C27.4996 56.3354 28.9393 57.9657 30.4884 59.4965C30.5886 59.6489 30.564 59.8209 30.564 59.9884C30.5643 62.4469 30.5631 64.9057 30.5646 67.3643C30.5663 69.9269 30.5707 72.4894 30.574 75.0521Z" fill="#EA543A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5739 75.0522C30.5706 72.4896 30.5662 69.9271 30.5645 67.3644C30.5629 64.9058 30.5642 62.4471 30.5639 59.9885C30.5639 59.821 30.5884 59.6491 30.4883 59.4966C30.4934 57.3222 30.5028 55.1478 30.4988 52.9734C30.4983 52.7144 30.5769 52.5478 30.7798 52.3819C32.8365 50.7007 34.8973 49.0247 36.8237 47.191C38.8319 45.2792 40.6785 43.2403 42.0224 40.7898C43.3931 38.2904 44.1644 35.6065 44.4687 32.7859C44.6532 31.0747 44.6881 29.3591 44.3754 27.6531C43.638 23.6307 41.3876 20.6823 37.9226 18.6095C35.6601 17.256 33.1904 16.6182 30.562 16.5665C30.5617 11.0492 30.5615 5.53188 30.5612 0.0147215C32.6805 -0.0279999 34.7745 0.206733 36.8373 0.670565C44.8896 2.48145 51.2634 6.79615 55.9267 13.5968C58.4293 17.2466 60.0208 21.2821 60.6774 25.6618C61.6777 32.3329 60.6665 38.7536 58.1226 44.9562C56.1178 49.8438 53.0532 54.0231 49.4247 57.826C46.1846 61.2218 42.7703 64.4334 39.2884 67.5766C36.4464 70.1419 33.5509 72.6443 30.5739 75.0522Z" fill="#C59368"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5165 35.1864C28.4585 35.3202 25.8589 33.5749 25.8164 30.5146C25.7804 27.9268 27.9307 25.7979 30.5357 25.7988C33.1422 25.7998 35.2687 27.9171 35.252 30.5176C35.2348 33.2 32.9792 35.2989 30.5165 35.1864Z" fill="#C59368"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon3',
}
</script>

<style lang="scss" scoped>
.logoIcon3{
  @include box(4vw, 4vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoIcon3{
    @include box(8vw, 8vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoIcon3{
    @include box(18vw, 18vw, relative, null, null, null, null);
  }
}
</style>
