<template>
  <div class="posterIndex">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Афиша', route: '/poster'}]" />
    <div class="container cont">
      <div class="row max mobile">
        <div class="top">
          <div class="title">{{getTotalCountTitle}}</div>
        </div>
      </div>
      <div class="row min">
        <Sidebar :regions="regions" :dates="dates" @setregion="setRegion" @setdates="setDates" />
      </div>
      <div class="row max">
        <div class="top">
          <div class="title">{{getTotalCountTitle}}</div>
        </div>
        <Items :items="items" />
        <Pagination
          :pages="totalCount"
          :current="page"
          :per-page="perPage"
          @setpage="setPageAction"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Sidebar from '@/components/views/poster/index/Sidebar.vue'
import Items from '@/components/views/poster/index/Items.vue'
import Pagination from '@/components/Pagination.vue'

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'EventsIndex',
  components: {
    Breadcrumbs,
    Sidebar,
    Items,
    Pagination
  },
  computed: {
    ...mapGetters('events', [
      'getPageItems'
    ]),
    ...mapState('events', [
      'items',
      'regions',
      'page',
      "totalCount",
      "perPage",
      'dates'
    ]),
    getTotalCountTitle() {
      if (this.totalCount > 9 && this.totalCount < 20) {
        return `Найдено: ${this.totalCount} событий`
      } else {
        const last = String(this.totalCount).slice(-1)
        if (last == 0 || (last >= 5 && last <= 9)) {
          return `Найдено: ${this.totalCount} событий`
        } else if (last == 1) {
          return `Найдено: ${this.totalCount} событие`
        }else {
          return `Найдено: ${this.totalCount} события`
        }
      }
    }
  },
  methods: {
    ...mapActions('events', [
      'setRegion',
      'setDates',
      'setPage',
      'getApi',
      'getApiRegion'
    ]),
    setPageAction(page) {
      this.$router.push({ name: 'PosterIndex', query: { page: page }})
      this.setPage(page)
    }
  },
  mounted() {
    this.setPage(this.$route.query.page > 0 ? this.$route.query.page : 1)
    this.getApi({})
    this.getApiRegion()
  }
}
</script>

<style lang="scss" scoped>
.posterIndex {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .breadcrumbs {
    padding: 2vw 0;
  }
  .container {
    @include flex(left, top, row);
    .row {
      &.min {
        @include box(22%, auto, relative, null, null, null, null);
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
        padding-top: 0.5vw;
        .top {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          .title {
            font-size: 1.4vw;
            padding-left: 2vw;
            font-weight: 700;
          }
        }
        .pagination {
          padding: 2vw 2vw;
        }
        &.mobile {
          display: none;
          .top {
            display: flex;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .posterIndex {
    padding-bottom: 0;
    .breadcrumbs {
      padding: 4vw 4.5vw;
    }
    .container {
      @include flex(left, top, column);
      padding: 0 3vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .top {
            display: none;
            .title {
              font-size: 2.5vw;
              padding-left: 1.5vw;
              padding-bottom: 2vw;
            }
          }
          .pagination {
            padding: 2vw 2vw;
          }
          &.mobile {
            display: flex;
            .top {
              display: flex;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .posterIndex {
    .breadcrumbs {
      padding: 6vw 6.5vw;
    }
    .container {
      @include flex(left, top, column);
      padding: 0 6vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .top {
            .title {
              font-size: 5.5vw;
              padding-left: 1vw;
            }
          }
          .pagination {
            padding: 2vw 1vw;
            padding-bottom: 4vw;
          }
        }
      }
    }
  }
}
</style>
