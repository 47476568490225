<template>
  <div class="forum">
    <div class="container">
      <div class="year">
        {{forum.year}}
      </div>
      <div class="logo">
        <LogoMFCWhite />
      </div>
      <div class="title" v-html="forum.title"></div>
      <div class="desc" v-html="forum.description"></div>

      <div class="buttons">
        <Button v-html="forum.button" white @click="route(forum.link)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/elements/Button";
import LogoMFCWhite from "@/components/icons/LogoMFCWhite"
import {mapState} from "vuex";

export default {
  name: "Forum",
  components: {
    Button,
    LogoMFCWhite
  },
  computed: {
    ...mapState('home', [
      'forum'
    ])
  },
  methods:{
    route: function (link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.forum {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  background: linear-gradient(90deg, #2467DA 0%, #E72334 100%);
  background-size: contain;
  .container {
    @include flex(center, center, row);
    padding: 1vw 0;
    .year{
      font-size: 4vw;
      font-family: Arial;
      font-weight: 700;
      color: #FFF;
    }
    .logo{
      padding: 0 1.5vw;
    }
    .title{
      font-size: 1.1vw;
      font-weight: 700;
      color: #FFF;
    }
    .desc{
      padding: 0 1.5vw;
      color: #FFF;
    }
    ul{
      margin: 0;
      padding: 0 4vw;
      li{
        font-size: 0.8vw;
        color: #FFF;
        font-style: italic;
        padding: 0.2vw 0;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .forum {
    .container {
      padding: 2vw 0;
      .year{
        font-size: 6vw;
      }
      .logo{
        padding: 0 2vw;
      }
      .title{
        font-size: 2vw;
      }
      ul{
        padding: 0 4vw;
        li{
          font-size: 1.5vw;
          padding: 0.2vw 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .forum {
    .container {
      @include flex(left, center, column);
      padding: 6vw 0;
      .year{
        font-size: 8vw;
        padding-bottom: 4vw;
      }
      .logo{
        padding: 0 2vw;
        padding-bottom: 4vw;
      }
      .title{
        font-size: 4vw;
        padding-bottom: 4vw;
        text-align: center;
      }
      ul{
        padding: 0 4vw;
        padding-bottom: 4vw;
        li{
          font-size: 4vw;
          padding: 1vw 0;
        }
      }
      .buttons{
        @include box(calc(100% - 12vw), auto, relative, null, null, null, null);
      }
    }
  }
}
</style>
