<template>
    <div class="header">
        <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Стандартизация', route: '/standardization'}]" black />
        <div class="container">
            <div class="name">{{ header.title }}</div>
            <div v-if="!!header.subTitle.length" class="subtitle" v-html="header.subTitle"></div>
        </div>
        <img :src="bannerDesktop" class="desc" alt="img"/>
        <img :src="bannerMd" class="mid" alt="img"/>
        <img :src="bannerMobile" class="mob" alt="img"/>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs.vue';
    import bannerDesktop from '../../../../assets/standardization/desktop.png';
    import bannerMd from '../../../../assets/standardization/md.png';
    import bannerMobile from '../../../../assets/standardization/mobile.png';

    export default {
        name: 'Header',
        components: {
            Breadcrumbs,
        },
        data() {
            return {
                bannerDesktop,
                bannerMd,
                bannerMobile,
            };
        },
        props: {
            header: Object
        },
    }
</script>

<style lang="scss" scoped>
    .header{
        @include box(100%, 22vw, relative, null, null, null, null);
        @include flex(left, top, column);
        overflow: hidden;
        img {
            @include box(100%, auto, absolute, 0, 0, null, null);

            &.desc {
                display: flex;
            }

            &.mid {
                display: none;
            }

            &.mob {
                display: none;
            }
        }

        .breadcrumbs{
            padding-top: 2vw;
            z-index: 10;
        }
        .container{
            @include flex(left, top, column);
            z-index: 10;
            .name{
                @include box(40%, auto, relative, null, null, null, null);
                color: #000000;
                font-size: 2.3vw;
                padding-top: 3vw;
            }
            .subtitle {
                @include box(50%, auto, relative, null, null, null, null);
                font-size: 1vw;
            }
            .button{
                margin-top: 3vw;
                &.mobile{
                    display: none;
                }
            }
        }
        .button{
            &.mobile{
                display: none;
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .header{
            @include box(100%, 330px, relative, null, null, null, null);
            img {
                @include box(100%, auto, absolute, 0, 0, null, null);

                &.desc {
                    display: flex;
                }

                &.mid {
                    display: none;
                }

                &.mob {
                    display: none;
                }
            }
            .breadcrumbs{
                display: none;
            }
            .container{
                padding: 0 4vw;
                @include box(100%, 100%, relative, null, null, null, null);
                @include flex(center, top, column);
                .name{
                    @include box(60%, auto, relative, null, null, null, null);
                    color: #000000;
                    font-size: 3vw;
                    padding-top: 0;
                }
                .subtitle {
                    @include box(60%, auto, relative, null, null, null, null);
                    font-size: 1.8vw;
                }
                .button{
                    margin-top: 3vw;
                }
            }
            .button{
                &.mobile{
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .header{
            @include box(100%, auto, relative, null, null, null, null);
            img {
                @include box(100%, auto, relative, null, null, null, null);

                &.desc {
                    display: flex;
                }

                &.mid {
                    display: none;
                }

                &.mob {
                    display: none;
                }

            }
            .container{
                position: absolute!important;
                padding: 0 6vw 6vw;
                @include box(100%, 100%, relative, null, null, null, null);
                @include flex(center, top, column);
                .name{
                    @include box(100%, auto, relative, null, null, null, null);
                    color: #222;
                    font-size: 6vw;
                }
                .subtitle {
                    @include box(75%, auto, relative, null, null, null, null);
                    font-size: 2.2vw;
                }
                .button{
                    display: none
                }
            }
            .button{
                @include box(calc(100% - 12vw), auto, relative, 6vw, null, null, null);
                margin-top: 6vw;
                @include flex(center, top, row);
                &.mobile{
                    display: flex
                }
            }
        }
    }
</style>
