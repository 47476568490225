export function getEsiaUrl() {
  return '/esia/login'
}

export function getAuth(code) {
  return '/esia/esia?code=' + code
}

export function checkAuth(token) {
  return '/esia/check-user?token=' + token
}

