<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4V5H5V6V10V12.3438C5 15.8849 4.58743 18.8598 2.29297 21.1543L2 21.4473V23H5V27H27V12.3438V6V5H23V4H21V5H11V4H9ZM7 7H9V8H11V7H21V8H23V7H25V9H7V7ZM7 11H25V12.3438C25 15.746 24.564 18.7448 22.5098 21H4.88672C6.5974 18.4343 7 15.4068 7 12.3438V11ZM9 13V15H11V13H9ZM13 13V15H15V13H13ZM17 13V15H19V13H17ZM21 13V15H23V13H21ZM8.68945 17L8 19H10L10.6895 17H8.68945ZM12.6895 17L12 19H14L14.6895 17H12.6895ZM16.6895 17L16 19H18L18.6895 17H16.6895ZM25 20.9727V25H7V23H23.4141L23.707 22.707C24.2432 22.1709 24.621 21.5713 25 20.9727Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Calendar"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
