<template>
  <div class="hashtags">
    <div class="hashtag" v-for="hashtag in hashtags" :key="hashtag" @click="hashtagClickHandler(hashtag)">{{ hashtag }}</div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'Hashtags',
  props: {
    hashtags: Array
  },
  data() {
    return {
      width: 0
    }
  },
  methods: {
    ...mapActions('practices', [
      'setPages',
    ]),
    hashtagClickHandler(hashtag) {
      this.$router.push({ name: 'PracticesIndex', query: { hashtag: hashtag.replace('#','') }});
      this.setPages(1);
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.hashtags {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1vw;
  padding-bottom: 10vw;
  .hashtag {
    @include box(auto, auto, relative, null, null, null, null);
    margin-top: 0.5vw;
    margin-right: 0.5vw;
    border: 0.04vw solid #E9D7C1;
    padding: 0.3vw 0.7vw;
    border-radius: 0.4vw;
    font-size: 0.8vw;
    color: rgba(44, 42, 41, 0.7);
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}
@media only screen and (max-width: 900px) {
  .hashtags {
    .hashtag {
      font-size: 4vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .hashtags {

  }
}
</style>
