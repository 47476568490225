<template>
  <div class="content__sidebar-hash">
    <p>
      Хэштеги
      <span id="CountSaidbarHesh"></span>
    </p>
    <ul>
      <li v-for="(hashtag, index) in hashtags" :key="index" @click="clickHashtag(hashtag)">
        <div :class="{ 'active': hashtag.select }">{{ hashtag.name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: 'HashtagSingle',
  computed: {
    ...mapGetters('practices', [
      'hashtags',
    ]),
  },
  methods: {
    ...mapMutations('practices', [
      'toggleHashtag',
    ]),
    ...mapActions('practices', [
      'getHashtags',
      'getApi'
    ]),
    clickHashtag(hashtag) {
      this.toggleHashtag(hashtag)
      this.getApi({})
    }
  },
  mounted() {
    this.getHashtags()
  }
}
</script>

<style lang="scss" scoped>
.content__sidebar-hash {
  margin: 16px 0;
  p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ul {
    max-height: 500px;
    height: fit-content;
    overflow-y: scroll;

    @media only screen and (max-width: 861px) {
      max-height: 175px;
      display: flex;
      flex-wrap: wrap;
    }

    &::-webkit-scrollbar {
      opacity: 0;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.304906);
      border-radius: 3px;
    }

    li {
      margin-bottom: 8px;
      cursor: pointer;

      @media only screen and (max-width: 861px) {
        display: block;
        margin-right: 8px;
      }

      &:last-child {
        @media only screen and (max-width: 861px) {
          margin-right: 0;
        }
      }

      div {
        width: fit-content;
        font-size: 14px;
        padding: 4px 12px;
        border: 1px solid #e9d7c1;
        box-sizing: border-box;
        border-radius: 6px;
        color: rgba(44, 42, 41, 0.7);

        &.active {
          background: rgba(234, 84, 58, 1);
          color: #fff;
        }
      }
    }
  }
}
</style>
