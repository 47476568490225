<template>
  <div class="mfc-implemented-practices">
    <div class="practical">
      <div class="mfc-cabinet-container">
        <div class="practical__items">
          <PracticesSidebar @change="getPractices" />
          <div>
            <PracticesList
              :items="items"
              :totalCount="totalCount"
              title="Внедренные практики"
              :show-progress="true"
              card-route-name="implemented-practice"
            />
            <Pagination
              v-if="totalCount > perPage"
              :pages="totalCount"
              :current="page"
              class="pagination"
              @setpage="setPageAction"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PracticesList from '../../../components/views/mfcCabinet/practices/PracticesList'
import PracticesSidebar from '../../../components/views/mfcCabinet/practices/PracticesSidebar'
import Pagination from '../../../components/Pagination'

export default {
  name: 'MfcCabinetImplementedPractices',
  components: { Pagination, PracticesSidebar, PracticesList },
  computed: {
    ...mapState('practices', ['items', 'categories', 'perPage', 'page', 'totalCount']),
  },
  methods: {
    ...mapActions('practices', [
      'setCategory',
      'getApi',
      'setPage',
      'getApiCategory',
      'resetFilterValues',
      'setPageMode',
    ]),
    setPageAction(page) {
      if (page !== this.page) {
        this.page = page
        this.$router.push({ name: 'implemented-practices', query: { page: page } }).catch(() => { })
        this.setPage(page)
      }
    },
    getPractices(filter = null) {
      this.getApi({ endpoint: '/profile/implemented-practices', customFilter: filter })
    },
  },
  async mounted() {
    await this.resetFilterValues()
    this.setPage(this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1)
    if (this.categories.length < 1) {
      this.getApiCategory()
    }
    this.getPractices()
  },
}
</script>

<style scoped>
.practical {
  overflow: hidden;
  padding: 36px 0 80px;
}

.practical__items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 861px) {
  .practical__items {
    display: block;
  }
}

.mfc-implemented-practices .pagination {
  margin-top: 32px;
}
</style>
