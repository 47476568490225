<template>
  <div class="mfcView" v-if="point" :key="key">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Найти МФЦ', route: '/mfc'}]" />
    <div class="container cont">
      <div class="row min tags">
        <Tag :label="point.region" class="tag" v-if="point.region && point.region !== ''" />
        <Tag :label="point.city" class="tag" v-if="point.city && point.city !== ''" />
        <Tag :label="point.district" class="tag" v-if="point.district && point.district !== ''" />
      </div>
      <div class="row max">
        <div class="title">{{point.name}}</div>
        <div class="map">
          <yandex-map :settings="settings" :coords="coords" :zoom="zoom" class="ymap" :controls="controls" v-if="coords">
            <ymap-marker
                v-for="point in [point]"
                :key="point.id"
                :marker-id="point.id"
                :coords="[point.lat, point.lon]"
                :icon="markerIcon"
            >
              <div slot="balloon">
                <h3>{{ point.name }}</h3>
                <p>{{ point.address }}<br>{{ point.phone }}</p>
              </div>
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="adress">{{point.address}}</div>
        <Button label="Как добраться" class="button" icon="map" v-if="false" />
        <div class="rows">
          <div class="column">
            <div class="name">{{point.window_count}}</div>
            <div class="text small">
              Окон для приёма граждан
            </div>
          </div>
          <div class="column" v-if="false">
            <TimeIcon />
            <div class="text">
              {{point.weekdays}}<br/>
              {{point.weekends}}
            </div>
          </div>
          <div class="column">
            <PhoneIcon />
            <div class="text red">
              {{point.phone}}
            </div>
          </div>
          <div class="column">
            <EachIcon />
            <div class="text red">
              <a :href="'mailto:' + point.email">{{point.email}}</a><br/>
              <a :href="'https://' + point.site" target="_blank">{{point.site}} </a>
            </div>
          </div>
        </div>
        <div class="rows noPadding" v-if="false">
          <div class="column">
            <div class="name">{{point.delay}}</div>
            <div class="text small">
              Среднее время ожидания
            </div>
          </div>
          <div class="column">
            <div class="name">{{point.window_count}}</div>
            <div class="text small">
              Окон для приёма граждан
            </div>
          </div>
          <div class="column">
            <div class="name">{{point.window_count_law}}</div>
            <div class="text small">
              Окон для приёма юридических лиц
            </div>
          </div>
        </div>
        <Share :item="point" />
      </div>
    </div>
    <Other v-if="regions.length" class="other" :currentPoint="point" />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import Tag from '@/components/elements/Tag'
import Button from '@/components/elements/Button'
import TimeIcon from '@/components/icons/TimeIcon'
import PhoneIcon from '@/components/icons/PhoneIcon'
import EachIcon from '@/components/icons/EachIcon'
import Share from '@/components/Share'
import Other from '@/components/views/mfc/view/Other'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import {mapGetters, mapState, mapActions} from 'vuex';

export default {
  name: 'MfcView',
  components: {
    Breadcrumbs,
    Tag,
    Button,
    TimeIcon,
    PhoneIcon,
    EachIcon,
    Share,
    Other,
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      key: 0,
      settings: {
        apiKey: 'cdb58544-05a1-44c3-9951-1af68ea958ce',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },
      controls: ['zoomControl','fullscreenControl'],
      coords: null,
      zoom: 12,
      markerIcon: {
        layout: 'islands#dotIcon',
      }
    }
  },
  computed: {
    ...mapGetters('mfc', [
      'getOther'
    ]),
    ...mapState('mfc', [
      'point',
      'regions',
    ]),
  },
  methods: {
    ...mapActions('mfc', [
      'getApi',
      'getApiOnce',
      'getApiRegion',
      'setRegion',
    ]),
    getData() {
      this.key++;
      this.getApiRegion();
      this.getApiOnce(this.$route.params.id);
    },
  },
  watch: {
    point(val) {
      this.coords = [val.lat ? val.lat : 0, val.lon ? val.lon : 0];
    },
    $route () {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
.mfcView{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .breadcrumbs{
    padding: 2vw 0;
  }
  .container{
    @include flex(left, top, row);
    .row{
      &.min{
        @include box(22%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding-top: 1vw;
        .tag{
          margin-bottom: 1vw;
        }
        &.tags{
          flex-wrap: wrap;
        }
      }
      &.max{
        @include box(78%, auto, relative, null, null, null, null);
        padding-left: 2vw;
        .title{
          font-size: 1.8vw;
          padding-bottom: 2vw;
        }
        .map{
          @include box(100%, 20vw, relative, null, null, null, null);
          .ymap {
            @include box(100%, 100%, relative, null, null, null, null);
          }
        }
        .adress{
          font-size: 1vw;
          padding: 2vw 0;
        }
        .button{
          @include box(10.5vw, null, relative, null, null, null, null);
        }
        .rows{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          .column{
            @include box(33.3%, auto, relative, null, null, null, null);
            padding: 2vw 0;
            .name{
              font-size: 1.3vw;
              font-weight: 700;
            }
            .text{
              padding: 1vw 0;
              font-size: 0.9vw;
              &.small{
                color: rgba(44, 42, 41, 0.7);
                font-size: 0.85vw;
              }
              &.red{
                color: #E04E39;
              }
              a{
                color: #E04E39;
                transition: 0.2s;
                &:hover{
                  opacity: 0.5;
                }
              }
            }
          }
          &.noPadding{
            .column{
              padding-top: 0;
            }
          }
        }
      }
    }
  }
  .other{
    margin: 3vw 0;
  }
}
@media only screen and (max-width: 900px) {
  .mfcView{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .breadcrumbs{
      padding: 4vw 4vw;
    }
    .container{
      @include flex(left, top, column);
      padding: 0 4vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          padding-top: 1vw;
          .tag{
            margin-right: 2vw;
            font-size: 2vw;
            padding: 1vw 2vw;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-left: 0;
          .title{
            font-size: 3vw;
            padding-bottom: 3vw;
            padding-top: 3vw;
          }
          .map{
            @include box(100%, 40vw, relative, null, null, null, null);
            iframe{
              @include box(100%, 100%, relative, null, null, null, null);
            }
          }
          .adress{
            font-size: 2vw;
            padding: 4vw 0;
          }
          .button{
            @include box(20vw, null, relative, null, null, null, null);
          }
          .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .column{
              @include box(33.3%, auto, relative, null, null, null, null);
              padding: 2vw 0;
              .name{
                font-size: 3vw;
                font-weight: 700;
              }
              .text{
                padding: 1vw 0;
                font-size: 2vw;
                &.small{
                  color: rgba(44, 42, 41, 0.7);
                  font-size: 0.85vw;
                }
                &.red{
                  color: #E04E39;
                }
              }
            }
            &.noPadding{
              .column{
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    .other{
      margin: 3vw 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .mfcView{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .breadcrumbs{
      padding: 6vw;
    }
    .container{
      @include flex(left, top, column);
      padding: 0 6vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          padding-top: 1vw;
          .tag{
            margin-right: 2vw;
            font-size: 4vw;
            padding: 2vw 4vw;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-left: 0;
          .title{
            font-size: 6vw;
            padding-bottom: 6vw;
            padding-top: 6vw;
          }
          .map{
            @include box(100%, 40vw, relative, null, null, null, null);
            iframe{
              @include box(100%, 100%, relative, null, null, null, null);
            }
          }
          .adress{
            font-size: 4vw;
            padding: 6vw 0;
          }
          .button{
            @include box(100%, null, relative, null, null, null, null);
            margin-bottom: 6vw;
          }
          .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .column{
              @include box(100%, auto, relative, null, null, null, null);
              padding: 2vw 0;
              .name{
                font-size: 4vw;
                font-weight: 700;
              }
              .text{
                padding: 6vw 0;
                font-size: 4vw;
                &.small{
                  color: rgba(44, 42, 41, 0.7);
                  font-size: 4vw;
                }
                &.red{
                  color: #E04E39;
                }
              }
            }
            &.noPadding{
              .column{
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    .other{
      margin: 3vw 0;
    }
  }
}
</style>
