<template>
  <div class="slider" v-touch:swipe.left="nextSlide" v-touch:swipe.right="prevSlide">
    <div class="scroll" :style="{ left: `-${width * count}px` }">
      <div
        class="slide"
        v-for="(slide, index) in slides"
        :key="index"
        :style="{ width: `${width}px` }"
      >
        <transition name="fade">
          <img :src="getImg(slide.image_url)" />
        </transition>
        <transition name="fade">
          <div class="text" v-show="index === count">
            <div class="title" v-html="slide.title" v-if="slide.title"></div>
            <div class="subtitle" v-html="slide.subtitle" v-if="slide.subtitle"></div>
            <div class="slideText" v-html="slide.body" v-if="slide.body"></div>
            <div class="subtext" v-html="slide.subbody" v-if="slide.subbody"></div>
            <a :href="slide.button_link">
              <Button
                :label="slide.button_text"
                v-if="slide.button_text"
                background
                class="button"
              />
            </a>
          </div>
        </transition>
      </div>
    </div>
    <div class="controll">
      <div class="lft">
        <div class="button" @click="prevSlide" v-if="count > 0">
          <ArrowRightWhiteIcon />
        </div>
      </div>
      <div class="rgt">
        <div class="button" @click="nextSlide" v-if="count < (slides.length - 1)">
          <ArrowLeftWhiteIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftWhiteIcon from '@/components/icons/ArrowLeftWhiteIcon'
import ArrowRightWhiteIcon from '@/components/icons/ArrowRightWhiteIcon'
import Button from '@/components/elements/Button'

import { mapActions, mapState } from 'vuex';

export default {
  name: 'Slider',
  components: {
    ArrowLeftWhiteIcon,
    ArrowRightWhiteIcon,
    Button
  },
  data() {
    return {
      count: 0,
      width: 0,
    }
  },
  computed: {
    ...mapState('home', [
      'slides',
    ])
  },
  methods: {
    ...mapActions('home', [
      'getApiOnce'
    ]),
    getImg(src) {
      return src
    },
    prevSlide() {
      this.count = this.count > 0 ? this.count - 1 : 0
    },
    nextSlide() {
      this.count = this.count < (this.slides.length - 1) ? this.count + 1 : this.slides.length - 1
    },
    setViewportWidth() {
      this.width = window.innerWidth
    },
  },
  mounted() {
    this.getApiOnce({})
    this.setViewportWidth()
    window.addEventListener('resize', this.setViewportWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewportWidth)
  }
}
</script>

<style lang="scss">
.slider {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  overflow: hidden;
  transition: 0.4s;
  .scroll {
    @include box(auto, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: nowrap;
    transition: 0.6s;
    .slide {
      @include box(100%, auto, relative, null, null, null, null);
      transition: 0.4s;
      img {
        @include box(100%, auto, relative, null, null, null, null);
        transition: 0.2s;
      }
      .text {
        @include box(60%, auto, absolute, 0, 0, null, null);
        padding: 6vw 10vw;
        .title {
          font-size: 1.1vw;
          font-weight: 700;
        }
        .subtitle {
          font-size: 1.6vw;
          padding-top: 1vw;
          font-weight: 700;
        }
        .slideText {
          font-size: 1vw;
          padding: 1vw 0;
          div {
            @include box(100%, auto, relative, null, null, null, null);
            margin: 1vw 0;
            span {
              &:first-child {
                font-weight: 700;
                font-size: 1.5vw;
                padding-right: 1vw;
              }
            }
          }
        }
        .subtext {
          font-size: 0.9vw;
          font-style: italic;
        }
        a {
          display: block;
        }
        .button {
          @include box(30%, auto, relative, null, null, null, null);
          z-index: 10;
        }
      }
    }
  }
  .controll {
    background-color: rgba(0, 0, 0, 0) !important;
    @include box(100%, 100%, absolute, 0, 0, null, null);
    z-index: 2;
    .lft {
      background-color: rgba(0, 0, 0, 0) !important;
      @include box(auto, 100%, absolute, 0, 0, null, null);
    }
    .rgt {
      background-color: rgba(0, 0, 0, 0) !important;
      @include box(auto, 100%, absolute, null, 0, 0, null);
    }
    .lft,
    .rgt {
      @include flex(center, center, row);
      .button {
        @include box(3vw, 3vw, relative, null, null, null, null);
        @include flex(center, center, row);
        background-color: rgba(44, 42, 41, 0.2) !important;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background-color: rgba(44, 42, 41, 0.4);
          transform: scale(0.9);
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .slider {
    .controll {
      .lft,
      .rgt {
        .button {
          @include box(8vw, 8vw, relative, null, null, null, null);
        }
      }
    }
    .scroll {
      .slide {
        .text {
          @include box(100%, auto, absolute, 0, 0, null, null);
          padding: 4vw 10vw;
          .title {
            font-size: 2vw;
          }
          .subtitle {
            font-size: 2.5vw;
            padding-top: 2vw;
          }
          .slideText {
            font-size: 2vw;
            padding: 1vw 0;
            div {
              margin: 1.5vw 0;
              span {
                &:first-child {
                  font-size: 2vw;
                  padding-right: 2vw;
                }
              }
            }
          }
          .subtext {
            font-size: 2vw;
          }
          .button {
            @include box(30%, auto, relative, null, null, null, null);
            z-index: 10;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .slider {
    user-select: none;
    margin-bottom: 6vw;
    .controll {
      display: none;
    }
    .scroll {
      .slide {
        overflow: hidden;
        @include flex(center, center, row);
        min-height: 100vw;
        img {
          position: absolute;
          object-position: right bottom;
          height: 100%;
          object-fit: cover;
        }
        .text {
          @include box(100%, auto, relative, 0, 0, null, null);
          padding: 8vw 10vw;
          .title {
            display: none;
            font-size: 5vw;
          }
          .subtitle {
            font-size: 6vw;
            padding-top: 4vw;
          }
          .slideText {
            font-size: 4vw;
            padding: 4vw 0;
            div {
              margin: 4vw 0;
              span {
                &:first-child {
                  font-size: 4vw;
                  padding-right: 4vw;
                }
              }
            }
          }
          .subtext {
            font-size: 4vw;
          }
          .button {
            margin-top: 150px;
            @include box(100%, auto, relative, null, null, null, null);
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
