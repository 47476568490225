<template>
  <div class="implemented-practice">
    <template v-if="item">
      <div
        class="practical__showcase"
        :style="{
          'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.banner})`, 'background-position': 'right'
        }"
      >
        <div class="mfc-cabinet-container">
          <div class="practical__showcase-inner">
            <ElTooltip  v-if="!!item.is_best" content="Лучший проект МФЦ 2020">
              <div class="practical__showcase-cup">
                <img
                  :src="require('../../../assets/mfcCabinet/cup.png')"
                  alt="Лучший проект МФЦ 2020"
                />
              </div>
            </ElTooltip>
            <ElTooltip  v-if="!!item.expert_review" content="Одобрено экспертами">
              <div class="practical__showcase-ok">
                <img :src="require('../../../assets/mfcCabinet/ok.png')" alt="Одобрено экспертами" />
              </div>
            </ElTooltip>
            <div v-if="!!item.is_best" class="practical__showcase-cup-block">
              <span>Лучший проект МФЦ</span>
            </div>
            <div v-if="!!item.expert_review" class="practical__showcase-ok-block">
              <span>Одобрено экспертами</span>
            </div>
            <div class="practical__showcase-page">
              <router-link to="/mfc-cabinet">Личный кабинет</router-link>
              <span>/</span>
              <p>Внедренные практики</p>
            </div>
            <p class="practical__showcase-title">{{ item.title }}</p>
            <p v-if="item.category" class="practical__showcase-category">{{ item.category }}</p>
            <div class="practical__showcase-block">
              <div v-if="!!item.embedded_count" class="practical__block-item">
                <div class="practical__block-icon">
                  <svg>
                    <use href="#ok" />
                  </svg>
                </div>
                <div class="practical__block-text">{{ item.embedded_count }}</div>
              </div>
              <div v-if="!!item.like" class="practical__block-item">
                <div class="practical__block-icon">
                  <svg>
                    <use href="#like" />
                  </svg>
                </div>
                <div class="practical__block-text">{{ item.like }}</div>
              </div>
              <div v-if="!!item.comment_count" class="practical__block-item">
                <div class="practical__block-icon">
                  <svg>
                    <use href="#comment" />
                  </svg>
                </div>
                <div class="practical__block-text">{{ item.comment_count }}</div>
              </div>
              <div v-if="!!item.selected_count" class="practical__block-item">
                <div class="practical__block-icon">
                  <svg>
                    <use href="#favorite" />
                  </svg>
                </div>
                <div class="practical__block-text">{{ item.selected_count }}</div>
              </div>
            </div>
              <div v-if="item.subject" class="practical__block-region">
                  <svg data-v-000d44a1="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-000d44a1="" d="M12 2C8.134 2 5 5.134 5 9C5 12.966 9.404 18.82 11.226 21.071C11.626 21.565 12.374 21.565 12.774 21.071C14.596 18.82 19 12.966 19 9C19 5.134 15.866 2 12 2ZM12 11.5C10.619 11.5 9.5 10.381 9.5 9C9.5 7.619 10.619 6.5 12 6.5C13.381 6.5 14.5 7.619 14.5 9C14.5 10.381 13.381 11.5 12 11.5Z" fill="white"></path></svg>
                  <span data-v-000d44a1="">{{ item.subject }}</span>
              </div>
          </div>
        </div>
      </div>

      <div class="practical">
        <div class="mfc-cabinet-container">
          <div class="practical__items practical__items-second">
            <div class="practical__sidebar">
              <div class="practical__projects">
                <a target="_blank" :href="item.redmine_link" class="practical__projects-item">
                  <div class="practical__projects-item-icon">
                    <svg>
                      <use href="#redmine" />
                    </svg>
                  </div>
                  <div class="practical__projects-item-text">Подпроект в Redmine</div>
                </a>
                <a
                  target="_blank"
                  :href="item.telegram_link"
                  class="practical__projects-item"
                >
                  <div class="practical__projects-item-icon">
                    <svg>
                      <use href="#telegram" />
                    </svg>
                  </div>
                  <div class="practical__projects-item-text">Чат в Telegram</div>
                </a>
                <a @click="switchMode('planning')" class="practical__projects-item">
                  <div class="practical__projects-item-icon">
                    <svg>
                      <use href="#plan" />
                    </svg>
                  </div>
                  <div class="practical__projects-item-text">Планирование ВКС</div>
                </a>
                <a
                  @click="$router.push({ name: 'PracticesView', params: { id: item.id } })"
                  class="practical__projects-item"
                >
                  <div class="practical__projects-item-icon">
                    <svg>
                      <use href="#practice" />
                    </svg>
                  </div>
                  <div class="practical__projects-item-text">Страница практики</div>
                </a>
              </div>
            </div>
            <div v-if="mode === 'progress'">
              <PracticeProgressCard :progressData="item" @finished="getPractice" />
            </div>
            <div v-else-if="mode === 'planning'">
              <PlanningPanel />
            </div>
          </div>
        </div>
        <div class="mfc-cabinet-container">
          <Other :items="filteredItems"></Other>
        </div>
      </div>
      <div></div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Other from '../../../components/views/practices/view/Other'
import PracticeProgressCard from './PracticeProgressCard.vue'
import PlanningPanel from './PlanningPanel.vue'

export default {
  name: 'MfcCabinetImplementedPractice',
  components: { Other, PracticeProgressCard, PlanningPanel },
  data() {
    return {
      progressData: {
        percent: 100,
        noReview: false,
      },
      mode: 'progress',
      id: null
    }
  },
  computed: {
    ...mapState('practices', ['item', 'items']),

    filteredItems() {
      return this.items.filter(item => item.id != this.id)
    },
  },
  methods: {
    ...mapActions('practices', ['getApiOnce', 'getApi']),
    switchMode(mode) {
      this.mode = mode
    },
    getPractice() {
      this.getApiOnce({
        id: this.$route.params.id,
        endpoint: '/profile/implemented-practices'
      })
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getPractice()
    this.getApi({})
  },
}
</script>

<style scoped>
.practical__showcase {
  padding: 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  width: 100%;
}

@media only screen and (max-width: 1170px) {
  .practical__showcase {
    background-position: center center;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .practical__showcase {
    padding: 60px 0 60px;
    background-position: center right;
  }
}

.practical__showcase-page {
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-page {
    margin-bottom: 30px;
  }
}

.practical__showcase-page a {
  color: #ffffff;
  transition: 300ms;
}

.practical__showcase-page a:hover {
  color: #e04e39;
}

.practical__showcase-page span {
  margin: 0 10px;
}

.practical__showcase-title {
    font-size: 44px;
    line-height: 52px;
    width: 730px;
    margin-bottom: 18px;
}

.practical__showcase-category {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
}

@media only screen and (max-width: 1170px) {
  .practical__showcase-title {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .practical__showcase-title {
    font-size: 24px;
    line-height: 26px;
  }
}

.practical__showcase-block {
  display: flex;
  align-items: center;
}

.practical__showcase-block li {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
}

.practical__showcase-block li img {
  margin-right: 5px;
}

.practical__showcase-block li:last-child {
  margin-right: 0;
}

.practical__showcase-inner {
  position: relative;
}

.practical__showcase-cup {
  position: absolute;
  top: -42px;
  right: 14px;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-cup {
    top: -62px;
  }
}

.practical__showcase-ok {
  position: absolute;
  top: -42px;
  right: 54px;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-ok {
    top: -62px;
  }
}

.practical__showcase-cup-block {
  position: absolute;
  top: 3px;
  right: -54px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  color: #fff;
  display: block;
  z-index: 20;
  opacity: 0;
  transition: 300ms;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-cup-block {
    right: 9px;
  }
}

.practical__showcase-cup-block.active {
  opacity: 1;
}

.practical__showcase-cup-block span {
  position: relative;
}

.practical__showcase-cup-block span::before {
  content: "";
  display: block;
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translate(0, -50%);
  background-size: cover;
  width: 20px;
  height: 8px;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-cup-block span::before {
    left: 89%;
  }
}

.practical__showcase-ok-block {
  position: absolute;
  top: 2px;
  right: -1px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  color: #fff;
  display: block;
  z-index: 20;
  opacity: 0;
  transition: 300ms;
}

.practical__showcase-ok-block-second {
  right: -46px;
}

@media only screen and (max-width: 767px) {
  .practical__showcase-ok-block-second {
    right: 3px;
  }

  .practical__showcase-ok-block-second span::before {
    left: 86% !important;
  }
}

.practical__showcase-ok-block.active {
  opacity: 1;
}

.practical__showcase-ok-block span {
  position: relative;
}

.practical__showcase-ok-block span::before {
  content: "";
  display: block;
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translate(0, -50%);
  background-size: cover;
  width: 20px;
  height: 8px;
}

.practical__projects a {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #e04e39;
  transition: 300ms;
}

@media only screen and (max-width: 1170px) {
  .practical__projects a {
    font-size: 16px;
  }
}

.practical__projects a:hover {
  color: #ac3d2c;
}

.practical__projects li a img {
  margin-right: 16px;
}

.practical__features {
  position: absolute;
  top: -8px;
  right: 8px;
  display: flex;
  flex-direction: row;
}

.practical__feature + .practical__feature {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .practical__features {
    right: 3px;
  }
}

.practical__block-item .practical__block-icon {
  margin-right: 2px;
}

.practical__block-icon {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
}

.practical__block-item {
  font-size: 18px;
    line-height: 28px;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.practical {
  overflow: hidden;
  padding: 36px 0 80px;
}

.practical__items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 861px) {
  .practical__items {
    display: block;
  }
}

.practical__sidebar {
  width: 255px;
}

@media screen and (max-width: 1170px) {
  .practical__sidebar {
    width: 200px;
  }
}

@media screen and (max-width: 861px) {
  .practical__sidebar {
    width: 100%;
    margin-bottom: 30px;
  }
}

.practical__location {
  width: 830px;
}

@media screen and (max-width: 1170px) {
  .practical__location {
    width: 600px;
  }
}

@media screen and (max-width: 861px) {
  .practical__location {
    width: 100%;
  }
}

.practical__content {
  background: #f6f2ec;
  border-radius: 4px;
  padding: 21px 24px 24px 24px;
}

.practical__content-percent {
  font-size: 18px;
  font-weight: 700;
  color: #2c2a29;
  margin-bottom: 11px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .practical__content-percent {
    font-size: 16px;
  }
}

.practical__content-line {
  width: 100%;
  height: 12px;
  position: relative;
  background: #e9d7c1;
  border-radius: 6px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .practical__content-line {
    height: 8px;
    margin-bottom: 15px;
  }
}

.practical__content-line-inner {
  content: "";
  height: 12px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #e04e39;
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .practical__content-line-inner {
    height: 8px;
  }
}

.practical__content-head {
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  margin-bottom: 4px;
}

.practical__content-header {
  font-size: 16px;
  font-weight: 700;
  color: #2c2a29;
  margin-bottom: 4px;
  text-transform: uppercase;
  margin-top: 16px;
}

.practical__content-text {
  font-size: 18px;
  color: #2c2a29;
}

@media only screen and (max-width: 767px) {
  .practical__content-text {
    font-size: 14px;
  }
}

.practical__projects-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
}

.practical__projects-item + .practical__projects-item {
  margin-top: 16px;
}

.practical__projects-item-icon {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
}

.practical__content-second textarea {
  resize: none;
  width: 100%;
  height: 132px;
  padding: 12px;
  font-size: 14px;
  color: #000;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.practical__content-second textarea::-moz-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second textarea::-ms-input-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second textarea::placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second .practical__content-value {
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  margin-bottom: 20px;
}

.practical__content-second .practical__content-btn {
  width: 213px;
  height: 44px;
  background: #e6dbd9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  transition: 300ms;
}

.practical__content-second .practical__content-btn:hover {
  background: #e04e39;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .practical__content-second .practical__content-btn {
    width: 100%;
  }
}

.practical__block-region {
    margin-top: 16px;
    font-size: 18px;
    display: flex;
}

.practical__block-region svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.practical__block-region span {
    margin-left: 8px;
}
</style>
