<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="searchicon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.14585 3.14585 0 7 0C10.8541 0 14 3.14585 14 7C14 8.748 13.348 10.345 12.2812 11.5742L12.707 12H14L20 18L18 20L12 14V12.707L11.5742 12.2812C10.345 13.348 8.748 14 7 14C3.14585 14 0 10.8541 0 7ZM12 7C12 4.22673 9.77327 2 7 2C4.22673 2 2 4.22673 2 7C2 9.77327 4.22673 12 7 12C9.77327 12 12 9.77327 12 7Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
}
</script>

<style lang="scss" scoped>
.searchicon{
  @include box(1vw, 0.9vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .searchicon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .searchicon{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
