<template>
    <div class="container">
        <div class="title">Размер долгов определяется на дату подачи заявления в МФЦ</div>
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        Размер долгов определяется на дату подачи заявления в МФЦ
                    </div>
                </div>
                <div class="row__table">
                    <div class="rows__table">
                        <div class="rows__title">
                            Узнать точную информацию о долгах можно
                        </div>
                    </div>
                    <div class="rows__table">
                        <div class="row">
                            <Document class="icon"/>
                        </div>
                        <div class="row">
                            <Book class="icon" />
                        </div>
                        <div class="row">
                            <Balance class="icon" />
                        </div>
                        <div class="row row__none">
                            <Edit class="icon" />
                        </div>
                    </div>
                    <div class="rows__table">
                        <div class="row">
                            <div class="text__title">
                                <div class="title__row">По налогам</div>
                                <div class="description__row">на сайте ФНС России</div>

                            </div>
                            <Button label="Перейти" background class="button custom"/>
                        </div>
                        <div class="row">
                            <div class="text__debts">
                                <div class="title__row">По штрафам</div>
                                <div class="description__row">на портале «Госуслуги»</div>
                            </div>
                            <Button label="Перейти" background class="button custom"/>
                        </div>
                        <div class="row">
                            <div class="text__title">
                                <div class="title__row">По исполнительным производствам</div>
                                <div class="description__row">на сайте ФССП</div>
                            </div>
                            <Button label="Перейти" background class="button custom"/>
                        </div>
                        <div class="row row__none">
                            <div class="text__debts">
                                <div class="title__row">Обратиться к кредитору с соответствующим запросом</div>
                            </div>
                        </div>
                    </div>
                    <div class="rows__table rows__table__none">
                        <div class="row">
                            <Edit class="icon" />
                        </div>
                    </div>
                    <div class="rows__table rows__table__none">
                        <div class="row"><div class="text__debts">
                            <div class="title__row">Обратиться к кредитору с соответствующим запросом</div>
                        </div></div>
                    </div>
                    <div class="rows__table">
                        <div class="rows__description">
                            Если по долгам истекли исковая давность либо срок для предъявления исполнительного листа к
                            исполнению, нет смысла подавать заявление о внесудебном банкротстве.
                        </div>
                    </div>
                    <div class="rows__table">
                        <div class="rows__description">
                            <b>Начало процедуры внесудебного банкротства возможно только после окончания испонительного
                                производства</b> в связи с тем, что у гражданина отсутствует имущество, на которое может быть
                            обращено <span class="red__text">взыскание</span> и если после окончания исполнительного производства не было возбуждено
                            новое исполнительное производство.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/elements/Button.vue'
    import Document from "../../icons/bankrutcy/Document";
    import Book from "../../icons/bankrutcy/Book";
    import Balance from "../../icons/bankrutcy/Balance";
    import Edit from "../../icons/bankrutcy/Edit";
    export default {
        name: "DebtsInfo",
        components: {
            Edit,
            Balance,
            Book,
            Document,
            Button
        }
    }
</script>

<style lang="scss" scoped>
    .rows__table__none {
        display: none!important;
    }
    .title {
        display: none;
    }
    .red__text {
        color: #E04E39!important;
        border-bottom: 1px dashed #E04E39;
        cursor: pointer;
    }
    .custom {
        width: 110px;
        height: 44px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        margin-top: 16px;
    }
    .sections {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding-top: 80px;
        .rows {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .row__name {
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
            .row__table {
                @include box(75%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                align-items: flex-start;
                padding-left: 15px;
                padding-right: 15px;
                .rows__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, row);
                    .rows__title {
                        text-align: left;
                        font-size: 22px;
                        line-height: 28px;
                        color: #2C2A29;
                        font-weight: 700;
                        padding: 0 15px 8px 15px;
                    }
                    .rows__description{
                        text-align: left;
                        font-size: 18px;
                        line-height: 28px;
                        color: #2C2A29;
                        font-weight: 400;
                        padding: 24px 15px 0 15px;
                    }
                    .row {
                        @include box(25%, auto, relative, null, null, null, null);
                        @include flex(center, center, column);
                        align-items: flex-start;
                        padding: 8px 15px;
                        .title__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 700;
                        }
                        .description__row {
                            text-align: left;
                            font-size: 18px;
                            line-height: 24px;
                            color: #2C2A29;
                            font-weight: 400;
                            padding-top: 4px;
                        }
                        .text__title {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                            color: #2C2A29;
                            .icon {
                                margin-left: 6px;
                            }
                        }
                        .text__debts {
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 36px;
                            color: #2C2A29;
                        }
                        .text {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #2C2A29;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .rows__table__none {
            display: block!important;
        }
        .title {
            font-weight: 700;
            font-size: 19px;
            line-height: 36px;
            padding-top: 80px;
            padding-left: 15px;
            display: block;
        }

        .custom {
            width: 100%;
            height: 44px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-top: 16px;
        }

        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-top: 24px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__name {
                    display: none!important;
                    @include box(25%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        .rows__title {
                            text-align: left;
                            font-size: 19px;
                            line-height: 28px;
                            color: #2C2A29;
                            font-weight: 700;
                            padding: 0 15px 8px 15px;
                        }
                        .rows__description{
                            text-align: left;
                            font-size: 16px;
                            line-height: 28px;
                            color: #2C2A29;
                            font-weight: 400;
                            padding: 24px 15px 0 15px;
                        }
                        .row__none {
                            display: none!important;
                        }
                        .row {
                            @include box(33%, auto, relative, null, null, null, null);
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 4px 15px;
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                                padding-top: 4px;
                            }
                            .text__title {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                                .icon {
                                    margin-left: 6px;
                                }
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .rows__table__none {
            display: block!important;
        }
        .title {
            font-weight: 700;
            font-size: 19px;
            line-height: 36px;
            padding-top: 80px;
            padding-left: 15px;
            display: block;
        }

        .custom {
            width: 100%;
            height: 44px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-top: 16px;
        }

        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-top: 24px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                display: block!important;
                .row__name {
                    display: none!important;
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    .name__table {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__table {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 0;
                    .rows__table {
                        @include box(100%, auto, relative, null, null, null, null);
                        @include flex(left, top, row);
                        display: block!important;
                        .rows__title {
                            text-align: left;
                            font-size: 19px;
                            line-height: 28px;
                            color: #2C2A29;
                            font-weight: 700;
                            padding: 0 15px 8px 15px;
                        }
                        .rows__description{
                            text-align: left;
                            font-size: 16px;
                            line-height: 28px;
                            color: #2C2A29;
                            font-weight: 400;
                            padding: 24px 15px 0 15px;
                        }
                        .row__none {
                            display: none!important;
                        }
                        .row {
                            @include box(100%, auto, relative, null, null, null, null);
                            width: 100% !important;
                            @include flex(center, center, column);
                            align-items: flex-start;
                            padding: 4px 15px;
                            .title__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 700;
                            }
                            .description__row {
                                text-align: left;
                                font-size: 16px;
                                line-height: 24px;
                                color: #2C2A29;
                                font-weight: 400;
                                padding-top: 4px;
                            }
                            .text__title {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 28px;
                                color: #2C2A29;
                                .icon {
                                    margin-left: 6px;
                                }
                            }
                            .text__debts {
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2C2A29;
                            }
                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #2C2A29;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

