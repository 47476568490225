<template>
  <router-link :to="to ? to : {}" class="button">
    <Print v-if="icon === 'print'" class="icon" />
    {{label}}
  </router-link>
</template>

<script>
import Print from '@/components/icons/Print.vue'

export default {
  name: 'Button',
  components: {
    Print
  },
  props: {
    to: String,
    label: String,
    icon: String
  }
}
</script>

<style lang="scss" scoped>
.button{
  @include box(auto, auto, relative, null, null, null, null);
  @include flex(center, center, row);
  padding: 0.7vw 1.3vw;
  border-radius: 0.4vw;
  border: 0.08vw solid #E04E39;
  color: #E04E39;
  font-size: 0.8vw;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.1s;
  &:hover{
    opacity: 0.8;
    transform: scale(0.97);
  }
  .icon{
    margin-right: 1vw;
  }
}
@media only screen and (max-width: 900px) {
  .button{
    padding: 1vw 2vw;
    border-radius: 0.4vw;
    border: 0.2vw solid #E04E39;
    font-size: 2vw;
    .icon{
      margin-right: 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .button{
    padding: 3vw 4vw;
    border-radius: 0.8vw;
    border: 0.5vw solid #E04E39;
    font-size: 4vw;
    .icon{
      margin-right: 4vw;
    }
  }
}
</style>
