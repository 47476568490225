<template>
  <div class="practical__location">
    <p class="practical__title">
      <span>{{ totalCount }}</span>
      {{ title }}
    </p>
    <div class="practical-list">
      <template v-for="item in items">
        <router-link
          :to="{ name: cardRouteName, params: { id: item.id } }"
          class="practical__item"
          :style="getBlockStyles(item)"
          :key="item.id"
        >
          <div class="practical__features">
            <template v-if="!!item.is_best">
              <ElTooltip content="Лучший проект МФЦ 2020">
                <div class="practical__cup practical__feature">
                  <img
                    :src="require('../../../../assets/mfcCabinet/cup.png')"
                    alt="Лучший проект МФЦ 2020"
                  />
                </div>
              </ElTooltip>
            </template>
            <template v-if="!!item.expert_review">
              <ElTooltip content="Одобрено экспертами">
                <div class="practical__ok practical__feature">
                  <img
                    :src="require('../../../../assets/mfcCabinet/ok.png')"
                    alt="Одобрено экспертами"
                  />
                </div>
              </ElTooltip>
            </template>
          </div>
          <p class="practical__head">
            {{ item.subject }}
          </p>
          <div class="practical__block">
            <p class="practical__block-head">{{ item.title }}</p>
            <div class="practical__block-items">
              <template v-if="showProgress">
                <div class="practical__block-items">
                  <div class="practical__line">
                    <div class="practical__block-item practical__block-item-first">
                      <div class="practical__block-icon">
                        <svg>
                          <use href="#ok" />
                        </svg>
                      </div>
                      <div class="practical__block-text">{{ item.progress + '%' }}</div>
                    </div>
                  </div>
                  <div class="practical__block-line practical__block-line--scale">
                    <div class="practical__block-line-inner" :style="getProgressStyles(item)"></div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="practical__line">
                  <div
                    class="practical__block-item practical__block-item-first"
                    @mouseenter="showAppTooltip('Внедрения', $event)"
                    @mouseleave="closeAppTooltip"
                  >
                    <div class="practical__block-icon">
                      <svg>
                        <use href="#ok" />
                      </svg>
                    </div>
                    <div class="practical__block-text">{{ +item.embedded_count }}</div>
                  </div>
                  <div
                    class="practical__block-item practical__block-item-second"
                    @mouseenter="showAppTooltip('Нравится', $event)"
                    @mouseleave="closeAppTooltip"
                  >
                    <div class="practical__block-icon">
                      <svg>
                        <use href="#like" />
                      </svg>
                    </div>
                    <div class="practical__block-text">{{ +item.like }}</div>
                  </div>
                  <div
                    class="practical__block-item practical__block-item-third"
                    @mouseenter="showAppTooltip('Комментарии', $event)"
                    @mouseleave="closeAppTooltip"
                  >
                    <div class="practical__block-icon">
                      <svg>
                        <use href="#comment" />
                      </svg>
                    </div>
                    <div class="practical__block-text">{{ +item.comment_count }}</div>
                  </div>
                </div>
                <div
                  class="practical__block-item practical__block-item-four"
                  @mouseenter="showAppTooltip('Добавили в избранное', $event)"
                  @mouseleave="closeAppTooltip"
                >
                  <div class="practical__block-text">{{ +item.selected_count }}</div>
                  <div class="practical__block-icon">
                    <svg>
                      <use href="#favorite" />
                    </svg>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PracticesList',
  inject: ['showAppTooltip', 'closeAppTooltip'],
  props: {
    title: {
      type: String,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    cardRouteName: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    getDate(date) {
      return new Date(date * 1000).toLocaleString('ru', { year: 'numeric', month: 'long', day: 'numeric' })
    },

    getBlockStyles(item) {
      return {
        'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.photo})`
      }
    },

    getProgressStyles(practice) {
      return { width: `${practice.progress}%` }
    },
  },
}
</script>

<style scoped>
.practical__location {
  width: 830px;
}

@media screen and (max-width: 1170px) {
  .practical__location {
    width: 600px;
  }
}

@media screen and (max-width: 861px) {
  .practical__location {
    width: 100%;
  }
}

.practical-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 22px;
  column-gap: 22px;
  row-gap: 24px;
}

@media screen and (max-width: 1170px) {
  .practical-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .practical-list {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
}

.practical__features {
  position: absolute;
  top: -8px;
  right: 8px;
  display: flex;
  flex-direction: row;
}

.practical__feature + .practical__feature {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .practical__features {
    right: 3px;
  }
}

.practical__item {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 300ms;
  position: relative;
}

.practical__item:hover {
  transform: scale(1.05);
  box-shadow: 0px 14px 14px rgba(59, 56, 56, 0.25);
}

.practical__item:hover .practical__head,
.practical__item:active .practical__head {
  display: block;
}

@media screen and (max-width: 767px) {
  .practical__item {
    background-position: center center;
  }
}

.practical__head {
  font-size: 14px;
  line-height: 20px;
  color: white;
  display: none;
}

.practical__block {
  margin-top: auto;
  margin-bottom: 0;
}

.practical__block-head {
  font-size: 18px;
  line-height: 24px;
  color: white;
  margin-bottom: 8px;
}

.practical__block-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.practical__block-item {
  display: flex;
  margin-right: 10px;
}

.practical__block-icon {
  min-width: 14px;
  max-width: 14px;
  height: 14px;
}

.practical__block-text {
  font-size: 12px;
  color: white;
  font-weight: 700;
}

.practical__block-item-four {
  margin-right: 0;
}

.practical__block-item .practical__block-icon {
  margin-right: 2px;
}

.practical__line {
  display: flex;
}

.practical__items-second .practical__title {
  display: flex;
  align-items: center;
}

.practical__items-second .practical__title span {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .practical__items-second .practical__title span {
    width: 30px;
    height: 30px;
  }
}

.practical__title {
  font-size: 44px;
  color: #2c2a29;
  margin-bottom: 28px;
}

@media screen and (max-width: 767px) {
  .practical__title {
    font-size: 22px;
    margin-bottom: 18px;
  }
}

.practical__title span {
  display: inline-block;
  padding: 8px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  background: #f6f2ec;
  border-radius: 4px;
  margin-right: 16px;
}

@media screen and (max-width: 767px) {
  .practical__title span {
    font-size: 22px;
    padding: 4px;
  }
}

.practical__block-line {
  height: 8px;
  width: 164px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

@media only screen and (max-width: 1170px) {
  .practical__block-line {
    width: 185px;
  }
}

@media only screen and (max-width: 767px) {
  .practical__block-line {
    width: 75%;
  }
}

.practical__block-line--scale {
  position: relative;
}

.practical__block-line-inner {
  content: "";
  height: 8px;
  background: #ffffff;
  border-radius: 4px;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
}
</style>
