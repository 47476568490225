<template>
  <div class="info">
    <div class="container">
      <div class="block">
        <div class="title">Наши ценности</div>
        <div class="rows">
          <div class="row">
            <LogoIcon1 />
            <div class="text">Внимание к потребностям людей</div>
          </div>
          <div class="row">
            <LogoIcon2 />
            <div class="text">Дружелюбный сервис и комфорт</div>
          </div>
          <div class="row">
            <LogoIcon3 />
            <div class="text">Близко, рядом, именно для меня</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon1 from '@/components/icons/home/LogoIcon1.vue'
import LogoIcon2 from '@/components/icons/home/LogoIcon2.vue'
import LogoIcon3 from '@/components/icons/home/LogoIcon3.vue'

export default {
  name: 'Info',
  components: {
    LogoIcon1,
    LogoIcon2,
    LogoIcon3
  }
}
</script>

<style lang="scss" scoped>
.info{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  padding: 2vw;
  .block{
    @include box(100%, auto, relative, null, null, null, null);
    background-color: #F6F2EC;
    padding: 3vw 0;
    .title{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(center, center, row);
      font-size: 1.4vw;
      padding: 3vw 0;
      padding-top: 0;
      font-weight: 700;
    }
    .rows{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      .row{
        @include box(33.3%, auto, relative, null, null, null, null);
        @include flex(center, center, column);
        .text{
          @include box(60%, auto, relative, null, null, null, null);
          @include flex(center, center, row);
          text-align: center;
          font-size: 1.1vw;
          padding-top: 1vw;
          font-weight: 700;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .info{
    @include box(100%, auto, relative, null, null, null, null);
    padding: 4vw 2vw;
    .block{
      padding: 4vw 0;
      .title{
        font-size: 3vw;
        padding: 4vw 0;
        padding-top: 0;
      }
      .rows{
        .row{
          .text{
            font-size: 2vw;
            padding-top: 2vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .info{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding: 4vw 6vw;
    .block{
      padding: 4vw 0;
      .title{
        font-size: 6vw;
        padding: 10vw 0;
        padding-top: 2vw;
      }
      .rows{
        @include flex(left, top, column);
        .row{
          @include box(100%, auto, relative, null, null, null, null);
          padding-bottom: 4vw;
          .text{
            font-size: 4.5vw;
            padding-top: 4vw;
          }
        }
      }
    }
  }
}
</style>
