<template>
  <div class="picture" :class="{ overlay }">
    <picture>
      <template v-for="(image, i) in images">
        <source :key="i" :srcset="imageUrl(image.name)" :media="getMediaQuery(image.minWidth, image.maxWidth)">
        <img v-if="image.default" :key="`default_image_${i}`" :src="imageUrl(image.name)" :alt="image.alt">
      </template>
    </picture>
  </div>
</template>

<script>

export default {
  name: 'Picture',
  props: {
    images: {type: Array, required: true},
    overlay: {type: Boolean, default: false},
  },
  methods: {
    imageUrl (imgName) {
      return imgName ? require('@/' + imgName) : '';
    },
    getMediaQuery(min, max) {
      const minQuery = min ? `(min-width: ${min})` : null;
      const maxQuery = max ? `(max-width: ${max})` : null;
      return [minQuery, maxQuery].filter(Boolean).join(' and ');
    },
  },
}
</script>
<style lang="scss" scoped>
.picture {
  &.overlay:after {
    @include box(100%, 100%, absolute, null, null, null, null);
    content: '';
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
  }
  source,
  img {
    width: 100%;
    height: auto;
  }
}
</style>
