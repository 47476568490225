<template>
  <div class="posterView">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Афиша', route: '/poster'}]" />
    <div class="container cont">
      <div class="section top">
        <div class="row min mobile">
          <div class="photo">
            <img :src="item.photo" />
          </div>
        </div>
        <div class="row max">
          <div class="title">{{item.name}}</div>
          <div class="photo">
            <img :src="item.photo" />
          </div>
          <div class="sponsor">{{item.organisators}}</div>
          <div class="date">{{getDate(item.date)}}</div>
          <a :href="item.action_link" target="_blank">
            <Button class="button" label="Перейти на сайт мероприятия" background />
          </a>
        </div>
      </div>
      <div class="section text">
        <div class="row min">
          <div class="title">Описание</div>
        </div>
        <div class="row max">
          <div class="text" v-html="item.about"></div>
        </div>
      </div>
      <div class="section text">
        <div class="row min">
          <div class="title">Участники</div>
        </div>
        <div class="row max">
          <div class="text" v-html="item.members"></div>
        </div>
      </div>
      <div class="section text">
        <div class="row min">
          <div class="title">Организатор</div>
        </div>
        <div class="row max">
          <div class="text">{{item.organisators}}</div>
        </div>
      </div>
      <div class="section text">
        <div class="row min">
          <div class="title">Место проведения</div>
        </div>
        <div class="row max">
          <div class="text">{{item.place}}</div>
        </div>
      </div>
      <ContentSection title="Скачать">
        <FilesList :files="getJson(item.docs)" />
      </ContentSection>
    </div>
    <Other :items="items.filter(initem => initem.id !== item.id)" />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Button from '@/components/elements/Button.vue'
import Other from '@/components/views/poster/view/Other.vue'
import ContentSection from "@/components/shared/ContentSection";
import FilesList from '@/components/shared/FilesList';

import {mapActions, mapState} from 'vuex';

export default {
  name: 'PosterView',
  components: {
    Breadcrumbs,
    Button,
    Other,
    ContentSection,
    FilesList,
  },
  computed: {
    ...mapState('events', [
      'item',
      'items'
    ]),
    id() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      docFiles: ['docs', 'doc', 'docx', 'rtf', 'txt']
    }
  },
  methods: {
    getJson(data) {
      return JSON.parse(data);
    },
  getDate(date) {
    return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
  },
    ...mapActions('events', [
      'getApiOnce',
        "getApi"
    ]),
  },
  mounted() {
    this.getApi({})
    this.getApiOnce(this.id)
  },
  watch: {
    id() {
      this.getApiOnce(this.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.posterView {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-bottom: 2vw;
  .breadcrumbs {
    padding: 2vw 0;
  }
  .cont {
    @include flex(left, top, column);
    padding: 1vw 0;
    .section {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      &.text {
        padding: 1vw 0;
      }
      .row {
        &.min {
          @include box(22%, auto, relative, null, null, null, null);
          .photo {
            @include box(100%, auto, relative, null, null, null, null);
            img {
              @include box(100%, auto, relative, null, null, null, null);
            }
          }
          .title {
            font-size: 1.1vw;
            font-weight: 700;
          }
        }
        &.max {
          @include box(78%, auto, relative, null, null, null, null);
          padding-left: 2vw;
          .photo {
            @include box(100%, auto, relative, null, null, null, null);
            display: none;
            img {
              @include box(100%, auto, relative, null, null, null, null);
            }
          }
          .title {
            @include box(60%, auto, relative, null, null, null, null);
            font-size: 2.2vw;
          }
          .sponsor {
            font-size: 1vw;
            padding: 1vw 0;
          }
          .date {
            font-size: 1vw;
          }
          .button {
            @include box(auto, auto, relative, null, null, null, null);
            margin-top: 2vw;
          }
          .text {
            font-size: 1vw;
            span {
              color: #e04e39;
            }
          }
          .files {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .file {
              @include box(100%, auto, relative, null, null, null, null);
              @include flex(left, top, row);
              border-top: 0.06vw solid #e9d7c1;
              padding: 1.5vw 0;
              .icon {
                @include box(3.3vw, auto, relative, null, null, null, null);
                @include flex(center, center, row);
                flex-shrink: 0;
              }
              .info {
                @include box(100%, auto, relative, null, null, null, null);
                padding-left: 1vw;
                .name {
                  font-size: 0.9vw;
                  color: #e04e39;
                  transition: 0.2s;
                  cursor: pointer;
                  &:hover {
                    opacity: 0.6;
                  }
                }
                .size {
                  font-size: 0.7vw;
                  color: rgba(44, 42, 41, 0.7);
                  padding-top: 0.5vw;
                }
              }
              &:first-child {
                border-top: 0;
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .posterView {
    @include box(100%, auto, relative, null, null, null, null);
    padding-bottom: 2vw;
    .breadcrumbs {
      padding: 4vw 4vw;
    }
    .cont {
      padding: 1vw 4vw;
      .section {
        &.text {
          padding: 2vw 0;
        }
        .row {
          &.min {
            @include box(22%, auto, relative, null, null, null, null);
            .title {
              font-size: 2.2vw;
              font-weight: 700;
            }
          }
          &.max {
            @include box(78%, auto, relative, null, null, null, null);
            padding-left: 4vw;
            .title {
              @include box(60%, auto, relative, null, null, null, null);
              font-size: 3.3vw;
            }
            .sponsor {
              font-size: 2vw;
              padding: 2vw 0;
            }
            .date {
              font-size: 2vw;
            }
            .button {
              @include box(auto, auto, relative, null, null, null, null);
              margin-top: 2vw;
            }
            .text {
              font-size: 2vw;
            }
            .files {
              .file {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                border-top: 0.15vw solid #e9d7c1;
                padding: 2vw 0;
                .icon {
                  @include box(7vw, auto, relative, null, null, null, null);
                  @include flex(center, center, row);
                  flex-shrink: 0;
                }
                .info {
                  @include box(100%, auto, relative, null, null, null, null);
                  padding-left: 3vw;
                  .name {
                    font-size: 2vw;
                  }
                  .size {
                    font-size: 1.5vw;
                    padding-top: 1vw;
                  }
                }
                &:first-child {
                  border-top: 0;
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .posterView {
    @include box(100%, auto, relative, null, null, null, null);
    padding-bottom: 2vw;
    .breadcrumbs {
      padding: 6vw 6vw;
    }
    .cont {
      padding: 4vw 6vw;
      .section {
        @include flex(left, top, column);
        &.text {
          padding: 4vw 0;
        }
        .row {
          &.min {
            @include box(100%, auto, relative, null, null, null, null);
            padding-bottom: 4vw;
            &.mobile {
              display: none;
            }
            .title {
              font-size: 4.9vw;
            }
            .photo {
              display: none;
            }
          }
          &.max {
            @include box(100%, auto, relative, null, null, null, null);
            padding-left: 0;
            .photo {
              display: flex;
              padding-top: 4vw;
            }
            .title {
              @include box(100%, auto, relative, null, null, null, null);
              font-size: 7.7vw;
              padding-top: 0;
            }
            .sponsor {
              font-size: 4vw;
              padding: 4vw 0;
            }
            .date {
              font-size: 4vw;
            }
            .button {
              @include box(100%, auto, relative, null, null, null, null);
              margin-top: 4vw;
            }
            .text {
              font-size: 4vw;
            }
            .files {
              .file {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                border-top: 0.15vw solid #e9d7c1;
                padding: 4vw 0;
                .icon {
                  @include box(12vw, auto, relative, null, null, null, null);
                  @include flex(center, center, row);
                  flex-shrink: 0;
                }
                .info {
                  @include box(100%, auto, relative, null, null, null, null);
                  padding-left: 5vw;
                  .name {
                    font-size: 4vw;
                  }
                  .size {
                    font-size: 3.5vw;
                    padding-top: 1vw;
                  }
                }
                &:first-child {
                  border-top: 0;
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
