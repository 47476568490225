<template>
  <div class="newsview">
    <div class="title">Новости</div>
    <div class="rows" v-if="news.length">
      <div class="row max">
        <router-link :to="{ name: 'NewsView', params: { id: item.id }}" class="item" v-for="item in getFirstNews" :key="item.id">
          <div class="image">
            <img :src="item.anons_photo" />
          </div>
          <div class="bottom">
            <span class="link">{{item.title}}</span>
            <div class="date">
              <span>{{getDate(item.date)}}</span>
              <span></span>
            </div>
          </div>
        </router-link >
      </div>
      <div class="row min list">
        <Item v-for="item in getAllNews" :key="item.id" :item="item" :groups="groups" :image="false" />
      </div>
    </div>
    <div class="rows" v-else>Ничего не найдено</div>
  </div>
</template>

<script>
import Item from '@/components/views/news/Item.vue'

export default {
  name: 'NewsView',
  components: {
    Item
  },
  props: {
    news: Array,
    groups: Array,
  },
  data() {
    return {
      size: 5
    }
  },
  computed: {
    getFirstNews() {
      return this.news.filter((item, i) => i < 1 && item)
    },
    getAllNews() {
      return this.news.filter((item, i) => i < 4 && i > 0 && item)
    }
  },
  methods: {
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    },
  }
}
</script>

<style lang="scss" scoped>
.newsview{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding: 0;
  padding-top: 1vw;
  .title{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    font-size: 2vw;
    padding-bottom: 1.5vw;
  }
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    .row{
      &.max{
        @include box(70%, auto, relative, null, null, null, null);
        padding-right: 2vw;
        .item{
          @include box(100%, 19vw, relative, null, null, null, null);
          @include flex(left, top, row);
          overflow: hidden;
          border-radius: 0.4vw;
          .image{
            @include box(100%, 20vw, relative, null, null, null, null);
            overflow: hidden;
            border-radius: 0.3vw;
            &:after{
              @include box(100%, 100%, absolute, 0, 0, null, null);
              content: '';
              background-color: rgba(0, 0, 0, 0.4);
            }
            img{
              @include box(100%, auto, relative, null, null, null, null);
              min-height: 100%;
              transition: 0.2s;
            }
          }
          &:after{
            content: '';
            @include box(100%, 100%, absolute, 0, 0, null, null);
            background-color: rgba(0, 0, 0, 0.3);
          }
          .bottom{
            @include box(100%, auto, absolute, 0, null, null, 0);
            padding: 1.5vw;
            z-index: 2;
            .link{
              color: #FFF;
              font-size: 1vw;
              transition: 0.1s;
              &:hover{
                opacity: 0.8;
              }
            }
            .date{
              color: #FFF;
              padding-top: 1vw;
              font-size: 0.8vw;
              span{
                margin-right: 1.5vw;
              }
            }
          }
          &:hover{
            .image{
              img{
                transform: scale(1.1);
              }
            }
          }
        }
      }
      &.min{
        @include box(30%, auto, relative, null, null, null, null);
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .newsview{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    margin-top: 3vw;
    padding: 0;
    .title{
      display: none;
    }
    .rows{
      @include flex(left, top, row);
      .row{
        &.max{
          @include box(65.5%, auto, relative, null, null, null, null);
          padding-right: 0;
          .item{
            @include box(100%, 30vw, relative, null, null, null, null);
            .bottom{
              padding: 3vw;
              .link{
                font-size: 2vw;
              }
              .date{
                padding-top: 2vw;
                font-size: 1.5vw;
                span{
                  margin-right: 2vw;
                }
              }
            }
          }
        }
        &.min{
          @include box(35.5%, auto, relative, null, null, null, null);
          padding: 0 3vw;
          padding-bottom: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .newsview{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    margin-top: 6vw;
    padding: 0;
    .title{
      display: none;
    }
    .rows{
      @include flex(left, top, column);
      .row{
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-right: 0;
          .item{
            @include box(100%, 60vw, relative, null, null, null, null);
            .bottom{
              padding: 6vw;
              .link{
                font-size: 4vw;
              }
              .date{
                padding-top: 4vw;
                font-size: 3.5vw;
                span{
                  margin-right: 4vw;
                }
              }
            }
          }
        }
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          padding: 6vw;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
