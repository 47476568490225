<template>
  <div class="models">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Models',
}
</script>

<style lang="scss" scoped>
.models{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
}
</style>
