<template>
  <div class="selectbox" :class="{ disabled: disabled }">
    <div class="title" @click="titleClickHandler">
      <div class="name">{{ getName }}</div>
      <div v-if="getSelectedOption" class="icon cross-icon" @click.stop="clearOption">
        <img src="@/assets/undecomposed/cross_black.svg" alt />
      </div>
      <div class="icon">
        <img src="@/assets/undecomposed/arrow-down.svg" alt />
      </div>
    </div>
    <div class="options" v-if="select" ref="options">
      <div class="search-container" v-if="hasSearch">
        <input
          v-model="searchValue"
          @keyup="searchKeyupHandler"
          type="text"
          ref="searchInput"
          placeholder="Поиск"
          class="search"
        />
      </div>
      <div class="option" v-if="hasAllChoice" @click="setDefault()">{{ allChoiceText }}</div>
      <div
        class="option option-real"
        v-for="item in getOptions"
        :key="item.type"
        :class="{ active: item.select }"
        @click="setOption(item, item.type)"
      >{{ item.name }}</div>
      <div class="option" v-if="hasSearch && getOptions.length === 0">Совпадений не найдено</div>
    </div>
  </div>
</template>

<script>
// import ClearIcon from '@/components/icons/ClearIcon.vue';

export default {
  name: 'SelectBox',
  components: {
    // ClearIcon
  },
  props: {
    options: Array,
    default: String,
    keydef: String,
    disabled: Boolean,
    hasAllChoice: { type: Boolean, default: false },
    hasSearch: { type: Boolean, default: false },
    allChoiceText: { type: String, default: 'Все' },
    cleansable: Boolean
  },
  computed: {
    getName() {
      return this.getSelectedOption?.name || this.default
    },
    getSelectedOption() {
      return this.options?.find(item => item.select)
    },
    getOptions() {
      if (this.hasSearch) {
        return this.options.filter(option => option.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1);
      } else {
        return this.options;
      }
    }
  },
  data() {
    return {
      select: false,
      searchValue: '',
    }
  },
  methods: {
    titleClickHandler() {
      this.select = this.select ? false : !this.disabled;
      this.$nextTick(() => {
        if (this.select && this.hasSearch) {
          this.$refs.searchInput.focus();
        }
      });
    },
    closeMenu(e) {
      if (!e.target.closest('.selectbox')) this.select = false
    },
    setOption(option, i) {
      this.select = false
      this.$emit('set', { index: i, value: this.keydef ? option[this.keydef] : option })
    },
    clearOption() {
      this.setDefault()
    },
    setDefault() {
      this.searchValue = '';
      this.select = false;
      this.setOption({}, -1);
    },
    searchKeyupHandler(e) {
      //Esc
      if (e.keyCode === 27) {
        this.searchValue = '';
        this.select = false;
      }
      //Enter
      if (e.keyCode === 13) {
        const option = this.$refs.options.getElementsByClassName('option-real')[0];
        if (option) {
          option.click();
        }
      }
      //ArrowDown
      if (e.keyCode === 40) {
        this.$refs.searchInput.blur();
        //todo: при нажатии стрелок сделать перемещение по списку
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.cross-icon {
  margin: 0 10px;
}
.selectbox {
  @include box(100%, 44px, relative, null, null, null, null);
  outline: none;
  border-radius: 0.2vw;
  border: 0.04vw solid #d0c7c2;
  user-select: none;
  margin: 0 0 16px 0;
  .title {
    @include box(100%, 44px, relative, null, null, null, null);
    @include flex(center, center, row);
    cursor: pointer;
    padding: 0 17px 0 12px;
    .name {
      @include box(100%, auto, relative, null, null, null, null);
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(44, 42, 41, 0.7);
    }
    .icon {
      @include box(14px, auto, relative, null, null, null, null);
      @include flex(center, center, row);
      flex-shrink: 0;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &.disabled {
    opacity: 0.5;
    .title {
      cursor: default !important;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .options {
    @include box(100%, auto, absolute, 0, 100%, null, null);
    max-height: 25vw;
    overflow-y: auto;
    @include flex(left, top, column);
    border-radius: 0.2vw;
    border: 0.04vw solid #d0c7c2;
    background-color: #fff;
    z-index: 10;
    margin-top: 0.5vw;
    div {
      @include box(100%, auto, relative, null, null, null, null);
      padding: 0.6vw 1vw;
      font-size: 14px;
      cursor: pointer;
      transition: 0.2s;
      &:hover,
      &:focus {
        opacity: 0.7;
        background-color: rgba(238, 238, 238, 0.6);
      }
      &.active {
        background-color: #eee;
      }
      &.search-container {
        @include box(100%, 3vw, sticky, null, null, null, null);
        background-color: #fff;
        font-size: 14px;
        top: 1px;
        z-index: 15;
        .search {
          @include box(100%, 2vw, relative, null, null, null, null);
          padding: 0 1vw;
          border-radius: 0.3vw;
          border: 0.04vw solid #d0c7c2;
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .selectbox {
    @include box(100%, 44px, relative, null, null, null, null);
    border-radius: 0.4vw;
    border: 0.04vw solid #d0c7c2;
    .title {
      @include box(100%, 44px, relative, null, null, null, null);
      padding: 0 17px 0 12px;
      .name {
        font-size: 14px;
      }
      .icon {
        @include box(14px, auto, relative, null, null, null, null);
      }
    }
    .options {
      @include box(100%, auto, absolute, 0, 100%, null, null);
      border-radius: 0.4vw;
      margin-top: 1vw;
      div {
        padding: 6px 12px;
        font-size: 14px;
        &.search-container {
          margin-bottom: 4vw;
          .search {
            @include box(100%, 4vw, relative, null, null, null, null);
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .selectbox {
    @include box(100%, 44px, relative, null, null, null, null);
    border-radius: 0.8vw;
    border: 0.08vw solid #d0c7c2;
    .title {
      @include box(100%, 44px, relative, null, null, null, null);
      padding: 0 17px 0 12px;
      .name {
        font-size: 14px;
      }
      .icon {
        @include box(14px, auto, relative, null, null, null, null);
      }
    }
    .options {
      max-height: 57vw;
      border-radius: 0.8vw;
      margin-top: 2vw;
      div {
        padding: 3vw 4vw;
        font-size: 14px;
        &.search-container {
          margin-bottom: 8vw;
          .search {
            @include box(100%, 8vw, relative, null, null, null, null);
            padding: 0 3vw;
            font-size: 4vw;
          }
        }
      }
    }
  }
}
</style>
