<template>
  <div class="contest-news-view-page">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Новости', route: '/news'}]" />
    <div class="container">
      <ContentSection no-side-padding default-font-size>
        <div slot="side">
          <router-link :to="{ name: 'ContestNewsIndex' }" class="back-link link">
            <ArrowLeft />
            <span>К списку новостей</span>
          </router-link>
        </div>
        <template v-if="newsPage">
          <div class="datetime">
            <span class="datetime__date">{{ getDate(newsPage.date) }}</span>
            <span class="datetime__time">{{ getDate(newsPage.date, true) }}</span>
          </div>
          <TextContent :title="newsPage.title" :content="newsPage.content"></TextContent>
          <div class="source">
            <div class="source__author">{{ newsPage.author }}</div>
            <a class="source__link link" :href="newsPage.source" target="_blank">Источник</a>
          </div>
        </template>
        <template v-else>
          <h2>Новость не найдена</h2>
        </template>
      </ContentSection>
      <Social :item="newsPage" v-if="newsPage" />
      <ContentSection title="Другие новости" no-side-padding>
        <NewsList
          :news="getOtherNews"
          page-name="ContestNewsView"
          :has-big-preview="false"
          :count="3"
        />
        <div class="buttons">
          <Button to="/contest/news" label="Все новости" />
        </div>
      </ContentSection>
      <ContentSection title="Другие разделы" no-side-padding>
        <Others :links="getOthers" />
      </ContentSection>
    </div>
  </div>
</template>

<script>
import ContentSection from '@/components/shared/ContentSection';
import Others from '@/components/views/contest/Others';
import Breadcrumbs from "@/components/Breadcrumbs";
import ArrowLeft from '@/components/icons/ArrowLeft.vue';
import TextContent from "@/components/shared/TextContent";
import Social from '@/components/views/news/Social.vue';
import NewsList from '@/components/views/contest/NewsList.vue';
import Button from '@/components/Button';
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: 'ContestNewsView',
  components: {
    ArrowLeft,
    Breadcrumbs,
    ContentSection,
    Others,
    TextContent,
    Social,
    NewsList,
    Button,
  },
  data() {
    return {
      pageName: 'Конкурс «Лучший МФЦ»',
      pageTitle: 'Конкурс «Лучший МФЦ»',
      headerTitle: 'Новости Всероссийского конкурса «Лучший многофункциональный центр России»',
      pagePath: '/contest',
    }
  },
  watch: {

  },
  computed: {
    ...mapState('contestNews', [
      'links',
      'pageType',
      'news',
      'newsPage',
    ]),
    ...mapGetters('contestNews', [
      'getOthers',
      'getNewsSortedByDate',
      'getOtherNews',
    ]),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('contestNews', [
      'getApi',
      'getApiOnce'
    ]),
    getDate(date, time = false) {
      const params = time ? { hour: 'numeric', minute: 'numeric' } : { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date * 1000).toLocaleString("ru", params);
    }
  },
  mounted() {
    this.getApiOnce(this.id);
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.contest-news-view-page {
  @include contest-page-style;
  .breadcrumbs {
    padding: 2vw 0 1vw;
  }
  .container {
    padding-top: 0;
  }
  .back-link {
    @include flex(left, center, row);
    font-size: 1.1vw;
    color: #e04e39;
    transition: opacity 0.2s;
    span {
      padding-left: 1vw;
    }
  }
  .datetime {
    font-size: 1vw;
    color: rgba(44, 42, 41, 0.7);
    &__date {
      margin-right: 1vw;
    }
  }
  .source {
    &__author {
      font-weight: bold;
      margin-bottom: 0.2vw;
    }
  }
  .link {
    color: #e04e39;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
  .buttons {
    @include box(15%, auto, relative, null, null, null, null);
    display: flex;
    padding: 1vw 0;
  }
}
</style>
