<template>
  <div class="text-content">
    <h1>{{ title }}</h1>
    <div v-html="content"></div>
  </div>
</template>

<script>

export default {
  name: 'TextContent',
  props: {
    title: {type: String, default: ''},
    content: {type: String, required: true},
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.text-content {
  h1 {
    font-size: 2.3vw;
    font-weight: normal;
  }
}
@media only screen and (max-width: 900px) {
  .text-content {
    h1 {
      font-size: 3.7vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .text-content {
    h1 {
      font-size: 6.7vw;
    }
  }
}
</style>
