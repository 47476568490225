<template>
  <div class="mfc-cabinet-notifications">
    <div class="mfc-cabinet-container">
      <div class="mfc-cabinet-notifications__items">
        <div class="mfc-cabinet-notifications__sidebar">
          <div class="mfc-cabinet-notifications__nav-list">
            <router-link
              v-for="link in routerLinks"
              :key="link.routeName"
              class="mfc-cabinet-notifications__nav-list-item"
              active-class="mfc-cabinet-notifications__nav-list-item--active"
              :to="{ name: link.routeName }"
            >{{ link.title }}</router-link>
          </div>
        </div>
        <div class="mfc-cabinet-notifications__list">
          <router-view :key="$route.path"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MfcCabinetNotificationsIndex',
  data: () => ({
    routerLinks: [
      {
        title: 'Уведомления',
        routeName: 'notifications',
      },
      {
        title: 'Комментарии',
        routeName: 'comments',
      },
      {
        title: 'Отзывы',
        routeName: 'reviews',
      },
    ]
  })
}
</script>

<style scoped>
.mfc-cabinet-notifications {
  overflow: hidden;
  padding: 36px 0;
}

.mfc-cabinet-notifications__items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 861px) {
  .mfc-cabinet-notifications__items {
    display: block;
  }
}

.mfc-cabinet-notifications__sidebar {
  width: 255px;
}

@media screen and (max-width: 1170px) {
  .mfc-cabinet-notifications__sidebar {
    width: 200px;
  }
}

.mfc-cabinet-notifications__nav-list {
  display: inline-flex;
  flex-direction: column;
}

.mfc-cabinet-notifications__nav-list-item {
  margin-bottom: 12px;
  display: inline-block;
  transition: 300ms;
  position: relative;
}

.mfc-cabinet-notifications__nav-list-item:hover,
.mfc-cabinet-notifications__nav-list-item.mfc-cabinet-notifications__nav-list-item--active {
  color: #e04e39;
}

.mfc-cabinet-notifications__nav-list-item:last-child {
  margin-bottom: 0;
}

.mfc-cabinet-notifications__nav-list-item--mark::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -8px;
  width: 8px;
  height: 8px;
  background: #ea543a;
  border-radius: 50%;
}

.mfc-cabinet-notifications__nav-list-item {
  font-size: 18px;
  color: #2c2a29;
}

.mfc-cabinet-notifications__list {
  width: 830px;
  max-width: 100%;
}

@media screen and (max-width: 1170px) {
  .mfc-cabinet-notifications__list {
    width: 600px;
    max-width: 100%;
  }
}
</style>
