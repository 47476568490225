<template>
  <div class="contest-regional-news-index-page">
    <PageHeader
      class="page-header"
      :page-name="pageName"
      :page-title="pageTitle"
      :header-title="headerTitle"
      :route-path="pagePath"
    >
      <LogoBigIcon class="logo" />
    </PageHeader>
    <div class="container">
      <ContentSection>
        <Sidebar
          :links="getLinksWithSelect"
          :current="pageType"
          slot="side"
          @set-page="setRouterPage"
        />
        <NewsList :news="getNewsSortedByDate" page-name="ContestRegionalNewsView" />
        <Pagination
          :pages="totalCount"
          :current="page"
          :per-page="perPage"
          @setpage="setPage"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </ContentSection>
      <ContentSection title="Другие разделы">
        <Others :links="getOthers" />
      </ContentSection>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/shared/PageHeader';
import ContentSection from '@/components/shared/ContentSection';
import Sidebar from '@/components/shared/Sidebar';
import Others from '@/components/views/contest/Others';
import NewsList from '@/components/views/contest/NewsList.vue';
import LogoBigIcon from "@/components/icons/LogoBigIcon";
import Pagination from "@/components/Pagination";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'ContestRegionalNewsIndex',
  components: {
    PageHeader,
    ContentSection,
    Sidebar,
    Others,
    NewsList,
    LogoBigIcon,
    Pagination,
  },
  data() {
    return {
      pageName: 'Конкурс «Лучший МФЦ»',
      pageTitle: 'Конкурс «Лучший МФЦ»',
      headerTitle: 'Новости Всероссийского конкурса «Лучший многофункциональный центр России»',
      pagePath: '/contest',
    }
  },
  watch: {

  },
  computed: {
    ...mapState('contestRegionalNews', [
      'links',
      'pageType',
      'news',
      'page',
      'totalCount',
      'perPage',
    ]),
    ...mapGetters('contestRegionalNews', [
      'getOthers',
      'getNewsSortedByDate',
      'getLinksWithSelect',
    ]),
  },
  methods: {
    ...mapActions('contestRegionalNews', [
      'getApi',
      'setPage',
      'setRouterPage',
    ]),
  },
  mounted() {
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.contest-regional-news-index-page {
  @include contest-page-style;
}
</style>
