<template>
  <div class="sidebar">
    <div class="select">
      <ComboSelect
        :value.sync="filter.categories"
        :options="categories"
        label="Выберите категорию"
        :sub="true"
      />
      <ComboSelect
        :value.sync="filter.regions"
        :options="regions"
        label="Выберите регион"
        has-search
        search-placeholder="Поиск региона"
      />
      <ComboSelect
        :value.sync="filter.mfc"
        :options="mfcSystemTypes"
        label="Офисы МФЦ"
        has-range
        range-title="Количество офисов МФЦ"
        options-title="Система организации МФЦ"
      />
      <ComboSelect :value.sync="filter.price" label="Стоимость внедрения" has-range rub/>
      <ComboSelect
        :value.sync="filter.bestProject"
        :options="bestProjects"
        label="Лучший проект МФЦ"
      />
      <HashtagSelect />
    </div>
  </div>
</template>

<script>
import HashtagSelect from '@/components/views/practices/index/HashtagSelect.vue'
import ComboSelect from '@/components/elements/ComboSelect.vue'

import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    HashtagSelect,
    ComboSelect
  },
  data: () => ({
    filter: {},
    mfcSystemTypes: [
      {
        name: 'Централизованная',
        select: false,
      },
      {
        name: 'Децентрализованная',
        select: false
      },
      {
        name: 'Смешанная',
        select: false
      }
    ]
  }),
  props: {
    sections: Array,
    regions: Array,
    categories: Array,
  },
  computed: {
    ...mapGetters('practices', [
      'getHashtags',
      'getFilter',
      'bestProjects'
    ])
  },
  methods: {
    ...mapActions('practices', [
      'getApi',
    ]),
    ...mapMutations('practices', [
      'setFilter',
    ]),
    ...mapActions('practices', [
      'getBestProjects'
    ]),
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.setFilter(this.filter)
        this.getApi({})
      }
    }
  },
  created() {
    this.filter = JSON.parse(JSON.stringify(this.getFilter))
  },
  mounted() {
    this.$emit('setsection', this.$route.params.section ? this.$route.params.section : 'all')
    this.getBestProjects()
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-right: 15px;
  .links {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .link {
      font-size: 1vw;
      color: #e04e39;
      margin-bottom: 1vw;
      &.active {
        color: #2c2a29;
        cursor: default;
      }
    }
  }
  .select {
    @include box(100%, auto, relative, null, null, null, null);
    margin-top: 0;
  }
}
@media only screen and (max-width: 900px) {
  .sidebar {
    @include flex(left, top, row);
    padding: 0 15px;
    .links {
      display: none;
    }
    .select {
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 15px;
      margin-right: 0;
      &.mobile {
        display: flex;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .sidebar {
    @include flex(left, top, column);
    padding: 0;
    .select {
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 4vw;
      &:nth-child(1) {
        margin-right: 0;
      }
      &:nth-child(3) {
        margin-left: 0;
      }
    }
  }
}
</style>
