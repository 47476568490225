<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M8.29297 4.79297L6 7.08594L4.70703 5.79297L3.29297 7.20703L6 9.91406L9.70703 6.20703L8.29297 4.79297ZM12 6V8H25V6H12ZM8.29297 11.793L6 14.0859L4.70703 12.793L3.29297 14.207L6 16.9141L9.70703 13.207L8.29297 11.793ZM12 14V16H21V14H12ZM24 17C22.75 17 21.6852 17.5048 21.002 18.2734C20.3187 19.0421 20 20.0278 20 21C20 21.9722 20.3187 22.9579 21.002 23.7266C21.0393 23.7686 21.103 23.7877 21.1426 23.8281C19.3016 24.8554 18 26.7517 18 29H20C20 26.7796 21.7796 25 24 25C26.2204 25 28 26.7796 28 29H30C30 26.7517 28.6984 24.8554 26.8574 23.8281C26.8971 23.7877 26.9607 23.7686 26.998 23.7266C27.6813 22.9579 28 21.9722 28 21C28 20.0278 27.6813 19.0421 26.998 18.2734C26.3148 17.5048 25.25 17 24 17ZM24 19C24.75 19 25.1852 19.2452 25.502 19.6016C25.8187 19.9579 26 20.4722 26 21C26 21.5278 25.8187 22.0421 25.502 22.3984C25.1852 22.7548 24.75 23 24 23C23.25 23 22.8148 22.7548 22.498 22.3984C22.1813 22.0421 22 21.5278 22 21C22 20.4722 22.1813 19.9579 22.498 19.6016C22.8148 19.2452 23.25 19 24 19ZM8.29297 19.793L6 22.0859L4.70703 20.793L3.29297 22.207L6 24.9141L9.70703 21.207L8.29297 19.793ZM12 22V24H17V22H12Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "List"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
