<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="modelicon">
    <path d="M11.6875 0L11.375 0.28125L4.59375 7H0V23H16.8438C18.2539 23 19.4844 22.0039 19.7812 20.625L21.9375 10.625C22.332 8.77734 20.8867 7 19 7H13.25L13.4375 6.25C13.6406 6.09375 13.7695 6.02734 14.0625 5.625C14.5312 4.98438 15 3.99219 15 2.65625C15 1.23047 13.7109 0 12.0938 0H11.6875ZM12.4062 2.09375C12.8281 2.17578 13 2.34766 13 2.65625C13 3.55859 12.7266 4.11719 12.4688 4.46875C12.2109 4.82031 12.0312 4.90625 12.0312 4.90625L11.6875 5.09375L11.5625 5.5L10.9688 7.75L10.6562 9H19C19.6602 9 20.1055 9.57422 19.9688 10.2188L17.8438 20.2188C17.7422 20.6875 17.3203 21 16.8438 21H6V8.40625L12.4062 2.09375ZM2 9H4V21H2V9Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: "ModelIcon4"
}
</script>

<style lang="scss" scoped>
.modelicon {
  @include box(1.3vw, 1.3vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .modelicon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .modelicon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
