<template>
<div class="region">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C8.134 2 5 5.134 5 9C5 12.966 9.404 18.82 11.226 21.071C11.626 21.565 12.374 21.565 12.774 21.071C14.596 18.82 19 12.966 19 9C19 5.134 15.866 2 12 2ZM12 11.5C10.619 11.5 9.5 10.381 9.5 9C9.5 7.619 10.619 6.5 12 6.5C13.381 6.5 14.5 7.619 14.5 9C14.5 10.381 13.381 11.5 12 11.5Z"
      fill="white"/>
  </svg>
  <span>
          <slot/>
      </span>
</div>
</template>

<script>
export default {
  name: 'PracticeRegion'
}
</script>

<style lang="scss" scoped>
.region {
  color: white;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  @media (max-width: 899px) {
    svg {
      display: none;
    }
  }
}
</style>
