<template>
  <div class="input">
    <input type="text" v-model="search" @keyup="setEnter" maxlength="50" />
    <div class="clear" v-if="search && search.length" @click="$emit('clear', '')">
      <ClearIcon />
    </div>
    <div class="button" @click="$emit('enter')">
      <SearchIcon />
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/icons/SearchIcon.vue'
import ClearIcon from '@/components/icons/ClearIcon.vue'

export default {
  name: 'Input',
  components: {
    SearchIcon,
    ClearIcon
  },
  watch: {
    search(val) {
      this.$emit('set', val)
    }
  },
  props: {
    search: String,
  },
  methods: {
    setEnter(e) {
      if (e.keyCode === 13) {
        this.$emit('enter')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  input{
    @include box(100%, 2.5vw, relative, null, null, null, null);
    font-size: 1vw;
    padding: 0 1vw;
    border-radius: 0.2vw 0 0 0.2vw;
    outline: none;
    border: 0.04vw solid #D0C7C2;
    border-right: 0;
  }
  .clear{
    @include box(3vw, 2.5vw, relative, null, null, null, null);
    @include flex(center, center, row);
    flex-shrink: 0;
    background-color: #FFF;
    border-top: 0.04vw solid #D0C7C2;
    border-bottom: 0.04vw solid #D0C7C2;
    transition: 0.2s;
    cursor: pointer;
    &:hover{
      opacity: 0.6;
    }
  }
  .button{
    @include box(3vw, 2.5vw, relative, null, null, null, null);
    @include flex(center, center, row);
    flex-shrink: 0;
    background-color: #E04E39;
    border-radius: 0 0.2vw 0.2vw 0;
    transition: 0.2s;
    cursor: pointer;
    &:hover{
      opacity: 0.6;
      background-color: #ff563d;
    }
  }
}
@media only screen and (max-width: 900px) {
  .input{
    input{
      @include box(100%, 6vw, relative, null, null, null, null);
      font-size: 2vw;
      padding: 0 2vw;
      border-radius: 0.6vw 0 0 0.6vw;
    }
    .clear{
      @include box(8vw, 6vw, relative, null, null, null, null);
    }
    .button{
      @include box(8vw, 6vw, relative, null, null, null, null);
      border-radius: 0 0.6vw 0.6vw 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .input{
    input{
      @include box(100%, 12vw, relative, null, null, null, null);
      font-size: 4vw;
      padding: 0 4vw;
      border-radius: 1vw 0 0 1vw;
    }
    .clear{
      @include box(14vw, 12vw, relative, null, null, null, null);
    }
    .button{
      @include box(14vw, 12vw, relative, null, null, null, null);
      border-radius: 0 1vw 1vw 0;
    }
  }
}
</style>
