<template>
  <div class="items">
    <div class="rows">
      <div class="item" v-for="(item, i) in items" :key="i">
        <router-link :to="{ name: 'ForumsView', params: { id: item.id }}" class="block">
          <div class="image">
            <img :src="item.photo" v-if="item.photo" />
          </div>
          <div class="name">№{{item.number}}, {{item.month}}</div>
          <div class="sub">{{item.address}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Items',
  props: {
    items: Array,
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/forums/' + pic)
    }
  }
}
</script>

<style lang="scss" scoped>
.items{
  @include box(calc(100% + 3vw), auto, relative, -1.5vw, null, null, null);
  @include flex(left, top, column);
  padding-top: 1vw;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: wrap;
    .item{
      @include box(25%, auto, relative, null, null, null, null);
      padding: 1.5vw;
      .block{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        .image{
          @include box(100%, 18vw, relative, null, null, null, null);
          border-radius: 0.3vw;
          overflow: hidden;
          img{
            @include box(100%, auto, relative, null, null, null, null);
            min-height: 100%;
            transition: 0.2s;
          }
          &:before{
            content: '';
            @include box(100%, 100%, absolute, 0, 0, null, null);
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: 0.2s;
            z-index: 2;
          }
        }
        .name{
          font-size: 1.1vw;
          color: #E04E39;
          padding: 1vw 0;
          transition: 0.2s;
        }
        .sub{
          font-size: 0.9vw;
          color: rgba(44, 42, 41, 0.7);
          padding-bottom: 1vw;
        }
        &:hover{
          .image{
            img{
              transform: scale(1.1);
            }
            &:before{
              opacity: 1;
            }
          }
          .name{
            color: #2C2A29;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .items{
    @include box(100%, auto, relative, 0vw, null, null, null);
    padding-top: 2vw;
    .rows{
      .item{
        @include box(25%, auto, relative, null, null, null, null);
        padding: 2vw;
        .block{
          @include box(100%, auto, relative, null, null, null, null);
          .image{
            @include box(100%, 25vw, relative, null, null, null, null);
            border-radius: 0.8vw;
          }
          .name{
            font-size: 2vw;
            padding: 1.5vw 0;
          }
          .sub{
            font-size: 1.7vw;
            padding-bottom: 2vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .items{
    @include box(100%, auto, relative, null, null, null, null);
    padding: 0 2vw;
    padding-top: 2vw;
    .rows{
      .item{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw;
        .block{
          @include box(100%, auto, relative, null, null, null, null);
          .image{
            @include box(100%, 70vw, relative, null, null, null, null);
            border-radius: 3vw;
          }
          .name{
            font-size: 5vw;
            padding: 4vw 0;
          }
          .sub{
            font-size: 4vw;
            padding-bottom: 4vw;
          }
        }
      }
    }
  }
}
</style>
