import {addPaginationFilters} from "./common";

export function getContestInformationQuery(page, size) {
  const filter = addPaginationFilters([], page, size);

  return 'contest/information?' + filter.join('&');
}

export function getContestNewsQuery(page, size) {
  const filter = addPaginationFilters([], page, size);

  return 'contest/news?' + filter.join('&');
}

export function getContestNewsPageQuery(id) {
  return 'contest/news/' + id;
}

export function getContestRegionalNewsQuery(page, size) {
  const filter = addPaginationFilters([], page, size);

  return 'contest/region-news?' + filter.join('&');
}

export function getContestRegionalNewsPageQuery(id) {
  return 'contest/region-news/' + id;
}

export function getContestDocumentsQuery(page, size) {
  const filter = addPaginationFilters([], page, size);

  return 'contest/docs?' + filter.join('&');
}

export function getContestWinnersQuery(region, year, nomination, page, size) {
  const filter = addPaginationFilters([], page, size);
  if (region) {
    filter.push(`filter[region]=${region}`);
  }
  if (year) {
    filter.push(`filter[year]=${year}`);
  }
  if (nomination) {
    filter.push(`filter[nomination]=${nomination}`);
  }
  return 'contest/winners?' + filter.join('&');
}
