// states
import axios from "axios";

const state = {
  footer: String,
  slides: [],
  bottom_banner: [],
  forum: []
}

// getters
const getters = {
}

// actions
const actions = {
  getApiOnce({ commit }) {
    axios.get('/mains/1')
        .then(response => {
          commit('setSlides', response.data.slides)
          commit('setBottomBanner', JSON.parse(response.data.bottom_banner_image_path))
          commit("setFooter", response.data.footer)
          commit("setForum", JSON.parse(response.data.forum))
        })
  },
}

// mutations
const mutations = {
  setSlides(state, value) {
    state.slides = value;
  },
  setBottomBanner(state, value) {
    state.bottom_banner = value;
  },
  setFooter(state, value){
    state.footer = value;
  },
  setForum(state, value){
    state.forum = value
  }
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
