<template>
  <div class="achievements" v-if="approve || best" :style="style">
    <div
      class="achievements__mark"
      v-if="approve"
      @mouseenter="showAppTooltip('Одобрено экспертами', $event)"
      @mouseleave="closeAppTooltip()"
    >
      <svg
        width="30"
        height="36"
        viewBox="0 0 30 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4H30V32C30 34.2091 28.2091 36 26 36H16H6C3.79086 36 2 34.2091 2 32V4Z"
          fill="#2C2A29"
          fill-opacity="0.7"
        />
        <path
          d="M0 2L5.51472 1.21218C11.143 0.408142 16.857 0.408143 22.4853 1.21218L28 2V30C28 32.2091 26.2091 34 24 34H14H4C1.79086 34 0 32.2091 0 30V2Z"
          fill="url(#paint0_linear_2790:36229)"
        />
        <g filter="url(#filter0_d_2790:36229)">
          <path
            d="M22.3896 17.3601C22.87 16.8798 23.0862 16.1941 22.9685 15.5259C22.8508 14.8576 22.4129 14.2865 21.7971 14L20.9135 13.5881C20.6425 13.4622 20.4778 13.1771 20.5043 12.8787L20.5896 11.9084C20.6489 11.232 20.3743 10.5678 19.8542 10.1312C19.3342 9.69512 18.6308 9.54048 17.9757 9.71474L17.0342 9.96791C16.7459 10.0441 16.4362 9.93278 16.2646 9.68737L15.7058 8.88907C15.3172 8.33255 14.679 8 14.0002 8C13.3214 8 12.6833 8.33255 12.2942 8.88816L11.7354 9.68645C11.5638 9.93187 11.255 10.0432 10.9653 9.967L10.0243 9.71382C9.3683 9.53911 8.66625 9.69375 8.14622 10.1303C7.62619 10.5669 7.35158 11.231 7.41088 11.9075L7.49618 12.8783C7.52218 13.1761 7.35796 13.4612 7.08654 13.5881L6.20294 13.9995C5.58712 14.286 5.1492 14.8567 5.0315 15.5254C4.91381 16.1941 5.13004 16.8798 5.61038 17.3597L6.29965 18.0485C6.51131 18.2597 6.56833 18.584 6.44198 18.855L6.0296 19.7386C5.74267 20.3539 5.77369 21.0724 6.11353 21.6604C6.45338 22.2484 7.05963 22.6348 7.73613 22.6936L8.70731 22.7785C9.00519 22.8045 9.25699 23.0161 9.33454 23.3049L9.58634 24.246C9.76197 24.9019 10.2473 25.432 10.8855 25.6642C11.5232 25.8964 12.2358 25.8029 12.7932 25.4129L13.591 24.8531C13.836 24.6825 14.1654 24.6807 14.4103 24.8531L15.2086 25.4129C15.5631 25.6615 15.9818 25.7896 16.4038 25.7896C16.6437 25.7896 16.8846 25.7481 17.1159 25.6642C17.7536 25.4316 18.2394 24.9015 18.415 24.246L18.6668 23.3049C18.7439 23.0157 18.9962 22.804 19.2941 22.7785L20.2643 22.6936C20.9408 22.6348 21.5475 22.2484 21.8869 21.6604C22.2263 21.0724 22.2578 20.3539 21.9704 19.7381L21.558 18.855C21.4317 18.584 21.4887 18.2597 21.7003 18.0485L22.3896 17.3601ZM17.7823 15.5546L13.2206 20.1163C13.087 20.2499 12.9118 20.3165 12.7366 20.3165C12.5615 20.3165 12.3863 20.2499 12.2526 20.1163L9.97181 17.8354C9.70449 17.5681 9.70449 17.1352 9.97181 16.8679C10.2391 16.6006 10.672 16.6006 10.9393 16.8679L12.7366 18.6648L16.8143 14.5871C17.0816 14.3198 17.5145 14.3198 17.7819 14.5871C18.0492 14.8544 18.0492 15.2873 17.7823 15.5546Z"
            fill="#99AC2D"
          />
        </g>
        <path d="M28 2L30 4H28V2Z" fill="#99AC2D" />
        <defs>
          <filter
            id="filter0_d_2790:36229"
            x="5"
            y="8"
            width="18"
            height="18.7891"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2790:36229" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2790:36229"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2790:36229"
            x1="14"
            y1="4"
            x2="14"
            y2="36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DAED76" />
            <stop offset="1" stop-color="#B1C642" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      class="achievements__mark"
      v-if="best"
      @mouseenter="showAppTooltip('Лучший проект МФЦ', $event)"
      @mouseleave="closeAppTooltip()"
    >
      <svg
        width="30"
        height="36"
        viewBox="0 0 30 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4H30V32C30 34.2091 28.2091 36 26 36H16H6C3.79086 36 2 34.2091 2 32V4Z"
          fill="#2C2A29"
          fill-opacity="0.7"
        />
        <path
          d="M0 2L5.51472 1.21218C11.143 0.408142 16.857 0.408143 22.4853 1.21218L28 2V30C28 32.2091 26.2091 34 24 34H14H4C1.79086 34 0 32.2091 0 30V2Z"
          fill="url(#paint0_linear_2790:36230)"
        />
        <path d="M28 2L30 4H28V2Z" fill="#DC9C0E" />
        <g filter="url(#filter0_d_2790:36230)">
          <path
            d="M11.2 9C10.276 9 9.52127 9.70253 9.42031 10.6H8.4C7.63402 10.6 7 11.234 7 12V14.4C7 15.8288 8.17122 17 9.6 17H9.61953C10.1543 18.6691 11.6184 19.9225 13.4 20.1563V21.4H12C10.5664 21.4 9.4 22.5664 9.4 24V24.4C9.4 24.7316 9.6684 25 10 25H18C18.3316 25 18.6 24.7316 18.6 24.4V24C18.6 22.5664 17.4336 21.4 16 21.4H14.6V20.1563C16.3816 19.9225 17.8457 18.6691 18.3805 17H18.4C19.8288 17 21 15.8288 21 14.4V12C21 11.234 20.366 10.6 19.6 10.6H18.5797C18.4787 9.70253 17.724 9 16.8 9H11.2ZM8.4 11.8H9.4V15.6C9.4 15.6612 9.40699 15.7206 9.40938 15.7813C8.72224 15.689 8.2 15.1147 8.2 14.4V12C8.2 11.882 8.28198 11.8 8.4 11.8ZM18.6 11.8H19.6C19.718 11.8 19.8 11.882 19.8 12V14.4C19.8 15.1147 19.2778 15.689 18.5906 15.7813C18.593 15.7206 18.6 15.6612 18.6 15.6V11.8Z"
            fill="#DC9C0E"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2790:36230"
            x="7"
            y="9"
            width="14"
            height="17"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2790:36230" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2790:36230"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2790:36230"
            x1="14"
            y1="0"
            x2="14"
            y2="36.2667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FBFF31" />
            <stop offset="1" stop-color="#FFB819" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Achievements',
  props: {
    approve: {
      default: false,
      type: Boolean
    },
    best: {
      default: false,
      type: Boolean
    },
    top: {
      default: -2,
      type: Number
    },
    right: {
      default: 8,
      type: Number
    }
  },
  inject: ['showAppTooltip', 'closeAppTooltip'],
  computed: {
    style() {
      return {
        top: this.top + 'px',
        right: this.right + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.achievements {
  position: absolute;

  &__mark {
    display: inline-block;
    position: relative;

    svg {
      display: block;
      width: 30px;
      height: 36px;
    }

    & + & {
      margin-left: 8px;
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }
}
</style>
