<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 3.63867L2.32227 16.2656L3.67773 17.7344L5 16.5137V29H27V16.5137L28.3223 17.7344L29.6777 16.2656L23.9746 11H24V7H22V9.17773L16 3.63867ZM16 6.36133L25 14.668V27H7V14.668L16 6.36133ZM9 18V19V25H15V18H9ZM17 18V19V25H23V18H17ZM11 20H13V23H11V20ZM19 20H21V23H19V20Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
