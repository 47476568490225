<template>
  <a @click="$emit('setlink', link)" class="link" :class="{active: link === type}">
    {{name}}
  </a>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    link: String,
    name: String,
    type: String,
  }
}
</script>

<style lang="scss" scoped>
.link{
  font-size: 1vw;
  margin-bottom: 1vw;
  color: #E04E39;
  transition: 0.2s;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
  &.active{
    color: #2C2A29;
    cursor: pointer;
  }
}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 600px) {

}
</style>
