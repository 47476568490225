<template>
  <div class="others-menu">
    <ul class="others-menu__list">
      <li class="others-menu__item item" v-for="(link, i) in links" :key="i">
        <router-link :to="link.link" class="item__link">
          <div class="item__image">
            <component :is="link.icon" />
          </div>
          <div class="item__name">{{ link.name }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Info from "@/components/icons/contest/Info";
import News from "@/components/icons/contest/News";
import Documents from "@/components/icons/contest/Documents";
import Winners from "@/components/icons/contest/Winners";
import RegionalNews from "@/components/icons/contest/RegionalNews";
export default {
  name: 'OthersMenu',
  components: {
    Info,
    News,
    RegionalNews,
    Documents,
    Winners,
  },
  props: {
    links: {type: Array, required: true}
  },
  computed: {

  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.others-menu {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  &__item {
    width: 13vw;
    margin-bottom: 4vw;
  }
  .item {
    &__link {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      height: 100%;
      color: #E04E39;
      transition: 0.2s;
      &:hover {
        color: #2C2A29;
        transform: scale(1.05);
      }
    }
    &__image {
      background: #F6F2EC;
      width: 100%;
      height: 8vw;
      border-radius: 0.3vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__name {
      margin-top: 1vw;
    }
  }
}
@media only screen and (max-width: 900px) {
  .others-menu {
    &__list {
      flex-flow: wrap;
    }
    &__item {
      width: 30vw;
    }
    .item {
      &__image {
        height: 15vw;
        border-radius: 0.6vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .others-menu {
    &__list {
      flex-flow: column;
    }
    &__item {
      width: 100%;
      margin-bottom: 10vw;
    }
    .item {
      &__image {
        height: 45vw;
        border-radius: 1.5vw;
      }
      &__name {
        margin-top: 5vw;
      }
    }
  }
}
</style>
