import axios from 'axios'
import router from '../../router'

export default {
  root: true,
  namespaced: true,
  state: {
    pages: [
      {
        name: 'Размещенные практики',
        type: 'hosted-practices',
        select: true,
      },
      {
        name: 'Внедренные практики',
        type: 'implemented-practices',
        select: false,
      },
      {
        name: 'Избранные практики',
        type: 'favorite-practices',
        select: false,
      },
      {
        name: 'Уведомления',
        type: 'notify',
        select: false,
      },
      {
        name: 'Профиль',
        type: 'profile',
        select: false,
        gov: true
      },
      {
        name: 'Отчёты',
        type: 'reports',
        select: false,
        gov: true
      },
    ],
    reportsDocuments: [],
    reportFilter: {
      filter: {
        and: [],
      },
      sort: { date: 'DESC' },
    },
    userAgreement: false,
    practiceAgreement: false,
    profile: null
  },
  getters: {
    getPages: state => state.pages,
    getReportsDocuments: state => state.reportsDocuments,
    isAuthorized: state => !!state.profile,
    userAgreement: state => state.userAgreement,
    practiceAgreement: state => state.practiceAgreement,
    profile: state => state.profile
  },
  mutations: {
    setPage(state, value) {
      state.pages.forEach(page => {
        page.select = page.type === value
      })
    },
    setReportFilter(state, newFilter) {
      state.reportFilter.filter.and = []
      if (newFilter?.regions?.options.length) {
        const regions = newFilter.regions.options.map(el => el.type.toString())
        state.reportFilter.filter.and.push({ region: { in: regions } })
      }
      const dateFilterFrom = newFilter.dates.from
      const dateFilterTo = newFilter.dates.to
      if (dateFilterFrom || dateFilterTo) {
        const dateFilter = { date: {} }
        if (dateFilterFrom) dateFilter.date.gt = dateFilterFrom
        if (dateFilterTo) dateFilter.date.lt = dateFilterTo
        state.reportFilter.filter.and.push(dateFilter)
      }
    },
    setReportsDocuments(state, value) {
      state.reportsDocuments = value
    },
    setUserAgreement(state, value) {
      state.userAgreement = value
    },
    setPracticeAgreement(state, value) {
      state.practiceAgreement = value
    },
    setProfile(state, value) {
      state.profile = value
    },

  },
  actions: {
    login({ dispatch }, userData) {
      return axios.post('/auth/login', userData).then(async response => {
        if (response?.data?.id) {
          return await dispatch('getProfile')
        }
      })
    },
    logout({ commit }) {
      return axios.get('/auth/logout').finally(() => {
        commit('setProfile', null)
        router.push({ path: '/' })
      })
    },
    async getReports({ state, commit }) {
      const body = state.reportFilter
      const { data } = await axios.post('/report', body)
      commit('setReportsDocuments', data || [])
    },
    async acceptProfileAgreement({ commit }) {
      const { status } = await axios.get('/profile/agreement')
      commit('setUserAgreement', status === 200)
    },
    async acceptPracticeAgreement({ commit }) {
      const { status } = await axios.get('/practice/agreement')
      commit('setPracticeAgreement', status === 200)
      return status === 200
    },
    async getProfile({ commit }) {
      const { status, data } = await axios.get('/profile')
      if (status === 200) {
        commit('setUserAgreement', !!data?.profile_agreement)
        commit('setPracticeAgreement', !!data?.practice_agreement)
        commit('setProfile', data)
        return {status, data}
      }
    }
  },
}
