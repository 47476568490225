<template>
    <NotifyList title="Отзывы" url="/profile/notify/reviews" />
</template>

<script>
import NotifyList from '../../../components/views/mfcCabinet/notify/NotifyList'

export default {
    name: 'MfcCabinetReviews',
    components: {NotifyList},
};
</script>

<style>
</style>