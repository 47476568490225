<template>
  <div class="content__item-search">
    <input
      v-model="search.value"
      class="content__item-input"
      type="search"
      placeholder="Поиск лучших практик"
    />
    <div v-if="search.value" @click="clearSearchInput" class="content__backspace">
      <img src="@/assets/undecomposed/back.svg" alt />
    </div>

    <button>
      <img src="@/assets/undecomposed/content-search.svg" alt />
    </button>
    <div v-if="isMenuShown" class="content__search-drop active">
      <div class="content__search-tabs">
        <a
          @click="setSearchType('title')"
          href="#"
          class="content__search-tab"
          :class="{ active: isTitleSearchType }"
          data-content-nav
        >По названию практики</a>
        <a
          @click="setSearchType('hashtag')"
          href="#"
          class="content__search-tab"
          :class="{ active: isHashtagSearchType }"
          data-content-nav
        >По хэштегам</a>
        <a
          @click="setSearchType('about')"
          href="#"
          class="content__search-tab"
          :class="{ active: isAboutSearchType }"
          data-content-nav
        >По содержанию практики</a>
      </div>
      <div class="content__search-content">
        <div
          v-if="isTitleSearchType"
          class="content__search-container content__search-item"
          data-content
        >
          <a
            v-for="item in result.practices"
            :key="item.id"
            @click="clickPractice(item)"
            href="#"
          >{{ item.title }}</a>
        </div>

        <div
          v-if="isHashtagSearchType"
          class="content__search-container content__search-hash"
          data-content
        >
          <div
            href="#"
            v-for="item in result.hashtags"
            :key="item.name"
            @click="clickHashtag(item)"
            class="hashtag"
            :class="{ 'hashtag-active': item.select }"
          >{{ item.name }}</div>
        </div>

        <div
          v-if="isAboutSearchType"
          class="content__search-container content__search-item"
          data-content
        >
          <a
            v-for="item in result.practices"
            :key="item.id"
            @click="clickPractice(item)"
            href="#"
          >{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'SearchInput',
  computed: {
    ...mapGetters('practices', [
      'hashtags',
    ]),
    isTitleSearchType() {
      return this.search.type === 'title'
    },
    isHashtagSearchType() {
      return this.search.type === 'hashtag'
    },
    isAboutSearchType() {
      return this.search.type === 'about'
    },
  },
  data: () => ({
    isMenuShown: false,
    search: {
      value: '',
      type: 'title'
    },
    result: {
      practices: [],
      hashtags: []
    },
  }),
  methods: {
    ...mapMutations('practices', [
      'toggleHashtag',
    ]),
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    closeMenu(e) {
      if (!e.target.closest('.content__item-header')) this.isMenuShown = false
    },
    setSearchType(value) {
      this.search.type = value
    },
    clearSearchInput() {
      this.search.value = ''
    },
    getSearchResult() {
      if (this.isHashtagSearchType) {
        this.searchByHashtag()
      } else {
        this.searchByTitleOrAbout()
      }
    },
    searchByHashtag() {
      this.result.hashtags = this.hashtags.filter(el => el.name.includes(this.search.value))
    },
    async searchByTitleOrAbout() {
      const body = {
        filter: { and: [] }
      }
      if (this.isTitleSearchType) {
        body.filter.and.push({ title: { like: this.search.value } })
      }
      if (this.isAboutSearchType) {
        body.filter.and.push({ about: { like: this.search.value } })
      }

      const { data } = await axios.post('/practice?per-page=20&page=1', body)
      this.result.practices = data
    },
    clickPractice(practice) {
      this.$router.push({ name: 'PracticesView', params: { id: practice.id } })
    },
    clickHashtag(hashtag) {
      this.toggleHashtag(hashtag)
      this.searchByHashtag()
    }
  },
  watch: {
    search: {
      deep: true,
      handler(enteredData) {
        if (enteredData.value) {
          this.getSearchResult()
        }
        this.isMenuShown = true
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.content__search-container {
  overflow-y: scroll;
}
.content__search-container::-webkit-scrollbar {
  opacity: 0;
  width: 6px;
}

.content__search-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.304906);
  border-radius: 3px;
}

.hashtag {
  width: fit-content;
  font-size: 14px;
  padding: 4px 12px;
  border: 1px solid #e9d7c1;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(44, 42, 41, 0.7);
  margin-bottom: 8px;
  cursor: pointer;

  &-active {
    background: rgba(234, 84, 58, 1);
    color: #fff;
  }
}
</style>