<template>
  <div class="list-item">
    <div v-for="item in arrPoints" :key="item.index" class="wrapper">
      <router-link :to="{ name: 'MfcView', params: {id: item.id} }" class="address">
        <span>{{item.address}}</span>
      </router-link>
      <div class="watch" v-if="false">
        <clock/>
        <div class="watches">
          <span>{{item.weekdays}}</span>
          <span>{{item.weekends}}</span>
        </div>
      </div>
      <div class="phone">
        <phone/>
        <div class="numbers">
          <span>{{item.phone}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Clock from "@/components/icons/Clock";
  import Phone from "@/components/icons/Phone";

  export default {
    name: "ListItem",
    components: {
      Clock,
      Phone
    },
    props: {
      arrPoints: Array
    }
  }
</script>

<style lang="scss" scoped>
  .list-item {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
    margin: auto;
    margin-bottom: 2.2vw;
    .wrapper {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      border-bottom: #E9D7C1 solid .07vw;
      padding-top: 1.4vw;
      padding-bottom: 1.4vw;
      .address {
        @include box(50%, auto, relative, null, null, null, null);
        padding-right: 5vw;
        span {
          font-size: 0.9vw;
          line-height: 1.4vw;
          color: #E04E39;
        }
      }
      .watch {
        @include box(25%, auto, relative, null, null, null, null);
        @include flex(center, top, row);
        margin-right: 5vw;
        .icon {
          margin-right: 1vw;
        }
        .watches {
          @include flex(left, top, column);
        }
        span {
          font-size: 0.9vw;
          line-height: 1.4vw;
        }
      }
      .phone {
        @include box(25%, auto, relative, null, null, null, null);
        @include flex(left, top, row);
        margin-left: 5vw;
        .icon {
          margin-right: 1vw;
        }
        .numbers {
          @include flex(left, top, column);
        }
        span {
          color: #E04E39;
          font-size: 0.9vw;
          line-height: 1.4vw;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .list-item {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      margin: auto;
      margin-bottom: 2.2vw;
      .wrapper {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, row);
        border-bottom: #E9D7C1 solid .07vw;
        padding-top: 2.4vw;
        padding-bottom: 2.4vw;
        .address {
          @include box(40%, auto, relative, null, null, null, null);
          padding-right: 5vw;
          span {
            font-size: 2.25vw;
            line-height: 2.4vw;
            color: #E04E39;
          }
        }
        .watch {
          @include box(30%, auto, relative, null, null, null, null);
          @include flex(center, top, row);
          margin-right: 5vw;
          .icon {
            margin-right: 1vw;
          }
          .watches {
            @include flex(left, top, column);
          }
          span {
            font-size: 2.25vw;
            line-height: 2.4vw;
          }
        }
        .phone {
          @include box(30%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          margin-left: 5vw;
          .icon {
            margin-right: 1vw;
          }
          .numbers {
            @include flex(left, top, column);
          }
          span {
            color: #E04E39;
            font-size: 2vw;
            line-height: 2.4vw;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .list-item {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      margin: auto;
      margin-bottom: 4.2vw;
      .wrapper {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        border-bottom: #E9D7C1 solid .07vw;
        padding-top: 3.4vw;
        padding-bottom: 3.4vw;
        .address {
          @include box(100%, auto, relative, null, null, null, null);
          padding-right: 5vw;
          margin-bottom: 4.2vw;
          span {
            font-size: 4.25vw;
            line-height: 1.4vw;
            color: #E04E39;
          }
        }
        .watch {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          margin-right: 5vw;
          margin-bottom: 4.2vw;
          .icon {
            margin-right: 3vw;
          }
          .watches {
            @include flex(left, top, column);
          }
          span {
            font-size: 4.25vw;
            line-height: 6.4vw;
          }
        }
        .phone {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);
          margin-left: 0;
          .icon {
            margin-right: 3vw;
          }
          .numbers {
            @include flex(left, top, column);
          }
          span {
            color: #E04E39;
            font-size: 4vw;
            line-height: 6.4vw;
          }
        }
      }
    }
  }
</style>
