// states
import axios from "axios";
import { getSearchPointsQuery } from "@/axios/searchPoints";

const state = {
  search: '',
  types: [
    { name: 'Поиск МФЦ', type: 'mfc' },
    { name: 'Новости', type: 'news' },
    { name: 'Другое', type: 'other' }
  ],
  type: 'mfc',
  items: [
    // { id: 1, name: '141090, обл. Московская, г. Королев, мкр. Юбилейный, ул. Пионерская, д. 1/4', link: '/xcvxcv' },
    // { id: 2, name: '141090, обл. Московская, г. Королев, мкр. Юбилейный, ул. Пионерская, д. 1/4', link: '/123' },
    // { id: 3, name: '141090, обл. Московская, г. Королев, мкр. Юбилейный, ул. Пионерская, д. 1/4', link: '/sdfsd' },
  ],
  points: [],
  point: null,
  page: 1,
  perPage: 10,
  totalCount: null,
}

// getters
const getters = {
  getPoints: state => {
    return state.points
  },
  getActiveType: state => {
    return state.type;
  },
  getSearch: state => {
    return state.search;
  }
}

// actions
const actions = {
  setSearch({ commit }, value) {
    commit('setSearch', value)
  },
  setType({ commit }, value) {
    commit('setType', value)
  },
  getApi({ commit, state }) {
    axios.get(getSearchPointsQuery(state.type, state.page, state.search))
      .then(response => {
        commit('setItems', response.data)
        commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
      })
  },
  getApiOnce({ commit }, type, page, search) {
    axios.get(getSearchPointsQuery(type, page, search))
      .then(response => {
        commit('setItems', response.data)
        commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
      })
  },
  setPage({ dispatch, commit }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  }
}

// mutations
const mutations = {
  setSearch(state, value) {
    state.search = value
  },
  setPage(state, value) {
    state.page = value
  },
  setType(state, value) {
    state.type = value
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setItems(state, value) {
    state.items = value
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
