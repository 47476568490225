<template>
  <div class="contest-documents-index-page">
    <PageHeader
      class="page-header"
      :page-name="pageName"
      :page-title="pageTitle"
      :header-title="headerTitle"
      :route-path="pagePath"
    >
      <LogoBigIcon class="logo" />
    </PageHeader>
    <div class="container">
      <ContentSection>
        <Sidebar
          :links="getLinksWithSelect"
          :current="pageType"
          slot="side"
          @set-page="setRouterPage"
        />
        <Documents :items="files" />
        <Pagination
          :pages="totalCount"
          :current="page"
          :per-page="perPage"
          @setpage="setPage"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </ContentSection>
      <ContentSection title="Другие разделы">
        <Others :links="getOthers" />
      </ContentSection>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/shared/PageHeader';
import ContentSection from '@/components/shared/ContentSection';
import Sidebar from '@/components/shared/Sidebar';
import Others from '@/components/views/contest/Others';
import LogoBigIcon from "@/components/icons/LogoBigIcon";
import Pagination from "@/components/Pagination";
import {mapActions, mapGetters, mapState} from "vuex";
import Documents from "@/components/views/documents/index/Items";

export default {
  name: 'ContestDocumentsIndex',
  components: {
    Documents,
    PageHeader,
    ContentSection,
    Sidebar,
    Others,
    LogoBigIcon,
    Pagination,
  },
  data() {
    return {
      pageName: 'Конкурс «Лучший МФЦ»',
      pageTitle: 'Конкурс «Лучший МФЦ»',
      headerTitle: 'Документы, регламентирующие проведение Всероссийского конкурса «Лучший многофункциональный центр России»',
      pagePath: '/contest',
    }
  },
  watch: {

  },
  computed: {
    ...mapState('contestDocuments', [
      'links',
      'pageType',
      'files',
      'page',
      'totalCount',
      'perPage',
    ]),
    ...mapGetters('contestDocuments', [
      'getOthers',
      'getLinksWithSelect',
    ]),
  },
  methods: {
    ...mapActions('contestDocuments', [
      'getApi',
      'setPage',
      'setRouterPage',
    ]),
  },
  mounted() {
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.contest-documents-index-page {
  @include contest-page-style;
}
</style>
