<template>
  <div class="slider" id="slider">
    <div class="rows" :style="{ left: `-${width * count}px` }">
      <div class="item" v-for="(item, i) in items" :key="i" :style="{ width: `${width}px` }">
        <router-link
          :to="{ name: 'PracticesView', params: { id: item.id } }"
          class="block"
          :style="{ backgroundImage: 'url(' + item.photo + ')' }"
        >
          <div class="overlay">
            <div class="top">Размещено: {{ getDate(item.date) }}</div>
            <div class="bottom">
              <div class="name">{{ item.title }}</div>
              <div class="region">{{ item.subject }}</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    items: Array,
    count: Number
  },
  methods: {
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    },
    setViewportWidth() {
      this.innerWidth = window.innerWidth
    },
  },
  data() {
    return {
      innerWidth: 0
    }
  },
  computed: {
    width() {
      const sliderWidth = document.getElementById('slider')?.clientWidth
      if (this.innerWidth < 600 && sliderWidth) {
        return sliderWidth / 1.3
      } else {
        return sliderWidth / 3
      }
    }
  },
  mounted() {
    this.setViewportWidth()
    window.addEventListener('resize', this.setViewportWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewportWidth)
  }
}
</script>

<style lang="scss" scoped>
.slider {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  overflow: hidden;
  padding: 1vw 0;
  .rows {
    @include box(auto, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: nowrap;
    transition: 0.3s;
    .item {
      @include box(auto, auto, relative, null, null, null, null);
      flex-shrink: 0;
      padding: 0 1.5vw;
      .block {
        @include box(100%, 18vw, relative, null, null, null, null);
        @include flex(left, top, row);
        overflow: hidden;
        border-radius: 0.4vw;
        transition: 0.2s;
        cursor: pointer;
        background-size: cover;
        img {
          @include box(100%, auto, relative, null, null, null, null);
          min-height: 100%;
        }
        .overlay {
          @include box(100%, 100%, absolute, 0, 0, null, null);
          z-index: 3;
          .top {
            @include box(80%, auto, absolute, 0, 0, null, null);
            padding: 1.5vw;
            font-size: 0.8vw;
            color: #fff;
            opacity: 0;
            transition: 0.2s;
          }
          .bottom {
            @include box(80%, auto, absolute, 0, null, null, 0);
            padding: 16px;
            .name {
              font-size: 16px;
              color: #fff;
            }
            .region {
              padding-top: 0.5vw;
              font-size: 14px;
              color: #fff;
              display: none;
            }
          }
        }
        &:after {
          content: "";
          @include box(100%, 100%, absolute, 0, 0, null, null);
          background-color: rgba(0, 0, 0, 0.2);
        }
        &:hover {
          transform: scale(1.05);
          box-shadow: 0 0.3vw 0.6vw rgba(44, 42, 41, 0.302885);
          .overlay {
            .top {
              opacity: 1;
            }
            .bottom {
              .region {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .slider {
    padding-top: 2vw;
    .rows {
      padding: 0;
      .item {
        @include box(33.3%, auto, relative, null, null, null, null);
        padding: 0 1.5vw;
        .block {
          @include box(100%, 35vw, relative, null, null, null, null);
          border-radius: 0.4vw;
          .overlay {
            .top {
              @include box(100%, auto, absolute, 0, 0, null, null);
              padding: 2vw;
              font-size: 1.8vw;
              opacity: 1;
            }
            .bottom {
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 16px;
              .name {
                font-size: 16px;
              }
              .region {
                padding-top: 2vw;
                font-size: 14px;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .slider {
    padding-top: 4vw;
    .rows {
      .item {
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        padding-right: 6vw;
        .block {
          @include box(100%, 70vw, relative, null, null, null, null);
          border-radius: 0.8vw;
          .overlay {
            .top {
              padding: 4vw;
              font-size: 4vw;
            }
            .bottom {
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 4vw;
              .name {
                font-size: 5vw;
              }
              .region {
                padding-top: 4vw;
                font-size: 4vw;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
</style>
