<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.33594C0 4.18927 4.1854 0 9.33073 0H22.6641C27.8107 0 32 4.1854 32 9.33073V22.6641C32 27.8107 27.8146 32 22.6693 32H21.8182V18.9091H25.7833L26.4058 14.5455H21.8182V12.6807C21.8182 11.008 22.3651 10.1818 23.9302 10.1818H26.4451V6.00873L26.4185 6.00514C25.966 5.94401 25.0345 5.81818 23.3033 5.81818C19.616 5.81818 17.4545 7.76582 17.4545 12.2022V14.5455H13.0909V18.9091H17.4545V32H9.33594C4.18927 32 0 27.8146 0 22.6693V9.33594Z" fill="white" fill-opacity="0.5"/>
  </svg>

</template>

<script>
    export default {
        name: "Facebook"
    }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(5vw, 5vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
      @include box(10vw, 10vw, relative, null, null, null, null);
    }
  }
</style>
