<template>
  <div class="select-bar">
    <div class="select-row">
      <selectbox :options="regions" default="Выберите область" keydef="type" @set="$emit('setregion', $event)" :has-all-choice="false" :has-search="true"/>
      <selectbox :options="cities" default="Выберите город" keydef="type" @set="$emit('setcity', $event)" :disabled="!getActiveRegion" :has-all-choice="true" :has-search="true"/>
      <Button v-if="false && changeBtn" class="mapBtn" label="Показать списком" icon="list" @click="showListMfc"/>
      <Button v-if="false && !changeBtn" class="listBtn" label="Показать на карте" icon="list" @click="showMapMfc"/>
    </div>
  </div>
</template>

<script>
  import Selectbox from "@/components/elements/Selectbox";
  import Button from "@/components/elements/Button";
  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    name: "SelectBar",
    components: {
      Selectbox,
      Button
    },
    props: {
      cities: Array,
      regions: Array,
      search: String,
    },
    computed: {
      ...mapState('mfc', [
        'changeBtn'
      ]),
      ...mapGetters('mfc', [
        'getActiveRegion'
      ])
    },
    methods: {
      ...mapActions('mfc', [
        'showListMfc',
        'showMapMfc',
        'setSearch'
      ]),
    }
  }
</script>

<style lang="scss" scoped>
  .select-bar {
    @include box(75%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    margin: auto;
    margin-top: 1vw;
    .select-row {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      .inputbox {
        @include box(27%, auto, relative, null, null, null, null);
        margin-right: 1vw;
      }
      .selectbox {
        @include box(27%, auto, relative, null, null, null, null);
        margin-right: 1vw;
      }
      .selectbox:last-child {
        margin-right: 0;
      }
      .button {
        @include box(19%, auto, relative, null, null, null, null);
        margin-left: 5vw;
        margin-top: 0;
      }
    }
    .button-row {
      @include box(27%, auto, relative, null, null, null, null);
      @include flex(right, bottom, row);
    }
  }
  @media only screen and (max-width: 900px) {
    .select-bar {
      @include box(98%, auto, relative, null, null, null, null);
      @include flex(left, center, row);
      margin: auto;
      margin-top: 1vw;
      flex-wrap: wrap;
      .select-row {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, center, row);
        flex-wrap: wrap;
        justify-content: space-between;
        .inputbox {
          @include box(100%, auto, relative, null, null, null, null);
          margin-right: 0;
          margin-bottom: 5vw;
          margin-top: 5vw;
        }
        .selectbox {
          @include box(30%, auto, relative, null, null, null, null);
          margin-left: 0;
          margin-right: 4vw;
          margin-bottom: 5vw;
        }
        .selectbox:last-child {
          margin-right: 0;
        }
        .button {
          @include box(30%, auto, relative, null, null, null, null);
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 5vw;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .select-bar {
      @include box(95%, auto, relative, null, null, null, null);
      @include flex(left, center, column);
      margin: auto;
      margin-top: 1vw;
      .select-row {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, center, column);
        .inputbox {
          margin-right: 0;
          margin-bottom: 5vw;
          margin-top: 5vw;
        }
        .selectbox {
          @include box(100%, auto, relative, null, null, null, null);
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 5vw;
        }
        .selectbox:last-child {
          margin-right: 0;
        }
        .button {
          @include box(100%, auto, relative, null, null, null, null);
          margin-top: 0;
        }
      }
    }
  }
</style>
