<template>
  <div class="sidebar">
    <div class="title">Афиша</div>
    <!--<Calendar class="calendar" @date="$emit('setdate', $event)" />-->
    <Selectbox :options="dates" default="Выберите дату" @set="$emit('setdates', $event)" class="selectA" :has-all-choice="true" all-choice-text="За все время"/>
    <Selectbox :options="regions" default="Выберите регион" keydef="type" @set="$emit('setregion', $event)" class="select" :has-search="true" :has-all-choice="true" all-choice-text="Все регионы" />
  </div>
</template>

<script>
import Selectbox from '@/components/elements/Selectbox.vue'
//import Calendar from '@/components/elements/Calendar.vue'

export default {
  name: 'Sidebar',
  components: {
    Selectbox,
    //Calendar
  },
  props: {
    regions: Array,
    dates: Array
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .title{
    @include box(100%, auto, relative, null, null, null, null);
    font-size: 2vw;
    margin-bottom: 2vw;
  }
  .select{
    margin-top: 1vw;
    &.mobile{
      display: none;
    }
  }
}
@media only screen and (max-width: 900px) {
  .sidebar{
    @include flex(left, top, row);
    padding: 0 1.5vw;
    flex-wrap: wrap;
    .title{
      display: none;
    }
    .calendar{
      @include box(calc(33.3% - 2vw), auto, relative, null, null, null, null);
      margin-top: 1vw;
      margin-right: 1.5vw;
    }
    .select{
      @include box(calc(33.3% - 2vw), auto, relative, null, null, null, null);
      margin-top: 1vw;
      margin-left: 1.5vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar{
    @include flex(left, top, column);
    padding: 0 1.5vw;
    .calendar{
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 4vw;
      margin-right: 0;
    }
    .select{
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 4vw;
      margin-left: 0;
    }
  }
}
</style>
