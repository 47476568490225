<template>
  <div :class="classes">
    <div class="statistic__group">
      <div
        v-if="approve > 0"
        class="statistic__item"
        @mouseenter="showAppTooltip('Внедрения', $event)"
        @mouseleave="closeAppTooltip"
      >
        <ApproveIcon />
        <span>{{ approve }}</span>
      </div>
      <div
        v-if="like > 0"
        class="statistic__item"
        @mouseenter="showAppTooltip('Нравится', $event)"
        @mouseleave="closeAppTooltip"
      >
        <LikeIcon />
        <span>{{ like }}</span>
      </div>
      <div
        v-if="comment > 0"
        class="statistic__item"
        @mouseenter="showAppTooltip('Комментарии', $event)"
        @mouseleave="closeAppTooltip"
      >
        <CommentIcon />
        <span>{{ comment }}</span>
      </div>
    </div>
    <div class="statistic__group" v-if="favorite > 0">
      <div
        class="statistic__item"
        @mouseenter="showAppTooltip('Добавили в избранное', $event)"
        @mouseleave="closeAppTooltip"
      >
        <FavoriteIcon :favoriteMe="isSelected" />
        <span>{{ favorite }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ApproveIcon from '@/assets/icons/ApproveIcon.vue'
import LikeIcon from '@/assets/icons/LikeIcon.vue'
import CommentIcon from '@/assets/icons/CommentIcon.vue'
import FavoriteIcon from '@/assets/icons/FavoriteIcon.vue'

export default {
  name: 'PracticeStatistic',
  components: {
    ApproveIcon,
    LikeIcon,
    CommentIcon,
    FavoriteIcon
  },
  inject: ['showAppTooltip', 'closeAppTooltip'],
  props: {
    approve: {
      type: [Number, String, null],
      default: null
    },
    like: {
      type: [Number, String, null],
      default: null
    },
    comment: {
      type: [Number, String, null],
      default: null
    },
    favorite: {
      type: [Number, String, null],
      default: null
    },
    size: {
      type: String,
      default: 'small'
    },
    split: {
      type: Boolean,
      default: true
    },
    isSelected: Boolean
  },
  computed: {
    classes() {
      const classes = ['statistic', `statistic--size-${this.size ? this.size : 'default'}`]
      if (this.split) classes.push('statistic--split')

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.statistic {
  display: flex;
  color: white;
  font-weight: 700;

  &--split {
    justify-content: space-between;
  }
    svg {
        width: 14px;
        height: 14px;
    }

  &--size {
    &-small {
      font-size: 12px;
      line-height: 14px;
    }

    &-normal {
      font-size: 18px;
      line-height: 28px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__group {
    & + & {
      margin-left: 10px;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    vertical-align: top;

    * + * {
      margin-left: 2px;
    }

    & + & {
      margin-left: 10px;
    }
  }
}
</style>
