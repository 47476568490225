<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M6 3V29H16.8125L18.8125 27H8V5H18V11H24V16.4062C24.6016 16.207 25.3008 16 26 16V9.59375L19.4062 3H6ZM20 6.40625L22.5938 9H20V6.40625ZM26.5 18C23.4727 18 21 20.4727 21 23.5C21 24.6602 21.3516 25.7344 21.9688 26.625L18.2812 30.2812L19.7188 31.7188L23.375 28.0312C24.2656 28.6484 25.3398 29 26.5 29C29.5273 29 32 26.5273 32 23.5C32 20.4727 29.5273 18 26.5 18ZM26.5 20C28.4453 20 30 21.5547 30 23.5C30 25.4453 28.4453 27 26.5 27C24.5547 27 23 25.4453 23 23.5C23 21.5547 24.5547 20 26.5 20Z" fill="#E04E39"/>
    </svg>
</template>

<script>
    export default {
        name: "DocumentSearch"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
