<template>
  <div class="sidebar">
    <a class="link" v-for="(type, i) in filteredTypes" :key="i" :class="{active: type.select}" @click="$emit('settype', type.type)">
      {{type.name}}
    </a>
    <div class="selects">
      <Selectbox :options="types" default="Выберите раздел" keydef="type" @set="$emit('settype', $event)" />
    </div>
  </div>
</template>

<script>
import Selectbox from '@/components/elements/Selectbox.vue'

export default {
  name: 'Sidebar',
  components: {
    Selectbox,
  },
  props: {
    types: Array,
    item: Object
  },
  computed: {
    filteredTypes() {
      return this.types.filter(type => this.item[type.type] !== null);
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .link{
    font-size: 1vw;
    color: #E04E39;
    margin-bottom: 1vw;
    transition: 0.2s;
    cursor: pointer;
    &.active{
      color: #2C2A29;
    }
    &:hover{
      opacity: 0.6;
    }
  }
  .selects{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, center, column);
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .sidebar{
    padding: 0 0;
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, row);
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar{
    padding: 0 0;
    .link{
      display: none;
    }
    .selects{
      @include flex(left, center, column);
    }
  }
}
</style>
