<template>
  <div class="reports">
    <div class="profile">
      <div class="container">
        <div class="reports__header">
          <div class="reports__title reports__title--mobile">Сформировать отчёт</div>
          <div class="reports__filters">
            <DateSelect :value.sync="query.dates" />
            <ComboSelect
                :value.sync="query.regions"
                :options="regions"
                label="Выберите регион"
                has-search
                search-placeholder="Поиск региона"
            />
          </div>
        </div>
        <div class="reports__items">
          <div class="reports__title reports__title--desktop">Сформировать отчёт</div>
          <Documents :items="reportsRequests" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Documents from "@/components/views/documents/index/Items"
import ComboSelect from "@/components/elements/ComboSelect.vue"
import DateSelect from '@/components/elements/DateSelect.vue'

export default {
  name: "MfcCabinetReports",
  components: {
    Documents,
    DateSelect,
    ComboSelect,
  },
  computed: {
    ...mapGetters('mfcCabinet', [
      'getReportsDocuments'
    ]),
    ...mapState('practices', ['regions'],),
  },
  data: () => ({
    query:{
      dates: {
        from: '',
        to: '',
      },
      regions: { options: [] }
    },
    reportsRequests:[
      { id: 1, name: "Детализированный отчет о практиках",  about:"Отчет позволяет определить популярность практик по частоте их внедрения, добавления в избранное, количеству пользовательских оценок, а также определить практики, отмеченные знаком лауреата конкурса «Лучший МФЦ России» и имеющие экспертные оценки.", link:""},
      { id: 2, name: "Детализированный отчет о комментариях к практикам", link:"", about: ""},
      { id: 3, name: "Детализированный отчет об отзывах к практикам", link:"", about: ""},
    ],
    reportLink:[
      {id: 1, link: "/web/profile/report-about-practice"},
      {id: 2, link: "/web/profile/report-about-comment"},
      {id: 3, link: "/web/profile/report-about-review"}
    ],
  }),
  methods: {
    ...mapActions('practices', ['setRegion', 'getApiRegion'],),
    ...mapActions('mfcCabinet', ['getReports']),
    ...mapMutations('mfcCabinet', ['setReportFilter']),
    buildLink(){
      let regions = []
      this.query.regions.options.forEach((value) => {
        regions.push("&regions[]=" + value.region)
      })
      this.reportsRequests.forEach((value, index) => {
        let regionsString = ''
        regions.forEach((value) => {
          regionsString = regionsString.concat(value);
        })
        value.link = this.reportLink[index].link + '?date[from]=' + this.query.dates.from + '&date[to]=' + this.query.dates.to + regions
      })
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.setReportFilter(this.query);
        this.buildLink()
      }
    },
  },
  created() {
    this.getReports()
    this.getApiRegion()
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: block;

  @media (min-width: 768px) {
    display: flex;
  }
}

.reports {
  padding-bottom: 80px;

  &__filters {
    min-width: 255px;
  }

  &__title {
    font-size: 29px;
    line-height: 38px;
    padding-left: 2vw;
    &--mobile {
      display: block;
      margin-bottom: 16px;
      @media (min-width: 768px) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 32px;
  }

  @media (min-width: 1440px) {
    &__title {
      font-size: 44px;
      line-height: 52px;
    }
  }
}
</style>
