<template>
  <div class="breadcrumbs" :class="{white: white}">
    <div class="container">
      <router-link to="/" class="link">Главная</router-link>
      <label v-for="(route, i) in pages" :key="i" class="route">
        <span>/</span>
        <router-link :to="route.route" class="link" >
           {{route.name}}
        </router-link>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    to: String,
    label: String,
    news: Boolean,
    poster: Boolean,
    pages: Array,
    white: Boolean
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
    font-size: 14px;
    line-height: 20px;
  color: rgba(44, 42, 41, 0.7);
  .container{
    padding-top: 0;
    @include flex(left, top, row);
  }
  span{
    padding: 0 1vw;
  }
  .link{
    color: rgba(44, 42, 41, 0.7);
    transition: 0.2s;
    &:hover{
      opacity: 0.7;
    }
  }
  &.white{
    color: rgba(255, 255, 255, 1);
    .link{
      color: rgba(255, 255, 255, 1);
    }
  }
}
@media only screen and (max-width: 900px) {
  .breadcrumbs{
    span{
      padding: 0 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .breadcrumbs{
    span{
      padding: 0 4vw;
    }
  }
}
</style>
