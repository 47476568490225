<template>
  <div class="newslist">
    <Item v-for="item in getAllNews" :key="item.id" :item="item" :groups="groups" :image="true" />
  </div>
</template>

<script>
import Item from '@/components/views/news/Item.vue'

export default {
  name: 'NewsList',
  components: {
    Item
  },
  props: {
    news: Array,
    groups: Array
  },
  computed: {
    getAllNews() {
      return this.news.filter((item, i) => i > 3 && item)
    }
  },
}
</script>

<style lang="scss" scoped>
.newslist{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-top: 1.5vw;
}
@media only screen and (max-width: 900px) {
  .newslist{
    padding: 0 3vw;
    padding-top: 3vw;
  }
}
@media only screen and (max-width: 600px) {
  .newslist{
    padding-top: 0;
    padding: 0 6vw;
  }
}
</style>
