import { render, staticRenderFns } from "./BaseSpinner.vue?vue&type=template&id=16a19ad1&scoped=true&functional=true&"
var script = {}
import style0 from "./BaseSpinner.vue?vue&type=style&index=0&id=16a19ad1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "16a19ad1",
  null
  
)

export default component.exports