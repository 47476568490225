<template>
  <div class="filesCom">
    <div class="cont">
      <div class="section text">
        <div class="row min">
<!--          <div class="title">Скачать</div>-->
        </div>
        <div class="row max">
          <div class="files">
            <a class="file" v-for="(file,i) in files" :key="`model-file-${i}`" :href="file.link" target="_blank">
              <div class="icon">
                <DocVertical v-if="docFiles.includes(getFileType(file.link))"/>
                <DocAlbum v-else />
              </div>
              <div class="info">
                <div class="name">{{file.name}}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocAlbum from '@/components/icons/DocAlbum.vue'
import DocVertical from '@/components/icons/DocVertical.vue'

export default {
  name: 'FilesCom',
  props: {
    files: [Object, Array]
  },
  components: {
    DocAlbum,
    DocVertical,
  },
  data() {
    return {
      docFiles: ['docs', 'doc', 'docx', 'rtf', 'txt']
    }
  },
  methods: {
    getFileType(link) {
      return link.split('.')[1].slice(1).toLowerCase();
    }
  }
}
</script>

<style lang="scss" scoped>
.filesCom{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .cont{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .section{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      &.text{
        padding: 1vw 0;
      }
      .row{
        &.min{
          display: none;
          @include box(22%, auto, relative, null, null, null, null);
          .title{
            font-size: 1vw;
            font-weight: 700;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-left: 0;
          .files{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            .file{
              @include box(100%, auto, relative, null, null, null, null);
              @include flex(left, top, row);
              border-top: 0.06vw solid #E9D7C1;
              padding: 1.5vw 0;
              .icon{
                @include box(3.3vw, auto, relative, null, null, null, null);
                @include flex(center, center, row);
                flex-shrink: 0;
              }
              .info{
                @include box(100%, auto, relative, null, null, null, null);
                padding-left: 1vw;
                .name{
                  font-size: 0.9vw;
                  color: #E04E39;
                  transition: 0.2s;
                  cursor: pointer;
                  &:hover{
                    opacity: 0.6;
                  }
                }
                .size{
                  font-size: 0.7vw;
                  color: rgba(44, 42, 41, 0.7);
                  padding-top: 0.5vw;
                }
              }
              &:first-child{
                border-top: 0;
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .filesCom{
    @include box(100%, auto, relative, null, null, null, null);
    .cont{
      padding: 0 4vw;
      .section{
        &.text{
          padding: 0;
        }
        .row{
          &.min{
            display: none;
            @include box(22%, auto, relative, null, null, null, null);
            .title{
              font-size: 2vw;
              font-weight: 700;
            }
          }
          &.max{
            @include box(100%, auto, relative, null, null, null, null);
            padding-left: 0;
            .files{
              .file{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                border-top: 0.15vw solid #E9D7C1;
                padding: 2vw 0;
                .icon{
                  @include box(7vw, auto, relative, null, null, null, null);
                  @include flex(center, center, row);
                  flex-shrink: 0;
                }
                .info{
                  @include box(100%, auto, relative, null, null, null, null);
                  padding-left: 3vw;
                  .name{
                    font-size: 2vw;
                  }
                  .size{
                    font-size: 1.5vw;
                    padding-top: 1vw;
                  }
                }
                &:first-child{
                  border-top: 0;
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .filesCom{
    @include box(100%, auto, relative, null, null, null, null);
    padding-bottom: 2vw;
    .cont{
      padding: 0 6vw;
      .section{
        @include flex(left, top, column);
        &.text{
          padding: 0;
        }
        .row{
          &.min{
            @include box(100%, auto, relative, null, null, null, null);
            padding-bottom: 4vw;
            .title{
              font-size: 4.5vw;
            }
          }
          &.max{
            @include box(100%, auto, relative, null, null, null, null);
            padding-left: 0;
            .files{
              .file{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                border-top: 0.15vw solid #E9D7C1;
                padding: 4vw 0;
                .icon{
                  @include box(12vw, auto, relative, null, null, null, null);
                  @include flex(center, center, row);
                  flex-shrink: 0;
                }
                .info{
                  @include box(100%, auto, relative, null, null, null, null);
                  padding-left: 5vw;
                  .name{
                    font-size: 4vw;
                  }
                  .size{
                    font-size: 3.5vw;
                    padding-top: 1vw;
                  }
                }
                &:first-child{
                  border-top: 0;
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
