<template>
  <div class="banner">
    <div class="banner__content">
      <router-link class="banner__bread" to="/">Главная /</router-link>
      <h2 v-html="title"/>
      <p v-html="description"/>
      <a class="banner__link" :href="link.href">{{ link.text }}</a>
      <a v-if="deviceSize.desktop"
         href="http://mfc.economy.gov.ru/web/uploads/docs/%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B8.docx"
         rel="noopener">
        <Button label="Подать заявку" background class="banner__button"/>
      </a>
      <ul ref="list">
        <li v-if="count">
          <DocPostedIcon/>
          <b>{{ count }}</b>
          <span>Практик размещено</span>
        </li>
        <li v-if="embed">
          <DocApprovedIcon/>
          <b>{{ embed }}</b>
          <span>Практик внедрено</span>
        </li>
        <li v-if="regions">
          <RegionsIcon/>
          <b>{{ regions }}</b>
          <span>Регионов участвует</span>
        </li>
      </ul>
    </div>
    <div class="banner__bg" :style="{bottom: bottomOffset, background: `url(${src})`}"/>
  </div>
</template>

<script>
import DocPostedIcon from '@/assets/icons/DocPostedIcon.vue'
import DocApprovedIcon from '@/assets/icons/DocApprovedIcon.vue'
import RegionsIcon from '@/assets/icons/RegionsIcon.vue'
import Button from '@/components/elements/Button.vue'

export default {
  name: 'PracticesBanner',
  components: {
    Button,
    DocPostedIcon,
    DocApprovedIcon,
    RegionsIcon
  },
  props: {
    breadcrumbs: {
      type: [Array, null],
      default: null
    },
    src: {
      type: String,
      required: true
    },
    title: {
      type: [String, null],
      default: null
    },
    description: {
      type: [String, null],
      default: null
    },
    link: {
      type: [Object, null],
      default: null
    },
    count: {
      type: Number,
      required: true,
      default: 0
    },
    embed: {
      type: Number,
      required: true,
      default: 0
    },
    regions: {
      type: Number,
      required: true,
      default: 0
    }
  },
  inject: ['deviceSize'],
  data() {
    return {
      bottomOffset: null
    }
  },
  mounted() {
    const interval = setInterval(() => {
      this.bottomOffset = this.deviceSize.mobile
          ? this.$refs.list.getBoundingClientRect().height + 'px'
          : null
    }, 10)

    setTimeout(() => {
      clearInterval(interval)
    }, 3000)
  }
}
</script>

<style lang="scss" scoped>
.banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 40px 0 0;

  &__bread {
    display: block;
    color: white;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 64px;
  }

  &__link {
    font-size: 18px;
    line-height: 28px;
    color: white;
    text-decoration: underline;
    margin-bottom: 32px;

    @media (min-width: 900px) {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
    }
  }

  &__button {
    width: 240px;
    margin-bottom: 32px;
  }

  &__content {
    width: 75%;
    height: auto;
    position: relative;
    margin: 0 auto;
    color: white;

    h2 {
      font-size: 44px;
      line-height: 52px;
      margin: 0 0 16px;

    }

    p {
      font-size: 18px;
      line-height: 28px;
      max-width: 544px;
      padding: 0 0 32px;
    }

    ul {
      list-style: none;
      padding: 24px 0;
      margin: 0;
      display: flex;
    }


    li {
      * {
        display: block;
      }

      svg {
        width: 32px;
        height: 32px;
      }

      b {
        margin: 12px 0 4px;
        font-size: 30px;
        line-height: 28px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }

    li + li {
      margin-left: 16px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: right !important;
    background-size: cover !important;
  }

  @media (max-width: 767px) {
    ul {
      color: #2C2A29;

      li {
        text-align: center;

        svg {
          margin: 0 auto;

          path {
            fill: #EA543A;
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    padding: 40px 0;
    min-height: 500px;

    ul {
      padding: 0;
    }
  }
}
</style>
