<template>
  <div class="contest-index-page">
    <PageHeader
      class="page-header"
      :page-name="pageName"
      :page-title="pageTitle"
      :header-title="headerTitle"
      :route-path="pagePath"
      :white-text="false"
    >
      <LogoBigIcon class="logo" />
    </PageHeader>
    <div class="container">
      <ContentSection>
        <Sidebar
          :links="getLinksWithSelect"
          :current="pageType"
          slot="side"
          @set-page="setRouterPage"
        />
        <CurrentStatus :items="status" />
      </ContentSection>
      <ContentSection title="Период подачи заявки" :content="periodText" />
      <ContentSection title="Описание" :content="description" />
      <ContentSection title="Скачать">
        <FilesList :files="files" />
      </ContentSection>
      <ContentSection title="Другие разделы">
        <Others :links="getOthers" />
      </ContentSection>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/shared/PageHeader';
import ContentSection from '@/components/shared/ContentSection';
import Sidebar from '@/components/shared/Sidebar';
import CurrentStatus from '@/components/views/contest/CurrentStatus';
import Others from '@/components/views/contest/Others';
import FilesList from '@/components/shared/FilesList';
import LogoBigIcon from "@/components/icons/LogoBigIcon";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'ContestIndex',
  components: {
    LogoBigIcon,
    PageHeader,
    ContentSection,
    Sidebar,
    CurrentStatus,
    Others,
    FilesList,
  },
  data() {
    return {
      pageName: 'Конкурс «Лучший МФЦ»',
      pageTitle: 'Конкурс «Лучший МФЦ»',
      headerTitle: 'Сведения о текущем Всероссийском конкурсе «Лучший многофункциональный центр России»',
      pagePath: '/contest',
    }
  },
  watch: {

  },
  computed: {
    ...mapState('contestInfo', [
        'links',
        'pageType',
        'files',
        'status',
        'period',
        'description',
    ]),
    ...mapGetters('contestInfo', [
      'getOthers',
      'getLinksWithSelect',
    ]),
    periodText() {
      return `${this.period?.from} - ${this.period?.to}`;
    },
  },
  methods: {
    ...mapActions('contestInfo', [
      'getApi',
      'setRouterPage',
    ]),
  },
  mounted() {
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.contest-index-page {
  @include contest-page-style;
}
</style>
