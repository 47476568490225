// states
import axios from "axios";
import { getEventsQuery } from "@/axios/events";
import { getDatesListFromItems } from "@/helpers/date";

const state = {
  items: [],
  item: null,
  regions: [],
  dates: [
    /*{ name: 'Август 2020', type: 'date1', dateIn: 1596240000, dateOut: 1598918400, select: false },
    { name: 'Сентябрь 2020', type: 'date2', dateIn: 1598918400, dateOut: 1601510400, select: false },
    { name: 'Октябрь 2020', type: 'date3', dateIn: 1601510400, dateOut: 1604188800, select: false },
    { name: 'Ноябрь 2020', type: 'date4', dateIn: 1604188800, dateOut: 1606780800, select: false },
    { name: 'Декабрь 2020', type: 'date5', dateIn: 1606780800, dateOut: 1609459200, select: false }*/
  ],
  dateIn: '',
  dateOut: '',
  page: 0,
  totalCount: null,
  perPage: 9
}

// getters
const getters = {
  getPageItems: state => {
    return state.items.filter((item, i) => state.page * 10 <= i && ((state.page * 10) + 10) > i && item)
  }
}

// actions
const actions = {
  setRegion({ dispatch, state, commit }, value) {
    commit('setRegion', state.regions.map(item => {
      value = value.value ? value.value : value;
      item.select = item.type === value || item.name === value;
      return item;
    }));
    dispatch('getApi', {})
  },
  setDates({ dispatch, state, commit }, value) {
    commit('setDates', state.dates.map(item => {
      if (item.type === value.value.type) {
        item.select = true
        dispatch('setDate', { in: item.dateIn, out: item.dateOut })
      } else {
        item.select = false
      }
      return item
    }))
    if (!Object.keys(value.value).length) {
      dispatch('setDate', { in: '', out: '' })
    }
    dispatch('getApi', {})
  },
  setDate({ commit }, value) {
    commit('setDate', value)
  },
  setPage({ commit, dispatch }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  },
  getApi({ dispatch, commit, state }) {
    if (!state.dates.length) {
      dispatch('getAllDates');
    }
    const region = state.regions.filter(item => item.select)[0]?.name

    axios.get(getEventsQuery(region, state.dateIn, state.dateOut, state.page, state.perPage))
      .then(response => {
        commit('setItems', response.data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  getApiOnce({ commit }, id) {
    axios.get('/events/' + id)
      .then(response => {
        commit('setItem', response.data)
      })
  },
  getApiRegion({ commit }) {
    axios.get('/mfc-find')
      .then(response => {
        commit('setRegion', response.data.map((item, i) => {
          item.type = i + 1
          item.select = false
          item.name = item.region
          return item
        }))
      })
  },
  getAllDates({ commit, state }) {
    const region = state.regions.filter(item => item.select)[0]?.name;
    axios.get(getEventsQuery(region))
      .then(response => {
        commit('setAllDates', response.data);
      });
  },
}

// mutations
const mutations = {
  setRegion(state, value) {
    state.regions = value
  },
  setDate(state, value) {
    state.dateIn = value.in
    state.dateOut = value.out
  },
  setDates(state, value) {
    state.dates = value
  },
  setAllDates(state, value) {
    const rawDates = value.map(item => item.date).sort((a, b) => b - a);
    state.dates = getDatesListFromItems(rawDates);
  },
  setItems(state, value) {
    state.items = value;
  },
  setItem(state, value) {
    state.item = value;
  },
  setPage(state, value) {
    state.page = Number(value)
  },
  setTotalCount(state, value) {
    state.totalCount = Number(value)
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
