<template>
    <div class="banner">
        <img :src="bannerDesktop" class="desc" alt="img"/>
        <img :src="bannerMd" class="mid" alt="img"/>
        <img :src="bannerMobile" class="mob" alt="img"/>
        <div class="info">
            <div class="container">
                <div class="info__text">
                    <div class="title">Внесудебное банкротство гражданина</div>
                    <div class="description">БАНКРОТСТВО — ситуация, когда человек не может платить по своим долгам. Он признается должником (или банкротом), а те, кому он должен, — его кредиторами.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bannerDesktop from '../../../assets/bankruptcy/banner-desktop.png';
    import bannerMd from '../../../assets/bankruptcy/banner-md.png';
    import bannerMobile from '../../../assets/bankruptcy/banner-mobile.png';
    export default {
        name: "Banner",
        data() {
            return {
                bannerDesktop,
                bannerMd,
                bannerMobile,
            };
        }
    }
</script>

<style lang="scss" scoped>
    .banner{
        position: relative;
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, center, row);
        padding-bottom: 0;
        img{
            @include box(100%, auto, relative, null, null, null, null);
            &.desc{
                display: flex;
            }
            &.mid{
                display: none;
            }
            &.mob{
                display: none;
            }
        }
        .info{
            width: 100%;
            position: absolute;
            color: #FFFFFF;
            .info__text {
                .title {
                    width: 730px;
                    font-size: 44px;
                    line-height: 52px;
                    font-weight: 400;
                    padding-bottom: 16px;
                }
                .description {
                    width: 625px;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 400;
                }
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .banner{
            img{
                &.desc{
                    display: none;
                }
                &.mid{
                    display: flex;
                }
                &.mob{
                    display: none;
                }
            }
            .info{
                .info__text {
                    margin-top: -90px;
                    .title {
                        width: 482px;
                        font-size: 29px;
                        line-height: 37px;
                        font-weight: 400;
                        padding-left: 15px;
                    }
                    .description {
                        width: 580px;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 400;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .banner{
            img{
                &.desc{
                    display: none;
                }
                &.mid{
                    display: none;
                }
                &.mob{
                    display: flex;
                }
            }
            .info{
                .info__text {
                    margin-top: -90px;
                    .title {
                        width: 290px;
                        font-size: 29px;
                        line-height: 37px;
                        font-weight: 400;
                        padding-left: 15px;
                    }
                    .description {
                        width: 290px;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 400;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
</style>

