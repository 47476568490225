<template>
  <form action="#" class="content__item-header nod">
    <SearchInput/>
    <SortSelect @change="changeSortType"/>
  </form>
</template>

<script>
import SearchInput from './components/SearchInput.vue'
import SortSelect from '../../../mfcCabinet/practices/SortSelect.vue'
import { mapActions, mapMutations } from 'vuex'


export default {
  name: 'SearchBar',
  components: {
    SearchInput,
    SortSelect
  },
  data: () => ({
    filter: {
      sort: {
        date: 'ASC',
      },
    },
    search: '',
  }),
  methods:{
    ...mapActions('practices', [
      'getApi',
    ]),
    ...mapMutations('practices', [
      'setSortType',
    ]),
    changeSortType(sortType) {
      this.setSortType(sortType)
      this.getApi({})
    },
  },
}
</script>

<style lang="scss" scoped>
.sort-select.content__item-select.sidebar-input {
  max-width: 100%;
  margin-bottom: 0;
}
</style>
