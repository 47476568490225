import { render, staticRenderFns } from "./Inputbox.vue?vue&type=template&id=a28014ec&scoped=true&"
import script from "./Inputbox.vue?vue&type=script&lang=js&"
export * from "./Inputbox.vue?vue&type=script&lang=js&"
import style0 from "./Inputbox.vue?vue&type=style&index=0&id=a28014ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a28014ec",
  null
  
)

export default component.exports