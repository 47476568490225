<template>
  <div class="items">
    <div class="rows">
      <a :href="item.link" class="item" v-for="(item, i) in items" :key="i" target="_blank">
        <div class="lft">
          <DocVertical />
        </div>
        <div class="rgt">
          <div class="link" target="_blank">
            {{item.name}}
          </div>
          <div class="sub">{{item.about ? item.about : item.description }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import DocVertical from '@/components/icons/DocVertical.vue'

export default {
  name: 'Items',
  components: {
    DocVertical
  },
  props: {
    items: Array,
    padding: Boolean,
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/poster/' + pic)
    },
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>

<style lang="scss" scoped>
.items{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-top: 1vw;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding-left: 2vw;
    .item{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      padding: 1.5vw 0;
      border-top: 0.1vw solid #E9D7C1;
      .lft{
        flex-shrink: 0;
      }
      .rgt{
        @include box(100%, auto, relative, null, null, null, null);
        padding-left: 1.5vw;
        padding-right: 4vw;
        .link{
          font-size: 1vw;
          color: #E04E39;
          transition: 0.2s;
          &:hover{
            color: #2C2A29;
          }
        }
        .sub{
          font-size: 0.8vw;
          padding-top: 0.5vw;
          color: rgba(44, 42, 41, 0.7);
        }
      }
      &:first-child{
        border-top: 0;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .items{
    padding-top: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    .rows{
      padding: 0;
      .item{
        padding: 2vw 0;
        border-top: 0.2vw solid #E9D7C1;
        .rgt{
          padding-left: 3vw;
          padding-right: 0;
          .link{
            font-size: 2vw;
          }
          .sub{
            font-size: 2vw;
            padding-top: 1vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .items{
    padding-top: 4vw;
    .rows{
      .item{
        padding: 3vw 0;
        border-top: 0.4vw solid #E9D7C1;
        .lft{
          padding-left: 0;
          padding-right: 4vw;
        }
        .rgt{
          padding-left: 0;
          padding-right: 0;
          .link{
            font-size: 4vw;
          }
          .sub{
            font-size: 3.5vw;
            padding-top: 1vw;
          }
        }
      }
    }
  }
}
</style>
