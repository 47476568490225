<template>
  <div class="news-list" :class="{'with--preview': hasBigPreview}">
    <div class="news-list__item item-news" v-for="(item, i) in getNews" :key="i">
      <router-link class="item-news__image" :to="{ name: pageName, params: { id: item.id }}">
        <img class="item-news__img" :src="item.anons_photo"/>
      </router-link>
      <div class="item-news__text">
        <router-link class="item-news__title" :to="{ name: pageName, params: { id: item.id }}">{{item.title}}</router-link>
        <div class="item-news__date">{{ getDate(item.date) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFullDateFromTimestamp} from "@/helpers/date";

export default {
  name: 'NewsList',
  props: {
    news: {type: Array, required: true},
    pageName: {type: String, required: true},
    hasBigPreview: {type: Boolean, default: true},
    count: {type: Number, default: 0},
  },
  computed: {
    getNews() {
      return this.count > 0 ? this.news.slice(0, this.count) : this.news;
    }
  },
  methods: {
    getDate(date) {
      return getFullDateFromTimestamp(date);
    }
  },
}
</script>
<style lang="scss" scoped>
.news-list {
  &__item {
    border-top: 1px solid #E9D7C1;
    padding: 1vw 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    &:first-child {
      border-top: none;
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 1px solid #E9D7C1;
    }
  }
  .item-news {
    &__image {
      max-width: 30%;
      img {
        width: auto;
        height: 7vw;
      }
    }
    &__title {
      display: block;
      font-size: 1vw;
      padding-right: 1vw;
      color: rgb(224, 78, 57);
      transition: 0.1s;
      &:hover{
        opacity: 0.8;
        transform: scale(0.98);
      }
    }
    &__date {
      padding-top: 1vw;
      margin-right: 1vw;
      font-size: 0.7vw;
      color: rgba(45, 43, 42, 0.7);
    }
  }
}
.with--preview {
  display: grid;
  grid-template-columns: calc(70% - 2vw) 1fr;
  grid-gap: 0 2vw;
  .item-news {
    grid-column: 1 / 3;
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / 4;
      position: relative;
      overflow: hidden;
      border-radius: 0.4vw;
      height: 20vw;
      padding: 0;
      margin: 1vw 0;
      .item-news__image {
        position: absolute;
        width: 100%;
        max-width: 100%;
        height: 20vw;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      .item-news__img {
        width: 100%;
        height: auto;
        transition: all 0.2s;
      }
      .item-news__text {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1.5vw;
        pointer-events: none;
      }
      .item-news__date,
      .item-news__title {
        color: #fff;
      }
      .item-news__title:hover {
        transform: none;
      }
      &:hover {
        .item-news__img {
          transform: scale(1.1);
        }
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      grid-column: 2;
      flex-direction: row;
      //background: #acb;
      .item-news__image {
        display: none;
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5) {
      border-top: none;
    }
  }
}
@media only screen and (max-width: 900px) {
  .news-list {
    &__item {
      .item-news__title {
        font-size: 2vw;
      }
      .item-news__date {
        font-size: 1.5vw;
      }
      .item-news__image {
        img {
          height: 18vw;
        }
      }
    }
  }
  .with--preview {
    .item-news {
      padding: 2vw 0;
      &:first-child {
        height: 30vw;
        .item-news__image {
          height: 30vw;
        }
      }
      &:nth-child(4) {
        grid-column: 1 / 3;
        border-top: none;
        padding-top: 2vw;
        flex-direction: row-reverse;
        border-bottom: 1px solid #E9D7C1;
        //background: #acb;
        .item-news__image {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .news-list {
    margin-bottom: 4vw;
    .item-news {
      flex-direction: column;
      padding: 4vw 0;
      .item-news__image {
        position: relative;
        max-width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      &__text {
        margin: 2vw 0;
      }
      &__title {
        font-size: 4vw;
        margin-bottom: 2vw;
      }
      &__date {
        font-size: 3.5vw;
      }
    }
  }
  .with--preview {
    display: block;
    .item-news {
      &:nth-child(1) {
        height: auto;
        border-radius: 0;
        .item-news__image {
          position: relative;
          height: auto;
          img {
            display: block;
          }
        }
        .item-news__text {
          padding: 3vw;
        }
        .item-news__title {
          display: block;
          padding-right: 1vw;
          color: #fff;
        }
        .item-news__date {
          font-size: 3.5vw;
          color: #fff;
        }
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex-direction: column;
        //background: #acb;
        .item-news__image {
          display: block;
        }
      }
    }
  }
}
</style>
