<template>
  <div class="content" v-if="item">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Новости', route: '/news'}]" />
    <div class="container cont">
      <div class="row min top">
        <Types :types="item.types" />
      </div>
      <div class="row max">
        <div class="date">
          <div class="dates">
            <span>{{getDate(item.date, false)}}</span>
            <span>{{getDate(item.date, true)}}</span>
          </div>
          <div class="autor">{{item.firstSource}}</div>
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="text" v-html="item.content"></div>
        <div class="text">
          <div class="p">
            <b>{{ item.author }}</b>
            <br />
            <a class="link" :href="item.source" target="_blank">Источник</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container column cont">
      <div class="row min bottom">
        <Types :bottom="true" />
      </div>
      <Social :item="item" />
    </div>
    <Quiz v-if="quiz" :quiz="quiz" @quiz-submit="quizSubmitHandler" />
    <Other :news="getNewsSortedByDate.filter(initem => initem.id !== item.id)" :groups="groups" />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Types from '@/components/views/news/Types.vue'
import Social from '@/components/views/news/Social.vue'
import Other from '@/components/views/news/Other.vue'
import Quiz from "@/components/views/news/Quiz";
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'HomeView',
  components: {
    Quiz,
    Breadcrumbs,
    Types,
    Social,
    Other
  },
  computed: {
    ...mapState('news', [
      'groups',
      'news',
      'item',
      'quiz',
    ]),
    ...mapGetters('news', [
      'getNewsSortedByDate',
    ]),
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions('news', [
      'setRegion',
      'getApiOnce',
      'getApi',
      'sendQuiz',
    ]),
    getDate(date, time) {
      if (time) {
        return new Date(date * 1000).toLocaleString("ru", { hour: 'numeric', minute: 'numeric' })
      } else {
        return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
      }
    },
    quizSubmitHandler(value) {
      this.sendQuiz(value);
    }
  },
  mounted() {
    this.getApiOnce(this.$route.params.id)
    this.getApi({});
  },
  watch: {
    id() {
      this.getApiOnce(this.id);
    }
  }

}
</script>

<style lang="scss">
.content {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .breadcrumbs {
    padding: 1vw 0;
    padding-top: 2vw;
  }
  .cont {
    @include flex(left, top, row);
    padding-top: 1vw;
    &.column {
      @include flex(left, top, column);
    }
    .row {
      &.min {
        @include box(22%, auto, relative, null, null, null, null);
        &.bottom {
          display: none;
        }
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
        .date {
          @include flex(left, top, row);
          .dates {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            font-size: 1vw;
            span {
              margin-right: 1vw;
              color: rgba(44, 42, 41, 0.7);
            }
          }
          .autor {
            @include box(auto, auto, relative, null, null, null, null);
            flex-shrink: 0;
            font-size: 1vw;
          }
        }
        .title {
          padding: 2vw 0;
          font-size: 2.7vw;
        }
        .text {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          img {
            @include box(100%, auto, relative, null, null, null, null);
          }
          .p {
            padding: 1vw 0;
            font-size: 1.1vw;
            &.sub {
              color: #c39367;
              font-style: italic;
            }
            .link {
              color: #e04e39;
            }
          }
          .image {
            @include box(100%, auto, relative, null, null, null, null);
            img {
              @include box(100%, auto, relative, null, null, null, null);
            }
            .sub {
              font-size: 0.9vw;
              color: rgba(44, 42, 41, 0.7);
              font-style: italic;
              padding-top: 0.5vw;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .content {
    .breadcrumbs {
      padding: 5vw 3vw;
      padding-top: 5vw;
    }
    .cont {
      @include flex(left, top, column);
      padding: 0 3vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
          &.top {
            display: none;
          }
          &.bottom {
            display: flex;
          }
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .date {
            .dates {
              font-size: 2vw;
              span {
                margin-right: 2vw;
              }
            }
            .autor {
              font-size: 2vw;
            }
          }
          .title {
            padding: 3vw 0;
            padding-bottom: 0;
            font-size: 4.4vw;
          }
          .text {
            .p {
              padding: 3vw 0;
              font-size: 2vw;
            }
            .image {
              .sub {
                font-size: 2vw;
                padding-top: 3vw;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .content {
    .breadcrumbs {
      padding: 8vw 6vw;
      padding-top: 6vw;
    }
    .cont {
      @include flex(left, top, column);
      padding: 0 6vw;
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .date {
            .dates {
              font-size: 4vw;
              span {
                margin-right: 4vw;
              }
            }
            .autor {
              font-size: 4vw;
            }
          }
          .title {
            padding: 6vw 0;
            font-size: 6.6vw;
          }
          .text {
            .p {
              padding: 4vw 0;
              font-size: 4vw;
            }
            .image {
              .sub {
                font-size: 4vw;
                padding-top: 3vw;
              }
            }
          }
        }
      }
    }
  }
}
</style>
