<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 5C12.1445 5 9 8.14453 9 12C9 14.4102 10.2305 16.5508 12.0938 17.8125C8.52734 19.3438 6 22.8828 6 27H8C8 22.5703 11.5703 19 16 19C18.1445 19 20.0625 19.8789 21.5 21.25L16.7812 25.9688L16.7188 26.2812L16.0312 29.8125L15.7188 31.2812L17.1875 30.9688L20.7188 30.2812L21.0312 30.2188L31.125 20.125C32.2852 18.9648 32.2852 17.0352 31.125 15.875C30.543 15.293 29.7695 15 29 15C28.2461 15 27.4844 15.2852 26.9062 15.8438L22.9375 19.8125C22.0664 18.9766 21.0352 18.293 19.9062 17.8125C21.7695 16.5508 23 14.4102 23 12C23 8.14453 19.8555 5 16 5ZM16 7C18.7734 7 21 9.22656 21 12C21 14.7734 18.7734 17 16 17C13.2266 17 11 14.7734 11 12C11 9.22656 13.2266 7 16 7ZM29 17C29.2539 17 29.5195 17.082 29.7188 17.2812C30.1172 17.6797 30.1172 18.2891 29.7188 18.6875L20.0312 28.375L18.25 28.75L18.625 26.9688L28.3125 17.2812C28.5117 17.082 28.7461 17 29 17Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Edit"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
