<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.33594C0 4.18927 4.1854 0 9.33073 0H22.6641C27.8107 0 32 4.1854 32 9.33073V22.6641C32 27.8107 27.8146 32 22.6693 32H9.33594C4.18927 32 0 27.8146 0 22.6693V9.33594ZM25.936 8.777C27.015 8.648 28.043 8.362 29 7.937C28.285 9.006 27.38 9.945 26.337 10.698C26.347 10.927 26.352 11.157 26.352 11.388C26.352 18.437 20.988 26.565 11.176 26.565C8.163 26.565 5.36 25.682 2.999 24.169C3.417 24.219 3.842 24.244 4.272 24.244C6.772 24.244 9.072 23.391 10.897 21.96C8.563 21.917 6.593 20.374 5.914 18.255C6.239 18.316 6.574 18.35 6.917 18.35C7.404 18.35 7.876 18.285 8.323 18.163C5.882 17.674 4.044 15.518 4.044 12.933V12.866C4.763 13.265 5.586 13.505 6.46 13.533C5.029 12.577 4.088 10.944 4.088 9.094C4.088 8.115 4.351 7.199 4.809 6.411C7.441 9.639 11.371 11.763 15.804 11.985C15.714 11.596 15.666 11.188 15.666 10.77C15.666 7.825 18.054 5.436 21 5.436C22.534 5.436 23.92 6.084 24.893 7.121C26.109 6.881 27.251 6.437 28.281 5.826C27.883 7.072 27.038 8.117 25.936 8.777Z" fill="white" fill-opacity="0.5"/>
  </svg>

</template>

<script>
    export default {
        name: "Twitter"
    }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(5vw, 5vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
      @include box(10vw, 10vw, relative, null, null, null, null);
    }
  }
</style>
