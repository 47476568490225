<template>
  <svg width="128" height="96" viewBox="0 0 128 96" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path d="M100.27 60.3V49.25H103.98V54.4C103.98 54.78 103.9 55.2 103.9 55.2H104.06L108.09 49.25H111.23V60.3H107.93V55.11C107.93 54.74 108.01 54.38 108.01 54.38H107.85L103.81 60.3H100.27Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M98.737 54.79C98.737 51.45 96.107 48.96 92.577 48.96C89.087 48.96 86.457 51.45 86.457 54.79C86.457 58.1 89.087 60.59 92.577 60.59C96.107 60.59 98.737 58.1 98.737 54.79ZM94.817 54.79C94.817 56.1 93.927 57.15 92.577 57.15C91.267 57.15 90.337 56.1 90.337 54.79C90.337 53.45 91.267 52.4 92.577 52.4C93.917 52.4 94.817 53.45 94.817 54.79Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0188 68.06C25.0188 64.7 22.5688 62.21 19.1088 62.21C15.6888 62.21 13.2088 64.7 13.2188 68.06C13.2188 71.38 15.6888 73.85 19.1088 73.85C22.5688 73.85 25.0188 71.37 25.0188 68.06ZM22.8588 68.06C22.8588 70.2 21.3388 71.82 19.1088 71.82C16.9188 71.82 15.3988 70.2 15.3988 68.06C15.3988 65.89 16.9188 64.24 19.1088 64.24C21.3488 64.24 22.8588 65.89 22.8588 68.06Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M74.197 68.06C74.197 68.29 74.187 68.53 74.167 68.75V68.76H65.737H65.727C65.937 70.69 67.107 72 69.517 72C71.037 72 72.327 71.41 73.177 70.77L74.047 72.35C72.907 73.28 71.317 73.85 69.457 73.85C65.597 73.85 63.747 71.63 63.497 68.75C63.467 68.53 63.457 68.29 63.457 68.06C63.457 64.7 65.707 62.21 68.817 62.21C72.457 62.21 74.197 64.7 74.197 68.06ZM72.137 67.04C71.967 65.34 70.927 64.05 68.937 64.05C67.297 64.05 66.087 65.29 65.767 67.04H72.137Z" fill="white"/>
    <path d="M83.8405 62.5098V67.0298H77.9305V62.5098H75.7305V73.5598H77.9305V68.7498H83.8405V73.5598H86.0405V62.5098H83.8405Z" fill="white"/>
    <path d="M97.6286 62.5098H87.5586V64.4698H91.4986V73.5598H93.6986V64.4698H97.6286V62.5098Z" fill="white"/>
    <rect x="109.438" y="62.5098" width="2.2" height="11.05" fill="white"/>
    <path d="M31.3573 67.7498V67.6498C31.3573 67.6498 31.5573 67.5798 31.7473 67.3698C31.9273 67.1598 35.9573 62.5098 35.9573 62.5098H33.4873L29.6073 67.0398H28.7273V62.5098H26.5273V73.5598H28.7273V68.7498H29.7273L33.9173 73.5598H36.6573C36.6573 73.5598 31.9573 68.2698 31.7573 68.0398C31.5773 67.8098 31.3573 67.7498 31.3573 67.7498Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M101.348 66.0198C101.348 66.0198 102.018 65.8898 103.048 65.8898C106.408 65.8898 107.418 67.7298 107.418 69.6898C107.418 71.6298 106.278 73.7298 102.828 73.7298C100.948 73.7298 99.1484 73.5598 99.1484 73.5598V62.5098H101.348V66.0198ZM101.358 71.8498C101.358 71.8498 101.978 71.8998 102.658 71.8998C104.598 71.8998 105.328 71.0698 105.338 69.7698C105.338 68.4398 104.528 67.7298 102.688 67.7298C101.838 67.7298 101.358 67.8598 101.358 67.8598V71.8498Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52894 63.9097L9.58894 62.5097H11.7189V72.9097C11.7189 76.4497 9.44894 78.2897 6.35894 78.2897C4.34172 78.2897 3.06943 77.6219 2.33606 77.2369C2.27688 77.2058 2.22121 77.1766 2.16894 77.1497L2.88894 75.3297C3.61894 75.7697 4.81894 76.3497 6.35894 76.3497C8.02894 76.3497 9.50894 75.4997 9.50894 73.3597V72.3697H9.36894C8.46894 73.4397 7.54894 73.8597 6.06894 73.8597C2.83894 73.8597 0.898944 71.3897 0.978944 68.0697C1.04894 64.7097 2.96894 62.2197 6.18894 62.2197C7.28894 62.2197 8.68894 62.8697 9.38894 63.9097H9.52894ZM3.08894 68.0597C3.04894 70.1997 4.21894 71.8197 6.43894 71.8197C7.73894 71.8197 8.80894 71.2897 9.48894 70.4397V67.3897C9.48894 65.3597 7.82894 64.2397 6.60894 64.2397C4.38894 64.2397 3.13894 65.8897 3.08894 68.0597Z" fill="white"/>
    <path d="M45.39 62.5098V70.5198C44.74 71.3298 43.75 71.8298 42.5 71.8298C40.48 71.8298 39.56 70.2098 39.61 68.0698V62.5098H37.48V68.0698C37.4 71.3898 38.73 73.8598 41.95 73.8598C43.42 73.8598 44.43 73.4198 45.25 72.3698H45.39V73.3598C45.39 75.4998 43.91 76.3498 42.23 76.3498C40.69 76.3498 39.49 75.7698 38.76 75.3298L38.04 77.1498C38.74 77.5098 40.05 78.2898 42.23 78.2898C45.32 78.2898 47.58 76.4498 47.58 72.9098V62.5098H45.39V62.5098Z" fill="white"/>
    <path d="M59.8706 62.5098C59.8706 62.5098 56.2306 67.6298 56.1206 67.7798C56.0206 67.9298 55.9806 68.0698 55.9806 68.0698H55.9006C55.9006 68.0698 55.8606 67.9298 55.7406 67.7798C55.6306 67.6298 51.7206 62.5098 51.7206 62.5098H49.6406V73.5598H51.8406V66.5198C51.8406 66.0298 51.7406 65.7498 51.7406 65.7498H51.8406L55.8906 71.1898H55.9806L59.7306 65.7498H59.8306C59.8306 65.7498 59.7306 66.0398 59.7306 66.5098V73.5598H61.9306V62.5098H59.8706Z" fill="white"/>
    <path d="M82.0076 49.25C82.0076 49.25 78.9076 53.27 78.7876 53.43C78.6376 53.62 78.6276 53.72 78.6276 53.72H78.4676C78.4676 53.72 78.4376 53.61 78.2876 53.43C78.0676 53.16 74.8575 49.25 74.8575 49.25H71.9375V60.3H75.5075V55.66C75.5075 55.16 75.4275 54.86 75.4275 54.86H75.5875L78.4576 58.16H78.6076L81.2676 54.86H81.4276C81.4276 54.86 81.3476 55.16 81.3476 55.66V60.3H84.9276V49.25H82.0076Z" fill="white"/>
    <path d="M105.008 27.3199V16.1099H96.168V27.3199C96.168 31.2699 98.278 34.9099 101.698 36.8899L111.638 42.6299V32.4299L106.118 29.2399C105.428 28.8399 105.008 28.1099 105.008 27.3199Z" fill="#C59368"/>
    <path d="M111.641 38.1999L121.581 32.4599C125.001 30.4799 127.111 26.8399 127.111 22.8899V0.629883H111.641V9.46988H118.271V22.8899C118.271 23.6799 117.851 24.4099 117.161 24.7999L111.641 27.9899V38.1999H111.641Z" fill="#EA543A"/>
    <path d="M111.638 0.639648L96.168 16.0996H111.638V0.639648Z" fill="white"/>
    <path d="M29.4589 90.75L29.5089 90.04H29.4789L29.1489 90.83L27.5689 93.44H27.1289V88.77H27.7989V91.55L27.7489 92.23H27.7789L28.0889 91.46L29.6889 88.77H30.1289V93.44H29.4589V90.75Z" fill="white"/>
    <path d="M36.6998 90.67L36.7598 89.78H36.7198L36.4498 90.57L35.5698 92.37H35.3298L34.4198 90.57L34.1698 89.78H34.1198L34.1998 90.66V93.44H33.5898V88.77H34.2698L35.2798 90.8L35.4898 91.41H35.5098L35.7298 90.78L36.6798 88.77H37.3698V93.44H36.6998V90.67Z" fill="white"/>
    <path d="M39.8197 91.79L40.0197 92.7H40.0697L40.2097 91.79L40.9197 88.77H41.5997L40.4897 92.97C40.3997 93.31 40.3197 93.62 40.2297 93.92C40.1397 94.21 40.0497 94.47 39.9497 94.69C39.8497 94.9 39.7297 95.07 39.5997 95.19C39.4697 95.31 39.3197 95.37 39.1497 95.37C38.9697 95.37 38.8197 95.34 38.6897 95.28L38.7997 94.64C38.8897 94.67 38.9697 94.68 39.0597 94.66C39.1497 94.64 39.2297 94.58 39.3097 94.5C39.3897 94.41 39.4597 94.29 39.5197 94.11C39.5897 93.94 39.6397 93.71 39.6897 93.44L38.1797 88.77H38.9497L39.8197 91.79Z" fill="white"/>
    <path d="M44.6472 91.36H43.0372V93.44H42.3672V88.77H43.0372V90.75H44.6472V88.77H45.3272V93.44H44.6472V91.36Z" fill="white"/>
    <path d="M48.9394 90.75L48.9894 90.04H48.9494L48.6294 90.83L47.0494 93.44H46.6094V88.77H47.2794V91.55L47.2294 92.23H47.2694L47.5694 91.46L49.1694 88.77H49.6094V93.44H48.9394V90.75Z" fill="white"/>
    <path d="M54.3284 94.63H53.8684L53.7484 93.44H50.8984V88.77H51.5784V92.84H53.1184V88.77H53.7884V92.84H54.3284V94.63Z" fill="white"/>
    <path d="M57.5487 90.75L57.6088 90.04H57.5687L57.2487 90.83L55.6587 93.44H55.2188V88.77H55.8988V91.55L55.8388 92.23H55.8788L56.1888 91.46L57.7887 88.77H58.2287V93.44H57.5487V90.75Z" fill="white"/>
    <path d="M61.7578 89.38H60.1878V93.44H59.5078V88.77H62.4278V93.44H61.7578V89.38Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.337 88.7897C64.077 88.8497 63.857 88.9397 63.677 89.0497L63.907 89.6097C64.037 89.5297 64.217 89.4597 64.417 89.4097C64.627 89.3597 64.817 89.3297 64.987 89.3297C65.257 89.3297 65.457 89.3997 65.557 89.5397C65.667 89.6897 65.717 89.9397 65.717 90.2897C65.717 90.4397 65.707 90.6097 65.687 90.8097C65.617 90.7997 65.547 90.7997 65.487 90.7997H65.307C65.027 90.7997 64.777 90.8197 64.547 90.8697C64.327 90.9097 64.127 90.9897 63.967 91.0997C63.807 91.2097 63.677 91.3597 63.587 91.5397C63.497 91.7197 63.457 91.9397 63.457 92.2097C63.457 92.6197 63.567 92.9397 63.777 93.1797C63.987 93.4097 64.267 93.5297 64.597 93.5297C64.897 93.5297 65.137 93.4697 65.317 93.3497C65.497 93.2297 65.637 93.0897 65.727 92.9297H65.767L65.927 93.4797H66.457C66.407 93.2897 66.387 93.0997 66.367 92.8997C66.347 92.6997 66.337 92.4797 66.337 92.2397C66.337 91.9197 66.347 91.5897 66.367 91.2297C66.377 90.8797 66.387 90.5097 66.387 90.1397C66.387 89.9497 66.377 89.7697 66.347 89.5997C66.317 89.4297 66.257 89.2797 66.167 89.1397C66.077 88.9997 65.947 88.8997 65.787 88.8197C65.627 88.7397 65.417 88.6997 65.157 88.6997C64.867 88.6997 64.597 88.7297 64.337 88.7897ZM65.507 91.2997C65.567 91.2997 65.627 91.2997 65.687 91.3097H65.697V92.2897C65.637 92.4397 65.537 92.5697 65.397 92.6997C65.257 92.8297 65.057 92.8897 64.797 92.8897C64.597 92.8897 64.447 92.8297 64.327 92.6897C64.207 92.5497 64.147 92.3597 64.147 92.1097C64.147 91.9497 64.177 91.8197 64.247 91.7097C64.307 91.6097 64.397 91.5197 64.507 91.4597C64.617 91.3997 64.747 91.3497 64.887 91.3297C65.027 91.2997 65.167 91.2897 65.317 91.2897C65.3534 91.2897 65.387 91.2924 65.4194 91.295C65.4493 91.2974 65.4782 91.2997 65.507 91.2997Z" fill="white"/>
    <path d="M69.6808 89.38H68.6608C68.6408 89.91 68.6208 90.42 68.5908 90.92C68.5608 91.42 68.5008 91.85 68.4308 92.23C68.3508 92.61 68.2408 92.91 68.1008 93.14C67.9608 93.37 67.7708 93.48 67.5308 93.48C67.4208 93.48 67.3308 93.47 67.2608 93.46C67.1808 93.45 67.1108 93.43 67.0508 93.4L67.1608 92.82C67.2908 92.86 67.4008 92.85 67.5108 92.8C67.6108 92.75 67.7008 92.59 67.7808 92.31C67.8508 92.04 67.9108 91.62 67.9608 91.06C68.0108 90.5 68.0408 89.74 68.0608 88.77H70.3708V93.44H69.7008V89.38H69.6808Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.6706 90.51C72.5606 90.52 72.4406 90.54 72.3206 90.56V88.77H71.6406V93.44C71.9906 93.48 72.3406 93.5 72.7106 93.5C73.3006 93.5 73.7206 93.37 74.0006 93.12C74.2706 92.87 74.4106 92.46 74.4106 91.89C74.4106 91.7 74.3906 91.51 74.3406 91.34C74.2806 91.17 74.2006 91.02 74.0906 90.9C73.9806 90.78 73.8306 90.68 73.6506 90.61C73.4706 90.54 73.2506 90.5 72.9906 90.5C72.8906 90.5 72.7806 90.5 72.6706 90.51ZM72.8306 92.9C72.6806 92.9 72.5006 92.88 72.3206 92.84H72.3106V91.13C72.4006 91.11 72.4906 91.09 72.5806 91.08C72.6706 91.07 72.7606 91.07 72.8406 91.07C73.1406 91.07 73.3606 91.15 73.5006 91.31C73.6406 91.47 73.7106 91.69 73.7106 91.97C73.7106 92.59 73.4106 92.9 72.8306 92.9Z" fill="white"/>
    <path d="M77.6494 91.36H76.0294V93.44H75.3594V88.77H76.0294V90.75H77.6494V88.77H78.3194V93.44H77.6494V91.36Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2989 90.5569C80.3123 90.5548 80.3257 90.5528 80.3389 90.5507C80.446 90.534 80.546 90.5185 80.6389 90.51C80.7489 90.5 80.8589 90.5 80.9589 90.5C81.2189 90.5 81.4289 90.54 81.6089 90.61C81.7789 90.68 81.9189 90.78 82.0289 90.9C82.1289 91.02 82.2089 91.17 82.2489 91.34C82.2889 91.51 82.3189 91.7 82.3189 91.89C82.3189 92.46 82.1889 92.87 81.9389 93.12C81.6789 93.37 81.2689 93.5 80.6789 93.5C80.3189 93.5 79.9689 93.48 79.6289 93.44V88.77H80.2989V90.5569ZM82.8589 88.77H83.5289V93.44H82.8589V88.77ZM80.2789 92.84C80.4689 92.88 80.6389 92.9 80.7989 92.9C81.0989 92.9 81.3089 92.82 81.4289 92.66C81.5489 92.5 81.6089 92.27 81.6089 91.97C81.6089 91.69 81.5489 91.47 81.4389 91.31C81.3289 91.15 81.1189 91.07 80.8289 91.07C80.7489 91.07 80.6589 91.07 80.5689 91.08C80.4789 91.09 80.3789 91.11 80.2989 91.13V92.84H80.2789Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M87.0472 93.4397C87.2772 93.3697 87.4672 93.2697 87.6172 93.1297L87.3872 92.6197C87.2972 92.7097 87.1772 92.7897 87.0072 92.8497C86.8372 92.9097 86.6772 92.9397 86.5072 92.9397C86.2872 92.9397 86.1072 92.8997 85.9572 92.8097C85.8072 92.7297 85.6872 92.6097 85.5972 92.4597C85.4972 92.2997 85.4272 92.1197 85.3872 91.8997C85.3472 91.6797 85.3272 91.4397 85.3272 91.1697H87.6972L87.6972 91.1697C87.7072 91.0397 87.7172 90.9097 87.7272 90.7897C87.7272 90.7284 87.7298 90.6697 87.7324 90.6122C87.7348 90.5573 87.7372 90.5035 87.7372 90.4497C87.7372 90.0697 87.6872 89.7597 87.6072 89.5297C87.5272 89.2997 87.4172 89.1097 87.2772 88.9797C87.1372 88.8497 86.9872 88.7697 86.8172 88.7197C86.6472 88.6797 86.4672 88.6597 86.2972 88.6597C85.7672 88.6597 85.3472 88.8597 85.0572 89.2797C84.7672 89.6897 84.6172 90.2997 84.6172 91.0997C84.6172 91.4697 84.6472 91.8097 84.7172 92.1097C84.7872 92.4097 84.8872 92.6597 85.0272 92.8797C85.1672 93.0997 85.3472 93.2697 85.5572 93.3797C85.7672 93.4897 86.0272 93.5497 86.3172 93.5497C86.5772 93.5497 86.8172 93.5197 87.0472 93.4397ZM86.8872 89.5897C87.0372 89.8097 87.1072 90.1497 87.0872 90.6197H85.3172C85.3472 90.1597 85.4472 89.8097 85.6172 89.5897C85.7872 89.3797 86.0072 89.2697 86.2972 89.2697C86.5472 89.2697 86.7472 89.3697 86.8872 89.5897Z" fill="white"/>
    <path d="M92.1495 91.79L92.3495 92.7H92.3995L92.5395 91.79L93.2495 88.77H93.9295L92.8195 92.97C92.7295 93.31 92.6495 93.62 92.5595 93.92C92.4695 94.21 92.3795 94.47 92.2795 94.69C92.1795 94.9 92.0595 95.07 91.9295 95.19C91.7995 95.31 91.6495 95.37 91.4795 95.37C91.2995 95.37 91.1495 95.34 91.0195 95.28L91.1295 94.64C91.2195 94.67 91.2995 94.68 91.3895 94.66C91.4795 94.64 91.5595 94.58 91.6395 94.5C91.7195 94.41 91.7895 94.29 91.8595 94.11C91.9195 93.94 91.9795 93.71 92.0295 93.44L90.5195 88.77H91.2895L92.1495 91.79Z" fill="white"/>
    <path d="M97.2275 93.2099C97.0675 93.3299 96.8975 93.4199 96.6975 93.4699C96.4975 93.5299 96.2875 93.5499 96.0675 93.5499C95.7675 93.5499 95.5175 93.4899 95.3075 93.3799C95.0975 93.2699 94.9375 93.0999 94.8075 92.8899C94.6775 92.6699 94.5875 92.4199 94.5275 92.1199C94.4675 91.8099 94.4375 91.4799 94.4375 91.1099C94.4375 90.3099 94.5775 89.6999 94.8675 89.2899C95.1475 88.8699 95.5575 88.6699 96.0875 88.6699C96.3275 88.6699 96.5375 88.6899 96.7175 88.7299C96.8875 88.7699 97.0375 88.8299 97.1675 88.8999L96.9775 89.4899C96.7275 89.3499 96.4575 89.2799 96.1575 89.2799C95.8175 89.2799 95.5675 89.4299 95.3975 89.7199C95.2275 90.0199 95.1375 90.4799 95.1375 91.1199C95.1375 91.3699 95.1575 91.6099 95.1875 91.8399C95.2275 92.0599 95.2875 92.2599 95.3775 92.4199C95.4675 92.5899 95.5775 92.7199 95.7175 92.8099C95.8575 92.9099 96.0275 92.9499 96.2375 92.9499C96.3975 92.9499 96.5475 92.9199 96.6875 92.8699C96.8275 92.8099 96.9475 92.7499 97.0275 92.6699L97.2275 93.2099Z" fill="white"/>
    <path d="M99.9972 89.38H98.9772C98.9572 89.91 98.9372 90.42 98.8972 90.92C98.8672 91.42 98.8172 91.85 98.7372 92.23C98.6572 92.61 98.5472 92.91 98.4072 93.14C98.2672 93.37 98.0772 93.48 97.8372 93.48C97.7372 93.48 97.6472 93.47 97.5672 93.46C97.4972 93.45 97.4272 93.43 97.3672 93.4L97.4672 92.82C97.5972 92.86 97.7172 92.85 97.8172 92.8C97.9172 92.75 98.0072 92.59 98.0872 92.31C98.1572 92.04 98.2172 91.62 98.2672 91.06C98.3172 90.5 98.3472 89.74 98.3672 88.77H100.677V93.44H99.9972V89.38Z" fill="white"/>
    <path d="M103.119 91.79L103.319 92.7H103.369L103.509 91.79L104.219 88.77H104.899L103.789 92.97C103.699 93.31 103.619 93.62 103.529 93.92C103.439 94.21 103.349 94.47 103.239 94.69C103.139 94.9 103.019 95.07 102.899 95.19C102.769 95.31 102.619 95.37 102.439 95.37C102.269 95.37 102.109 95.34 101.979 95.28L102.089 94.64C102.179 94.67 102.269 94.68 102.349 94.66C102.439 94.64 102.519 94.58 102.599 94.5C102.679 94.41 102.749 94.29 102.809 94.11C102.879 93.94 102.929 93.71 102.989 93.44L101.469 88.77H102.239L103.119 91.79Z" fill="white"/>
    <path d="M107.888 89.38H106.338V93.44H105.668V88.77H107.888V89.38Z" fill="white"/>
    <path d="M110.947 90.75L111.007 90.04H110.967L110.637 90.83L109.057 93.44H108.617V88.77H109.297V91.55L109.237 92.23H109.277L109.587 91.46L111.187 88.77H111.617V93.44H110.947V90.75Z" fill="white"/>
    <path d="M56.2395 80.5397H54.6895V84.6097H54.0195V79.9297H56.2395V80.5397Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.0784 80.4098C56.7884 80.8098 56.6484 81.4298 56.6484 82.2698C56.6484 83.0898 56.7884 83.7098 57.0484 84.1098C57.3184 84.5198 57.7484 84.7198 58.3184 84.7198C58.8584 84.7198 59.2584 84.5198 59.5584 84.1298C59.8484 83.7398 59.9984 83.1198 59.9984 82.2698C59.9984 81.4498 59.8584 80.8398 59.5884 80.4298C59.3184 80.0198 58.8884 79.8198 58.3184 79.8198C57.7784 79.8198 57.3684 80.0098 57.0784 80.4098ZM57.4084 83.0198C57.3784 82.7898 57.3584 82.5398 57.3584 82.2698C57.3584 81.6298 57.4384 81.1598 57.6084 80.8898C57.7684 80.5898 58.0084 80.4498 58.3284 80.4498C58.5084 80.4498 58.6584 80.4898 58.7784 80.5798C58.8984 80.6698 58.9984 80.7998 59.0784 80.9598C59.1584 81.1198 59.2184 81.3098 59.2484 81.5398C59.2784 81.7698 59.2984 82.0198 59.2984 82.2898C59.2984 82.9298 59.2184 83.3998 59.0584 83.6898C58.8984 83.9798 58.6484 84.1198 58.3284 84.1198C58.1484 84.1198 57.9984 84.0698 57.8784 83.9798C57.7584 83.8898 57.6484 83.7598 57.5784 83.5998C57.4984 83.4398 57.4384 83.2498 57.4084 83.0198Z" fill="white"/>
    <path d="M63.6689 84.3698C63.5089 84.4898 63.3389 84.5798 63.1389 84.6298C62.9389 84.6798 62.7289 84.7098 62.5089 84.7098C62.2089 84.7098 61.9589 84.6498 61.7489 84.5398C61.5389 84.4298 61.3789 84.2598 61.2489 84.0398C61.1189 83.8298 61.0289 83.5698 60.9689 83.2698C60.9089 82.9698 60.8789 82.6298 60.8789 82.2598C60.8789 81.4598 61.0189 80.8498 61.2989 80.4298C61.5889 80.0098 61.9889 79.7998 62.5189 79.7998C62.7689 79.7998 62.9689 79.8198 63.1489 79.8598C63.3289 79.9098 63.4789 79.9598 63.5989 80.0298L63.4089 80.6198C63.1589 80.4798 62.8889 80.4098 62.5989 80.4098C62.2589 80.4098 62.0089 80.5598 61.8389 80.8598C61.6689 81.1598 61.5789 81.6198 61.5789 82.2598C61.5789 82.5198 61.5989 82.7498 61.6389 82.9798C61.6689 83.1998 61.7389 83.3998 61.8189 83.5598C61.9089 83.7298 62.0189 83.8598 62.1589 83.9498C62.2989 84.0498 62.4689 84.0998 62.6789 84.0998C62.8389 84.0998 62.9889 84.0698 63.1289 84.0198C63.2689 83.9598 63.3789 83.8998 63.4689 83.8198L63.6689 84.3698Z" fill="white"/>
    <path d="M65.65 82.9499L65.84 83.8599H65.89L66.03 82.9499L66.74 79.9299H67.42L66.31 84.1299C66.22 84.4699 66.14 84.7799 66.05 85.0699C65.96 85.3599 65.87 85.6199 65.76 85.8299C65.66 86.0399 65.54 86.2099 65.41 86.3399C65.28 86.4599 65.13 86.5199 64.96 86.5199C64.79 86.5199 64.63 86.4899 64.5 86.4299L64.61 85.7999C64.69 85.8299 64.78 85.8399 64.87 85.8199C64.96 85.7999 65.04 85.7499 65.12 85.6599C65.2 85.5699 65.27 85.4399 65.34 85.2699C65.41 85.0999 65.46 84.8799 65.51 84.5999L64 79.9199H64.77L65.65 82.9499Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M71.0108 85.7997H71.4708V83.9997H70.9208V79.9297H68.5708C68.5608 80.5997 68.5408 81.1697 68.4908 81.6497C68.4508 82.1297 68.3908 82.5297 68.3308 82.8597C68.2708 83.1797 68.2108 83.4397 68.1408 83.6197C68.0808 83.7997 68.0308 83.9297 67.9908 83.9997H67.5508V85.7997H68.0108L68.1308 84.6097H70.9008L71.0108 85.7997ZM70.2508 80.5397V83.9997H68.6808C68.7508 83.7997 68.8308 83.5597 68.8908 83.2697L68.8908 83.2697C68.9508 82.9797 69.0108 82.6897 69.0508 82.3697C69.1008 82.0597 69.1308 81.7397 69.1508 81.4197C69.1544 81.3623 69.158 81.3059 69.1615 81.2504C69.1776 80.9963 69.1926 80.7613 69.2008 80.5397H70.2508Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.9798 79.9499C72.7198 80.0099 72.4998 80.0999 72.3198 80.2099L72.5298 80.7699C72.6598 80.6899 72.8398 80.6199 73.0398 80.5699C73.2498 80.5199 73.4398 80.4899 73.6098 80.4899C73.8998 80.4899 74.0898 80.5599 74.1898 80.6999C74.2998 80.8399 74.3498 81.0899 74.3498 81.4499C74.3498 81.5999 74.3398 81.7699 74.3198 81.9599C74.2898 81.9599 74.2573 81.9574 74.2248 81.9549C74.1923 81.9524 74.1598 81.9499 74.1298 81.9499H73.9398C73.6598 81.9499 73.4098 81.9699 73.1798 82.0199C72.9598 82.0699 72.7598 82.1499 72.5998 82.2599C72.4398 82.3699 72.3098 82.5199 72.2198 82.6999C72.1298 82.8799 72.0898 83.0999 72.0898 83.3699C72.0898 83.7799 72.1998 84.1099 72.4098 84.3399C72.6198 84.5799 72.8998 84.6899 73.2298 84.6899C73.5298 84.6899 73.7698 84.6299 73.9498 84.5099C74.1298 84.3899 74.2698 84.2499 74.3598 84.0899H74.3998L74.5598 84.6399H75.0898C75.0498 84.4499 75.0198 84.2599 74.9998 84.0599C74.9898 83.8599 74.9798 83.6399 74.9798 83.3999C74.9798 83.0893 74.9893 82.7599 74.999 82.4207L74.9998 82.3899C75.0098 82.0399 75.0198 81.6699 75.0198 81.2999C75.0198 81.1099 75.0098 80.9299 74.9798 80.7599C74.9498 80.5899 74.8898 80.4399 74.7998 80.2999C74.7098 80.1599 74.5898 80.0599 74.4298 79.9799C74.2698 79.8999 74.0598 79.8599 73.7998 79.8599C73.5098 79.8599 73.2398 79.8799 72.9798 79.9499ZM74.2398 82.4699C74.2698 82.4724 74.2998 82.4749 74.3298 82.4799V83.4699C74.2798 83.6199 74.1798 83.7499 74.0398 83.8799C73.8998 84.0099 73.6998 84.0699 73.4398 84.0699C73.2398 84.0699 73.0898 83.9999 72.9698 83.8599C72.8498 83.7199 72.7898 83.5299 72.7898 83.2799C72.7898 83.1199 72.8198 82.9799 72.8898 82.8799C72.9498 82.7799 73.0398 82.6899 73.1498 82.6299C73.2598 82.5699 73.3898 82.5299 73.5298 82.4999C73.6698 82.4699 73.8198 82.4599 73.9598 82.4599H74.1498C74.1798 82.4649 74.2098 82.4674 74.2398 82.4699Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.7708 79.9298H76.3008V86.4798H76.9808V84.5498C77.1008 84.6198 77.2108 84.6598 77.3208 84.6798C77.4308 84.7098 77.5708 84.7198 77.7508 84.7198C78.0108 84.7198 78.2608 84.6598 78.4708 84.5498C78.6908 84.4298 78.8708 84.2598 79.0208 84.0398C79.1708 83.8198 79.2808 83.5598 79.3608 83.2398C79.4408 82.9298 79.4808 82.5698 79.4808 82.1798C79.4808 81.3398 79.3508 80.7398 79.1108 80.3698C78.8608 79.9998 78.4908 79.8198 78.0008 79.8198C77.5108 79.8198 77.1408 80.0298 76.9108 80.4398H76.8708L76.7708 79.9298ZM77.2608 84.0498C77.1508 84.0098 77.0508 83.9398 76.9708 83.8698H76.9808V81.2798C77.1108 80.7298 77.4008 80.4498 77.8608 80.4498C78.0208 80.4498 78.1608 80.4898 78.2808 80.5698C78.3908 80.6498 78.4908 80.7698 78.5608 80.9198C78.6308 81.0798 78.6908 81.2598 78.7208 81.4698C78.7508 81.6798 78.7708 81.9198 78.7708 82.1798C78.7708 82.7998 78.6808 83.2798 78.4808 83.6198C78.2808 83.9498 78.0208 84.1198 77.6908 84.1198C77.5208 84.1198 77.3708 84.0998 77.2608 84.0498Z" fill="white"/>
    <path d="M83.1298 84.3698C82.9698 84.4898 82.7898 84.5798 82.5998 84.6298C82.3998 84.6798 82.1898 84.7098 81.9698 84.7098C81.6698 84.7098 81.4198 84.6498 81.2098 84.5398C81.0098 84.4298 80.8398 84.2598 80.7098 84.0398C80.5798 83.8298 80.4898 83.5698 80.4298 83.2698C80.3698 82.9698 80.3398 82.6298 80.3398 82.2598C80.3398 81.4598 80.4798 80.8498 80.7598 80.4298C81.0398 80.0098 81.4498 79.7998 81.9798 79.7998C82.2198 79.7998 82.4298 79.8198 82.5998 79.8598C82.7698 79.9098 82.9198 79.9598 83.0498 80.0298L82.8598 80.6198C82.6098 80.4798 82.3398 80.4098 82.0398 80.4098C81.7098 80.4098 81.4498 80.5598 81.2798 80.8598C81.1098 81.1598 81.0198 81.6198 81.0198 82.2598C81.0198 82.5198 81.0398 82.7498 81.0798 82.9798C81.1198 83.1998 81.1798 83.3998 81.2598 83.5598C81.3498 83.7298 81.4598 83.8598 81.5998 83.9498C81.7398 84.0498 81.9098 84.0998 82.1098 84.0998C82.2698 84.0998 82.4198 84.0698 82.5598 84.0198C82.6998 83.9598 82.8098 83.8998 82.8998 83.8198L83.1298 84.3698Z" fill="white"/>
    <path d="M86.3997 80.5397H85.2497V84.6097H84.5797V80.5397H83.4297V79.9297H86.3997V80.5397Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M87.6584 79.8959C87.4803 79.9138 87.3103 79.9309 87.1484 79.9399H87.1684V84.6099C87.2598 84.6206 87.3569 84.6285 87.4568 84.6365C87.5427 84.6435 87.6305 84.6506 87.7184 84.6599C87.9184 84.6699 88.1184 84.6799 88.3284 84.6799C88.8984 84.6799 89.3184 84.5699 89.6084 84.3499C89.8884 84.1299 90.0384 83.7799 90.0384 83.2899C90.0384 82.9699 89.9684 82.7199 89.8484 82.5399C89.7184 82.3499 89.5084 82.2299 89.2084 82.1599V82.1199C89.4484 82.0099 89.6284 81.8499 89.7284 81.6499C89.8284 81.4399 89.8784 81.2299 89.8784 81.0099C89.8784 80.6299 89.7684 80.3399 89.5484 80.1499C89.3284 79.9599 88.9684 79.8599 88.4584 79.8599C88.1684 79.8599 87.9284 79.8699 87.7184 79.8899C87.6983 79.8919 87.6783 79.8939 87.6584 79.8959ZM88.5184 81.9199C88.5984 81.9199 88.6884 81.8999 88.7684 81.8599C88.8484 81.8199 88.9184 81.7699 88.9884 81.6999C89.0584 81.6299 89.1084 81.5499 89.1484 81.4599C89.1884 81.3699 89.1984 81.2799 89.1984 81.1699C89.1984 80.9299 89.1384 80.7499 89.0284 80.6299C88.9084 80.5199 88.7184 80.4599 88.4384 80.4599C88.2884 80.4599 88.1684 80.4599 88.0884 80.4699C88.0448 80.4747 88.0036 80.4772 87.9636 80.4796L87.9636 80.4796C87.9209 80.4822 87.8797 80.4847 87.8384 80.4899V81.9099H88.5184V81.9199ZM87.8184 84.0299L88.0884 84.0599C88.1684 84.0699 88.2784 84.0699 88.3984 84.0699C88.7184 84.0699 88.9484 83.9999 89.0984 83.8699C89.2384 83.7399 89.3184 83.5299 89.3184 83.2299C89.3184 82.9999 89.2584 82.8199 89.1284 82.6699C89.0084 82.5199 88.7784 82.4499 88.4484 82.4499H87.8184V84.0299Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M93.3284 84.6101C93.5584 84.5301 93.7484 84.4301 93.8984 84.2901L93.6484 83.7701C93.5584 83.8601 93.4384 83.9301 93.2684 84.0001C93.1084 84.0601 92.9384 84.0901 92.7684 84.0901C92.5484 84.0901 92.3684 84.0401 92.2184 83.9601C92.0784 83.8801 91.9484 83.7601 91.8584 83.6101C91.7584 83.4501 91.6984 83.2701 91.6484 83.0501C91.6084 82.8301 91.5884 82.5901 91.5884 82.3201H93.9684L93.9684 82.32C93.9784 82.1901 93.9884 82.0601 93.9984 81.9401C94.0184 81.8201 94.0184 81.7101 94.0184 81.6001C94.0184 81.2301 93.9784 80.9201 93.8884 80.6801C93.8084 80.4401 93.6984 80.2601 93.5584 80.1301C93.4184 80.0001 93.2684 79.9201 93.0984 79.8701C92.9284 79.8301 92.7484 79.8101 92.5784 79.8101C92.0484 79.8101 91.6284 80.0201 91.3384 80.4401C91.0484 80.8601 90.8984 81.4701 90.8984 82.2701C90.8984 82.6401 90.9284 82.9801 90.9984 83.2801C91.0684 83.5801 91.1684 83.8401 91.3084 84.0501C91.4484 84.2701 91.6184 84.4401 91.8384 84.5501C92.0484 84.6601 92.3084 84.7201 92.5984 84.7201C92.8584 84.7201 93.0984 84.6801 93.3284 84.6101ZM93.1784 80.7501C93.3284 80.9701 93.3884 81.3101 93.3784 81.7801H91.6084C91.6384 81.3101 91.7484 80.9701 91.9084 80.7501C92.0784 80.5301 92.2984 80.4201 92.5884 80.4201C92.8384 80.4201 93.0384 80.5301 93.1784 80.7501Z" fill="white"/>
    <path d="M97.4502 82.5197H95.8302V84.6097H95.1602V79.9297H95.8302V81.9097H97.4502V79.9297H98.1202V84.6097H97.4502V82.5197Z" fill="white"/>
    <path d="M101.688 82.5197H100.078V84.6097H99.3984V79.9297H100.078V81.9097H101.688V79.9297H102.368V84.6097H101.688V82.5197Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M106.901 79.9297H107.571V84.6097H106.901V79.9297ZM104.321 83.9997C104.511 84.0397 104.681 84.0497 104.841 84.0497C105.141 84.0497 105.351 83.9697 105.461 83.8097C105.581 83.6497 105.641 83.4197 105.641 83.1297C105.641 82.8497 105.581 82.6297 105.471 82.4697C105.351 82.3097 105.151 82.2297 104.861 82.2297C104.781 82.2297 104.691 82.2297 104.601 82.2397C104.511 82.2497 104.421 82.2697 104.331 82.2897V83.9997H104.321ZM104.402 81.7069C104.376 81.7111 104.349 81.7154 104.321 81.7197H104.311V79.9397H103.641V84.6197C103.991 84.6597 104.341 84.6797 104.701 84.6797C105.291 84.6797 105.711 84.5497 105.961 84.2997C106.211 84.0397 106.341 83.6297 106.341 83.0597C106.341 82.8597 106.321 82.6797 106.281 82.5097C106.231 82.3397 106.161 82.1897 106.061 82.0597C105.961 81.9397 105.821 81.8397 105.651 81.7697C105.471 81.6897 105.261 81.6597 105.001 81.6597C104.901 81.6597 104.791 81.6597 104.681 81.6697C104.586 81.6775 104.498 81.6915 104.402 81.7069Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M111.09 84.6101C111.32 84.5301 111.51 84.4301 111.66 84.2901L111.42 83.7701C111.33 83.8601 111.21 83.9301 111.04 84.0001C110.87 84.0601 110.71 84.0901 110.54 84.0901C110.32 84.0901 110.14 84.0401 109.99 83.9601C109.84 83.8801 109.72 83.7601 109.63 83.6101C109.53 83.4501 109.46 83.2701 109.42 83.0501C109.37 82.8301 109.35 82.5901 109.35 82.3201H111.73L111.73 82.32C111.74 82.1901 111.75 82.0601 111.76 81.9401C111.77 81.8201 111.77 81.7101 111.77 81.6001C111.77 81.2301 111.73 80.9201 111.65 80.6801C111.57 80.4401 111.46 80.2601 111.32 80.1301C111.18 80.0001 111.03 79.9201 110.86 79.8701C110.69 79.8301 110.51 79.8101 110.34 79.8101C109.8 79.8101 109.39 80.0201 109.1 80.4401C108.81 80.8601 108.66 81.4701 108.66 82.2701C108.66 82.6401 108.7 82.9801 108.76 83.2801C108.83 83.5801 108.93 83.8401 109.07 84.0501C109.2 84.2701 109.39 84.4401 109.6 84.5501C109.81 84.6601 110.07 84.7201 110.36 84.7201C110.61 84.7201 110.86 84.6801 111.09 84.6101ZM110.94 80.7501C111.09 80.9701 111.15 81.3101 111.14 81.7801H109.37C109.4 81.3101 109.5 80.9701 109.67 80.7501C109.84 80.5301 110.06 80.4201 110.35 80.4201C110.6 80.4201 110.79 80.5301 110.94 80.7501Z" fill="white"/>
  </svg>
</template>

<script>
    export default {
        name: "MfcBigLogo"
    }
</script>

<style lang="scss" scoped>
  .icon{
  @include box(20vw, auto, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
    @include box(28vw, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
    @include box(45vw, auto, relative, null, null, null, null);

    }
  }
</style>
