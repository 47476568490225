<template>
  <div class="forumView">
    <Breadcrumbs
      class="breadcrumbs"
      :pages="[{name: 'Всероссийский форум МФЦ', route: '/forums'}]"
    />
    <div class="container cont" id="side">
      <div class="section top">
        <div class="row min mobile">
          <div class="photo" v-if="item">
            <img :src="item.photo ? item.photo : ''" />
          </div>
        </div>
        <div class="row max" v-if="item">
          <div class="title">№{{item.number}}, {{item.month}}</div>
          <div class="photo">
            <img :src="item.photo ? item.photo : ''" />
          </div>
          <div class="sponsor">{{item.address}}</div>
        </div>
      </div>
      <div class="section text">
        <div class="row min minC" :class="{fixed: fixed}">
          <Sidebar :types="types" @settype="setTypeMethods" :item="item" v-if="item" />
        </div>
        <div class="row max maxC">
          <div class="text" v-html="item.program" v-if="getType === 'program'"></div>
          <FilesList :files="getJson(item.presentations)" v-if="getType === 'presentations'" />
          <ArchiveForums :items="getJson(item.archive)" v-if="getType === 'archive'" />
        </div>
      </div>
    </div>
    <Other :items="items.filter(initem => initem.id !== item.id)" />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Sidebar from '@/components/views/forums/view/Sidebar.vue'
import Other from '@/components/views/forums/view/Other.vue'

import {mapActions, mapGetters, mapState} from 'vuex';
import ArchiveForums from "@/components/views/forums/view/Archive";
import FilesList from '@/components/shared/FilesList';

export default {
  name: 'PosterView',
  components: {
    ArchiveForums,
    Breadcrumbs,
    Sidebar,
    Other,
    FilesList,
  },
  computed: {
    ...mapGetters('forums', [
      'getType',
    ]),
    ...mapState('forums', [
      'items',
      'item',
      'types',
    ]),
    id() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      fixed: false,
      fixTop: null,
    }
  },
  methods: {
    getJson(data) {
      return JSON.parse(data);
    },
    ...mapActions('forums', [
      'setType',
        'getApi',
        "getApiOnce"
    ]),
    setTypeMethods(val) {
      this.$router.push({ name: 'ForumsView', params: { type: val.value ? val.value : val } })
      this.setType(val)
    },
  },
  mounted() {
    this.getApi({})
    this.setType(this.$route.params.type ? this.$route.params.type : '')
    this.getApiOnce(this.id)
    window.addEventListener('scroll', this.setFixed)
  },
  watch: {
    id() {
      this.getApiOnce(this.id);
    }
  },
}
</script>

<style lang="scss">
.forumView {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-bottom: 2vw;
  .breadcrumbs {
    padding: 2vw 0;
  }
  .cont {
    @include flex(left, top, column);
    padding: 1vw 0;
    .section {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      &.text {
        padding: 2vw 0;
      }
      .row {
        &.min {
          @include box(22%, auto, relative, null, null, null, null);
          .photo {
            @include box(100%, auto, relative, null, null, null, null);
            overflow: hidden;
            border-radius: 0.3vw;
            img {
              @include box(100%, auto, relative, null, null, null, null);
              min-height: 100%;
            }
          }
        }
        &.minC {
          @include box(22%, auto, sticky, null, 6vw, null, null);
        }
        &.max {
          @include box(78%, auto, relative, null, null, null, null);
          padding-left: 2vw;
          .photo {
            @include box(100%, 70vw, relative, null, null, null, null);
            display: none;
            img {
              @include box(100%, auto, relative, null, null, null, null);
              min-height: 100%;
            }
          }
          .title {
            @include box(60%, auto, relative, null, null, null, null);
            font-size: 2.2vw;
          }
          .sponsor {
            font-size: 1vw;
            padding: 1vw 0;
          }
          .text {
            font-size: 1vw;
            img {
              @include box(100%, auto, relative, null, null, null, null);
              margin: 1.5vw 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .forumView {
    @include box(100%, auto, relative, null, null, null, null);
    padding-bottom: 2vw;
    .breadcrumbs {
      padding: 4vw 4vw;
    }
    .cont {
      padding: 1vw 4vw;
      .section {
        &.text {
          padding: 2vw 0;
        }
        .row {
          &.min {
            @include box(22%, auto, relative, null, null, null, null);
            .title {
              font-size: 2.2vw;
              font-weight: 700;
            }
          }
          &.max {
            @include box(78%, auto, relative, null, null, null, null);
            padding-left: 4vw;
            .title {
              @include box(60%, auto, relative, null, null, null, null);
              font-size: 3.4vw;
            }
            .sponsor {
              font-size: 2vw;
              padding: 2vw 0;
            }
            .text {
              font-size: 2vw;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .forumView {
    @include box(100%, auto, relative, null, null, null, null);
    padding-bottom: 2vw;
    .breadcrumbs {
      padding: 6vw 6vw;
    }
    .cont {
      padding: 4vw 6vw;
      .section {
        @include flex(left, top, column);
        &.text {
          padding: 4vw 0;
        }
        .row {
          &.min {
            @include box(100%, auto, relative, null, null, null, null);
            padding-bottom: 4vw;
            &.mobile {
              display: none;
            }
            .title {
              font-size: 4.9vw;
            }
            .photo {
              display: none;
            }
          }
          &.minC {
            padding-bottom: 10vw;
          }
          &.max {
            @include box(100%, auto, relative, null, null, null, null);
            padding-left: 0;
            .photo {
              display: flex;
              padding-top: 4vw;
            }
            .title {
              @include box(100%, auto, relative, null, null, null, null);
              font-size: 7.7vw;
              padding-top: 0;
            }
            .sponsor {
              font-size: 4vw;
              padding: 4vw 0;
            }
            .text {
              font-size: 4vw;
              img {
                margin: 4vw 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
