<template>
  <svg width="106" height="32" viewBox="0 0 106 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoicon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5809 24.4948C17.5809 22.1772 15.8888 20.4512 13.4867 20.4512C11.1236 20.4512 9.40234 22.1772 9.40234 24.4948C9.40234 26.7833 11.1139 28.49 13.4867 28.49C15.879 28.49 17.5809 26.7833 17.5809 24.4948ZM16.0833 24.4948C16.0833 25.9687 15.033 27.0936 13.4867 27.0936C11.9697 27.0936 10.9194 25.9687 10.9194 24.4948C10.9194 22.9918 11.9697 21.8572 13.4867 21.8572C15.033 21.8572 16.0833 22.9918 16.0833 24.4948Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.6582 24.4948C51.6582 24.6597 51.6485 24.8148 51.629 24.97V24.9603H45.7942H45.7844C45.9303 26.2984 46.7375 27.2003 48.4101 27.2003C49.4701 27.2003 50.3648 26.793 50.9483 26.3566L51.5512 27.4524C50.7635 28.0924 49.6646 28.49 48.3712 28.49C45.6969 28.49 44.423 26.9578 44.2479 24.97C44.2285 24.8148 44.2188 24.6597 44.2188 24.4948C44.2188 22.1675 45.7747 20.4512 47.9336 20.4512C50.4523 20.4512 51.6582 22.1772 51.6582 24.4948ZM50.2189 23.7869C50.1022 22.6136 49.3826 21.7215 48.0017 21.7215C46.8639 21.7215 46.0276 22.5748 45.8039 23.7869H50.2189Z" fill="white"/>
    <path d="M58.3201 20.6543V23.7864H54.226V20.6543H52.6992V28.2858H54.226V24.9694H58.3201V28.2858H59.8469V20.6543H58.3201Z" fill="white"/>
    <path d="M67.8672 20.6543H60.8945V22.0119H63.6175V28.2858H65.1443V22.0119H67.8672V20.6543Z" fill="white"/>
    <rect x="76.0469" y="20.6543" width="1.52679" height="7.63152" fill="white"/>
    <path d="M21.9761 24.2713V24.2034C21.9761 24.2034 22.1123 24.1549 22.2387 24.0094C22.3651 23.864 25.1561 20.6543 25.1561 20.6543H23.4446L20.7508 23.7767H20.1479V20.6543H18.6211V28.2858H20.1479V24.9598H20.8383L23.7363 28.2858H25.6327C25.6327 28.2858 22.3846 24.6301 22.2387 24.4749C22.122 24.3198 21.9761 24.2713 21.9761 24.2713Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M70.4448 23.0785C70.4448 23.0785 70.9115 22.9913 71.6215 22.9913C73.9457 22.9913 74.6459 24.2616 74.6459 25.6094C74.6459 26.9476 73.8582 28.4022 71.4659 28.4022C70.1725 28.4022 68.918 28.2858 68.918 28.2858V20.6543H70.4448V23.0785ZM70.4448 27.1028C70.4448 27.1028 70.8726 27.1416 71.3492 27.1416C72.7009 27.1416 73.2066 26.5694 73.2066 25.6676C73.2066 24.7561 72.6426 24.2616 71.3686 24.2616C70.7754 24.2616 70.4448 24.3488 70.4448 24.3488V27.1028Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85526 21.6148L6.89416 20.6548H8.34315V27.8306C8.34315 30.2742 6.77746 31.5445 4.63802 31.5445C3.23133 31.5445 2.34974 31.08 1.84309 30.8131C1.80684 30.794 1.7725 30.7759 1.74003 30.7591L2.236 29.4984C2.74168 29.8087 3.57802 30.2063 4.63802 30.2063C5.79526 30.2063 6.82609 29.6245 6.82609 28.1506V27.4621H6.72884C6.10646 28.1991 5.46462 28.49 4.44352 28.49C2.21655 28.49 0.874529 26.7833 0.923153 24.4948C0.981501 22.1772 2.30407 20.4512 4.54077 20.4512C5.2993 20.4512 6.27178 20.8972 6.75802 21.6148H6.85526ZM2.40132 24.4948C2.36242 25.9687 3.1793 27.0936 4.71581 27.0936C5.61049 27.0936 6.3593 26.7251 6.83581 26.1336V24.0294C6.83581 22.633 5.68829 21.8572 4.84224 21.8572C3.296 21.8572 2.43049 22.9918 2.40132 24.4948Z" fill="white"/>
    <path d="M31.6925 20.6542V26.1815C31.2451 26.7439 30.5644 27.0833 29.6989 27.0833C28.2985 27.0833 27.6567 25.9585 27.6956 24.4845V20.6542H26.2174V24.4845C26.1591 26.773 27.0829 28.4797 29.3099 28.4797C30.331 28.4797 31.0215 28.1791 31.5952 27.4518H31.6925V28.1403C31.6925 29.6142 30.6617 30.196 29.5044 30.196C28.4444 30.196 27.6081 29.7985 27.1024 29.4882L26.6064 30.7488C27.0927 31.0009 27.9971 31.5342 29.5044 31.5342C31.6439 31.5342 33.2095 30.2639 33.2095 27.8203V20.6445H31.6925V20.6542Z" fill="white"/>
    <path d="M41.7183 20.6542C41.7183 20.6542 39.1995 24.1839 39.1218 24.2906C39.044 24.3973 39.0245 24.4942 39.0245 24.4942H38.9662C38.9662 24.4942 38.937 24.3973 38.8495 24.2906C38.7717 24.1839 36.0682 20.6542 36.0682 20.6542H34.6289V28.2857H36.1557V23.4179C36.1557 23.0785 36.0876 22.8845 36.0876 22.8845H36.1557L38.9564 26.6373H39.0245L41.621 22.8845H41.6891C41.6891 22.8845 41.621 23.0882 41.621 23.4082V28.276H43.1478V20.6445H41.7183V20.6542Z" fill="white"/>
    <path d="M69.6953 19.1325V11.501H72.2627V15.0598C72.2627 15.3119 72.214 15.6125 72.214 15.6125H72.321L75.1217 11.5107H77.5821V19.1422H75.0148V15.5543C75.0148 15.3022 75.0731 15.0598 75.0731 15.0598H74.9661L72.1751 19.1422H69.6953V19.1325Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M68.6284 15.3308C68.6284 13.0229 66.8098 11.2969 64.3689 11.2969C61.9572 11.2969 60.1386 13.0229 60.1289 15.3308C60.1289 17.6096 61.9474 19.3357 64.3689 19.3357C66.8098 19.3357 68.6284 17.6096 68.6284 15.3308ZM65.9346 15.3308C65.9346 16.2326 65.3122 16.9599 64.3786 16.9599C63.4645 16.9599 62.8227 16.2326 62.8227 15.3308C62.8227 14.3999 63.4645 13.6726 64.3786 13.6726C65.3122 13.6726 65.9346 14.3999 65.9346 15.3308Z" fill="white"/>
    <path d="M57.0547 11.501C57.0547 11.501 54.9055 14.284 54.8277 14.3907C54.7207 14.5264 54.711 14.5943 54.711 14.5943H54.604C54.604 14.5943 54.5846 14.5167 54.4776 14.3907C54.3318 14.2064 52.1048 11.501 52.1048 11.501H50.082V19.1325H52.5521V15.9325C52.5521 15.5834 52.4938 15.3701 52.4938 15.3701H52.6007L54.5943 17.6489H54.7013L56.549 15.3701H56.656C56.656 15.3701 56.5976 15.5737 56.5976 15.9325V19.1325H59.0774V11.501H57.0547Z" fill="white"/>
    <path d="M89.8161 19.2392V11.501H83.6992V19.2392C83.6992 21.964 85.1579 24.4852 87.5211 25.8525L94.4062 29.8186V22.7786L90.5844 20.5773C90.1078 20.2961 89.8161 19.7919 89.8161 19.2392Z" fill="#C59368"/>
    <path d="M94.4062 26.7635L101.291 22.7975C103.655 21.4399 105.113 18.9187 105.113 16.1841V0.814453H94.4062V6.92354H98.9963V16.1841C98.9963 16.7272 98.7046 17.2314 98.2281 17.5029L94.4062 19.7041V26.7635Z" fill="#EA543A"/>
    <path d="M94.4081 0.824219L83.6914 11.5006H94.4081V0.824219Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.logoicon{
  @include box(5.3vw, auto, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoicon{
    @include box(17vw, auto, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoicon{
    @include box(24vw, auto, relative, null, null, null, null);
  }
}
</style>
