<template>
  <div class="items">
    <div class="rows">
      <div class="item" v-for="(item, i) in items" :key="i">
        <div class="achievements">
          <div class="achievements__wrapper">
            <Achievements :approve="!!item.expert_review" :best="!!item.is_best" />
          </div>
        </div>
        <router-link
          :to="{ name: 'PracticesView', params: { id: item.id } }"
          class="block"
          :style="{ backgroundImage: 'url(' + item.photo + ')' }"
        >
          <div class="overlay">
            <div class="top">
                {{item.subject}}
            </div>
            <div class="bottom">
              <div class="name">{{ item.title }}</div>
              <div class="region">
                <PracticeStatistic
                  :approve="item.embedded_count"
                  :like="item.like"
                  :comment="item.comment_count"
                  :favorite="item.selected_count"
                  :isSelected="item.is_selected"
                />
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PracticeStatistic from '@/components/views/practices/index/PracticeStatistic.vue'
import Achievements from '@/components/elements/Achievements.vue'

export default {
  name: 'Items',
  components: {
    PracticeStatistic,
    Achievements
  },
  props: {
    items: Array,
  },
  methods: {
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>

<style lang="scss" scoped>
.items {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .rows {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: wrap;
    .item {
      @include box(33.3%, auto, relative, null, null, null, null);
      padding: 15px;
      transition: transform 0.2s;
      &.padding {
        padding: 0 15px;
      }
      .achievements {
        &__wrapper {
          position: absolute;
          top: 15px;
          right: 15px;
          width: 100%;
          z-index: 2;
        }
      }
      .block {
        @include box(100%, 300px, relative, null, null, null, null);
        @include flex(left, top, row);
        overflow: hidden;
        border-radius: 0.4vw;
        transition: 0.2s;
        cursor: pointer;
        background-size: cover;
        img {
          @include box(100%, auto, relative, null, null, null, null);
          min-height: 100%;
        }
        .overlay {
          @include box(100%, 100%, absolute, 0, 0, null, null);
          z-index: 1;
          .top {
            @include box(80%, auto, absolute, 0, 0, null, null);
            padding: 15px;
            font-size: 0.8vw;
            color: #fff;
            opacity: 0;
            transition: opacity 0.2s;
          }
          .bottom {
            @include box(100%, auto, absolute, 0, null, null, 0);
            padding: 15px;
            .name {
                padding-top: 20px;
                max-height: 240px;
                overflow: hidden;
              font-size: 1vw;
              color: #fff;
            }
            .region {
              padding-top: 0.5vw;
              //font-size: 0.8vw;
              //color: #FFF;
              //display: none;
            }
          }
        }
        &:after {
          content: "";
          @include box(100%, 100%, absolute, 0, 0, null, null);
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &:hover {
        transform: scale(1.05);
        .overlay {
          box-shadow: 0 0.3vw 0.6vw rgba(44, 42, 41, 0.302885);
          .top {
            opacity: 1;
          }
          //.bottom{
          //  .region{
          //    display: block;
          //  }
          //}
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .items {
    padding-top: 2vw;
    .rows {
      padding: 0;
      .item {
        @include box(33.3%, auto, relative, null, null, null, null);
        padding: 15px;
        .block {
          @include box(100%, 35vw, relative, null, null, null, null);
          border-radius: 0.4vw;
          .overlay {
            .top {
              @include box(100%, auto, absolute, 0, 0, null, null);
              padding: 2vw;
              font-size: 1.8vw;
              opacity: 1;
            }
            .bottom {
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 15px;
              .name {
                font-size: 2vw;
              }
              .region {
                padding-top: 2vw;
                //font-size: 1.8vw;
                //display: flex;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .items {
    padding-top: 4vw;
    .rows {
      .item {
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        &.padding {
          padding: 4 0;
        }
        .block {
          @include box(100%, 70vw, relative, null, null, null, null);
          border-radius: 0.8vw;
          .overlay {
            .top {
              padding: 4vw;
              font-size: 4vw;
            }
            .bottom {
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 4vw;
              .name {
                font-size: 5vw;
              }
              .region {
                padding-top: 4vw;
                //font-size: 4vw;
                //display: flex;
              }
            }
          }
        }
      }
    }
  }
}
</style>
