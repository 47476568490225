<template>
  <div class="list">
    <div class="title">Найти МФЦ</div>
    <list-item :arr-points="getPoints" />
    <Pagination
      :pages="totalCount"
      :current="page"
      :per-page="perPage"
      @setpage="setPageAction"
      class="pagination"
      v-if="totalCount > perPage"
    />
  </div>
</template>

<script>
import ListItem from "./ListItem";
import Pagination from "@/components/Pagination";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "List",
  components: {
    ListItem,
    Pagination
  },
  computed: {
    ...mapState('mfc', [
      'totalCount',
      'page',
      'perPage'
    ]),
    ...mapGetters('mfc', [
      'getPoints'
    ])
  },
  methods: {
    ...mapActions('mfc', [
      'setPage',
      'getApi'
    ]),
    setPageAction(page) {
      this.setPage(page)
      this.getApi({})
      this.$router.push({ name: 'MfcIndex', query: { page: page } })
    }
  },
  mounted() {
    this.setPage(this.$route.query.page > 0 ? this.$route.query.page : 1)
    this.getApi({})
  }
}
</script>

<style lang="scss" scoped>
.list {
  @include box(75%, auto, relative, null, null, null, null);
  @include flex(center, top, column);
  margin: auto;
  .title {
    margin-top: 1.8vw;
    font-size: 2vw;
  }
  .pagination {
    margin-bottom: 5.6vw;
  }
}
@media only screen and (max-width: 900px) {
  .list {
    @include box(98%, auto, relative, null, null, null, null);
    @include flex(center, top, column);
    margin: auto;
    .title {
      margin-top: 1.8vw;
      font-size: 6vw;
      line-height: 3.6vw;
      margin-bottom: 3.5vw;
    }
    .pagination {
      margin-bottom: 5.6vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .list {
    @include box(95%, auto, relative, null, null, null, null);
    @include flex(center, top, column);
    margin: auto;
    .title {
      margin-top: 4.5vw;
      font-size: 7vw;
      line-height: 3.6vw;
      margin-bottom: 5.5vw;
    }
    .pagination {
      margin-bottom: 25vw;
    }
  }
}
</style>
