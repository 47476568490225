<template>
  <div class="content contentModels">
    <div class="block" v-for="(article, i) in articles" :key="`model-${i}`" :id="`model-article-${i}`">
      <div class="title">{{article.title}}</div>
      <div class="text" v-html="article.article"></div>
      <FilesList class="files" :files="article.files"/>
    </div>
  </div>
</template>

<script>
import FilesList from '@/components/shared/FilesList';

export default {
  name: 'Sidebar',
  props: {
    articles: [Object, Array]
  },
  components: {
    FilesList,
  }
}
</script>

<style lang="scss" scoped>
.content{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .block{
    .title{
      font-weight: 700;
      font-size: 1vw;
    }
    .files {
      margin: 1vw 0;
    }
  }
}
@media only screen and (max-width: 900px) {
  .content{
    padding: 0 2vw;
    .block{
      .title{
        font-size: 2vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .content{
    padding: 6vw 6vw;
    .block{
      .title{
        font-size: 4vw;
      }
    }
  }
}
</style>
