export function mapPractice(practice) {
    return {
        id: practice.id,
        name: practice.title,
        region: practice.subject,
        category: practice.category,
        image: practice.photo,
        date: new Date(practice.date),
        text: [
            { name: 'Цель практики', text: ''}
        ]
    };
}