<template>
  <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5 63C48.897 63 63 48.897 63 31.5C63 14.103 48.897 0 31.5 0C14.103 0 0 14.103 0 31.5C0 48.897 14.103 63 31.5 63ZM28 13C28 10.7909 29.7909 9 32 9C34.2091 9 36 10.7909 36 13C36 15.2091 34.2091 17 32 17C29.7909 17 28 15.2091 28 13ZM36 21V43H41V51H36H28H23V43H28V29H24V21H28H32H36Z" fill="#EA543A"/>
  </svg>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style lang="scss" scoped>
.icon {
  @include box(63px, 63px, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .icon {
    @include box(63px, 63px, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .icon {
    @include box(63px, 63px, relative, null, null, null, null);
  }
}
</style>
