<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 6C14.707 6 13.6055 6.84375 13.1875 8H6V10H7.40625L7.125 10.5L3.125 17.5L3 17.75V18C3 20.75 5.25 23 8 23C10.75 23 13 20.75 13 18V17.75L12.875 17.5L8.875 10.5L8.59375 10H13.1875C13.4922 10.8438 14.1562 11.5078 15 11.8125V25H11V27H21V25H17V11.8125C17.8398 11.5078 18.5078 10.8398 18.8125 10H23.4062L23.125 10.5L19.125 17.5L19 17.75V18C19 20.75 21.25 23 24 23C26.75 23 29 20.75 29 18V17.75L28.875 17.5L24.875 10.5L24.5938 10H26V8H18.8125C18.3945 6.84375 17.293 6 16 6ZM16 8C16.5625 8 17 8.4375 17 9C17 9.5625 16.5625 10 16 10C15.4375 10 15 9.5625 15 9C15 8.4375 15.4375 8 16 8ZM8 13.0312L10.2812 17H5.71875L8 13.0312ZM24 13.0312L26.2812 17H21.7188L24 13.0312ZM5.25 19H10.75C10.3477 20.1602 9.30469 21 8 21C6.69531 21 5.65234 20.1602 5.25 19ZM21.25 19H26.75C26.3477 20.1602 25.3047 21 24 21C22.6953 21 21.6523 20.1602 21.25 19Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Balance"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
