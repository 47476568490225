// states
import axios from "axios";
import { getJournalsQuery } from "@/axios/journals";

const state = {
  items: [],
  page: 1,
  perPage: 12,
  totalCount: null
}

// getters
const getters = {
}

// actions
const actions = {
  getApi({ commit, state }) {
    axios.get(getJournalsQuery(state.page, state.perPage))
      .then(response => {
        commit('setItems', response.data);
        commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
      });
  },
  setPage({ dispatch, commit }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  }
}

// mutations
const mutations = {
  setItems(state, value) {
    state.items = value;
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setPage(state, value) {
    state.page = value
  }
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
