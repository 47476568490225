// states
import axios from "axios";
import { getStandardizationQuery } from "@/axios/standardization";

const state = {
    header: null,
    content: "",
    items: [],
    types: [],
    subType: null,
    page: 1,
    totalCount: null,
    perPage: 12
}

// getters
const getters = {
    getType: state => {
        return state.types.filter(item => item.select)[0]?.type
    },
    getFiles: (state, getters) => {
        if (!state.items.length) { return []; }
        const filesStr = state.items.find(item => item.type === getters.getType)?.files || '';
        let files;
        try {
            files = JSON.parse(filesStr);
        } catch (e) {
            console.info('Не удалось преобразовать файлы', e, state.items);
            files = [];
        }
        return files;
    },
}

// actions
const actions = {
    setType({ state, commit }, value) {
        let next = true
        value = value && value.value ? value.value : value
        commit('setType', state.types.map(item => {
            if (item.type === value) {
                item.select = true
                next = false
            } else {
                item.select = false
            }
            return item
        }))
        if (next && state.types[0]) {
            state.types[0].select = true
        }
    },
    getApi({ commit, state }) {
        axios.get(getStandardizationQuery(state.page, state.perPage))
            .then(response => {
                const types = [];
                const items = [];
                response.data[0].partitions.forEach((element) => {
                    types.push({ name: element.title, type: element.render_view_name, select: false });
                    items.push({ type: element.render_view_name, content: element.content, header: element.header, files: element.files })
                });
                if (types.length > 0) {
                    types[0].select = true;
                }
                commit('setHeader', {
                    title: response.data[0].title,
                    subTitle: response.data[0].description
                });
                commit('setType', types);
                commit('setItems', items);
                commit('setTotalCount', response.headers['x-pagination-total-count']);
            });
    },
    setPage({ commit, dispatch }, value) {
        commit('setPage', value);
        dispatch('getApi', {})
    },
    setContent({ commit }, value) {
        commit('setContent', value);
    },
    setSubtype({ commit }, value) {
        commit('setSubtype', value);
    }
}

// mutations
const mutations = {
    setHeader(state, value) {
        state.header = value;
    },
    setType(state, value) {
        state.types = value
    },
    setItems(state, value) {
        state.items = value;
    },
    setItem(state, value) {
        state.item = value;
    },
    setPage(state, value) {
        state.page = Number(value)
    },
    setTotalCount(state, value) {
        state.totalCount = Number(value)
    },
    setContent(state, value) {
        state.content = value;
    },
    setSubtype(state, value) {
        state.subType = value;
    }
}

export default {
    root: true,
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
