// states
import axios from 'axios'
import { getPointsQuery } from "@/axios/points";
import { getDistanceBetweenTwoPoints } from "@/utils/mapUtils";

const state = {
  search: '',
  defaultRegion: {
    name: "г. Москва",
    region: "г. Москва",
    select: true,
    type: 11,
  },
  regions: [],
  cities: [],
  points: [],
  point: null,
  item: null,
  page: 1,
  perPage: 1000,
  totalCount: null,
  changeBtn: true
}

// getters
const getters = {
  getOther: state => {
    const { lat: currentLat, lon: currentLon } = state.point;
    const pointsWithDistance = state.points.filter(point => point.id !== state.point.id).map(point => {
      const { lat, lon } = point;
      return {
        ...point,
        distanceFromCurrent: getDistanceBetweenTwoPoints(currentLat, currentLon, lat, lon).toFixed(1),
      }
    });
    pointsWithDistance.sort((a, b) => {
      return a.distanceFromCurrent - b.distanceFromCurrent;
    });
    return pointsWithDistance.slice(0, 3);
  },
  getPoints: state => {
    return state.points
  },
  getActiveRegion: state => {
    let res = null
    const data = state.regions.filter(item => item.select);
    if (data.length) res = data[0]
    return res
  },
  getActiveCity: state => {
    return state.cities.filter(item => item.select).map(item => item.name).join('');
  }
}
// actions
const actions = {
  async setRegion({ dispatch, state, commit }, value) {
    commit('setRegion', state.regions.map(item => {
      value = value.value ? value.value : value;
      item.select = item.type === value || item.name === value;
      return item;
    }));
    if (state.cities.length) {
      commit('setCity', state.cities.map(item => {
        item.select = false;
        return item;
      }));
    }
    await dispatch('getApiCity')
    await dispatch('getApi', {})
  },
  setCity({ dispatch, state, commit }, value) {
    commit('setCity', state.cities.map((item, i) => {
      if (i === value.index) {
        item.select = true
      } else {
        item.select = false
      }
      return item
    }))
    dispatch('getApi', {})
  },
  showListMfc(context) {
    context.commit('showListMfc')
  },
  showMapMfc(context) {
    context.commit('showMapMfc')
  },
  getApi({ commit, state }) {
    let region = state.regions.filter(item => item.select)[0]?.name ?? state.defaultRegion.name;
    const city = state.cities.filter(item => item.select)[0]?.name
    axios.get(getPointsQuery(region, city, state.page, state.perPage))
      .then(response => {
        commit('setPoints', response.data)
        commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
      })
  },
  getApiOnce({ commit }, id) {
    axios.get('/mfcs/' + id)
      .then(response => {
        commit('setPoint', response.data)
      })
  },
  getApiRegion({ commit }) {
    axios.get('/mfc-find')
      .then(response => {
        commit('setRegion', response.data.map((item, i) => {
          item.type = i + 1
          item.select = false
          item.name = item.region
          return item
        }))
      })
  },
  getApiCity({ commit }) {
    const region = state.regions.filter(item => item.select)[0]?.name
    axios.get('/mfc-find-city?region=' + region)
      .then(response => {
        commit('setCity', response.data.map((item, i) => {
          item.type = i
          item.select = false
          item.name = item.city
          return item
        }))
      })
  },
  setSearch({ commit }, value) {
    commit('setSearch', value)
  },
  setPage({ dispatch, commit }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  }
}

// mutations
const mutations = {
  setRegion(state, value) {
    state.regions = value
  },
  setSearch(state, value) {
    state.search = value
  },
  setCity(state, value) {
    state.cities = value
  },
  showListMfc(state) {
    state.changeBtn = false
  },
  showMapMfc(state) {
    state.changeBtn = true
  },
  setPoints(state, value) {
    state.points = value;
  },
  setPoint(state, value) {
    state.point = value;
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setPage(state, value) {
    state.page = value
  }
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
