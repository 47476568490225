import Vue from 'vue'
import VueRouter from 'vue-router'
import Template from '../views/Template.vue'
import HomeView from '../views/HomeView.vue'
import News from '../views/News.vue'
import NewsIndex from '../views/news/NewsIndex.vue'
import NewsView from '../views/news/NewsView.vue'
import Search from "../views/Search"
import Practices from "../views/Practices"
import PracticesIndex from "../views/practices/PracticesIndex"
import PracticesView from "../views/practices/PracticesView"
import Mfc from "../views/Mfc";
import MfcIndex from "../views/mfc/MfcIndex";
import MfcView from "../views/mfc/MfcView";
import Poster from "../views/Poster"
import PosterIndex from "../views/poster/PosterIndex"
import PosterView from "../views/poster/PosterView"
import Documents from "../views/Documents"
import DocumentsIndex from "../views/documents/DocumentsIndex"
import Models from "../views/Models"
import ModelsIndex from "../views/models/ModelsIndex"
import ModelsView from "../views/models/ModelsView"
import Forums from "../views/Forums"
import ForumsIndex from "../views/forums/ForumsIndex"
import ForumsView from "../views/forums/ForumsView"
import Experience from "../views/Experience"
import ExperienceIndex from "../views/experience/ExperienceIndex"
import Journal from "../views/Journal"
import JournalIndex from "../views/journal/JournalIndex"
import BankruptcyIndex from "../views/bankruptcy/BankruptcyIndex";
import Brand from "../views/Brand";
import BrandIndex from "../views/brand/BrandIndex";
import Standardization from "../views/Standardization";
import StandardizationIndex from "../views/standardization/StandardizationIndex";
import ContentStand from "../components/views/standardization/view/Content";
// import ExpertPlatforms from "@/views/expertPlatforms/ExpertPlatformsIndex";
import Contest from "@/views/Contest";
import ContestIndex from "@/views/contest/ContestIndex";
import ContestNewsIndex from "@/views/contest/news/ContestNewsIndex";
import ContestNewsView from "@/views/contest/news/ContestNewsView";
import ContestRegionalNewsIndex from "@/views/contest/regionalNews/ContestRegionalNewsIndex";
import ContestRegionalNewsView from "@/views/contest/regionalNews/ContestRegionalNewsView";
import ContestDocumentsIndex from "@/views/contest/documents/ContestDocumentsIndex";
import ContestWinnersIndex from "@/views/contest/winners/ContestWinnersIndex";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Template,
    children: [
      {
        path: '/',
        name: 'Home',
        child: ['Home'],
        component: HomeView,
        description: 'Главная',
        menu: false,
        route: '/'
      },
      {
        path: '/mfc',
        name: 'Mfc',
        child: ['MfcIndex', 'MfcView'],
        component: Mfc,
        description: 'Найти МФЦ',
        menu: true,
        route: '/mfc',
        children: [
          {
            path: '',
            name: 'MfcIndex',
            child: ['MfcIndex'],
            component: MfcIndex,
            route: '',
          },
          {
            path: 'view/:id?',
            name: 'MfcView',
            child: ['MfcView'],
            component: MfcView,
            route: 'MfcView',
          }
        ]
      },
      {
        path: '/news',
        name: 'News',
        child: ['NewsIndex', 'NewsView'],
        component: News,
        description: 'Новости',
        menu: true,
        route: '/news',
        children: [
          {
            path: ':type?',
            name: 'NewsIndex',
            child: ['NewsIndex'],
            component: NewsIndex,
            route: '',
          },
          {
            path: 'view/:id?',
            name: 'NewsView',
            child: ['NewsView'],
            component: NewsView,
            route: '/view',
          }
        ]
      },
      {
        path: '/practices',
        name: 'Practices',
        child: ['PracticesIndex', 'PracticesView'],
        component: Practices,
        description: 'Портал Идей',
        menu: true,
        route: '/practices',
        children: [
          {
            path: ':section?',
            name: 'PracticesIndex',
            child: ['PracticesIndex'],
            component: PracticesIndex,
            route: '',
          },
          {
            path: 'view/:id/:page?',
            name: 'PracticesView',
            child: ['PracticesView'],
            component: PracticesView,
            route: 'view',
          }
        ]
      },
      {
        path: '/poster',
        name: 'Poster',
        child: ['PosterIndex', 'PosterView'],
        component: Poster,
        description: 'Афиша',
        menu: true,
        route: '/poster',
        children: [
          {
            path: '',
            name: 'PosterIndex',
            child: ['PosterIndex'],
            component: PosterIndex,
            route: '',
          },
          {
            path: 'view/:id',
            name: 'PosterView',
            child: ['PosterView'],
            component: PosterView,
            route: 'view',
          }
        ]
      },
      {
        path: '/models',
        name: 'Models',
        child: ['ModelsIndex', 'ModelsView'],
        component: Models,
        description: 'Целевые модели МФЦ 2.0',
        menu: false,
        route: '/models',
        children: [
          {
            path: '',
            name: 'ModelsIndex',
            child: ['ModelsIndex'],
            component: ModelsIndex,
            route: '',
          },
          {
            path: 'view/:id/:type?',
            name: 'ModelsView',
            child: ['ModelsView'],
            component: ModelsView,
            route: 'view',
          }
        ]
      },
      {
        path: '/forums',
        name: 'Forums',
        child: ['ForumsIndex', 'ForumsView'],
        component: Forums,
        description: 'Форум',
        menu: false,
        route: '/forums',
        children: [
          {
            path: '',
            name: 'ForumsIndex',
            child: ['ForumsIndex'],
            component: ForumsIndex,
            route: '',
          },
          {
            path: 'view/:id/:type?',
            name: 'ForumsView',
            child: ['ForumsView'],
            component: ForumsView,
            route: 'view',
          }
        ]
      },
      {
        path: '/experience',
        name: 'Experience',
        child: ['ExperienceIndex'],
        component: Experience,
        description: 'Международный опыт',
        menu: false,
        route: '/experience',
        children: [
          {
            path: ':type?',
            name: 'ExperienceIndex',
            child: ['ExperienceIndex'],
            component: ExperienceIndex,
            route: '',
          },
        ]
      },
      {
        path: '/journal',
        name: 'Journal',
        child: ['JournalIndex'],
        component: Journal,
        description: 'Журнал "Мои документы"',
        menu: false,
        route: '/journal',
        children: [
          {
            path: '',
            name: 'JournalIndex',
            child: ['JournalIndex'],
            component: JournalIndex,
            route: '',
          },
        ]
      },
      {
        path: '/documents',
        name: 'Documents',
        child: ['DocumentsIndex'],
        component: Documents,
        description: 'Документы',
        menu: true,
        route: '/documents',
        children: [
          {
            path: ':type?',
            name: 'DocumentsIndex',
            child: ['DocumentsIndex'],
            component: DocumentsIndex,
            route: '',
          }
        ]
      },
      {
        path: '/brand',
        name: 'Brand',
        child: ['BrandIndex'],
        component: Brand,
        description: 'Бренд',
        menu: false,
        route: '/brand',
        children: [
          {
            path: ':type?',
            name: 'BrandIndex',
            child: ['BrandIndex'],
            component: BrandIndex,
            route: '',
          }
        ]
      },
      {
        path: '/standardization',
        name: 'Standardization',
        child: ['StandardizationIndex'],
        component: Standardization,
        description: 'Стандартизация',
        menu: false,
        route: '/standardization',
        children: [
          {
            path: ':type?',
            name: 'StandardizationIndex',
            child: ['StandardizationIndex'],
            component: StandardizationIndex,
            route: '',
            children: [
              {
                path: ':type?',
                name: 'ContentStandIndex',
                child: ['ContentStandIndex'],
                component: ContentStand,
                route: '',
              }
            ]
          }
        ]
      },
      {
        path: '/search',
        name: 'Search',
        child: ['Search'],
        component: Search,
        description: 'Поиск',
        menu: false,
        route: '/search'
      },
      {
        path: '/bankruptcy',
        name: 'Bankruptcy',
        child: ['Bankruptcy'],
        component: BankruptcyIndex,
        description: 'Банкротство',
        menu: false,
        route: '/bankruptcy'
      },
      /*{
        path: '/expert-platforms',
        name: 'Expert Platforms',
        child: ['ExpertPlatformsView'],
        component: ExpertPlatforms,
        description: 'Экспертные площадки',
        menu: false,
        route: '/expert-platforms'
      },*/
      {
        path: '/mfc-cabinet',
        name: 'mfc-cabinet',
        component: () => import('../views/mfcCabinet/MfcCabinetIndex'),
        redirect: 'mfc-cabinet/hosted-practices',
        children: [
          {
            path: 'hosted-practices',
            name: 'hosted-practices',
            component: () => import('../views/mfcCabinet/MfcCabinetHostedPractices'),
          },
          {
            path: 'favorite-practices',
            name: 'favorite-practices',
            component: () => import('../views/mfcCabinet/MfcCabinetFavoritePractices'),
          },
          {
            path: 'implemented-practices',
            name: 'implemented-practices',
            component: () => import('../views/mfcCabinet/MfcCabinetImplementedPractices/MfcCabinetImplementedPractices'),
          },
          {
            path: 'implemented-practice/:id',
            name: 'implemented-practice',
            component: () => import('../views/mfcCabinet/MfcCabinetImplementedPractices/MfcCabinetImplementedPractice'),
            props: true,
          },
          {
            path: 'profile',
            name: 'profile',
            component: () => import('../views/mfcCabinet/MfcCabinetProfile'),
          },
          {
            path: 'notify',
            name: 'notify',
            component: () => import('../views/mfcCabinet/MfcCabinetNotifications/MfcCabinetNotificationsIndex'),
            redirect: 'notify/notifications',
            children: [
              {
                path: 'notifications',
                name: 'notifications',
                component: () => import('../views/mfcCabinet/MfcCabinetNotifications/MfcCabinetNotifications'),
              },
              {
                path: 'comments',
                name: 'comments',
                component: () => import('../views/mfcCabinet/MfcCabinetNotifications/MfcCabinetComments'),
              },
              {
                path: 'reviews',
                name: 'reviews',
                component: () => import('../views/mfcCabinet/MfcCabinetNotifications/MfcCabinetReviews'),
              },
            ],
          },
          {
            path: 'reports',
            name: 'reports',
            component: () => import('../views/mfcCabinet/MfcCabinetReports'),
          },
        ],
      },
      {
        path: '/contest',
        name: 'Contest',
        component: Contest,
        description: 'Конкурс «Лучший МФЦ»',
        menu: false,
        route: '/contest',
        children: [
          {
            path: '',
            name: 'ContestIndex',
            child: ['ContestIndex'],
            component: ContestIndex,
            route: '',
          },
          {
            path: 'news/',
            name: 'ContestNewsIndex',
            child: ['ContestNewsIndex'],
            component: ContestNewsIndex,
            route: '/news',
          },
          {
            path: 'news/:id',
            name: 'ContestNewsView',
            child: ['ContestNewsView'],
            component: ContestNewsView,
            route: '/news',
          },
          {
            path: 'regional-news/',
            name: 'ContestRegionalNewsIndex',
            child: ['ContestRegionalNewsIndex'],
            component: ContestRegionalNewsIndex,
            route: '/regional-news',
          },
          {
            path: 'regional-news/:id',
            name: 'ContestRegionalNewsView',
            child: ['ContestRegionalNewsView'],
            component: ContestRegionalNewsView,
            route: '/regional-news',
          },
          {
            path: 'documents/',
            name: 'ContestDocumentsIndex',
            child: ['ContestDocumentsIndex'],
            component: ContestDocumentsIndex,
            route: '/documents',
          },
          {
            path: 'winners/',
            name: 'ContestWinnersIndex',
            child: ['ContestWinnersIndex'],
            component: ContestWinnersIndex,
            route: '/winners',
          }
        ]
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
