<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="telegramIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.33594C0 4.18927 4.1854 0 9.33073 0H22.6641C27.8107 0 32 4.1854 32 9.33073V22.6641C32 27.8107 27.8146 32 22.6693 32H9.33594C4.18927 32 0 27.8146 0 22.6693V9.33594ZM23.4955 7.157C24.1045 6.9125 24.6935 6.958 25.0685 7.276C25.3375 7.5035 25.6155 7.9665 25.4155 8.8765L22.1375 25.915C22.11 25.986 21.847 26.6125 21.1895 26.8825C20.9965 26.9615 20.7945 27.001 20.584 27.001C20.3115 27.001 20.026 26.935 19.728 26.803L19.6235 26.7405L15.235 23.318L12.5165 25.8395C12.2745 26.0295 11.9005 26.162 11.5245 26.017C11.096 25.8865 10.8515 25.2525 10.7015 24.77C10.4795 24.0556 9.53597 20.9142 9.15999 19.6624C9.12861 19.5579 9.10119 19.4666 9.07847 19.391L4.63197 17.8685C4.52097 17.828 3.61197 17.477 3.50797 16.6295C3.39147 15.6895 4.52247 15.2675 4.65197 15.2225C5.36397 14.916 22.854 7.414 23.4945 7.157H23.4955ZM11.5 24L13 20L21.5 10.5L9.99997 19L11.5 24Z" fill="white" fill-opacity="0.5"/>
  </svg>

</template>

<script>
export default {
  name: "TelegramIcon"
}
</script>

<style lang="scss" scoped>
.telegramIcon{
  @include box(2vw, 2vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .telegramIcon{
    @include box(5vw, 5vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .telegramIcon{
    @include box(10vw, 10vw, relative, null, null, null, null);
  }
}
</style>
