<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.489 2 2 6.489 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM12 4C16.4301 4 20 7.56988 20 12C20 16.4301 16.4301 20 12 20C7.56988 20 4 16.4301 4 12C4 7.56988 7.56988 4 12 4ZM11 12.4141V6H13V11.5859L16.707 15.293L15.293 16.707L11 12.4141Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>

</template>

<script>
  export default {
    name: "Clock"
  }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(1.5vw, 1.5vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(3.1vw, 3.1vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon {
      @include box(6.4vw, 6.4vw, relative, null, null, null, null);
    }
  }
</style>
