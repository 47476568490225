<template>
  <div class="account">
    <div class="mfc-cabinet-container">
      <Selectbox
        v-if="deviceSize.mobileNormal"
        :options="pages"
        keydef="type"
        @set="onChange($event.value)"
        class="select"
      />
      <div class="account__nav" v-else>
        <router-link
          v-for="link in pages"
          :key="link.type"
          :to="{ name: link.type }"
          class="account__nav-item"
          active-class="account__nav-item--active"
        >{{ link.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from 'vuex'
import Selectbox from '@/components/elements/Selectbox.vue'

export default {
  name: 'MfcCabinetHeader',
  components: {
    Selectbox
  },
  inject: ['deviceSize'],
    data () {
      return {
          GOV: false
      }
    },
  computed: {
    ...mapGetters('mfcCabinet', ['getPages']),

      pages () {
        return this.GOV ? this.getPages.filter(page => page?.gov) : this.getPages
      }
  },
    async mounted () {
        const { status, data } = await axios.get('/profile')
        this.GOV = status === 200 && data.role === 'GOV'
    },
  methods: {
    ...mapMutations('mfcCabinet', ['setPage', 'getApi']),

    onChange(value) {
      this.setPage(value)
      this.getApi({})
      this.$router.push({ name: value })
    }
  }
};
</script>

<style lang="scss" scoped>
.account {
  @media (max-width: 767px) {
    margin-top: 18px;
  }

  @media (min-width: 768px) {
    width: 100%;
    background: #f6f2ec;
    box-shadow: 0 1px 0 rgba(black, 0.2);
  }
}

.account__nav {
  padding: 22px 0 22px 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 861px) {
  .account__nav {
    padding: 16px 0;
  }
}

@media only screen and (max-width: 767px) {
  .account__nav {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .account__nav {
    display: flex;
    flex-direction: column;
  }
}

.account__nav .account__nav-item {
  margin-right: 24px;
}

@media only screen and (max-width: 861px) {
  .account__nav .account__nav-item {
    margin-right: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .account__nav .account__nav-item {
    display: inline-block;
  }
}

@media only screen and (max-width: 360px) {
  .account__nav .account__nav-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.account__nav .account__nav-item {
  font-size: 14px;
  color: #623b2a;
  transition: 300ms;
  position: relative;
}

.account__nav .account__nav-item:hover {
  color: #834d36;
}

.account__nav .account__nav-item:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 360px) {
  .account__nav .account__nav-item:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 666px) {
  .account__nav .account__nav-item--active {
    margin-bottom: 10px;
  }
}

.account__nav .account__nav-item--active::before {
  content: "";
  width: 100%;
  height: 4px;
  background: #e04e39;
  display: block;
  position: absolute;
  bottom: -23px;
  left: 0;
}

@media only screen and (max-width: 861px) {
  .account__nav .account__nav-item--active::before {
    bottom: -17px;
  }
}

@media only screen and (max-width: 767px) {
  .account__nav .account__nav-item--active::before {
    display: none;
  }
}

.account__nav .account__nav-item-mark {
  color: #e04e39;
}

.account__nav--mark ::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ea543a;
  display: block;
  position: absolute;
  top: 0;
  right: -8px;
}
</style>
