// states
import axios from 'axios'
import { getPracticeQuery } from '@/axios/practices'
import mapPracticeFilter from '@/utils/mapPracticeFilter'

const state = {
  stats: {
    count: null,
    embed: null,
    regions: null,
  },
  hashtags: [],
  sections: [
    { name: 'Все практики', type: 'all', select: false },
    { name: 'Категория 1', type: 'cat1', select: false },
    { name: 'Категория 2', type: 'cat2', select: false },
    { name: 'Категория N', type: 'canN', select: false },
  ],
  regions: [],
  categories: [],
  items: [],
  item: null,
  pages: [
    { name: 'Описание практики', type: 'about', select: true },
    { name: 'Сеть МФЦ', type: 'network', select: false },
    { name: 'Обоснование', type: 'priority_target', select: false },
    { name: 'Результаты', type: 'results', select: false },
    { name: 'Ресурсы', type: 'resources', select: false },
    { name: 'Сроки', type: 'timings', select: false },
    { name: 'Дорожная карта', type: 'events', select: false },
    { name: 'Материалы', type: 'documents', select: false },
    {
      name: 'Тиражирование',
      type: 'replication',
      select: false,
      subPages: [
        { name: 'Внедрили практику', type: 'alreadyReplicated', select: false },
        { name: 'В процессе внедрения практики', type: 'inProgress', select: false },
        { name: 'Отзывы о внедрении', type: 'reviews', select: false },
        { name: 'Комментарии', type: 'comments', select: false },
        { name: 'Контакты', type: 'contacts', select: false },
      ],
    },
  ],
  links: [
    { name: 'Субъект РФ', link: 'link1', select: true },
    { name: 'Цель практики', link: 'link1', select: false },
    { name: 'Задачи практики', link: 'link1', select: false },
  ],
  page: 1,
  perPage: 9,
  totalCount: null,
  pageMode: 'hosted',
  currentSubPage: null,
  sort: {
    title: 'ASC',
  },
  filter: {
    categories: { options: [] },
    regions: { options: [] },
    mfc: {
      range: { gt: '', lt: '' },
      options: [],
    },
    price: {
      range: { gt: '', lt: '' },
    },
    bestProject: { options: [] },
    hashtags: { options: [] },
  },
  bestProjects: [],
}

// getters
const getters = {
  getStats: (state) => state.stats,
  hashtags: (state) => state.hashtags,
  getPage: (state) => state.pages.find((item) => item.select),
  getPageItems: (state) => state.items,
  currentSubPage: (state) => state.currentSubPage,
  subPages: (state) => state.pages.find((item) => item.select)?.subPages,
  regions: (state) => state.regions,
  getFilter: (state) => state.filter,
  bestProjects: (state) => state.bestProjects,
  practice: (state) => state.item
}

// actions
const actions = {
  async setStats({ state, commit }) {
    if (state.stats.count === null) {
      const {
        data: { count_practices: count, embed_practices: embed, region_participants: regions },
      } = await axios.get('/practice/stats')
      commit('setStats', { count, embed, regions })
    }
  },

  async getHashtags({ state, commit }) {
    if (!state.hashtags.length) {
      let { data } = await axios.get('/practice/hashtag')
      data = data.map((el) => ({
        name: el,
        select: false,
      }))
      commit('setHashtags', data)
    }
  },

  setRegion({ state, commit }, value) {
    commit(
      'setRegion',
      state.regions.map((item) => {
        value = value.value ? value.value : value
        item.select = item.type === value || item.name === value
        return item
      })
    )
  },

  setCategory({ state, commit }, value) {
    commit(
      'setCategory',
      state.categories.map((item) => {
        value = value?.value ? value.value : value
        item.select = item.type === value || item.name === value
        return item
      })
    )
  },

  resetFilterValues({ commit, state }) {
    commit(
      'setCategory',
      state.categories.map((item) => ({ ...item, select: false }))
    )
    commit(
      'setSections',
      state.categories.map((item) => ({ ...item, select: false }))
    )
    commit(
      'setRegion',
      state.categories.map((item) => ({ ...item, select: false }))
    )
    commit('setPageMode', 'hosted')
  },

  setPageMode({ commit }, mode) {
    commit('setPageMode', mode)
  },

  setSections({ state, commit }, value) {
    let result = state.sections
    result = result.map((item) => {
      if (value === item.type) {
        item.select = true
      } else {
        item.select = false
      }
      return item
    })
    if (!value) {
      result[0].select = true
    }
    commit('setSections', result)
  },
  setPages({ state, commit }, value) {
    let result = state.pages
    result = result.map((item) => {
      if (value === item.type) {
        item.select = true
      } else {
        item.select = false
      }
      return item
    })
    if (!value) {
      result[0].select = true
    }
    commit('setPages', result)
  },

  getApi({ commit, state }, { endpoint = '/practice', customFilter }) {
    const filter = state.filter
    const sort = state.sort
    const hashtags = state.hashtags.filter((el) => el.select).map(el => el.name)

    const params = { 'per-page': state.perPage, page: state.page }
    const body = customFilter || mapPracticeFilter({ filter, sort, hashtags })

    return axios.post(getPracticeQuery(params, endpoint), body).then((response) => {
      commit('setItems', response.data)
      commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']) || 0)
      return response
    })
  },
  getApiOnce({ commit }, { id, endpoint = '/practices' }) {
    axios.get(endpoint + '/' + id).then((response) => {
      commit('setItem', response.data)
    })
  },
  setPage({ commit }, value) {
    commit('setPage', value)
  },
  getApiRegion({ commit }) {
    axios.get('/mfc-find').then((response) => {
      commit(
        'setRegion',
        response.data.map((item, i) => {
          item.type = i + 1
          item.select = false
          item.name = item.region
          return item
        })
      )
    })
  },

  getApiCategory({ commit }) {
    axios.get('/category-find/get-with-sub-categories').then((response) => {
      commit(
        'setCategory',
        response.data.map(({name, sub_category}, i) => {
          return {
            type: i + 1,
            select: false,
            name,
            sub: sub_category?.map(item => ({name: item.name, select: false}))
          }
        })
      )
    })
  },

  selectCurrentSubPage({ commit, getters }, value) {
    commit('setCurrentSubPage', value)
    commit('flagCurrentSubPage', { value, getters })
  },

  async getBestProjects({ commit }) {
    const response = await axios.get('/practice/best')
    const bestProjects = Object.keys(response?.data)?.map((el) => ({
      name: response.data[el],
      select: false,
      value: el
    }))
    commit('setBestProjects', bestProjects)
  },

  async embedPractice({ getters }) {
    const body = { id: getters.practice?.id }
    const { status } = await axios.post('/practice/embed', body)
    return status === 200
  }
}

// mutations
const mutations = {
  setStats(state, { count, embed, regions }) {
    state.stats.count = count
    state.stats.embed = embed
    state.stats.regions = regions
  },
  setHashtags(state, value) {
    state.hashtags = value
  },
  setRegion(state, value) {
    state.regions = value
  },
  setCategory(state, value) {
    state.categories = value
  },
  setPages(state, value) {
    state.pages = value
  },
  setSections(state, value) {
    state.sections = value
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setItems(state, value) {
    state.items = value
  },
  setItem(state, value) {
    state.item = value
  },
  setPage(state, value) {
    state.page = value
  },
  setPageMode(state, value) {
    state.pageMode = value
  },
  setCurrentSubPage(state, value) {
    state.currentSubPage = value
  },
  flagCurrentSubPage(state, { value, getters }) {
    const currentPage = getters.getPage
    currentPage?.subPages.forEach((el) => {
      el.select = el.type === value.type
    })
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setSortType(state, value) {
    state.sort = value
  },
  setBestProjects(state, value) {
    state.bestProjects = value
  },
  toggleHashtag(state, hashtag) {
    state.hashtags = state.hashtags.map(el => {
      return el.name === hashtag.name
        ? { ...el, select: !el.select }
        : el
    })
  }
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
