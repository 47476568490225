<template>
    <NotifyList title="Комментарии" url="/profile/notify/comments" />
</template>

<script>
import NotifyList from '../../../components/views/mfcCabinet/notify/NotifyList'

export default {
    name: 'MfcCabinetComments',
    components: {NotifyList},
};
</script>

<style>
</style>