<template>
  <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowLeftPath">
    <path d="M4 0V3H22V5H4V8L0 4L4 0Z" fill="#E04E39"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftPath',
}
</script>

<style lang="scss" scoped>
.arrowLeftPath{
  @include box(2vw, 2vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowLeftPath{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowLeftPath{
    @include box(7vw, 7vw, relative, null, null, null, null);
  }
}
</style>
