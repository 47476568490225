<template>
  <div class="rowItem" v-if="item">
    <div class="imagemobile" v-if="image">
      <router-link :to="{ name: 'NewsView', params: { id: item.id }}">
        <img :src="item.anons_photo" />
      </router-link>
    </div>
    <div class="text">
      <router-link :to="{ name: 'NewsView', params: { id: item.id }}" class="name">{{item.title}}</router-link>
      <div class="date">
        <span>{{getDate(item.date)}}</span>
        <span v-if="getGroupName(item.type)">{{getGroupName(item.type)}}</span>
      </div>
    </div>
    <div class="image" v-if="image">
      <router-link :to="{ name: 'NewsView', params: { id: item.id }}">
        <img :src="item.anons_photo" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsList',
  props: {
    item: Object,
    groups: Array,
    image: Boolean
  },
  methods: {
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
    },
    getGroupName(type) {
      return this.groups.filter(item => item.type === type)[0]?.name
    }
  }
}
</script>

<style lang="scss" scoped>
.rowItem{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  border-top: 0.08vw solid #E9D7C1;
  padding: 1vw 0;
  .text{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .name{
      font-size: 1vw;
      padding-right: 1vw;
      color: rgb(224, 78, 57);
      transition: 0.1s;
      &:hover{
        opacity: 0.8;
        transform: scale(0.98);
      }
    }
    .date{
      padding-top: 1vw;
      span{
        margin-right: 1vw;
        font-size: 0.7vw;
        color: rgba(45, 43, 42, 0.7);
      }
    }
  }
  .image{
    @include box(30%, auto, relative, null, null, null, null);
    @include flex(right, top, row);
    flex-shrink: 0;
    img{
      @include box(auto, 7vw, relative, null, null, null, null);
    }
  }
  .imagemobile{
    display: none;
  }
  &:first-child{
    border: 0;
    padding-top: 0;
  }
}
@media only screen and (max-width: 900px) {
  .rowItem{
    border-top: 0.2vw solid #E9D7C1;
    padding: 3vw 0;
    .text{
      .name{
        font-size: 2vw;
        padding-right: 0;
      }
      .date{
        padding-top: 1vw;
        span{
          margin-right: 2vw;
          font-size: 1.5vw;
        }
      }
    }
    .image{
      @include box(30%, auto, relative, null, null, null, null);
      @include flex(right, top, row);
      flex-shrink: 0;
      img{
        @include box(auto, 10vw, relative, null, null, null, null);
      }
    }
    .imagemobile{
      display: none;
    }
  }
}
@media only screen and (max-width: 600px) {
  .rowItem{
    border-top: 0.5vw solid #E9D7C1;
    padding: 4vw 0;
    @include flex(left, top, column);
    .text{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      .name{
        font-size: 4.5vw;
        padding-right: 0;
      }
      .date{
        padding-top: 4vw;
        span{
          margin-right: 4vw;
          font-size: 4vw;
          color: rgba(45, 43, 42, 0.7);
        }
      }
    }
    .image{
      display: none;
    }
    .imagemobile{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(center, center, row);
      padding-bottom: 4vw;
      img{
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
    &:first-child{
      border-top: 0;
      padding-top: 4vw;
    }
  }
}
</style>
