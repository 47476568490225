<template>
    <div class="documents">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'Standardization',
    }
</script>

<style lang="scss" scoped>
    .documents{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
    }
</style>
