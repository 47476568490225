<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.33594C0 4.18927 4.1854 0 9.33073 0H22.6641C27.8107 0 32 4.1854 32 9.33073V22.6641C32 27.8107 27.8146 32 22.6693 32H9.33594C4.18927 32 0 27.8146 0 22.6693V9.33594ZM26.6667 6.66667C26.6667 5.93067 26.0693 5.33333 25.3333 5.33333C24.5973 5.33333 24 5.93067 24 6.66667C24 7.40267 24.5973 8 25.3333 8C26.0693 8 26.6667 7.40267 26.6667 6.66667ZM16 8C20.412 8 24 11.588 24 16C24 20.412 20.412 24 16 24C11.588 24 8 20.412 8 16C8 11.588 11.588 8 16 8ZM10.6667 16C10.6667 13.0545 13.0545 10.6667 16 10.6667C18.9455 10.6667 21.3333 13.0545 21.3333 16C21.3333 18.9455 18.9455 21.3333 16 21.3333C13.0545 21.3333 10.6667 18.9455 10.6667 16Z" fill="white" fill-opacity="0.5"/>
    </svg>
</template>

<script>
  export default {
    name: "Instagram"
  }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(5vw, 5vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
      @include box(10vw, 10vw, relative, null, null, null, null);
    }
  }
</style>
