<template>
  <section :class="{'no--side--padding': noSidePadding, 'default--font--size': defaultFontSize}">
    <aside>
      <h2 v-if="title">{{ title }}</h2>
      <slot name="side"/>
    </aside>
    <article>
      <div v-if="content" v-html="content"></div>
      <slot/>
    </article>
  </section>
</template>

<script>
export default {
  name: 'ContentSection',
  props: {
    title: {type: String, default: ''},
    content: {type: String, default: ''},
    noSidePadding: {type: Boolean, default: false},
    defaultFontSize: {type: Boolean, default: false},
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
section {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1vw 0;
  position: relative;
  aside {
    width: 22%;
    h2 {
      font-size: 1vw;
      font-weight: 700;
      margin: 0 0 1vw;
    }
  }
  article {
    width: 78%;
    padding-left: 2vw;
    font-size: 1vw;
  }
  &.no--side--padding {
    article {
      padding-left: 0;
    }
  }
  &.default--font--size {
    article {
      font-size: revert;
    }
  }
}
@media only screen and (max-width: 900px) {
  section {
    aside {
      width: 100%;
      h2 {
        font-size: 2.2vw;
        margin: 2vw 0;
      }
    }
    article {
      width: 100%;
      padding-left: 0;
      font-size: 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  section {
    padding: 3vw 2vw;
    aside {
      h2 {
        font-size: 4.9vw;
      }
    }
    article {
      font-size: 4vw;
    }
  }
}
</style>
