<template>
  <div class="replication__tabs">
    <component :is="isTab" :practice="practice" />
  </div>
</template>

<script>
import AlreadyReplicated from './components/AlreadyReplicated.vue'
import InProgress from './components/InProgress.vue'
import ReplicationReviews from './components/ReplicationReviews.vue'
import ReplicationComments from './components/ReplicationComments.vue'
import ReplicationContacts from './components/ReplicationContacts.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Replication',
  components: {
    AlreadyReplicated,
    InProgress,
    ReplicationReviews,
    ReplicationComments,
    ReplicationContacts,
  },
  props: {
    practice: Object
  },
  computed: {
    ...mapGetters('practices', [
      'currentSubPage'
    ]),
    isTab() {
      const tabs = {
        alreadyReplicated: 'AlreadyReplicated',
        inProgress: 'InProgress',
        reviews: 'ReplicationReviews',
        comments: 'ReplicationComments',
        contacts: 'ReplicationContacts'
      }

      return tabs[this.currentSubPage.type]
    }
  }
}
</script>

<style lang="scss">
.replication__tabs {
  width: 100%;
}

.replication {
  padding: 64px 0 75px 0;

  @media only screen and (max-width: 767px) {
    padding: 20px 0 60px 0;
  }

  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: 861px) {
      display: block;
    }
  }

  &__item-scale {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 700;
      margin-right: 8px;
      color: rgba(44, 42, 41, 0.7);

      @media only screen and (max-width: 767px) {
      }
    }
  }

  &__item-info {
    width: 100%;
  }

  &__item-line {
    width: 278px;
    height: 12px;
    background: #e9d7c1;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 8px;
    }

    &::before {
      content: "";
      height: 12px;
      background: #e04e39;
      border-radius: 6px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      @media only screen and (max-width: 767px) {
        height: 8px;
      }
    }
  }

  &__comment {
    padding: 20px 24px;
    background: #f6f2ec;
    border-radius: 4px;
    margin-bottom: 16px;

    @media only screen and (max-width: 767px) {
      padding: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      @media only screen and (max-width: 767px) {
        display: block;
      }

      p {
        font-size: 18px;
        font-weight: 700;
        color: rgba(44, 42, 41, 1);

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      span {
        font-size: 14px;
        color: rgba(44, 42, 41, 0.7);
      }
    }

    &-text {
      font-size: 18px;
      line-height: 28px;
      color: #2c2a29;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &__load {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .replication__header-foure {
    span {
      font-size: 22px;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  &__form {
    margin-bottom: 36px;
    textarea {
      background: rgba(44, 42, 41, 0.1);
      border: 1px solid #d0c7c2;
      box-sizing: border-box;
      border-radius: 4px;
      resize: none;
      width: 100%;
      height: 88px;
      padding: 12px;
      font-size: 14px;
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }

      &::placeholder {
        color: rgba(44, 42, 41, 0.7);
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 235px;
      height: 44px;
      background: #e04e39;
      border-radius: 4px;
      font-size: 14px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      border: 0;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        font-size: 12px;
        height: 35px;
        width: 100%;
      }
    }
  }

  &__contact {
    &-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 36px;
      color: rgba(44, 42, 41, 1);

      @media only screen and (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }

    &-block {
      margin-bottom: 16px;

      p {
        font-size: 14px;
        color: rgba(44, 42, 41, 0.7);
        margin-bottom: 4px;
      }

      span {
        font-size: 18px;
        color: rgba(44, 42, 41, 1);

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      a {
        font-size: 18px;
        color: rgba(224, 78, 57, 1);

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__sidebar {
    width: 255px;

    @media only screen and (max-width: 1170px) {
      width: 220px;
    }

    @media only screen and (max-width: 861px) {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }

  &__content {
    width: 823px;

    @media only screen and (max-width: 1170px) {
      width: 620px;
    }

    @media only screen and (max-width: 861px) {
      width: 100%;
    }
  }

  &__sidebar {
    li {
      margin-bottom: 12px;

      @media only screen and (max-width: 861px) {
        margin-bottom: 6;
      }

      &:hover {
        a {
          color: rgba(44, 42, 41, 1);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 18px;
        color: #e04e39;
        transition: 300ms;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      &.active {
        a {
          color: rgba(44, 42, 41, 1);
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 15px;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f6f2ec;
      border-radius: 4px;
      font-size: 30px;
      font-weight: 700;
      color: rgba(44, 42, 41, 1);
      margin-right: 13px;
      width: 52px;
      height: 52px;

      @media only screen and (max-width: 767px) {
        font-size: 20px;
        width: 35px;
        height: 35px;
        margin-right: 8px;
      }
    }

    p {
      font-size: 30px;
      font-weight: 700;
      color: rgba(44, 42, 41, 1);

      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
      flex-direction: column;
    }

    input {
      background: rgba(44, 42, 41, 0.1);
      border: 1px solid #d0c7c2;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      width: 100%;
      margin-right: 29px;
      padding: 0 12px;
      height: 44px;

      @media only screen and (max-width: 767px) {
        margin: 0 0 16px;
      }

      &::placeholder {
        color: rgba(44, 42, 41, 0.7);
      }
    }

    select {
      width: 100%;
      padding: 0 12px;
      height: 44px;
      appearance: none;
      background: url(/assets/undecomposed/shevron-bottom.svg) no-repeat;
      background-position: 96% 50%;
      border: 1px solid #d0c7c2;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      color: rgba(44, 42, 41, 1);
      cursor: pointer;
    }
  }

  &__item {
    &-block {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9d7c1;
      margin: 20px 0;

      @media only screen and (max-width: 767px) {
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 19px;

      @media only screen and (max-width: 767px) {
        width: 60px;
        height: 60px;
        min-height: 60px;
        min-width: 60px;
      }
    }

    &-info {
      p {
        font-size: 18px;
        color: #2c2a29;
        margin-bottom: 4px;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          margin-bottom: 2px;
        }
      }

      span {
        font-size: 14px;

        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
