<template>
  <svg width="60" height="75" viewBox="0 0 60 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H45.5L60 14.5V75H0V0ZM9.15201 34.4595H50.3385V40.5406H9.15201V34.4595ZM50.3385 24.8311H9.15201V30.9122H50.3385V24.8311ZM9.15201 44.5947H50.3385V50.6757H9.15201V44.5947ZM42.7115 54.7297H16.7793V60.8108H42.7115V54.7297Z" fill="#EA543A"/>
    <path d="M45.5 -1.26763e-06L45.5 14.5L60 14.5L45.5 -1.26763e-06Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: "Documents"
}
</script>

<style lang="scss" scoped>
.icon {
  @include box(60px, 75px, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .icon {
    @include box(60px, 75px, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .icon {
    @include box(60px, 75px, relative, null, null, null, null);
  }
}
</style>
