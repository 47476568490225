export function mapNews(news) {
    return {
        name: news.lid,
        type: null,
        image: news.anons_photo,
        actual: true,
        date: new Date(news.date * 1000),
        author: news.author,
        firstSource: news.first_source,
        source: news.source,
        text: '',
        link: `${news.id}`,
        lid: '',
        content: news.content,
        types: news.lid
    };
}