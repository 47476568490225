<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M16 4C13.0003 4 10.5918 5.08594 10.5918 5.08594L9.63867 5.51172L11.6816 9.59766C9.54687 10.854 4 14.706 4 21C4 23.9513 4.96859 26.1877 7.04102 27.4297C9.11344 28.6717 12.0098 29 16 29C19.9902 29 22.8866 28.6717 24.959 27.4297C27.0314 26.1877 28 23.9513 28 21C28 16.8992 25.6217 13.8273 23.373 11.8281C25.2996 12.6149 26.2637 13.6777 26.2637 13.6777L27.7363 12.3223C27.7363 12.3223 25.1028 9.75457 20.5137 9.21094L22.3613 5.51172L21.4082 5.08594C21.4082 5.08594 18.9997 4 16 4ZM16 6C17.5871 6 18.7673 6.30785 19.5938 6.57617L18.3828 9H13.6172L12.4062 6.57617C13.2327 6.30785 14.4129 6 16 6ZM13.3008 11H18.7012C18.9793 11.1463 26 14.8687 26 21C26 23.5717 25.3955 24.8356 23.9316 25.7129C22.4678 26.5902 19.8648 27 16 27C12.1352 27 9.53219 26.5902 8.06836 25.7129C6.60453 24.8356 6 23.5717 6 21C6 14.869 13.0225 11.1463 13.3008 11ZM13 14V18H12V20H13V21H12V23H13V24H15V23H18V21H15V20H17C18.2566 20 19.1406 19.1067 19.584 18H20V17C20 15.355 18.645 14 17 14H13ZM15 16H17C17.565 16 18 16.435 18 17C18 17.565 17.565 18 17 18H15V16Z" fill="#C39367"/>
    </svg>

</template>

<script>
    export default {
        name: "Money"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
