<template>
    <div class="profile">
        <div class="container">
            <div v-if="profile" class="profile__items">
                <div class="profile__image-container">
                    <img class="profile__image" :src="'/api' + profile.profile.regionLogo" alt />
                </div>
                <div class="profile__item">
                    <p class="profile__title">{{ profile.profile.regionName }}</p>
                    <div class="profile__block">
                        <p class="profile__head">Руководитель</p>
                        <p class="profile__text">{{ profile.profile.headName }}</p>
                    </div>
                    <div class="profile__block profile__block-last">
                        <p class="profile__head">Email</p>
                        <a
                            :href="'mailto:' + profile.profile.email"
                            class="profile__text"
                        >{{ profile.profile.email }}</a>
                    </div>
                    <button @click="logout" class="profile__btn">ВЫЙТИ</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "MfcCabinetProfile",
    computed: {
        ...mapGetters('mfcCabinet', [
            'profile',
        ]),
    },
    methods: {
        ...mapActions('mfcCabinet', ['logout']),
    }
}
</script>

<style>
.profile {
    padding: 36px 0;
}

.profile__items {
    display: flex;
    align-items: flex-start;
}

@media only screen and (max-width: 639px) {
    .profile__items {
        display: block;
    }
}

.profile__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

@media only screen and (max-width: 639px) {
    .profile__image-container {
        margin: 0 0 20px 0;
    }
}

.profile__title {
    font-size: 44px;
    color: #2c2a29;
    margin-bottom: 16px;
}

@media only screen and (max-width: 1170px) {
    .profile__title {
        font-size: 33px;
    }
}

@media only screen and (max-width: 861px) {
    .profile__title {
        font-size: 28px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 639px) {
    .profile__title {
        font-size: 24px;
    }
}

.profile__head {
    font-size: 14px;
    color: rgba(44, 42, 41, 0.7);
    margin-bottom: 4px;
}

.profile__text {
    font-size: 18px;
    color: #2c2a29;
}

a.profile__text:hover {
    text-decoration: underline;
}

.profile__block {
    margin-bottom: 16px;
}

.profile__block-last {
    margin-bottom: 24px;
}

.profile__btn {
    width: 97px;
    height: 44px;
    border: 1px solid #e04e39;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #e04e39;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    background: #ffffff;
    cursor: pointer;
}

@media only screen and (max-width: 639px) {
    .profile__btn {
        width: 100%;
    }
}

.profile__btn:hover {
    background: #e04e39;
    color: #fff;
}

.practical__logo-image {
    margin-right: 8px;
    min-height: 160px;
    min-width: 160px;
    max-height: 160px;
    max-width: 160px;
    object-fit: contain;
}
</style>
