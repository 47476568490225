// states
import axios from "axios";
import { getExperiencesQuery } from "@/axios/experiences";

const state = {
  items: [],
  types: [
    { name: 'Центры госуслуг на карте мира', type: 'type1', select: true },
    { name: 'Исследования работы центров госуслуг', type: 'type2', select: false },
  ],
  page: 1,
  totalCount: null,
  perPage: 12
}

// getters
const getters = {
  getType: state => {
    return state.types.filter(item => item.select)[0]?.type
  }
}

// actions
const actions = {
  setType({ state, commit }, value) {
    let next = true
    value = value.value ? value.value : value
    commit('setType', state.types.map(item => {
      if (item.type === value) {
        item.select = true
        next = false
      } else {
        item.select = false
      }
      return item
    }))
    if (next) {
      state.types[0].select = true
    }
  },
  getApi({ commit, state }) {
    axios.get(getExperiencesQuery(state.page, state.perPage))
      .then(response => {
        commit('setItems', response.data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  setPage({ commit, dispatch }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  },
}

// mutations
const mutations = {
  setType(state, value) {
    state.types = value
  },
  setItems(state, value) {
    state.items = value;
  },
  setItem(state, value) {
    state.item = value;
  },
  setPage(state, value) {
    state.page = Number(value)
  },
  setTotalCount(state, value) {
    state.totalCount = Number(value)
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
