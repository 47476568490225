<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M6 3V29H17.7715C19.241 30.825 21.4886 32 24 32C28.4 32 32 28.4 32 24C32 20.291 29.4389 17.1606 26 16.2656V9.58594L19.4141 3H6ZM8 5H18V11H24V16C19.6 16 16 19.6 16 24C16 25.0604 16.2128 26.0717 16.5918 27H8V5ZM20 6.41406L22.5859 9H20V6.41406ZM24 18C27.3 18 30 20.7 30 24C30 27.3 27.3 30 24 30C20.7 30 18 27.3 18 24C18 20.7 20.7 18 24 18ZM23 20V25H25V20H23ZM23 26V28H25V26H23Z" fill="#E04E39"/>
    </svg>
</template>

<script>
    export default {
        name: "DocumentWarning"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
