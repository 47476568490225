<template>
  <div class="newsIndex">
    <Breadcrumbs class="breadcrumbs" :pages="[{ name: 'Новости', route: '/news' }]" />
    <div class="container">
      <div class="row min">
        <Sidebar
          :groups="groups"
          :regions="regions"
          :dates="dates"
          @setregion="setRegion"
          @setdates="setDates"
        />
      </div>
      <div class="row max">
        <NewsView :news="getNewsSortedByDate" :groups="groups" />
        <NewsList :news="getNewsSortedByDate" :groups="groups" />
        <Pagination
          :pages="Number(totalCount)"
          :per-page="perPage"
          :current="page"
          @setpage="setPageAction"
          class="pagination"
          v-if="totalCount > perPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Sidebar from '@/components/views/news/Sidebar.vue'
import NewsView from '@/components/views/news/NewsView.vue'
import NewsList from '@/components/views/news/NewsList.vue'
import Pagination from '@/components/Pagination.vue'

import { mapState, mapActions, mapGetters } from 'vuex';


export default {
  name: 'HomeView',
  components: {
    Breadcrumbs,
    Sidebar,
    NewsView,
    NewsList,
    Pagination,
  },
  computed: {
    ...mapState('news', [
      'groups',
      'news',
      'dates',
      'regions',
      'page',
      'totalCount',
      'perPage'
    ]),
    ...mapGetters('news', [
      'getNewsSortedByDate',
    ]),
  },
  methods: {
    ...mapActions('news', [
      'setRegion',
      'getApi',
      'setDate',
      'setPage',
      'setDates',
      'getApiRegion'
    ]),
    setPageAction(page) {
      this.$router.push({ name: 'NewsIndex', query: { page: page } })
      this.setPage(page)
    }
  },
  created() {
    this.setPage(this.$route.query.page > 0 ? Number(this.$route.query.page) : 1)
    this.getApi({});
    if (this.regions.length < 1) {
      this.getApiRegion()
    }
  }
}
</script>

<style lang="scss" scoped>
.newsIndex {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .breadcrumbs {
    padding: 1vw 0;
    padding-top: 2vw;
  }
  .container {
    @include flex(left, top, row);
    .row {
      &.min {
        @include box(22%, auto, relative, null, null, null, null);
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
        padding-left: 2vw;
        .pagination {
          padding: 2vw 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .newsIndex {
    .breadcrumbs {
      padding: 2vw 4vw;
      padding-top: 5vw;
    }
    .container {
      @include flex(left, top, column);
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          .pagination {
            padding: 3vw 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .newsIndex {
    .breadcrumbs {
      padding: 4vw 6vw;
      padding-top: 6vw;
    }
    .container {
      @include flex(left, top, column);
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
          padding-left: 0;
          .pagination {
            padding: 4vw 0;
          }
        }
      }
    }
  }
}
</style>
