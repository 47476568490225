<template>
  <svg width="61" height="83" viewBox="0 0 61 83" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoIcon1">
    <path d="M17.3234 52.0952V30.2148H0.0273438V52.0952C0.0273438 59.8 4.15199 66.9289 10.8339 70.795L30.3022 82.0094V62.1032L19.4957 55.879C18.1483 55.0839 17.3234 53.6581 17.3234 52.0952Z" fill="#C59368"/>
    <path d="M30.3022 73.3733L49.7706 62.1589C56.4525 58.3203 60.5771 51.1913 60.5771 43.4591V0H30.3022V17.274H43.2811V43.4591C43.2811 44.9946 42.4562 46.4204 41.1088 47.1881L30.3022 53.4122V73.3733Z" fill="#EA543A"/>
    <path d="M30.3024 0.0273438L0 30.2157H30.3024V0.0273438Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon1',
}
</script>

<style lang="scss" scoped>
.logoIcon1{
  @include box(4vw, 4vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoIcon1{
    @include box(8vw, 8vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoIcon1{
    @include box(18vw, 18vw, relative, null, null, null, null);
  }
}
</style>
