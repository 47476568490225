import {addPaginationFilters} from "./common";

export function getPointsQuery(region, city, page, size) {
  const filter = addPaginationFilters([], page, size);
  if (region) {
      filter.push(`filter[region]=${region}`);
  }
  if(city) {
      filter.push(`filter[city]=${city}`);
  }
  return '/mfcs?' + filter.join('&');
}
