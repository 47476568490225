import {addPaginationFilters} from "./common";

export function getBrandQuery(type, page, size) {
    const filter = addPaginationFilters([], page, size);

    if(type) {
        filter.push(`filter[type]=${type}`);
    }

    return '/brand/index?' + filter.join('&');
}
