<template>
  <div class="search">
    <div class="block">
      <div class="eyeiconBlock" @click.capture="$emit('accessibility-mode-change', $event)">
        <EyeIcon />
      </div>
      <!--      <input type="text" placeholder="Поиск" v-model="search" :style="{width: `${searchWidth}vw`}" maxlength="30" @keyup="routeSearch" />-->
    </div>
    <div class="searchicon" @click="buttonSearch">
      <span>Поиск</span>
      <SearchIcon />
    </div>
  </div>
</template>

<script>
import EyeIcon from '@/components/icons/EyeIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'

import { mapActions } from 'vuex';

export default {
  name: 'MenuComponent',
  components: {
    EyeIcon,
    SearchIcon
  },
  data() {
    return {
      search: '',
      searchWidth: 2.5,
    }
  },
  watch: {
    search(val) {
      this.searchWidth = val.length > 5 ? 0.42 + (val.length * 0.42) : 2.5
    }
  },
  methods: {
    ...mapActions('search', [
      'setSearch'
    ]),
    routeSearch(e) {
      if (e.keyCode === 13) {
        this.setSearch(this.search)
        this.$router.push({ name: 'Search', query: { search: this.search } })
      }
    },
    buttonSearch() {
      this.setSearch(this.search)
      this.$router.push({ name: 'Search', query: { search: this.search } })
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(right, center, row);
  .block {
    @include box(auto, auto, relative, null, null, null, null);
    @include flex(right, center, row);
    .eyeiconBlock {
      @include box(auto, auto, absolute, null, null, calc(100% + 1vw), null);
      @include flex(center, center, row);
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.3;
      }
    }
    input {
      min-width: 1vw;
      @include box(2.5vw, auto, relative, null, null, null, null);
      font-size: 0.75vw;
      outline: none;
      border: 0;
      background-color: transparent;
      color: rgba(255, 255, 255, 0.7);
      text-align: right;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
        transition: 0.1s;
      }
      &:focus::placeholder {
        color: rgba(255, 255, 255, 0);
      }
    }
  }
  .searchicon {
    @include flex(center, center, row);
    cursor: pointer;
    transition: 0.2s;
    margin-left: 1vw;
    span {
      font-size: 0.75vw;
      color: rgba(255, 255, 255, 0.7);
    }
    svg {
      margin-top: 0.2vw;
    }
    &:hover {
      opacity: 0.3;
    }
  }
}
@media only screen and (max-width: 900px) {
  .search .searchicon {
    display: none;
  }
  .block {
    margin-right: 70px;
  }
}
</style>
