<template>
  <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="docVerticalIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 52C1.34315 52 0 50.6569 0 49L0 3C0 1.34314 1.34315 0 3 0H24L40 16V49C40 50.6569 38.6569 52 37 52H3Z" fill="#F6F2EC"/>
    <rect x="7" y="26" width="26" height="2" fill="#D0C7C2"/>
    <rect x="7" y="31" width="26" height="2" fill="#D0C7C2"/>
    <rect x="7" y="36" width="18" height="2" fill="#D0C7C2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 16V0L40 16H24Z" fill="#E9D7C1"/>
  </svg>
</template>

<script>
    export default {
        name: "DocVertical"
    }
</script>

<style lang="scss" scoped>
  .docVerticalIcon{
    @include box(2.3vw, auto, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .docVerticalIcon{
      @include box(5vw, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .docVerticalIcon{
      @include box(9vw, auto, relative, null, null, null, null);
    }
  }
</style>
