<template>
  <div class="slider" id="slider">
    <div class="rows" :style="{left: `-${width * count}px`}">
      <div class="item" v-for="(item, i) in items" :key="i" :style="{width: `${width}px`}">
        <router-link :to="{ name: 'PosterView', params: { id: item.id }}" class="block">
          <img :src="item.photo" v-if="item.photo" />
          <div class="overlay" v-bind:style="[item.date >= Math.round(Date.now()/1000) ? {'background-color': 'rgba(0,0,0,0)'}:{'background-color': 'rgba(0,0,0,0.6)'}]">
            <div class="top">
              {{item.organisators}}
            </div>
            <div class="bottom">
              <div class="end" v-if="item.end">Прошедшее</div>
              <div class="name">{{item.title}}</div>
              <div class="region">{{item.city}}</div>
              <div class="date">{{getDate(item.date)}}</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Items',
  props: {
    items: Array,
    count: Number
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/poster/' + pic)
    },
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    }
  },
  data() {
    return {
      width: 0
    }
  },
  mounted() {
    if (window.innerWidth < 600) {
      this.width = (document.getElementById('slider').clientWidth / 1.3) / 1
    } else if (window.innerWidth < 900) {
      this.width = document.getElementById('slider').clientWidth / 3
    } else {
      this.width = document.getElementById('slider').clientWidth / 3
    }
  }
}
</script>

<style lang="scss" scoped>
.slider{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  padding: 1vw 0;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: nowrap;
    transition: 0.3s;
    .item{
      @include box(auto, auto, relative, null, null, null, null);
      flex-shrink: 0;
      padding: 0 1.5vw;
      .block{
        @include box(100%, 18vw, relative, null, null, null, null);
        @include flex(left, top, row);
        overflow: hidden;
        border-radius: 0.4vw;
        transition: 0.2s;
        cursor: pointer;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.304906), rgba(0, 0, 0, 0.304906)), #E04E39;
        img{
          @include box(100%, auto, relative, null, null, null, null);
          min-height: 100%;
        }
        .overlay{
          @include box(100%, 100%, absolute, 0, 0, null, null);
          z-index: 3;
          .top{
            @include box(80%, auto, absolute, 0, 0, null, null);
            padding: 1.5vw;
            font-size: 0.8vw;
            color: #FFF;
          }
          .bottom{
            @include box(100%, auto, absolute, 0, null, null, 0);
            padding: 16px;
            .end{
              @include box(5.8vw, auto, relative, null, null, null, null);
              @include flex(center, center, row);
              font-size: 0.8vw;
              color: #2C2A29;
              padding: 0.3vw 0;
              background-color: rgba(255, 255, 255, 0.300071);
              border-radius: 0.4vw;
            }
            .date{
              font-size: 0.8vw;
              color: #FFF;
              padding: 0.5vw 0;
            }
            .name{
              font-size: 16px;
              color: #FFF;
            }
            .region{
              padding-top: 0.5vw;
              font-size: 12px;
              color: #FFF;
            }
          }
        }
        &:after{
          content: '';
          @include box(100%, 100%, absolute, 0, 0, null, null);
          background-color: rgba(0, 0, 0, 0.2)
        }
        &:hover{
          transform: scale(1.05);
          box-shadow: 0 0.3vw 0.6vw rgba(44, 42, 41, 0.302885);
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .slider{
    padding-top: 2vw;
    .rows{
      padding: 0;
      .item{
        @include box(33.3%, auto, relative, null, null, null, null);
        padding: 1.5vw;
        .block{
          @include box(100%, 35vw, relative, null, null, null, null);
          border-radius: 0.4vw;
          .overlay{
            .top{
              @include box(100%, auto, absolute, 0, 0, null, null);
              padding: 2vw;
              font-size: 1.8vw;
            }
            .bottom{
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 16px;
              .end{
                @include box(11vw, auto, relative, null, null, null, null);
                font-size: 1.5vw;
                padding: 0.6vw 0;
                border-radius: 0.8vw;
              }
              .date{
                font-size: 1.5vw;
                padding: 1vw 0;
              }
              .name{
                font-size: 16px;
              }
              .region{
                padding-top: 2vw;
                font-size: 14px;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .slider{
    padding-top: 4vw;
    .rows{
      .item{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        padding-right: 6vw;
        &.padding{
          padding: 4vw 0;
          padding-right: 6vw;
        }
        .block{
          @include box(100%, 90vw, relative, null, null, null, null);
          border-radius: 0.8vw;
          .overlay{
            .top{
              padding: 4vw;
              font-size: 4vw;
            }
            .bottom{
              @include box(100%, auto, absolute, 0, null, null, 0);
              padding: 4vw;
              .end{
                @include box(40%, auto, relative, null, null, null, null);
                font-size: 4vw;
                padding: 2vw 0;
                border-radius: 1.8vw;
              }
              .date{
                font-size: 4vw;
                padding: 4vw 0;
              }
              .name{
                font-size: 5vw;
              }
              .region{
                padding-top: 4vw;
                font-size: 4vw;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
</style>
