import {addPaginationFilters} from "./common";

export function getEventsQuery(region, dateFrom, dateTo, page, size) {
    const filter = addPaginationFilters([], page, size);
    if (region) {
        filter.push(`filter[region]=${region}&`);
    }
    if (dateFrom && dateTo) {
        //filter.push(`filter[date][lte]=${(dateFrom.getTime() / 1000) + 6*60*60}` + `&filter[date][gte]=${(dateTo.getTime() / 1000) - 6*60*60}`);
        filter.push(`filter[date][gte]=${dateFrom}` + `&filter[date][lte]=${dateTo}`)
    }
    return '/events?' + filter.join('&');
}
