<template>
  <div class="other">
    <div class="container cont">
      <div class="row min">
        <div class="title">Другие события</div>
        <div class="controll">
          <div @click="lftSlide"><ArrowLeftPath /></div>
          <div @click="rgtSlide"><ArrowRightPath /></div>
        </div>
      </div>
      <div class="row max">
        <Slider :items="getOther" :count="count" class="slider" />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/views/poster/view/Slider.vue'
import ArrowLeftPath from '@/components/icons/ArrowLeftPath.vue'
import ArrowRightPath from '@/components/icons/ArrowRightPath.vue'

export default {
  name: 'Other',
  props: {
    items: Array,
  },
  components: {
    Slider,
    ArrowLeftPath,
    ArrowRightPath
  },
  computed: {
    getOther() {
      return this.items.filter((item, i) => i < 8 && item)
    }
  },
  data() {
    return {
      count: 0
    }
  },
  methods: {
    lftSlide(){
      this.count = this.count > 0 ? this.count - 1 : 0
    },
    rgtSlide(){
      let size = 4;
      if (window.innerWidth < 600) {
        size = 2
      }
      this.count = this.count > (this.getOther.length - size) ? 0 : this.count + 1
    }
  },
}
</script>

<style lang="scss" scoped>
.other{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  .cont{
    @include flex(left, top, row);
    padding: 1vw 0;
    .row{
      &.min{
        @include box(22%, auto, relative, null, null, null, null);
        .title{
          font-size: 1vw;
          font-weight: 700;
        }
        .controll{
          margin-top: 1vw;
          @include flex(left, top, row);
          div{
            margin-right: 1vw;
            cursor: pointer;
            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
      &.max{
        @include box(78%, auto, relative, null, null, null, null);
        .slider{
          @include box(100%, auto, relative, null, null, null, null);
          overflow: hidden;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .other{
    .cont{
      @include flex(left, top, column);
      padding: 2vw 4vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          .title{
            font-size: 2vw;
          }
          .controll{
            margin-top: 2vw;
            div{
              margin-right: 2vw;
            }
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          .slider{
            @include box(calc(100% + 3vw), auto, relative, -1.5vw, null, null, null);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .other{
    .cont{
      @include flex(left, top, column);
      padding: 4vw 6vw;
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          .title{
            font-size: 4.5vw;
          }
          .controll{
            margin-top: 4vw;
            div{
              margin-right: 8vw;
            }
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          .slider{
            @include box(calc(100% + 2vw), auto, relative, -1vw, null, null, null);
          }
        }
      }
    }
  }
}
</style>
