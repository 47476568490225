import axios from "axios";
import {
  getContestRegionalNewsQuery,
  getContestRegionalNewsPageQuery,
} from "@/axios/contest";
import {
  PAGE_TYPE_REGIONAL_NEWS,
  PAGES
} from "@/store/modules/contest/const";
import { getOthers, getOtherNews, getLinksWithSelect } from "@/store/modules/contest/getters";
import { setRouterPage } from "@/store/modules/contest/actions";

const state = {
  pageType: PAGE_TYPE_REGIONAL_NEWS,
  news: [],
  newsPage: null,
  links: PAGES,
  page: 1,
  totalCount: null,
  perPage: 12
}

const getters = {
  getOthers,
  getOtherNews,
  getLinksWithSelect,
  getNewsSortedByDate: state => {
    return [...state.news].sort((a, b) => (b.date && a.date) && b.date - a.date);
  },
}

const actions = {
  getApi({ commit, state }) {
    axios.get(getContestRegionalNewsQuery(state.page, state.perPage))
      .then(response => {
        const { data } = response;
        commit('setNews', data);
        commit('setTotalCount', response.headers['x-pagination-total-count']);
      });
  },
  getApiOnce({ commit }, id) {
    axios.get(getContestRegionalNewsPageQuery(id))
      .then(response => {
        commit('setNewsPage', response.data);
      })
  },
  setPage({ commit, dispatch }, value) {
    commit('setPage', value);
    dispatch('getApi', {});
  },
  async setRouterPage({ state }, value) {
    await setRouterPage(state, value);
  },
}

// mutations
const mutations = {
  setNews(state, value) {
    state.news = value;
  },
  setNewsPage(state, value) {
    if (Object.keys(value).length) {
      state.newsPage = value;
    }
  },
  setPage(state, value) {
    state.page = Number(value);
  },
  setTotalCount(state, value) {
    state.totalCount = Number(value);
  },
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}