<template>
    <div class="posterIndex">
        <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Бренд', route: '/brand'}]" />
        <div class="container cont">
            <div class="row max mobile">
                <div class="top">
                    <div class="title">{{getType.name}}</div>
                </div>
            </div>
            <div class="row min">
                <Sidebar :types="types" @settype="setType" />
            </div>
            <div class="row max">
                <div class="top">
                    <div class="title">{{getType.name}}</div>
                </div>
                <Items :items="items" />
                <Pagination
                    :pages="totalCount"
                    :current="Number(page)"
                    :per-page="perPage"
                    @setpage="setPageAction"
                    class="pagination"
                    v-if="totalCount > perPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Sidebar from '@/components/views/brand/index/Sidebar.vue'
    import Items from '@/components/views/brand/index/Items.vue'
    import Pagination from '@/components/Pagination.vue'

    import {mapActions, mapGetters, mapState} from 'vuex';

    export default {
        name: 'BrandIndex',
        components: {
            Breadcrumbs,
            Sidebar,
            Items,
            Pagination
        },
        computed: {
            ...mapGetters('brand', [
                'getType',
            ]),
            ...mapState('brand', [
                'items',
                'types',
                'perPage',
                'page',
                'totalCount'
            ])
        },
        methods: {
            ...mapActions('brand', [
                'setType',
                'setPage',
                'getApi'
            ]),
            setPageAction(page) {
                this.$router.push({ name: 'DocumentsIndex', params: { type: this.getType.type }, query: { page: page }})
                this.setPage(page)
            }
        },
        mounted() {
            this.setPage(this.$route.query.page > 0 ? Number(this.$route.query.page) : 1)
            this.setType(this.$route.params.type)
            this.getApi({})
        }
    }
</script>

<style lang="scss" scoped>
.posterIndex {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    .breadcrumbs {
        padding: 2vw 0;
    }
    .container {
        @include flex(left, top, row);
        .row {
            &.min {
                @include box(22%, auto, relative, null, null, null, null);
            }
            &.max {
                @include box(78%, auto, relative, null, null, null, null);
                padding-top: 0.5vw;
                .top {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, column);
                    .title {
                        font-size: 2vw;
                        padding-left: 2vw;
                        font-weight: 700;
                    }
                }
                .pagination {
                    padding: 2vw 2vw;
                }
                &.mobile {
                    display: none;
                    .top {
                        display: flex;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    .posterIndex {
        padding-bottom: 0;
        .breadcrumbs {
            padding: 4vw 4.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 3vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        display: none;
                        .title {
                            font-size: 3vw;
                            padding-left: 1.5vw;
                            padding-bottom: 2vw;
                        }
                    }
                    .pagination {
                        padding: 4vw 3vw;
                    }
                    &.mobile {
                        display: flex;
                        .top {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .posterIndex {
        .breadcrumbs {
            padding: 6vw 6.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 6vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        .title {
                            font-size: 8vw;
                            padding-left: 1vw;
                        }
                    }
                    .pagination {
                        padding: 6vw 1vw;
                    }
                }
            }
        }
    }
}
</style>
