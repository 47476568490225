import axios from "axios";
import {
    getContestWinnersQuery
} from "@/axios/contest";
import {
    PAGE_TYPE_WINNERS,
    PAGES
} from "@/store/modules/contest/const";
import { getLinksWithSelect, getOthers } from "@/store/modules/contest/getters";
import { setRouterPage } from "@/store/modules/contest/actions";

const state = {
    pageType: PAGE_TYPE_WINNERS,
    files: [],
    links: PAGES,
    regions: [],
    years: [],
    filterNominations: [],
    year: null,
    nomination: null,
    nominations: [],
    nominations1: [
        {
            id: 1,
            title: 'Номинация «Лучший МФЦ»',
            winners: [
                {
                    id: 1,
                    title: 'Липецкий городской отдел № 1 МФЦ Липецкой области',
                    region: 'Липецкая область',
                    /*region: {
                      id: 40,
                      name: 'Липецкая область'
                    },
                    region: 40,*/
                    year: 2019,
                    images: [
                        'http://185.154.53.24:9091/uploads/newsform/7904e3c28e33a7a1ad9337ad5bf97d305d443a4b.jpg',
                        'http://185.154.53.24:9091/uploads/newsform/f537199eda6b93daaa0f66e90aba03656c6a3405.jpg'
                    ],
                    content: 'Центр развивается по принципу «Больше, чем услуги». Это означает, что созданы максимально комфортные условия, имеются дополнительные сервисы – пункт буккроссинга, зарядная станция, актуализатор транспортных карт, ячейки для хранения, машинка для чистки обуви. Для посетителей с детьми организован специальный уголок, оборудован пеленальный столик. Соблюдены все необходимые условия для обслуживания лиц с особенностями здоровья. ',
                },
                {
                    id: 2,
                    title: 'МФЦ города Мытищи Московской области',
                    region: 'Московская область',
                    year: 2019,
                    images: [],
                    content: 'Текст описания',
                }
            ],
        },
        {
            id: 2,
            title: 'Номинация «Лучший проект МФЦ»',
            winners: [],
        },
        {
            id: 3,
            title: 'Номинация «Лучший универсальный специалист МФЦ»',
            winners: [],
        }
    ],
    page: 1,
    totalCount: null,
    perPage: 12
}

const getters = {
    getOthers,
    getLinksWithSelect,
}

const actions = {
    getApi({ commit, state }) {
        const region = state.regions.find(item => item.select)?.region ?? '';
        const year = state.years.find(item => item.select)?.name ?? '';
        const nomination = state.filterNominations.find(item => item.select)?.name ?? '';
        axios.get(getContestWinnersQuery(region, year, nomination, state.page, state.perPage))
            .then(response => {
                const { data } = response;
                data.forEach((element) => {
                    if (element.winners.length > 0)
                        element.winners.sort((a, b) => {
                            if (a.year < b.year) {
                                return 1;
                            }
                            if (a.year > b.year) {
                                return -1;
                            }
                            return 0;
                        });
                });
                commit('setNominations', data);
                commit('setTotalCount', response.headers['x-pagination-total-count']);
            });
    },
    getApiRegion({ commit }) {
        axios.get('/mfc-find')
            .then(response => {
                commit('setRegion', response.data.map((item, i) => {
                    item.type = i + 1
                    item.select = false
                    item.name = item.region
                    return item
                }))
            })
    },
    getApiYears({ commit }) {
        axios.get(getContestWinnersQuery('', state.year, '', state.page, state.perPage))
            .then(response => {
                const { data } = response;
                const tempYears = [];
                data.forEach((element) => {
                    element.winners.forEach((items) => {
                        tempYears.push(items.year)
                    })
                });
                const years = Array.from(new Set(tempYears)).sort().map((item, i) => {
                    const newItem = {};
                    newItem.type = i + 1;
                    newItem.select = false;
                    newItem.name = item;
                    return newItem;
                });
                commit('setYears', years);
            });
    },
    getApiFilterNominations({ commit }) {
        axios.get(getContestWinnersQuery('', state.year, '', state.page, state.perPage))
            .then(response => {
                const { data } = response;
                const tempNominations = [];
                data.forEach((element) => {
                    tempNominations.push(element.title)
                });
                const nominations = Array.from(new Set(tempNominations)).sort().map((item, i) => {
                    const newItem = {};
                    newItem.type = i + 1;
                    newItem.select = false;
                    newItem.name = item;
                    return newItem;
                });

                commit('setFilterNominations', nominations);
            });
    },
    async setRegion({ dispatch, state, commit }, value) {
        commit('setRegion', state.regions.map(item => {
            value = value.value ? value.value : value;
            item.select = item.type === value || item.name === value;
            return item;
        }));
        await dispatch('getApi', {});
    },
    async setYear({ dispatch, commit }, value) {
        commit('setYear', state.years.map(item => {
            value = value.value ? value.value : value;
            item.select = item.type === value || item.name === value;
            return item;
        }));
        await dispatch('getApi', {});
    },
    async setFilterNomination({ dispatch, commit }, value) {
        commit('setFilterNomination', state.filterNominations.map(item => {
            value = value.value ? value.value : value;
            item.select = item.type === value || item.name === value;
            return item;
        }));
        await dispatch('getApi', {});
    },
    async setYears({ commit }, value) {
        commit('setYears', value);
    },
    async setFilterNominations({ commit }, value) {
        commit('setFilterNominations', value);
    },
    async setPage({ dispatch, commit }, value) {
        commit('setPage', value);
        await dispatch('getApi', {});
    },
    async setRouterPage({ state }, value) {
        await setRouterPage(state, value);
    },
}

// mutations
const mutations = {
    setRegion(state, value) {
        state.regions = value;
    },
    setYear(state, value) {
        state.year = value;
    },
    setFilterNomination(state, value) {
        state.nomination = value;
    },
    setYears(state, value) {
        state.years = value;
    },
    setFilterNominations(state, value) {
        state.filterNominations = value;
    },
    setNominations(state, value) {
        state.nominations = value;
    },
    setPage(state, value) {
        state.page = Number(value);
    },
    setTotalCount(state, value) {
        state.totalCount = Number(value);
    },
}

export default {
    root: true,
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
