<template>
  <svg width="93" height="76" viewBox="0 0 93 76" fill="none" xmlns="http://www.w3.org/2000/svg" class="logoIcon2">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M46.1234 67.7184V75.8602C45.1465 75.5215 44.2247 75.2097 43.3086 74.8826C36.3886 72.4117 29.7317 69.3919 23.5385 65.4065C19.1708 62.5958 15.1311 59.3858 11.6035 55.5594C6.35723 49.8683 2.64744 43.349 0.987331 35.7394C0.0872264 31.6143 -0.270304 27.4391 0.222044 23.2401C1.08217 15.9021 4.31778 9.81582 10.1427 5.20212C13.9465 2.18922 18.3021 0.492107 23.1481 0.0992203C29.9033 -0.448806 35.9024 1.54041 41.2971 5.53818C42.9028 6.72791 44.4119 8.02866 45.8242 9.4421C45.9111 9.52917 46.0074 9.60666 46.0993 9.68844C46.098 16.6413 46.0965 23.5941 46.0951 30.547C45.9945 30.4579 45.8731 30.3837 45.7964 30.2775C44.8808 29.0108 43.8745 27.8186 42.8129 26.6741C41.7129 25.488 40.5044 24.4191 39.2444 23.4023C37.4612 21.9634 35.5396 20.764 33.4149 19.9037C31.9439 19.3079 30.423 18.9224 28.818 19.0092C28.2309 19.0407 27.6412 19.0853 27.0613 19.1785C24.7654 19.5476 22.7754 20.4783 21.2719 22.3262C19.7575 24.1877 19.1865 26.3772 19.0353 28.6998C18.9673 29.7458 19.0324 30.7984 19.1781 31.8482C19.4001 33.4465 19.7732 35.0049 20.3873 36.4897C21.6762 39.6057 23.5957 42.309 25.9538 44.7063C27.0852 45.8563 28.2974 46.9162 29.567 47.9136C31.7769 49.6499 34.1404 51.1501 36.5942 52.5101C38.0912 53.3399 39.6246 54.0992 41.1875 54.7999C42.7845 55.5159 44.383 56.2274 46.0292 56.8256C46.1308 56.9892 46.0986 57.1713 46.0986 57.3475C46.0998 60.6268 46.0991 63.906 46.1004 67.1851C46.1006 67.363 46.1155 67.5406 46.1234 67.7184Z" fill="#EA543A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M46.1236 67.7176C46.1157 67.5398 46.1008 67.3622 46.1007 67.1842C46.0993 63.9052 46.1 60.6259 46.0989 57.3467C46.0989 57.1704 46.1311 56.9883 46.0293 56.8248C46.1449 56.6782 46.0901 56.5066 46.0904 56.3471C46.0944 54.1466 46.0975 51.9461 46.0873 49.7457C46.0861 49.484 46.1522 49.3563 46.42 49.2894C48.3751 48.8008 50.3035 48.2265 52.2132 47.5776C55.7499 46.3754 59.1537 44.89 62.3592 42.9697C65.1958 41.2704 67.8088 39.2924 69.9093 36.7049C72.3246 33.7297 73.6414 30.4145 73.0227 26.5122C72.7205 24.6074 72.0281 22.8756 70.6297 21.5058C68.4037 19.3252 65.6282 18.7937 62.6609 19.0579C60.8118 19.2228 59.0831 19.8919 57.4199 20.7043C52.9063 22.9091 49.2961 26.1623 46.3835 30.2235C46.3006 30.3393 46.2632 30.5033 46.0954 30.5461C46.0967 23.5933 46.0982 16.6404 46.0995 9.6876C47.4736 8.35744 48.8847 7.07105 50.4051 5.90561C53.8586 3.258 57.6456 1.28861 61.9488 0.49573C70.1301 -1.01155 77.4322 0.904809 83.685 6.43034C88.0138 10.2556 90.6446 15.0948 91.7393 20.7568C92.2487 23.3911 92.2735 26.0516 92.0342 28.7228C91.6498 33.0137 90.5476 37.0987 88.5802 40.9352C86.2728 45.4347 83.0187 49.1701 79.1645 52.4014C74.8323 56.0332 69.9729 58.8165 64.8561 61.1717C59.5132 63.6312 53.9624 65.5184 48.309 67.1163C47.5818 67.3217 46.8521 67.5173 46.1236 67.7176Z" fill="#C59368"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon2',
}
</script>

<style lang="scss" scoped>
.logoIcon2{
  @include box(4vw, 4vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .logoIcon2{
    @include box(8vw, 8vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .logoIcon2{
    @include box(18vw, 18vw, relative, null, null, null, null);
  }
}
</style>
