import {addPaginationFilters} from "./common";

export function getSearchPointsQuery(type, page, search) {
    const filter = addPaginationFilters([], page);
    if (type) {
        filter.push(`type=${type}`);
    }
    filter.push(`like=${search}`);

    return '/search?' + filter.join('&');
}
