<template>
  <div class="sidebar">
    <router-link :to="{ name: 'BrandIndex', params: { type: type.type }}" v-for="(type, i) in types" :key="i" class="link" :class="{active: type.select}">
      <span @click="$emit('settype', type.type)">{{type.name}}</span>
    </router-link>
    <Selectbox :options="types" default="Выберите тип" keydef="type" @set="$emit('settype', $event)" class="select" />
  </div>
</template>

<script>
import Selectbox from '@/components/elements/Selectbox.vue'

export default {
  name: 'Sidebar',
  components: {
    Selectbox
  },
  props: {
    types: Array,
  },
  mounted() {
    //console.log(this.types);
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-top: 1vw;
  .link{
    font-size: 1vw;
    color: #E04E39;
    margin-bottom: 1vw;
    &.active{
      color: #2C2A29;
    }
  }
  .select{
    margin-top: 1vw;
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .sidebar{
    @include flex(left, top, row);
    padding: 0 1.5vw;
    flex-wrap: wrap;
    .link{
      display: none;
    }
    .select{
      display: flex;
      @include box(50vw, auto, relative, null, null, null, null);
      margin-top: 1vw;
      margin-left: 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar{
    @include flex(left, top, column);
    padding: 0 1.5vw;
    .link{
      display: none;
    }
    .select{
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 4vw;
      margin-left: 0;
    }
  }
}
</style>
