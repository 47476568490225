<template>
  <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowRightPath">
    <path d="M18 0V3H0V5H18V8L22 4L18 0Z" fill="#E04E39"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightPath',
}
</script>

<style lang="scss" scoped>
.arrowRightPath{
  @include box(2vw, 2vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowRightPath{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowRightPath{
    @include box(7vw, 7vw, relative, null, null, null, null);
  }
}
</style>
