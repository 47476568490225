<template>
  <a id="specialButton" class="bvi-open" href="#">
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="eyeicon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C4.86536 0 0.256791 7.36894 0.173438 7.50312C0.0612427 7.64445 0.000121298 7.81955 0 8C0.000141923 8.1548 0.045192 8.30623 0.129688 8.43594L0.132812 8.44063C0.146567 8.46901 4.01507 16 12 16C19.9511 16 23.8139 8.54299 23.8609 8.45156L23.8703 8.43594C23.9548 8.30623 23.9999 8.1548 24 8C24 7.82028 23.9394 7.6458 23.8281 7.50469L23.8266 7.50312C23.7432 7.36894 19.1346 0 12 0ZM12 2.4C15.0928 2.4 17.6 4.9072 17.6 8C17.6 11.0928 15.0928 13.6 12 13.6C8.9072 13.6 6.4 11.0928 6.4 8C6.4 4.9072 8.9072 2.4 12 2.4ZM9.6 8C9.6 6.67452 10.6745 5.6 12 5.6C13.3255 5.6 14.4 6.67452 14.4 8C14.4 9.32548 13.3255 10.4 12 10.4C10.6745 10.4 9.6 9.32548 9.6 8Z" fill="white"/>
  </svg>
  </a>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
#specialButton {
}
.eyeicon{
  @include box(1vw, 1vw, relative, null, null, null, null);
  margin-top: 0.3vw;
}
@media only screen and (max-width: 900px) {
  .eyeicon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .eyeicon{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
