<template>
  <div class="input">
    <input type="text" :placeholder="label" @keyup="$emit('keyup', $event)" v-model="val" />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    label: String,
    value: String,
  },
  data() {
    return {
      val: ''
    }
  },
  mounted() {
    this.val = this.value
  }
}
</script>

<style lang="scss" scoped>
.input{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  input{
    @include box(100%, 2.5vw, relative, null, null, null, null);
    padding: 0 1vw;
    border-radius: 0.3vw;
    background-color: rgba(44, 42, 41, 0.1);
    border: 0.04vw solid #D0C7C2;
    font-size: 0.85vw;
  }
}
@media only screen and (max-width: 900px) {
  .input{
    input{
      @include box(100%, 6vw, relative, null, null, null, null);
      padding: 0 2vw;
      border-radius: 0.5vw;
      border: 0.06vw solid #D0C7C2;
      font-size: 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .input{
    input{
      @include box(100%, 12vw, relative, null, null, null, null);
      padding: 0 4vw;
      border-radius: 0.5vw;
      border: 0.06vw solid #D0C7C2;
      font-size: 4vw;
    }
  }
}
</style>
