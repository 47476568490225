// states
import axios from "axios";
import { getForumsQuery } from "@/axios/forums";

const state = {
  items: [],
  types: [
    { name: 'Программа', type: 'program', select: true },
    { name: 'Фото и видеоархив', type: 'archive', select: false },
    { name: 'Презентации участников', type: 'presentations', select: false },
  ],
  item: null,
  page: 1,
  perPage: 12,
  totalCount: null
}

// getters
const getters = {
  getType: state => {
    return state.types.filter(item => item.select)[0]?.type
  }
}

// actions
const actions = {
  setType({ state, commit }, value) {
    let next = true
    value = value.value ? value.value : value
    commit('setType', state.types.map(item => {
      if (item.type === value) {
        item.select = true
        next = false
      } else {
        item.select = false
      }
      return item
    }))
    if (next) {
      state.types[0].select = true
    }
  },
  getApi({ commit, state }) {
    axios.get(getForumsQuery(state.page, state.perPage))
      .then(response => {
        commit('setItems', response.data);
        commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
      });
  },
  getApiOnce({ commit }, id) {
    axios.get('/forums/' + id)
      .then(response => {
        commit('setItem', response.data)
      })
  },
  setPage({ dispatch, commit }, value) {
    commit('setPage', value);
    dispatch('getApi', {})
  }
}

// mutations
const mutations = {
  setType(state, value) {
    state.types = value
  },
  setItems(state, value) {
    state.items = value;
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setItem(state, value) {
    state.item = value;
  },
  setPage(state, value) {
    state.page = value
  }
}

export default {
  root: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
