<template>
  <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="docAlbumsIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H48C50.2091 0 52 1.79086 52 4V36C52 38.2091 50.2091 40 48 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F6F2EC"/>
    <rect x="7" y="7" width="38" height="2" fill="#D0C7C2"/>
    <rect x="7" y="19" width="26" height="2" fill="#D0C7C2"/>
    <rect x="7" y="24" width="26" height="2" fill="#D0C7C2"/>
    <rect x="7" y="29" width="18" height="2" fill="#D0C7C2"/>
  </svg>
</template>

<script>
    export default {
        name: "DocAlbum"
    }
</script>

<style lang="scss" scoped>
  .docAlbumsIcon{
    @include box(3.3vw, auto, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .docAlbumsIcon{
      @include box(7vw, auto, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .docAlbumsIcon{
      @include box(12vw, auto, relative, null, null, null, null);
    }
  }
</style>
