<template>
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0C1.35547 0 0 1.35547 0 3V21C0 22.6445 1.35547 24 3 24H20V0H3ZM3 2H18V18H3C2.64844 18 2.31641 18.0742 2 18.1875V3C2 2.43359 2.43359 2 3 2ZM5 5V7H16V5H5ZM3 20H18V22H3C2.43359 22 2 21.5664 2 21C2 20.4336 2.43359 20 3 20Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Book"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(20px, 24px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(20px, 24px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(20px, 24px, relative, null, null, null, null);
        }
    }
</style>
