<template>
  <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="clearicon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.109 0H21C22.105 0 23 0.895 23 2V14C23 15.105 22.105 16 21 16H6.109C5.419 16 4.778 15.645 4.412 15.06L0 8L4.413 0.94C4.778 0.355 5.419 0 6.109 0ZM16.59 13L18 11.59L14.41 8L18 4.41L16.59 3L13 6.59L9.41 3L8 4.41L11.59 8L8 11.59L9.41 13L13 9.41L16.59 13Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>
</template>

<script>
export default {
  name: 'ClearIcon',
}
</script>

<style lang="scss" scoped>
.clearicon{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .clearicon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .clearicon{
    @include box(7vw, 7vw, relative, null, null, null, null);
  }
}
</style>
