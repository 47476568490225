<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path d="M6 4V28H22.1016C22.5683 30.275 24.5921 32 27 32C29.7504 32 32 29.7504 32 27C32 24.5512 30.2151 22.5026 27.8828 22.082C27.6261 21.0071 26.931 20.1065 26 19.5605V4H6ZM8 6H24V19C21.8029 19 20 20.8029 20 23C20 24.1996 20.5429 25.2691 21.3867 26H8V6ZM10 10V12H22V10H10ZM10 16V18H22V16H10ZM10 20V22H17V20H10ZM24 21C24.8122 21 25.4975 21.4722 25.8125 22.1582C24.4847 22.4865 23.3739 23.3469 22.6992 24.5C22.2745 24.1393 22 23.609 22 23C22 21.8851 22.8851 21 24 21ZM27 24C28.6716 24 30 25.3284 30 27C30 28.6716 28.6716 30 27 30C25.3284 30 24 28.6716 24 27C24 25.3284 25.3284 24 27 24Z" fill="#C39367"/>
    </svg>
</template>

<script>
    export default {
        name: "Document"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
