<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 3V29H7H26V3H6ZM8 5H24V9H21.7988C21.5309 8.38128 21.0358 8.0001 20.5 8C19.9649 8.00103 19.4707 8.38209 19.2031 9H15.7988C15.5309 8.38128 15.0358 8.0001 14.5 8C13.9649 8.00103 13.4707 8.38209 13.2031 9H12.7988C12.5309 8.38128 12.0358 8.0001 11.5 8C10.9649 8.00103 10.4707 8.38209 10.2031 9H8V5ZM8 11H10.2012C10.4691 11.6187 10.9642 11.9999 11.5 12C12.0351 11.999 12.5293 11.6179 12.7969 11H13.2012C13.4691 11.6187 13.9642 11.9999 14.5 12C15.0351 11.999 15.5293 11.6179 15.7969 11H19.2012C19.4691 11.6187 19.9642 11.9999 20.5 12C21.0351 11.999 21.5293 11.6179 21.7969 11H24V15H21.7988C21.5309 14.3813 21.0358 14.0001 20.5 14C19.9649 14.001 19.4707 14.3821 19.2031 15H18.7988C18.5309 14.3813 18.0358 14.0001 17.5 14C16.9649 14.001 16.4707 14.3821 16.2031 15H12.7988C12.5309 14.3813 12.0358 14.0001 11.5 14C10.9649 14.001 10.4707 14.3821 10.2031 15H8V11ZM8 17H10.2012C10.4691 17.6187 10.9642 17.9999 11.5 18C12.0351 17.999 12.5293 17.6179 12.7969 17H16.2012C16.4691 17.6187 16.9642 17.9999 17.5 18C18.0351 17.999 18.5293 17.6179 18.7969 17H19.2012C19.4691 17.6187 19.9642 17.9999 20.5 18C21.0351 17.999 21.5293 17.6179 21.7969 17H24V21H21.7988C21.5309 20.3813 21.0358 20.0001 20.5 20C19.9649 20.001 19.4707 20.3821 19.2031 21H15.7988C15.5309 20.3813 15.0358 20.0001 14.5 20C13.9649 20.001 13.4707 20.3821 13.2031 21H12.7988C12.5309 20.3813 12.0358 20.0001 11.5 20C10.9649 20.001 10.4707 20.3821 10.2031 21H8V17ZM8 23H10.2012C10.4691 23.6187 10.9642 23.9999 11.5 24C12.0351 23.999 12.5293 23.6179 12.7969 23H13.2012C13.4691 23.6187 13.9642 23.9999 14.5 24C15.0351 23.999 15.5293 23.6179 15.7969 23H19.2012C19.4691 23.6187 19.9642 23.9999 20.5 24C21.0351 23.999 21.5293 23.6179 21.7969 23H24V27H8V23Z" fill="white"/>
    </svg>

</template>

<script>
    export default {
        name: "Calculator"
    }
</script>

<style lang="scss" scoped>
    .icon {
        @include box(32px, 32px, relative, null, null, null, null);
    }
    @media only screen and (max-width: 900px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
    @media only screen and (max-width: 600px) {
        .icon {
            @include box(32px, 32px, relative, null, null, null, null);
        }
    }
</style>
