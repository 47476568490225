<template>
  <div class="practical__location">
    <template v-if="progressData.progress < 100">
      <div class="practical__content">
        <span class="practical__content-percent">{{ progressData.progress }}%</span>
        <div class="practical__content-line">
          <div
            class="practical__content-line-inner"
            :style="{ width: `${progressData.progress}%` }"
          ></div>
        </div>
        <p class="practical__content-head">Текущая задача</p>
        <p class="practical__content-text">{{ progressData.current }}</p>
        <p class="practical__content-header">Следующая задача</p>
        <p class="practical__content-text">{{ progressData.next }}</p>
      </div>
    </template>
    <template v-else-if="!progressData.is_reviewed">
      <div class="practical__content practical__content-second">
        <span class="practical__content-percent">{{ progressData.progress }}%</span>
        <div class="practical__content-line">
          <div
            class="practical__content-line-inner"
            :style="{ width: `${progressData.progress}%` }"
          ></div>
        </div>
        <p class="practical__content-header">Вы завершили все задачи.</p>
        <p class="practical__content-text">Пожалуйста, оставьте отзыв о процессе внедрения практики.</p>
        <textarea
          v-model="review"
          name="practical"
          type="text"
          id="practical"
          cols="30"
          rows="10"
          placeholder="Как практика улучшила вашу работу, с какими проблемами столкнулись при внедрении..."
        ></textarea>
        <p class="practical__content-value">*Поле обязательно для заполнения</p>
        <button
          class="practical__content-btn"
          id="practical-btn"
          @click="sendReview"
        >завершить внедрение</button>
      </div>
    </template>
    <template v-else>
      <div class="practical__content practical__content-second">
        <span class="practical__content-percent">{{ progressData.progress }}%</span>
        <div class="practical__content-line">
          <div
            class="practical__content-line-inner"
            :style="{ width: `${progressData.progress}%` }"
          ></div>
        </div>
        <p class="practical__content-header">Поздравляем! Практика внедрена..</p>
        <p
          class="practical__content-text"
        >Вы завершили внедрение практики за {{ daysAfterImplementation }}.</p>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PracticeProgressCard',
  props: {
    progressData: Object,
  },
  data: () => ({
    review: ''
  }),
  computed: {
    daysAfterImplementation() {
      const daysAfteImplement = this.progressData.days_after_implementation.toString()
      const ending = daysAfteImplement.endsWith('1')
        ? 'день'
        : [2, 3, 4].some(el => el == daysAfteImplement.slice(-1))
          ? 'дня'
          : 'дней'
      return `${daysAfteImplement} ${ending}`
    }
  },
  methods: {
    async sendReview() {
      const body = {
        practice: this.progressData.id,
        description: this.review,
      }
      const { status } = await axios.post('/practice/review', body)
      if (status === 200) {
        this.$emit('finished')
        this.review = ''
      }
    },
  },
}
</script>

<style scoped>
.practical__location {
  width: 830px;
}

@media screen and (max-width: 1170px) {
  .practical__location {
    width: 600px;
  }
}

@media screen and (max-width: 861px) {
  .practical__location {
    width: 100%;
  }
}

.practical__content {
  background: #f6f2ec;
  border-radius: 4px;
  padding: 21px 24px 24px 24px;
}

.practical__content-percent {
  font-size: 18px;
  font-weight: 700;
  color: #2c2a29;
  margin-bottom: 11px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .practical__content-percent {
    font-size: 16px;
  }
}

.practical__content-line {
  width: 100%;
  height: 12px;
  position: relative;
  background: #e9d7c1;
  border-radius: 6px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .practical__content-line {
    height: 8px;
    margin-bottom: 15px;
  }
}

.practical__content-line-inner {
  content: "";
  height: 12px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #e04e39;
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .practical__content-line-inner {
    height: 8px;
  }
}

.practical__content-head {
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  margin-bottom: 4px;
}

.practical__content-header {
  font-size: 16px;
  font-weight: 700;
  color: #2c2a29;
  margin-bottom: 4px;
  text-transform: uppercase;
  margin-top: 16px;
}

.practical__content-text {
  font-size: 18px;
  color: #2c2a29;
}

@media only screen and (max-width: 767px) {
  .practical__content-text {
    font-size: 14px;
  }
}
.practical__content-second textarea {
  resize: none;
  width: 100%;
  height: 132px;
  padding: 12px;
  font-size: 14px;
  color: #000;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.practical__content-second textarea::-moz-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second textarea::-ms-input-placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second textarea::placeholder {
  color: rgba(44, 42, 41, 0.7);
}

.practical__content-second .practical__content-value {
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  margin-bottom: 20px;
}

.practical__content-second .practical__content-btn {
  width: 213px;
  height: 44px;
  background: #e6dbd9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  transition: 300ms;
}

.practical__content-second .practical__content-btn:hover {
  background: #e04e39;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .practical__content-second .practical__content-btn {
    width: 100%;
  }
}
</style>
