<template>
  <div class="notify-list">
    <p class="notify-list__title">{{ title }}</p>
    <div v-for="(item, index) in items" :key="index">
      <div class="notify-list__item">
        <div class="notify-list__message">
          <p class="notify-list__message-head">{{ item.practice }}</p>
          <p class="notify-list__message-date">{{ formatDate(item.timestamp) }}</p>
        </div>
        <div v-if="descriptionAsHtml">
          <p class="practical__massage-text" v-html="item.description"></p>
        </div>
        <div v-else>
          <p class="practical__massage-text">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import formatDate from '@/utils/formatDate'

export default {
  name: 'NotifyList',
  props: {
    title: String,
    url: String,
    descriptionAsHtml: Boolean
  },
  data: () => ({
    items: [],
  }),
  methods: {
    formatDate,
    async getItems() {
      const { data, status } = await axios.get(this.url)
      if (status === 200) {
        this.items = data || []
      }
    },
  },
  mounted() {
    this.getItems()
  },
}
</script>

<style scoped>
.notify-list__title {
  font-size: 44px;
  color: #2c2a29;
  margin-bottom: 28px;
}

@media screen and (max-width: 767px) {
  .notify-list__title {
    font-size: 22px;
    margin-bottom: 18px;
  }
}

.notify-list__message {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .notify-list__message {
    display: block;
  }
}

.notify-list__message-head {
  font-size: 18px;
  color: #e04e39;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .notify-list__message-head {
    font-size: 16px;
  }
}

.notify-list__message-head--mark::before {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  transform: translate(-50%, 0);
  width: 8px;
  height: 8px;
  background: #e04e39;
  border-radius: 50%;
  left: -10px;
}

@media only screen and (max-width: 767px) {
  .notify-list__message-head--mark::before {
    width: 6px;
    height: 6px;
  }
}

.notify-list__message-date {
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  min-width: 110px;
  margin: 4px 0 0 8px;
}

@media only screen and (max-width: 767px) {
  .notify-list__message-date {
    font-size: 12px;
    margin: 4px 0 0;
  }
}

.notify-list__item {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  display: block;
  color: rgba(44, 42, 41, 0.7);
}

.notify-list__item {
  display: block;
  background: #f6f2ec;
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 16px;
  transition: 300ms;
}

@media only screen and (max-width: 767px) {
  .notify-list__item {
    padding: 20px;
  }
}

.notify-list__item:hover {
  transform: translateY(-3px);
}

.notify-list__item:last-child {
  margin-bottom: 0;
}
</style>
