<template>
  <div class="sidebar" :class="{ 'sticky': sticky }">
    <Selectbox :options="links" default="Выберите раздел" keydef="type" @set="$emit('set-page', $event)" class="select" />
    <ul class="menu">
      <li class="menu__item" v-for="(link, i) in links" :key="i">
        <a class="menu__link" :class="{ 'active': current === link.type }" @click="$emit('set-link', link)" v-if="local">
          {{ link.name }}
        </a>
        <router-link :to="link.link" class="menu__link" :class="{ 'active': current === link.type }" v-else>
          {{ link.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Selectbox from '@/components/elements/Selectbox.vue';

export default {
  name: 'SideMenu',
  components: {
    Selectbox,
  },
  props: {
    links: {type: Array, required: true},
    sticky: {type: Boolean, default: false},
    local: {type: Boolean, default: false},
    current: {type: String, default: ''},
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 90%; //подумать насчет ширины
  .select {
    display: none;
  }
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    &__link{
      display: block;
      font-size: 1vw;
      margin-bottom: 1vw;
      color: #E04E39;
      transition: 0.2s;
      cursor: pointer;
      &:hover{
        opacity: 0.7;
      }
      &.active{
        color: #2C2A29;
        cursor: pointer;
      }
    }
  }
  &.sticky {
    position: sticky;
    top: 5vw;
  }
}
@media only screen and (max-width: 900px) {
  .sidebar {
    width: 100%;
    .menu {
      display: none;
    }
    .select {
      display: block;
      width: 98%;
      margin: 1vw auto 2vw;
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar {
    .select {
      margin-bottom: 8vw;
    }
  }
}
</style>
