<template>
  <div class="current-status">
    <h3 class="current-status__title">Текущий статус</h3>
    <ul class="current-status__list">
      <li class="current-status__item" v-for="(item, i) in statusItems" :key="i">
        <CheckIcon class="icon" :fill="item.iconColor"/>
        <span :class="item.cssClass">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import CheckIcon from "@/components/icons/Check";

export default {
  name: 'CurrentStatus',
  components: {
    CheckIcon,
  },
  props: {
    items: {type: Array, required: true},
  },
  data() {
    return {
      statusColors: {
        past: 'rgba(233, 215, 193, 0.2)',
        future: '#E9D7C1',
        present: '#E04E39'
      },
    }
  },
  computed: {
    statusItems() {
      const activeItemIndex = this.items.findIndex(item => item.active);
      return this.items.map((item, i) => {
        let resultItem = {...item};
        if (i < activeItemIndex) {
          resultItem.cssClass = 'past';
          resultItem.iconColor = this.statusColors.past;
        } else if (i > activeItemIndex) {
          resultItem.cssClass = 'future';
          resultItem.iconColor = this.statusColors.future;
        } else {
          resultItem.cssClass = 'present';
          resultItem.iconColor = this.statusColors.present;
        }
        return resultItem;
      });
    },
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.current-status {
  background: #F6F2EC;
  padding: 1vw;
  border-radius: 0.3vw;
  &__title {
    margin-top: 0;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1vw 0;
    .icon {
      margin-right: 2vw;
      width: 2vw; //проверить ширину
    }
    .past {
      opacity: 0.2;
    }
    .present {
      font-weight: bold;
    }
    .future {

    }
  }
}
@media only screen and (max-width: 900px) {
  .current-status {
    border-radius: 0.6vw;
    &__item {
      margin: 1.5vw 0;
      .icon {
        width: 3.5vw; //проверить ширину
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .current-status {
    padding: 0;
    background: none;
    &__item {
      margin: 2.5vw 0;
      .icon {
        width: 5.5vw; //проверить ширину
      }
    }
  }
}
</style>
