<template>
  <div class="replication__content" data-link-content>
    <div class="replication__item">
      <div class="replication__header">
        <span>{{ mfcs.length }}</span>
        <p>
          В процессе внедрения
          практики
        </p>
      </div>
      <div class="replication__block">
        <input v-model="search" type="text" placeholder="Поиск региона" />
        <SortSelect :options="sortOptions" @change="changeSortSelect" />
      </div>

      <div v-for="item in filteredMfcs" :key="item.nick" class="replication__item-block">
        <div class="replication__item-image">
          <img src="images/region-11.png" alt />
        </div>
        <div class="replication__item-info">
          <p>{{ item.nick }}</p>
          <div class="replication__item-scale">
            <span>{{ item.progress }}%</span>
            <div class="replication__item-line" :style="{ '--progress': `${item.progress}%` }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import SortSelect from './SortSelect.vue'

export default {
  name: 'InProgress',
  components: {
    SortSelect
  },
  data: () => ({
    mfcs: [],
    search: '',
    sortType: {
      label: 'Названию МФЦ',
      value: 'nick',
      id: 1
    },
    sortOptions: [
      {
        label: 'Названию МФЦ',
        value: 'nick',
        id: 1
      },
      {
        label: 'Степени внедрения',
        value: 'progress',
        id: 2
      },
    ],
  }),
  computed: {
    filteredMfcs() {
      return this.mfcs.filter(el => el.nick.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => {
          if (this.sortType.value === 'nick') {
            return a.nick < b.nick ? -1 : 1
          } else if (this.sortType.value === 'date') {
            return moment(a.date, 'DD.MM.YYYY').format('x') - moment(b.date, 'DD.MM.YYYY').format('x')
          }
        })
    }
  },
  methods: {
    async getMfcs() {
      const body = { id: this.$route.params.id }
      const { data } = await axios.post('/practice/embedded-process', body)
      this.mfcs = data
    },
    changeSortSelect(sortType) {
      this.sortType = sortType
    }
  },
  created() {
    this.getMfcs()
  }
}
</script>

<style lang="scss" scoped>
.replication__item-line {
  width: 278px;
  height: 12px;
  background: #e9d7c1;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 8px;
  }

  &::before {
    content: "";
    height: 12px;
    width: var(--progress);
    background: #e04e39;
    border-radius: 6px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    @media only screen and (max-width: 767px) {
      height: 8px;
    }
  }
}
</style>