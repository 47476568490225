<template>
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowDownIcon">
    <path d="M0.898438 1.83518L6.96875 7.74512L13.0391 1.83518L11.5391 0.374805L6.96875 4.82437L2.39844 0.374805L0.898438 1.83518Z" fill="#2C2A29"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.arrowDownIcon{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowDownIcon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowDownIcon{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
