// states
import axios from "axios";
import { getBrandQuery } from "@/axios/brand";

const state = {
    types: [
        { name: 'Мои документы', type: 'my_docs', select: true },
        { name: 'Мои документы +', type: 'my_docs_plus', select: false },
        //{ name: 'Мой бизнес', type: 'my_bissness', select: false },
        //{ name: 'Портал госуслуг', type: 'gosuslugi', select: false }
    ],
    items: [],
    page: 1,
    perPage: 8,
    totalCount: null
}

// getters
const getters = {
    getType: state => {
        return state.types.filter(item => item.select)[0]
    }
}

// actions
const actions = {
    setType({ dispatch, state, commit }, value) {
        value = value.value ? value.value : value
        commit('setType', state.types.map(item => {
            if (item.type === value) {
                item.select = true
            } else {
                item.select = false
            }
            return item
        }))
        dispatch('setPage', 0)
    },
    getApi({ commit, state }) {
        const type = state.types.filter(item => item.select)[0]?.type

        axios.get(getBrandQuery(type, state.page, state.perPage))
            .then(response => {
                commit('setItems', response.data);
                commit('setTotalCount', parseInt(response.headers['x-pagination-total-count']));
            });
    },
    setPage({ commit, dispatch }, value) {
        commit('setPage', value)
        dispatch('getApi', {});
    }
}

// mutations
const mutations = {
    setType(state, value) {
        state.types = value
    },
    setPage(state, value) {
        state.page = value
    },
    setItems(state, value) {
        state.items = value;
    },
    setTotalCount(state, value) {
        state.totalCount = value
    },
}

export default {
    root: true,
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
