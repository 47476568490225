<template>
  <div class="inputbox">
    <div class="title">
      <div class="name">{{label}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Inputbox',
    props: {
      label: String,
    },
    data() {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .inputbox{
    @include box(100%, 2.5vw, relative, null, null, null, null);
    outline: none;
    border-radius: 0.2vw;
    background-color: rgba(44, 42, 41, 0.1);
    border: 0.04vw solid #D0C7C2;
    .title{
      @include box(100%, 2.5vw, relative, null, null, null, null);
      @include flex(center, center, row);
      cursor: pointer;
      .name{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 0 1vw;
        font-size: 1.1vw;
        color: rgba(44, 42, 41, 0.7);
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .inputbox{
      @include box(100%, 4.5vw, relative, null, null, null, null);
      outline: none;
      border-radius: 0.4vw;
      background-color: rgba(44, 42, 41, 0.1);
      border: 0.04vw solid #D0C7C2;
      .title{
        @include box(100%, 6vw, relative, null, null, null, null);
        @include flex(center, center, row);
        cursor: pointer;
        .name{
          @include box(100%, auto, relative, null, null, null, null);
          padding: 0 2vw;
          font-size: 2.2vw;
          color: rgba(44, 42, 41, 0.7);
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .inputbox{
      @include box(100%, 13.75vw, relative, null, null, null, null);
      outline: none;
      border-radius: 1vw;
      background-color: rgba(44, 42, 41, 0.1);
      border: 0.2vw solid #D0C7C2;
      .title{
        @include box(100%, 13.75vw, relative, null, null, null, null);
        @include flex(center, center, row);
        cursor: pointer;
        .name{
          @include box(100%, auto, relative, null, null, null, null);
          padding: 0 4vw;
          font-size: 4.9vw;
          color: rgba(44, 42, 41, 0.7);
        }
      }
    }
  }
</style>
