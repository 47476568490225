<template>
  <div class="slider" id="slider">
    <div class="rows" :style="{left: `-${width * count}px`}">
      <div class="item" v-for="(item, i) in items" :key="i" :style="{width: `${width}px`}">
        <router-link :to="{ name: 'ForumsView', params: { id: item.id }}" class="block">
          <div class="image">
            <img :src="item.photo" v-if="item.photo" />
          </div>
          <div class="name">№{{item.number}}, {{item.month}}</div>
          <div class="sub">{{item.address}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Items',
  props: {
    items: Array,
    count: Number
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/forums/' + pic)
    },
  },
  data() {
    return {
      width: 0
    }
  },
  mounted() {
    if (window.innerWidth < 600) {
      this.width = (document.getElementById('slider').clientWidth / 1.3) / 1
    } else if (window.innerWidth < 900) {
      this.width = document.getElementById('slider').clientWidth / 3
    } else {
      this.width = document.getElementById('slider').clientWidth / 3
    }
  }
}
</script>

<style lang="scss" scoped>
.slider{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  padding: 1vw 0;
  user-select: none;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    flex-wrap: nowrap;
    transition: 0.3s;
    .item{
      @include box(auto, auto, relative, null, null, null, null);
      flex-shrink: 0;
      padding: 0 1.5vw;
      .block{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        .image{
          @include box(100%, 18vw, relative, null, null, null, null);
          border-radius: 0.3vw;
          overflow: hidden;
          img{
            @include box(100%, auto, relative, null, null, null, null);
            min-height: 100%;
          }
        }
        .name{
          font-size: 1vw;
          padding-top: 1vw;
          color: #E04E39;
          transition: 0.2s;
        }
        .sub{
          font-size: 0.9vw;
          padding-top: 1vw;
        }
        &:hover{
          .name{
            color: #2C2A29;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .slider{
    padding-top: 2vw;
    .rows{
      padding: 0;
      .item{
        @include box(33.3%, auto, relative, null, null, null, null);
        padding: 1.5vw;
        .block{
          .image{
            @include box(100%, auto, relative, null, null, null, null);
            border-radius: 0.3vw;
          }
          .name{
            font-size: 2vw;
            padding-top: 2vw;
          }
          .sub{
            font-size: 1.8vw;
            padding-top: 2vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .slider{
    padding-top: 4vw;
    .rows{
      .item{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        padding-right: 6vw;
        .block{
          .image{
            @include box(100%, 45vw, relative, null, null, null, null);
            border-radius: 2vw;
            .icon{
              @include box(25vw, auto, relative, null, null, null, null);
            }
          }
          .name{
            font-size: 5vw;
            padding-top: 4vw;
          }
          .sub{
            font-size: 4vw;
            padding-top: 4vw;
          }
        }
      }
    }
  }
}
</style>
