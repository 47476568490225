<template>
  <div class="tabs">
    <div class="container">
      <div class="block" v-for="(tab, i) in tabs" :key="i" :class="{active: tab.select}">
        <router-link :to="{ name: 'PracticesView', params: { page: tab.type } }" class="tab">
          <span @click="$emit('set', tab.type)">{{tab.name}}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: Array,
  }
}
</script>

<style lang="scss" scoped>
.tabs{
  @include box(100%, auto, fixed, null, 3vw, null, null);
  @include flex(left, top, row);
  background-color: #F6F2EC;
  box-shadow: 0 0.3vw 0.6vw rgba(0, 0, 0, 0.198864);
  z-index: 50;
  .container{
    @include flex(center, center, row);
    .block{
      @include box(auto, auto, relative, null, null, null, null);
      @include flex(center, center, row);
      flex-grow: 1;
      padding: 1.2vw 0;
      .tab{
        font-size: 0.85vw;
        color: #623B2A;
        transition: 0.2s;
        &:hover{
          color: #E04E39;
        }
      }
      &.active{
        &:after{
          content: '';
          @include box(100%, 0.2vw, absolute, 0, null, null, 0);
          background-color: #E04E39;
        }
        .tab{
          color: #E04E39;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 600px) {

}
</style>
