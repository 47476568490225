<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.33594C0 4.18927 4.1854 0 9.33073 0H22.6641C27.8107 0 32 4.1854 32 9.33073V22.6641C32 27.8107 27.8146 32 22.6693 32H9.33594C4.18927 32 0 27.8146 0 22.6693V9.33594ZM27.1553 20.9537C27.0553 20.7704 26.6235 19.9787 25 18.4712C24.2254 17.7533 23.8211 17.387 23.7785 16.9826C23.7201 16.4282 24.3415 15.8022 25.6202 14.101C26.9663 12.3077 27.5048 11.2115 27.3365 10.7452C27.1731 10.2981 26.1875 10.4135 26.1875 10.4135L22.8942 10.4375C22.8942 10.4375 22.649 10.4038 22.4663 10.5096C22.2885 10.6154 22.1731 10.8654 22.1731 10.8654C22.1731 10.8654 21.6538 12.25 20.9567 13.4327C19.4952 15.9231 18.9087 16.0529 18.6683 15.899C18.2009 15.5969 18.2231 14.7848 18.2426 14.0741C18.2463 13.9368 18.25 13.8032 18.25 13.6779C18.25 13.1243 18.2692 12.6448 18.2858 12.2314C18.3415 10.8427 18.3673 10.2001 17.5337 10C17.1779 9.91346 16.9135 9.85577 16 9.84615C14.8269 9.83173 13.8317 9.85096 13.2692 10.125C12.8942 10.3077 12.601 10.7163 12.7788 10.7404C13 10.7692 13.4904 10.875 13.75 11.226C14.0913 11.6875 14.0769 12.7212 14.0769 12.7212C14.0769 12.7212 14.274 15.5577 13.625 15.9135C13.1827 16.1538 12.5721 15.6587 11.2644 13.399C10.5962 12.2452 10.0913 10.9615 10.0913 10.9615C10.0913 10.9615 9.99039 10.7212 9.81731 10.5962C9.60577 10.4423 9.3125 10.3894 9.3125 10.3894L6.18269 10.4038C6.18269 10.4038 5.71154 10.4135 5.53846 10.6154C5.38942 10.7981 5.52885 11.1587 5.52885 11.1587C5.52885 11.1587 7.97596 16.8558 10.75 19.7452C13.2933 22.3942 16.1827 22.1538 16.1827 22.1538H17.4952C17.4952 22.1538 17.8894 22.1731 18.0913 21.9615C18.274 21.7596 18.2692 21.4519 18.2692 21.4519C18.2692 21.4519 18.2452 19.6923 19.0577 19.4327C19.5446 19.282 20.1119 19.9315 20.7259 20.6345C21.1303 21.0976 21.555 21.5839 21.9904 21.8798C22.8173 22.4519 23.4471 22.3269 23.4471 22.3269L26.3702 22.2837C26.3702 22.2837 27.899 22.1923 27.1731 20.9856C27.1689 20.9786 27.1631 20.968 27.1553 20.9537Z" fill="white" fill-opacity="0.5"/>
  </svg>
</template>

<script>
    export default {
        name: "VKontacte"
    }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(5vw, 5vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon{
      @include box(10vw, 10vw, relative, null, null, null, null);
    }
  }
</style>
