<template>
  <div class="other">
    <div class="container">
      <div class="row min">
        <div class="title">Другие новости</div>
      </div>
      <div class="row max">
        <div class="list">
          <Item v-for="item in getNews" :key="item.id" :item="item" :groups="groups" :image="true" />
        </div>
        <div class="buttons">
          <Button to="/news" label="Все новости" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/views/news/Item.vue'
import Button from '@/components/Button'

export default {
  name: 'Other',
  components: {
    Item,
    Button
  },
  props: {
    news: Array,
    groups: Array
  },
  computed: {
    getNews() {
      return this.news.filter((item, i) => i < 3 && item)
    }
  }
}
</script>

<style lang="scss" scoped>
.other{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding-top: 1.5vw;
  padding-bottom: 4vw;
  .container{
    @include flex(left, top, row);
    padding-top: 1vw;
    .row{
      &.min{
        @include box(22%, auto, relative, null, null, null, null);
        .title{
          font-size: 1.1vw;
          font-weight: 700;
          padding-right: 7vw;
        }
      }
      &.max{
        @include box(78%, auto, relative, null, null, null, null);
        .list{
          @include box(100%, auto, relative, null, null, null, null);
          border-bottom: 0.08vw solid #E9D7C1;
        }
        .buttons{
          @include box(15%, auto, relative, null, null, null, null);
          padding-top: 1vw;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .other{
    padding: 3vw;
    .container{
      @include flex(left, top, column);
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          .title{
            font-size: 3vw;
            padding-right: 0;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 3vw;
          .list{
            @include box(100%, auto, relative, null, null, null, null);
            border-bottom: 0.2vw solid #E9D7C1;
          }
          .buttons{
            @include box(30%, auto, relative, null, null, null, null);
            margin: 0 auto;
            padding-top: 3vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .other{
    padding: 6vw;
    .container{
      @include flex(left, top, column);
      .row{
        &.min{
          @include box(100%, auto, relative, null, null, null, null);
          .title{
            font-size: 5vw;
            padding-right: 0;
          }
        }
        &.max{
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 6vw;
          .list{
            @include box(100%, auto, relative, null, null, null, null);
            border-bottom: 0.5vw solid #E9D7C1;
          }
          .buttons{
            @include box(100%, auto, relative, null, null, null, null);
            padding-top: 6vw;
          }
        }
      }
    }
  }
}
</style>
