<template>
    <div class="sections">
        <div class="container">
            <div class="rows">
                <div class="row">
                    <Handshake class="icon" />
                    <div class="name">Институт банкротства — только для добросовестных должников. Если человек может платить, но не хочет, он — не банкрот.</div>
                </div>
                <div class="row">
                    <PiggyBank class="icon"/>
                    <div class="name">Банкротство — не преступление. Оно не стыдно и не позорно. Оно может случиться с каждым. Банкротство нередко наступает не по вине должника (увольнение, болезнь, авария, смерть близких и т.п.).</div>
                </div>
                <div class="row">
                    <Calculator />
                    <div class="name">Чтобы решить свои проблемы, должник и кредиторы могут инициировать процедуру банкротства — судебную или внесудебную.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Handshake from "../../icons/bankrutcy/Handshake";
    import PiggyBank from "../../icons/bankrutcy/PiggyBank";
    import Calculator from "../../icons/bankrutcy/Calculator";

    export default {
        name: "Advantages",
        components: {
            Calculator,
            PiggyBank,
            Handshake,
        }
    }
</script>

<style lang="scss" scoped>
    .sections{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding: 2vw 0;
        background-color: #E04E39;
        color: #FFFFFF;
        .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            padding: 2vw 0;
            .row{
                @include box(33%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .name{
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    padding-top: 1.5vw;
                }
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .sections{
            padding: 2vw 0;
            .rows{
                padding: 2vw 2vw;
                flex-wrap: wrap;
                .row{
                    @include box(33%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-left: 15px;
                    padding-right: 15px;
                    .name{
                        font-size: 2vw;
                        padding-top: 2vw;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .sections{
            padding: 4vw 0;
            .rows{
                padding: 0;
                display: block!important;
                .row{
                    @include box(100%, auto, relative, null, null, null, null);
                    padding: 4vw 0;
                    .name{
                        font-size: 5vw;
                        padding-top: 4vw;
                    }
                }
            }
        }
    }
</style>

