<template>
    <div class="content">
        <Banner />
        <Advantages />
        <About />
        <Debts />
        <Exceptions />
        <DebtsInfo />
        <DeliveryPlace />
        <CheckInfo />
        <MainStep />
        <FinishBankruptcy />
        <Map/>
    </div>
</template>

<script>
    import Map from '@/components/views/home/Map.vue';
    import Banner from "../../components/views/bankruptcy/Banner";
    import Advantages from "../../components/views/bankruptcy/Advantages";
    import About from "../../components/views/bankruptcy/About";
    import Debts from "../../components/views/bankruptcy/Debts";
    import Exceptions from "../../components/views/bankruptcy/Exceptions";
    import DebtsInfo from "../../components/views/bankruptcy/DebtsInfo";
    import DeliveryPlace from "../../components/views/bankruptcy/DeliveryPlace";
    import CheckInfo from "../../components/views/bankruptcy/CheckInfo";
    import FinishBankruptcy from "../../components/views/bankruptcy/FinishBankruptcy";
    import MainStep from "../../components/views/bankruptcy/MainStep";

    export default {
        name: "BankruptcyIndex",
        components: {
            MainStep,
            FinishBankruptcy,
            CheckInfo,
            DeliveryPlace,
            DebtsInfo,
            Exceptions,
            Debts,
            About,
            Advantages,
            Map,
            Banner
        }
    }
</script>

<style scoped>
    .content {
        margin-top: -2px;
    }
</style>
