<template>
  <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.5 28.5H71.5V63C71.5 68.2467 67.2467 72.5 62 72.5H57.5V28.5Z" stroke="#C59368" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60 0H0V75H60V0ZM42.7115 14.1891H16.7793V20.2702H42.7115V14.1891ZM9.15201 34.4595H50.3385V40.5406H9.15201V34.4595ZM50.3385 24.8311H9.15201V30.9122H50.3385V24.8311ZM9.15201 44.5947H50.3385V50.6757H9.15201V44.5947ZM42.7115 54.7297H16.7793V60.8108H42.7115V54.7297Z" fill="#EA543A"/>
  </svg>
</template>

<script>
export default {
  name: "News"
}
</script>

<style lang="scss" scoped>
.icon {
  @include box(74px, 75px, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .icon {
    @include box(74px, 75px, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .icon {
    @include box(74px, 75px, relative, null, null, null, null);
  }
}
</style>
