<template>
  <div class="practical__sidebar">
    <div v-if="profile" class="practical__logo">
      <img class="practical__logo-image" :src="'/api' + profile.profile.regionLogo" alt />
      <p>{{ profile.profile.regionName }}</p>
    </div>
    <div class="practical__form">
      <div class="practical__search">
        <input
          @change="changeSearch($event)"
          type="search"
          placeholder="Поиск по названию"
          autocomplete="off"
        />
      </div>
      <div class="practical__selected">
        <Selectbox
          :options="categories"
          default="Выберите категорию"
          keydef="type"
          @set="changeCategory"
          cleansable
        />
        <SortSelect @change="changeSortType" />
      </div>
    </div>
  </div>
</template>

<script>
import Selectbox from '../../../elements/Selectbox.vue'
import SortSelect from './SortSelect.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'PracticesSidebar',
  components: {
    Selectbox,
    SortSelect,
  },
  data: () => ({
    filter: {
      filter: {
        and: [],
      },
      sort: {
        date: 'ASC',
      },
    },
    search: '',
  }),
  computed: {
    ...mapState('practices', ['categories', 'sort']),
    ...mapGetters('mfcCabinet', ['isAuthorized', 'profile']),
  },
  methods: {
    ...mapActions('practices', ['setCategory']),
    buildFilter() {
      this.filter.filter.and = []
      const selectedCategory = this.categories.find(el => el.select)
      if (selectedCategory) {
        this.filter.filter.and.push({
          category: {
            like: selectedCategory?.category,
          },
        })
      }
      if (this.search) {
        this.filter.filter.and.push({
          title: {
            like: this.search,
          },
        })
      }
    },
    changeCategory(category) {
      this.setCategory(category)
      this.buildFilter()
      this.$emit('change', this.filter)
    },
    changeSearch(event) {
      this.search = event.target.value
      this.buildFilter()
      this.$emit('change', this.filter)
    },
    changeSortType(sortType) {
      this.filter.sort = sortType
      this.$emit('change', this.filter)
    },
  },
  watch: {
    // filter: {
    //   deep: true,
    //   handler() {
    //     this.$emit('change', this.filter)
    //   },
    // },
  },
}
</script>

<style scoped>
.practical__sidebar {
  width: 255px;
}

@media screen and (max-width: 1170px) {
  .practical__sidebar {
    width: 200px;
  }
}

@media screen and (max-width: 861px) {
  .practical__sidebar {
    width: 100%;
    margin-bottom: 30px;
  }
}

.practical__logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 0;
  margin-bottom: 24px;
}

@media screen and (max-width: 861px) {
  .practical__logo {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1170px) {
  .practical__logo {
    padding: 0;
  }
}

@media screen and (max-width: 861px) {
  .practical__logo img {
    margin-right: 15px;
  }
}

.practical__logo p {
  font-size: 14px;
  color: #2c2a29;
  line-height: 20px;
}

.practical__search {
  width: 100%;
  height: 2.5vw;
  margin-bottom: 12px;
}

@media screen and (max-width: 900px) {
  .practical__search {
    height: 5vw;
  }

  .practical__search input {
    padding: 0 calc(12px + 2vw);
  }
}

.practical__search input {
  width: 100%;
  height: 100%;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  padding: 0 calc(12px + 1vw);
}

.practical__search input::-moz-placeholder {
  color: rgba(44, 42, 41, 0.7);
  font-size: 14px;
}

.practical__search input::-ms-input-placeholder {
  color: rgba(44, 42, 41, 0.7);
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .practical__search {
    height: 12vw;
  }

  .practical__search input {
    padding: 0 calc(12px + 4vw);
  }
}

.practical__search input::placeholder {
  color: rgba(44, 42, 41, 0.7);
  font-size: 14px;
}

.practical__selected {
  width: 100%;
}

.practical__selected .selectbox {
  width: 100%;
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}

.practical__selected select:last-child {
  margin-bottom: 0;
}

.practical__logo-image {
  margin-right: 8px;
  min-height: 64px;
  min-width: 64px;
  max-height: 64px;
  max-width: 64px;
  object-fit: contain;
}
</style>
