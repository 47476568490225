<template>
  <div class="header">
    <Breadcrumbs class="breadcrumbs" :pages="[{name: 'Целевые модели МФЦ 2.0', route: '/models'}]" />
    <div class="container">
      <div class="rows">
        <div class="row">
          <div class="name">{{model.name}}</div>
          <LogoBigIcon class="mobile" />
          <div class="buttons" v-if="false">
            <Button label="Внедрить модель" background />
          </div>
        </div>
        <div class="row mobile">
          <img  width="394" :src="model.image_url"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Button from '@/components/elements/Button.vue'
import LogoBigIcon from '@/components/icons/LogoBigIcon.vue'

export default {
  name: 'Header',
  props: {
    model: Object
  },
  components: {
    Breadcrumbs,
    Button,
    LogoBigIcon
  }
}
</script>

<style lang="scss" scoped>
.header{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  background-color: #F6F2EC;
  padding: 2vw 0 0;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    .row{
      padding-bottom: 0;
      &:nth-child(1){
        @include box(60%, auto, relative, null, null, null, null);
        padding-top: 4vw;
        .name{
          font-size: 2vw;
        }
        .mobile{
          display: none;
        }
        .buttons{
          @include box(30%, auto, relative, null, null, null, null);
          margin-top: 2vw;
        }
      }
      &:nth-child(2){
        @include box(40%, auto, relative, null, null, null, null);
        @include flex(right, top, row);
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .header{
    @include flex(left, top, row);
    padding: 0;
    flex-wrap: wrap;
    .breadcrumbs{
      background-color: #FFF;
      padding: 2vw;
    }
    .rows{
      padding: 2vw 2vw;
      .row{
        &:nth-child(1){
          @include box(70%, auto, relative, null, null, null, null);
          padding-top: 0;
          .name{
            font-size: 2.5vw;
          }
          .buttons{
            @include box(30%, auto, relative, null, null, null, null);
            margin-top: 2vw;
          }
        }
        &:nth-child(2){
          @include box(30%, auto, relative, null, null, null, null);
          @include flex(right, top, row);
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .header{
    @include flex(left, top, column);
    .breadcrumbs{
      padding: 4vw 6vw;
    }
    .rows{
      padding: 0;
      .row{
        &:nth-child(1){
          @include box(100%, auto, relative, null, null, null, null);
          padding-top: 0;
          .name{
            padding: 6vw;
            padding-top: 2vw;
            font-size: 6vw;
            background-color: #FFF;
          }
          .mobile{
            display: flex;
            margin: 5vw auto;
          }
          .buttons{
            @include box(100%, auto, relative, null, null, null, null);
            background-color: #FFF;
            margin-top: 0;
            padding: 6vw;
            padding-bottom: 3vw;
          }
        }
        &:nth-child(2){
          display: none;
        }
      }
    }
  }
}
</style>
