<template>
  <div class="content__sidebar-content" :id="'combo-box-' + _uid">
    <div class="content__sidebar-select content__sidebar-select--first" @click="toggleMenu">
      <div data-info="CategoryInfoCaption" class="content__sidebar__select--block">
        <div>{{ label }}</div>
        <div>
          <b>{{ subLabel }}</b>
        </div>
      </div>
      <img src="@/assets/undecomposed/arrow-down.svg" alt/>
    </div>

    <div v-if="isMenuShown" class="content__sidebar-drop content__sidebar-drop--first active">
      <div class="content__sidebar-drop--header">
        <input
            v-if="hasSearch"
            v-model="search"
            type="text"
            class="select__search"
            :placeholder="searchPlaceholder"
        />

        <p class="content__sidebar-head">{{ rangeTitle }}</p>
        <div v-if="hasRange" class="select__range-inputs">
          <input v-model="value.range.gt" type="number" :placeholder="`От ${rub ? '(руб.)' : ''}`"
                 data-info="MFCOffices"/>
          <input v-model="value.range.lt" type="number" :placeholder="`До ${rub ? '(руб.)' : ''}`"
                 data-info="MFCOffices"/>
        </div>
        <p
            v-if="optionsTitle"
            class="content__sidebar-head"
            data-info="MFCOffices"
        >{{ optionsTitle }}</p>
      </div>

      <div v-if="options" class="content__sidebar-block">
        <div v-for="(option, optionIndex) in options" :key="option.name" class="checkbox__container">
          <label v-if="searchMatch(option)" class="checkbox">
            <span class="checkbox__inner">
              <input v-model="option.select" @input="mainSet(optionIndex)" data-info="Category" type="checkbox"
                     class="agreement"/>
              <span></span>
            </span>
            <b class="checkbox__text">{{ option.name }}</b>
          </label>
          <template v-if="option.sub">
            <div v-for="subOption in option.sub" :key="subOption.name" class="checkbox__container">
              <label v-if="searchMatch(subOption)" class="checkbox">
                    <span class="checkbox__inner">
                      <input v-model="subOption.select" @input="subSet(optionIndex)" data-info="Category"
                             type="checkbox" class="agreement"/>
                      <span></span>
                    </span>
                <span class="checkbox__text checkbox__text--sub">{{ subOption.name }}</span>
              </label>
            </div>
          </template>
        </div>
      </div>
      <div class="content__sidebar-nav">
        <button @click="apply" class="content__sidebar-accept">Применить</button>
        <div class="content__sidebar-addotional-buttons">
          <button @click="reset" class="content__sidebar--additional-button">Сбросить фильтр</button>
          <button @click="selectAll" class="content__sidebar--additional-button">Выбрать все</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComboSelect',
  computed: {
    selectedOptions() {
      return this.options
          ? JSON.parse(JSON.stringify(this.options))?.filter(el => el.select)
          : []
    },
    subLabel() {
      const rangeFrom = this.value?.range?.gt
          ? `от ${this.value.range.gt} `
          : ''
      const rangeTo = this.value?.range?.lt
          ? `до ${this.value.range.lt}`
          : ''

      return this.options && this.selectedOptions.length
          ? `Выбрано: ${this.selectedOptions.length}`
          : rangeFrom + rangeTo
    }
  },
  data: () => ({
    isMenuShown: false,
    search: '',
  }),
  props: {
    label: String,
    hasSearch: Boolean,
    hasRange: Boolean,
    searchPlaceholder: String,
    value: Object,
    options: Array,
    rangeTitle: String,
    optionsTitle: String,
    rub: Boolean,
    sub: Boolean,
  },
  methods: {
    mainSet(optionIndex) {
      setTimeout(() => {
        if (this.sub) {
          this.options[optionIndex].sub.forEach(x => {
            x.select = this.options[optionIndex].select
          })
        }
      }, 1)
    },
    subSet(optionIndex) {
      setTimeout(() => {
        this.options[optionIndex].select = this.options[optionIndex].sub.findIndex(x => x.select) >= 0
      }, 1)
    },
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    apply() {
      this.updateValue()
      this.isMenuShown = false
    },
    reset() {
      if (this.value.options) this.resetCheckboxes()
      if (this.value.range) this.resetRange()
      this.updateValue()
    },
    updateValue() {
      this.$emit('update:value', {...this.value, options: this.selectedOptions})
    },
    closeMenu(e) {
      if (!e.target.closest('#combo-box-' + this._uid)) {
        this.isMenuShown = false
        this.updateValue()
      }
    },
    resetCheckboxes() {
      if (this.options != null)
        this.options.forEach(el => {
          el.select = false

          if (this.sub && el.sub) {
            el.sub.forEach(x => {
              x.select = false
            })
          }
        })
    },
    resetRange() {
      this.value.range.gt = ''
      this.value.range.lt = ''
    },
    searchMatch(option) {
      return !this.hasSearch || !this.search ||
          option.name.toLowerCase().includes(this.search.toLowerCase())
    },
    selectAll() {
      this.options.forEach(el => {
        el.select = true

        if (this.sub && el.sub) {
          el.sub.forEach(x => {
            x.select = true
          })
        }
      })
      this.updateValue()
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.select__search {
  width: 100%;
  background: rgba(44, 42, 41, 0.1);
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 44px;
  padding: 0 12px;
  font-size: 14px;

  @media only screen and (lt-width: 861px) {
    height: 30px;
    margin-bottom: 10px;
  }

  &::placeholder {
    color: rgba(44, 42, 41, 0.7);
  }
}

.content__sidebar-block .checkbox__container .checkbox {
  margin-bottom: 20px;
}

.select__range-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (lt-width: 861px) {
    display: block;
    margin-bottom: 10px;
  }

  &--second {
    margin-bottom: 0;
    padding: 0 20px;
  }

  input {
    width: 140px;
    height: 44px;
    background: rgba(44, 42, 41, 0.1);
    border: 1px solid #d0c7c2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 14px;
    color: #000;

    @media only screen and (lt-width: 861px) {
      width: 100%;
      margin-bottom: 10px;
      height: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::placeholder {
      color: rgba(44, 42, 41, 0.7);
    }
  }
}

.content__sidebar-drop--header {
  margin: 0 20px;
}

.content__sidebar-addotional-buttons {
  display: flex;
  flex-direction: column;
}

.content__sidebar--additional-button {
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: rgba(234, 84, 58, 1);
  margin: 2px 0;
}

.checkbox__text--sub {
  padding-left: 12px;
}
</style>
