<template>
  <div class="calendar">
    <div class="title" @click="select = select ? false : true">
      <div class="icon">
        <CalendarIcon />
      </div>
      <div class="name">{{getDate}}</div>
    </div>
    <div class="options" v-if="select">
      <date-picker v-model="selectedDate" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

Vue.component('date-picker', DatePicker)

export default {
  name: 'Sidebar',
  components: {
    CalendarIcon,
    DatePicker
  },
  props: {
    options: Array,
    default: String,
    keydef: String
  },
  computed: {
    getDate() {
      const date = this.selectedDate ? this.selectedDate.getDate() < 10 ? '0' + this.selectedDate.getDate() : this.selectedDate.getDate() : null
      const month = this.selectedDate ? (this.selectedDate.getMonth() + 1) < 10 ? '0' + (this.selectedDate.getMonth() + 1) : (this.selectedDate.getMonth() + 1) : null
      const year = this.selectedDate ? this.selectedDate.getFullYear() : null
      return date && month && year ? date + '.' + month + '.' + year : 'Дата публикации'
    }
  },
  watch: {
    selectedDate(val) {
      this.$emit('date', val)
      this.select = false
    }
  },
  data() {
    return {
      select: false,
      selectedDate: ''
    }
  },
  methods: {
    closeMenu(e) {
      if (!e.target.closest('.calendar')) this.select = false
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.calendar {
  @include box(100%, auto, relative, null, null, null, null);
  outline: none;
  border-radius: 0.2vw;
  border: 0.04vw solid #d0c7c2;
  user-select: none;
  .title {
    @include box(100%, 2.5vw, relative, null, null, null, null);
    @include flex(center, center, row);
    cursor: pointer;
    .name {
      @include box(100%, auto, relative, null, null, null, null);
      padding: 0;
      font-size: 0.9vw;
      color: rgba(44, 42, 41, 0.7);
    }
    .icon {
      @include box(2.5vw, auto, relative, null, null, null, null);
      @include flex(center, center, row);
      flex-shrink: 0;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .options {
    @include box(100%, auto, absolute, 0, 100%, null, null);
    @include flex(left, top, column);
    border-radius: 0.2vw;
    border: 0.04vw solid #d0c7c2;
    background-color: #fff;
    z-index: 10;
    overflow: hidden;
    div {
      @include box(100%, auto, relative, null, null, null, null);
      padding: 0.6vw 1vw;
      font-size: 1vw;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.7;
        background-color: rgba(238, 238, 238, 0.6);
      }
      &.active {
        background-color: #eee;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .calendar {
    @include box(100%, 5vw, relative, null, null, null, null);
    border-radius: 0.4vw;
    border: 0.04vw solid #d0c7c2;
    .title {
      @include box(100%, 5vw, relative, null, null, null, null);
      .name {
        padding: 0 2vw;
        font-size: 1.8vw;
      }
      .icon {
        @include box(5vw, auto, relative, null, null, null, null);
      }
    }
    .options {
      @include box(100%, auto, absolute, 0, 100%, null, null);
      border-radius: 0.4vw;
      margin-top: 1vw;
      div {
        padding: 1vw 2vw;
        font-size: 2vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .calendar {
    @include box(100%, 12vw, relative, null, null, null, null);
    border-radius: 0.8vw;
    border: 0.08vw solid #d0c7c2;
    .title {
      @include box(100%, 12vw, relative, null, null, null, null);
      .name {
        padding: 0 4vw;
        font-size: 4vw;
      }
      .icon {
        @include box(10vw, auto, relative, null, null, null, null);
      }
    }
    .options {
      border-radius: 0.8vw;
      margin-top: 2vw;
      div {
        padding: 3vw 4vw;
        font-size: 4vw;
      }
    }
  }
}
</style>
