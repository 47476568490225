<template>
    <div class="posterIndex">
        <div class="container cont">
            <div class="row min">
                <Sidebar :types="typesSidebar" @settype="setNewContent" />
            </div>
            <div class="row max" ref="content">
                <Items :content="typesSidebar" />
                <Documents :items="JSON.parse(allFiles)" />
                <Pagination
                    :pages="totalCount"
                    :current="Number(page)"
                    :per-page="perPage"
                    @setpage="setPageAction"
                    class="pagination"
                    v-if="totalCount > perPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/views/standardization/index/Sidebar.vue'
import Items from '@/components/views/standardization/index/Items.vue'
import Pagination from '@/components/Pagination.vue'
import Documents from "../index/Documents";

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'ContentStandIndex',
    components: {
        Sidebar,
        Items,
        Pagination,
        Documents
    },
    props: {
        contentProp: String,
        file: String,
    },
    data() {
        return {
            typesSidebar: [],
            allFiles: '[]',
            contentData: 'njknjdkfvnj',
        }
    },
    computed: {
        ...mapGetters('standardization', [
            'getType',
        ]),
        ...mapState('standardization', [
            'content',
            'items',
            'types',
            'perPage',
            'page',
            'totalCount'
        ])
    },
    methods: {
        ...mapActions('standardization', [
            'setType',
            'setPage',
            'getApi',
            'setContent',
            'setSubtype',
        ]),
        setPageAction(page) {
            this.$router.push({ name: 'DocumentsIndex', params: { type: this.getType.type }, query: { page: page } })
            this.setPage(page)
            this.getApi({})
        },
        setNewContent(data) {
            this.setContent(data);
            this.setSubtype(data);
            const link = this.$refs.content;
            if (link) {
                const pos = link.getBoundingClientRect();
                let offset = 150;
                if (window.innerWidth > 3000) {
                    offset = 400;
                }
                this.type = data;
                window.scrollTo({
                    top: (window.scrollY + pos.top) - offset,
                    behavior: "smooth"
                });
            }
        },
        setTypesSidebar(data) {
            const types = [];
            let index = 1;
            this.setSubtype('type_1');
            data.forEach((element) => {
                const selected = index === 1 ? true : false;
                if (selected === true) {
                    this.setContent(element.content);
                }
                types.push({
                    type: 'type_' + index,
                    name: element.menu_title,
                    select: selected,
                    content: element.content,
                    header: element.header,
                    files: element.files,
                });
                index++;
            });
            return types;
        }
    },
    mounted() {
        this.typesSidebar = this.setTypesSidebar(JSON.parse(this.contentProp));
        this.allFiles = this.file
    }
}
</script>

<style lang="scss" scoped>
.posterIndex {
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, column);
    padding: 2vw 0;
    .container {
        width: 100%;
        @include flex(left, top, row);
        .row {
            &.min {
                @include box(22%, auto, sticky, null, 7vw, null, null);
            }
            &.max {
                @include box(78%, auto, relative, null, null, null, null);
                padding-top: 0.5vw;
                .top {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(left, top, column);
                    .title {
                        font-size: 2vw;
                        padding-left: 2vw;
                        font-weight: 700;
                    }
                }
                .pagination {
                    padding: 2vw 2vw;
                }
                &.mobile {
                    display: none;
                    .top {
                        display: flex;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    .posterIndex {
        padding-bottom: 0;
        .breadcrumbs {
            padding: 4vw 4.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 3vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        display: none;
                        .title {
                            font-size: 3vw;
                            padding-left: 1.5vw;
                            padding-bottom: 2vw;
                        }
                    }
                    .pagination {
                        padding: 4vw 3vw;
                    }
                    &.mobile {
                        display: flex;
                        .top {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .posterIndex {
        .breadcrumbs {
            padding: 6vw 6.5vw;
        }
        .container {
            @include flex(left, top, column);
            padding: 0 6vw;
            .row {
                &.min {
                    @include box(100%, auto, relative, null, null, null, null);
                }
                &.max {
                    @include box(100%, auto, relative, null, null, null, null);
                    .top {
                        .title {
                            font-size: 8vw;
                            padding-left: 1vw;
                        }
                    }
                    .pagination {
                        padding: 6vw 1vw;
                    }
                }
            }
        }
    }
}
</style>
