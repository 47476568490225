<template>
  <div id="app">
    <router-view />
    <div class="app-tooltip" ref="tooltip" />
    <SvgStatic />
  </div>
</template>

<script>
import SvgStatic from '@/components/views/mfcCabinet/SvgStatic'
import { mapActions } from 'vuex'

const TABLET = 600
const TABLET_NORMAL = 768
const DESKTOP = 900
const DESKTOP_NORMAL = 1024

export default {
  components: { SvgStatic },
  data() {
    return {
      deviceSize: {
        mobile: false,
        mobileNormal: false,
        tablet: false,
        tabletNormal: false,
        desktop: true,
        desktopNormal: true,
      }
    }
  },
  mounted() {
    this.setDeviceSize()
    window.addEventListener('resize', this.setDeviceSize)
    this.getProfile()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDeviceSize)
  },
  methods: {
    ...mapActions('mfcCabinet', [
      'getProfile'
    ]),
    setDeviceSize() {
      const { innerWidth } = window

      this.deviceSize.mobile = innerWidth < TABLET
      this.deviceSize.tablet = TABLET <= innerWidth && innerWidth < DESKTOP
      this.deviceSize.desktop = DESKTOP <= innerWidth

      this.deviceSize.mobileNormal = innerWidth < TABLET_NORMAL
      this.deviceSize.tabletNormal = TABLET_NORMAL <= innerWidth && innerWidth < DESKTOP_NORMAL
      this.deviceSize.desktopNormal = DESKTOP_NORMAL <= innerWidth
    },
    showAppTooltip(text, e) {
      if (this.deviceSize.desktop) {
        const { tooltip } = this.$refs
        const { style } = tooltip

        if (!style.display) {
          const { bottom, left, width } = e.target.getBoundingClientRect()

          tooltip.innerHTML = text

          style.top = 8 + bottom + window.scrollY + 'px'
          style.left = left + width / 2 - tooltip.getBoundingClientRect().width / 2 + 'px'
          style.opacity = '1'
          style.zIndex = 3000
        }
      }
    },
    closeAppTooltip() {
      if (this.deviceSize.desktop) {
        this.$refs.tooltip.style.opacity = '0'
      }
    }
  },
  provide() {
    return {
      deviceSize: this.deviceSize,
      showAppTooltip: this.showAppTooltip,
      closeAppTooltip: this.closeAppTooltip
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  background-color: #fff;

  * {
    box-sizing: border-box;
    letter-spacing: 0.01vw;
  }

  a {
    text-decoration: none;
    color: #2c2a29;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c2a29;
    min-height: 100vh;
  }

  .app-tooltip {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    color: white;
    font-size: 14px;
    line-height: 20px;
    min-height: 40px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s;

    &::before {
      content: url(assets/tooltip/arrow-smooth.svg);
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.practicesView,
.contentModels {
  ul,
  ol {
    margin: 0;
    padding: 0;

    li {
      @include box(100%, auto, relative, null, null, null, null);
      list-style: none;
      margin: 1.5vw 0;
      padding-left: 3vw;

      &:before {
        content: "";
        background-image: url("./assets/check1.png");
        background-size: cover;
        @include box(1.3vw, 1.3vw, absolute, 0, 0.1vw, null, null);
      }
    }
  }

  @include olCounterStart(start, 100);

  ol {
    li {
      counter-increment: start;

      &:before {
        content: counter(start);
        @include flex(center, center, row);
        background-image: none;
        background-color: #e04e39;
        @include box(1.3vw, 1.3vw, absolute, 0, 0, null, null);
        color: #fff;
        font-size: 0.8vw;
        border-radius: 0.2vw;
      }
    }
  }
}

.special-images,
.special-audio {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .practicesView,
  .contentModels {
    ul,
    ol {
      li {
        margin: 3vw 0;
        padding-left: 8vw;

        &:before {
          @include box(4vw, 4vw, absolute, 0, 0.1vw, null, null);
        }
      }
    }

    ol {
      li {
        &:before {
          @include box(4vw, 4vw, absolute, 0, 0.1vw, null, null);
          font-size: 2vw;
          border-radius: 0.4vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .practicesView,
  .contentModels {
    ul,
    ol {
      li {
        margin: 5vw 0;
        padding-left: 13vw;

        &:before {
          @include box(6vw, 6vw, absolute, 0, 0.1vw, null, null);
        }
      }
    }

    ol {
      li {
        &:before {
          @include box(6vw, 6vw, absolute, 0, 0.1vw, null, null);
          font-size: 4vw;
          border-radius: 0.8vw;
        }
      }
    }
  }
}
</style>
