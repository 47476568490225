<template>
  <div class="category">
    <div class="block" v-for="item in types" :key="item.type">
      <span class="link" :class="{active: type === item.type}" @click="setType(item)">{{item.name}}</span>
    </div>
    <div class="select">
      <div class="title" @click="select = select ? false : true">
        <div class="name">{{type ? getNameType : 'Выберите категорию'}}</div>
        <div class="icon">
          <ArrowDownIcon />
        </div>
      </div>
      <div class="popup" v-if="select">
        <div class="option" v-for="item in types" :key="item.type" :class="{active: type === item.type}" @click="setType(item)">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'

export default {
  name: 'Category',
  components: {
    ArrowDownIcon
  },
  props: {
    types: Array,
    type: String,
  },
  computed: {
    getNameType() {
      return this.types.filter(item => this.type === item.type)[0]?.name
    }
  },
  data() {
    return {
      select: false,
    }
  },
  methods: {
    setType(type) {
      this.select = false
      this.$emit('set', type.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.category{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .block{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    padding-bottom: 1vw;
    .link{
      font-size: 1vw;
      color: #E04E39;
      cursor: pointer;
      transition: 0.2s;
      &:hover{
        opacity: 0.5;
      }
      &.active{
        opacity: 1;
        color: #2C2A29;
        cursor: default;
      }
    }
  }
  .select{
    @include box(100%, 12vw, relative, null, null, null, null);
    outline: none;
    border-radius: 1vw;
    border: 0.04vw solid #D0C7C2;
    display: none;
    .title{
      @include box(100%, 12vw, relative, null, null, null, null);
      @include flex(center, center, row);
      .name{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 0 4vw;
        font-size: 4vw;
        color: rgba(44, 42, 41, 0.7);
      }
      .icon{
        @include box(14vw, auto, relative, null, null, null, null);
        @include flex(center, center, row);
        flex-shrink: 0;
      }
    }
    .popup{
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, column);
      border-radius: 1vw;
      border: 0.04vw solid #D0C7C2;
      margin-top: 2vw;
      background-color: #FFF;
      z-index: 10;
      overflow: hidden;
      div{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 3vw 4vw;
        font-size: 4vw;
        &.active{
          background-color: #EEE;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .category{
    .block{
      padding-bottom: 2vw;
      .link{
        font-size: 2vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .category{
    .block{
      display: none;
      padding-bottom: 4vw;
      .link{
        font-size: 4vw;
      }
    }
    .select{
      display: block;
    }
  }
}
</style>
