<template>
  <svg class="telegramIcon2" :style="{ backgroundColor: bgColor }" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" d="M26.7763 14.1859L17.8679 22.4663C17.5549 22.7573 17.3527 23.1479 17.2955 23.5714L16.9923 25.8199C16.952 26.1199 16.5298 26.1501 16.4471 25.859L15.28 21.7587C15.1468 21.2907 15.341 20.7911 15.7553 20.536L26.5493 13.8875C26.743 13.7683 26.9429 14.0309 26.7763 14.1859ZM40 0V40H0V0H40ZM31.0986 9.61004L8.43213 18.354C7.87334 18.5696 7.87763 19.3618 8.43872 19.5713L13.9615 21.6329L16.0994 28.5079C16.2364 28.9481 16.7751 29.1105 17.1324 28.8191L20.2111 26.3093C20.5338 26.0462 20.993 26.0334 21.33 26.278L26.8831 30.3097C27.2653 30.5873 27.8068 30.3779 27.9029 29.9154L31.9702 10.3497C32.0752 9.8451 31.5796 9.42444 31.0986 9.61004Z"/>
  </svg>
</template>

<script>
export default {
  name: "TelegramIcon2",
  props: {
    fill: {type: String, default: '#F6F2EC'},
    bgColor: {type: String, default: '#E9D7C1'},
  },
}
</script>

<style lang="scss" scoped>
.telegramIcon2 {
  width: 100%;
  height: auto;
}
/*@media only screen and (max-width: 900px) {
  .telegramIcon2 {
    @include box(7vw, 7vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .telegramIcon2 {
    @include box(10vw, 10vw, relative, null, null, null, null);
  }
}*/
</style>
