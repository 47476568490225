<template>
  <div class="header">
    <Breadcrumbs class="breadcrumbs" :pages="[{ name: pageName, route: routePath }]" :white="whiteText" />
    <div class="container" :class="{ 'white--text': whiteText }">
      <div class="titles">
        <div class="header__title" v-html="title"></div>
        <div v-if="pageSubTitle" class="header__subtitle" v-html="pageSubTitle"></div>
      </div>
      <div class="header__content">
        <slot></slot>
      </div>
    </div>
    <Picture v-if="images.length" :images="images" :overlay="bannerOverlay" class="header__banner"/>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Picture from "@/components/shared/Picture";

export default {
  name: 'PageHeader',
  components: {
    Breadcrumbs,
    Picture,
  },
  props: {
    pageName: {type: String, required: true},
    pageTitle: {type: String, default: ''},
    headerTitle: {type: String, default: ''},
    pageSubTitle: {type: String, default: ''},
    routePath: {type: String, default: '/'},
    assets: {type: String, default: ''},
    whiteText: {type: Boolean, default: false},
    bannerOverlay: {type: Boolean, default: false},
  },
  data() {
    return {

    }
  },
  computed: {
    images() {
      return this.assets ? [
        {name: `${this.assets}/banner-mobile.png`, maxWidth: '600px'},
        {name: `${this.assets}/banner-md.png`, minWidth: '600px', maxWidth: '900px'},
        {name: `${this.assets}/banner-desktop.png`, minWidth: '900px', default: true, alt: this.title},
      ] : [];
    },
    title() {
      return this.headerTitle || this.pageTitle;
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.header {
  @include box(100%, 26vw, relative, null, null, null, null);
  @include flex(left, top, column);
  overflow: hidden;
  .breadcrumbs {
    padding-top: 2vw;
    z-index: 10;
  }
  &__banner {
    @include box(100%, auto, absolute, 0, 0, null, null);
    z-index: -1;
    overflow: hidden;

    //opacity: 0.3; //test!
  }
  .container {
    @include flex(left, top, row);
    height: 100%;
    z-index: 10;
    &.white--text {
      color: #FFF;
    }
  }
  .titles {
    width: 50%;
  }
  &__title {
    font-size: 2.3vw;
    padding-top: 3vw;
  }
  &__subtitle {
    font-size: 1vw;
    padding-top: 1vw;
  }
  &__content {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 900px) {
  .header {
    height: 30vw;
    .breadcrumbs {
      padding: 2vw 4vw;
    }
    .container {
      padding: 0 4vw;
      &.white--text {
        color: initial;
      }
    }
    .titles {

    }
    &__title {
      font-size: 3vw;
    }
    &__subtitle {
      font-size: 1.5vw;
    }
    &__banner {
      //top: 6.5vw;
    }
    &__content {
      justify-content: flex-end;
    }
  }
}
@media only screen and (max-width: 600px) {
  .header {
    height: auto;
    .breadcrumbs {
      padding: 6vw;
      &.white {
        color: inherit;
        /deep/ .link {
          color: inherit;
        }
      }
    }
    .container {
      flex-direction: column;
      position: absolute;
      margin-top: 30px;
    }
    .titles {
      width: 100%;
    }
    &__title {
      font-size: 8vw;
      width: 100%;
      padding: 12vw 2vw;
    }
    &__subtitle {
      width: 100%;
      font-size: 1.2vh;
      padding: 0 2vw;
    }
    &__banner {
      position: relative;
    }
    &__content {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
