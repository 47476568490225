<template>
  <div class="map">
    <div class="container">
      <div class="inputs">
        <Input label="Найти МФЦ" maxlength="30" @keyup="routeSearch" />
      </div>
      <div class="mapBlock">
        <Russia @setregion="setRegionMove" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/elements/Input.vue'
import Russia from '@/components/elements/Russia.vue'

import {mapActions} from 'vuex'

export default {
  name: 'Map',
  components: {
    Input,
    Russia
  },
  methods: {
    ...mapActions('mfc', [
      'setSearch',
      'setRegion',
      'getApiRegion'
    ]),
    setRegionMove(val) {
      this.setRegion(val)
      this.$router.push({ name: 'MfcIndex' })
    },
    async routeSearch(e) {
      if (e.keyCode === 13) {
        this.setSearch(e.target.value)
        await this.$router.push({ name: 'Search', query: { search: e.target.value } })
      }
    },
  },
  mounted() {
    this.getApiRegion()
  }
}
</script>

<style lang="scss" scoped>
.map{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(center, top, row);
  padding: 2vw;
  padding-bottom: 0;
  .container{
    @include flex(center, center, column);
    .inputs{
      @include box(70%, auto, relative, null, null, null, null);
      margin: 0 auto;
    }
    .mapBlock{
      @include box(100%, auto, relative, null, null, null, null);
      margin-top: 2vw;
    }
  }
}
@media only screen and (max-width: 900px) {
  .map{
    padding: 0 2vw;
    .container{
      .inputs{
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .map{
    padding: 0 6vw;
    .container{
      .inputs{
        @include box(100%, auto, relative, null, null, null, null);
      }
    }
  }
}
</style>
