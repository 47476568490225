<template>
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mapIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7C14 12 7 20 7 20C7 20 0 12 0 7ZM4.5 7C4.5 8.381 5.619 9.5 7 9.5C8.381 9.5 9.5 8.381 9.5 7C9.5 5.619 8.381 4.5 7 4.5C5.619 4.5 4.5 5.619 4.5 7Z" fill="#E04E39"/>
  </svg>
</template>

<script>
  export default {
    name: "List"
  }
</script>

<style lang="scss" scoped>
.mapIcon {
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .mapIcon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .mapIcon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
