<template>
  <div class="news">
    <div class="container">
      <div class="title">Новости МФЦ</div>
      <div class="rows">
        <div class="row">
          <router-link
            :to="{ name: 'NewsView', params: { id: item.id }}"
            class="item"
            v-for="item in getFirstNews"
            :key="item.id"
          >
            <div class="image">
              <img :src="item.anons_photo" />
            </div>
            <div class="bottom">
              <div class="name" >{{item.title}}</div>
              <div class="date">
                <span>{{getDate(item.date)}}</span>
                <span>Новости МФЦ</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="row">
          <div class="items">
            <div class="item" v-for="item in getAllNews" :key="item.id">
              <router-link
                :to="{ name: 'NewsView', params: { id: item.id }}"
                class="name"
              >{{item.title}}</router-link>
              <div class="date">
                <span>{{getDate(item.date)}}</span>
                <span>Новости МФЦ</span>
              </div>
            </div>
          </div>
          <div class="buttons">
            <Button label="Все новости" to="/news" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import {mapState, mapActions, mapGetters} from 'vuex';

export default {
  name: 'Slider',
  components: {
    Button
  },
  computed: {
    ...mapState('news', [
      'groups',
      'news',
      'regions'
    ]),
    ...mapGetters('news', [
      'getNewsSortedByDate',
    ]),
    getFirstNews() {
      return this.getNewsSortedByDate.filter((item, i) => i < 1 && item);
    },
    getAllNews() {
      let count = 4
      if (window.innerWidth < 1000 && window.innerWidth > 600) {
        count = 3
      }
      return this.getNewsSortedByDate.filter((item, i) => i > 0 && i < count && item);
    },
  },
  methods: {
    ...mapActions('news', [
      'getApi',
    ]),
    getDate(date) {
      return new Date(date * 1000).toLocaleString("ru", { year: 'numeric', month: 'long', day: 'numeric' })
    },
  },
  mounted() {
    this.getApi({})
    this.getStyle()
  }
}
</script>

<style lang="scss" scoped>
.news {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, row);
  padding: 2vw;
  .container {
    @include flex(left, top, column);
    .title {
      @include box(100%, auto, relative, null, null, null, null);
      text-align: center;
      font-size: 1.6vw;
      font-weight: 700;
      padding-bottom: 2vw;
    }
    .rows {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, top, row);
      padding: 2vw 0;
      .row {
        @include box(auto, auto, relative, null, null, null, null);
        &:nth-child(1) {
          padding-right: 1vw;
        }
        &:nth-child(2) {
          padding-left: 1vw;
        }
        .item {
          .image {
            @include box(100%, 20vw, relative, null, null, null, null);
            overflow: hidden;
            border-radius: 0.3vw;
            &:after {
              @include box(100%, 100%, absolute, 0, 0, null, null);
              content: "";
              background-color: rgba(0, 0, 0, 0.4);
            }
            img {
              @include box(100%, auto, relative, null, null, null, null);
              min-height: 100%;
              transition: 0.2s;
            }
          }
          .bottom {
            @include box(100%, auto, absolute, 0, null, null, 0);
            padding: 1.5vw;
            .name {
              color: #fff;
              font-size: 1vw;
            }
            .date {
              color: #fff;
              font-size: 0.8vw;
              padding-top: 1vw;
              span {
                margin-right: 1vw;
              }
            }
          }
          &:hover {
            .image {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
        .items {
          @include box(100%, auto, relative, null, null, null, null);
          .item {
            @include box(100%, auto, relative, null, null, null, null);
            padding: 1.5vw 0;
            border-bottom: 0.1vw solid #e9d7c1;
            .name {
              font-size: 1vw;
              color: #e04e39;
            }
            .date {
              font-size: 0.8vw;
              padding-top: 0.7vw;
              color: rgba(44, 42, 41, 0.7);
              span {
                margin-right: 1vw;
              }
            }
            &:nth-child(1) {
              padding-top: 0;
            }
            &:nth-child(3) {
              border-bottom: 0;
            }
          }
        }
        .buttons {
          @include box(30%, auto, relative, null, null, null, null);
          padding-top: 0.7vw;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .news {
    padding: 2vw;
    .container {
      .title {
        font-size: 3.4vw;
        padding-bottom: 2vw;
      }
      .rows {
        padding: 2vw 0;
        .row {
          &:nth-child(1) {
            padding-right: 2vw;
          }
          &:nth-child(2) {
            padding-left: 2vw;
          }
          .item {
            .image {
              @include box(100%, 27vw, relative, null, null, null, null);
              border-radius: 0.3vw;
            }
            .bottom {
              padding: 3vw;
              .name {
                font-size: 2vw;
              }
              .date {
                font-size: 1.8vw;
                padding-top: 1.5vw;
                span {
                  margin-right: 1vw;
                }
              }
            }
          }
          .items {
            .item {
              padding: 2.8vw 0;
              border-bottom: 0.4vw solid #e9d7c1;
              .name {
                font-size: 2vw;
              }
              .date {
                font-size: 1.8vw;
                padding-top: 1vw;
                span {
                  margin-right: 1vw;
                }
              }
              &:nth-child(1) {
                padding-top: 0;
              }
              &:nth-child(2) {
                border-bottom: 0;
              }
              &:nth-child(3) {
                border-bottom: 0;
              }
            }
          }
          .buttons {
            @include box(40%, auto, relative, null, null, null, null);
            padding-top: 1vw;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .news {
    padding: 4vw 6vw;
    .container {
      .title {
        font-size: 6.6vw;
        padding-bottom: 6vw;
      }
      .rows {
        padding: 2vw 0;
        @include flex(left, top, column);
        .row {
          @include box(100%, auto, relative, null, null, null, null);
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2) {
            padding-left: 0;
          }
          .item {
            .image {
              @include box(100%, 60vw, relative, null, null, null, null);
              border-radius: 0.8vw;
            }
            .bottom {
              padding: 5vw;
              .name {
                font-size: 4vw;
              }
              .date {
                font-size: 3.5vw;
                padding-top: 2vw;
                span {
                  margin-right: 4vw;
                }
              }
            }
          }
          .items {
            .item {
              padding: 4vw 0;
              border-bottom: 0.4vw solid #e9d7c1;
              .name {
                font-size: 4vw;
              }
              .date {
                font-size: 3.5vw;
                padding-top: 4vw;
                span {
                  margin-right: 1vw;
                }
              }
              &:nth-child(1) {
                padding-top: 4vw;
              }
              &:nth-child(2) {
                border-bottom: 0.4vw solid #e9d7c1;
              }
              &:nth-child(3) {
                border-bottom: 0;
              }
            }
          }
          .buttons {
            @include box(100%, auto, relative, null, null, null, null);
            padding-top: 4vw;
          }
        }
      }
    }
  }
}
</style>
