import { render, staticRenderFns } from "./Bug.vue?vue&type=template&id=0276e031&scoped=true&"
import script from "./Bug.vue?vue&type=script&lang=js&"
export * from "./Bug.vue?vue&type=script&lang=js&"
import style0 from "./Bug.vue?vue&type=style&index=0&id=0276e031&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0276e031",
  null
  
)

export default component.exports