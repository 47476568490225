<template>
  <div class="search">
    <div class="container">
      <Input :search="search" @clear="setSearchAction" @set="setSearchAction" @enter="setEnter" />
    </div>
    <div class="container cont">
      <div class="row min">
        <Category :types="types" :type="type" @set="setTypeAction" />
      </div>
      <div class="row max">
        <Items :items="items" :totalCount="totalCount" />
        <br />
        <Pagination
          :per-page="perPage"
          :pages="totalCount"
          :current="page"
          @setpage="setPageAction"
          class="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/search/Input.vue'
import Category from '@/components/views/search/Category.vue'
import Items from '@/components/views/search/Items.vue'

import { mapActions, mapState } from 'vuex';
import Pagination from "@/components/Pagination";

export default {
  name: 'Search',
  components: {
    Pagination,
    Input,
    Category,
    Items
  },
  computed: {
    ...mapState('search', [
      'search',
      'types',
      'type',
      'items',
        'page',
        'perPage',
        'totalCount'
    ])
  },
  methods: {
    ...mapActions('search', [
      'setSearch',
      'setPage',
      'setType',
      'getApi',
    ]),
    setTypeAction(type) {
      this.$router.push({ name: 'Search', params: { type: type }, query: { page: '1', search:this.search }})
      this.setType(type);
      this.setPage(1)
    },
    setPageAction(page) {
      this.$router.push({ name: 'Search', params: { type: this.type }, query: { page: page, search:this.search }})
      this.setPage(page)
    },
    setSearchAction(search) {
      this.$router.push({ name: 'Search', params: { type: this.type }, query: { page: '1', search: search }})
      this.setPage(1)
      this.setSearch(search)
    },
    setEnter() {

      this.$router.push({ name: 'Search', params: {type: this.type }, query: { search: this.search, page: '1' } })
    }
  },
  mounted() {
    this.setSearch(this.$route.query.search)
  },
  watch: {
    search() {
      this.getApi({})
    },
    type() {
      this.getApi({})
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding: 2vw 0;
  .container {
    @include flex(left, top, row);
    &.cont {
      padding-top: 2vw;
    }
    .row {
      &.min {
        @include box(22%, auto, relative, null, null, null, null);
      }
      &.max {
        @include box(78%, auto, relative, null, null, null, null);
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .search {
    padding: 3vw;
    .container {
      &.cont {
        padding-top: 2vw;
      }
      .row {
        &.min {
          @include box(22%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(78%, auto, relative, null, null, null, null);
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .search {
    padding: 6vw;
    .container {
      @include flex(left, top, column);
      &.cont {
        padding-top: 6vw;
      }
      .row {
        &.min {
          @include box(100%, auto, relative, null, null, null, null);
        }
        &.max {
          @include box(100%, auto, relative, null, null, null, null);
        }
      }
    }
  }
}
</style>
