<template>
    <svg width="82" height="96" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <path fill="white" d="M 25.00,44.27
            C 24.92,44.27 24.84,44.26 24.78,44.20
            24.78,44.20 17.73,39.92 17.73,39.92
            15.41,38.57 13.73,35.80 13.59,33.02
            13.59,33.00 13.59,33.00 13.59,32.99
            13.59,32.99 13.59,22.09 13.59,22.09
            13.59,21.79 13.80,21.56 14.06,21.56
            14.33,21.56 14.53,21.79 14.53,22.09
            14.53,22.09 14.53,32.97 14.53,32.97
            14.66,35.38 16.11,37.80 18.17,38.97
            18.17,38.97 24.53,42.88 24.53,42.88
            24.53,42.88 24.53,36.77 24.53,36.77
            24.53,36.77 20.34,33.97 20.34,33.97
            20.34,33.97 20.33,33.97 20.33,33.95
            19.84,33.60 19.58,32.99 19.56,32.11
            19.56,32.11 19.56,26.48 19.56,26.48
            19.56,26.18 19.77,25.95 20.03,25.95
            20.30,25.95 20.50,26.18 20.50,26.48
            20.50,26.48 20.50,32.09 20.50,32.09
            20.52,32.81 20.73,32.99 20.83,33.06
            20.83,33.06 25.25,36.00 25.25,36.00
            25.39,36.09 25.48,36.26 25.48,36.45
            25.48,36.45 25.48,43.75 25.48,43.75
            25.48,43.94 25.39,44.10 25.25,44.20
            25.17,44.26 25.08,44.27 25.00,44.27 Z"/>
        <path fill="white" d="M 25.00,41.04
             C 24.92,41.04 24.83,41.02 24.77,40.97
             24.62,40.88 24.53,40.70 24.53,40.51
             24.53,40.51 24.53,33.23 24.53,33.23
             24.53,33.04 24.62,32.88 24.77,32.78
             24.77,32.78 29.19,29.80 29.19,29.80
             29.25,29.75 29.48,29.57 29.50,28.84
             29.50,28.84 29.50,19.09 29.50,19.09
             29.50,19.09 27.69,19.09 27.69,19.09
             27.42,19.09 27.22,18.87 27.22,18.57
             27.22,18.27 27.42,18.04 27.69,18.04
             27.69,18.04 29.97,18.04 29.97,18.04
             30.23,18.04 30.44,18.27 30.44,18.57
             30.44,18.57 30.44,28.86 30.44,28.86
             30.42,29.73 30.16,30.36 29.67,30.71
             29.67,30.71 25.47,33.53 25.47,33.53
             25.47,33.53 25.47,39.64 25.47,39.64
             25.47,39.64 31.83,35.77 31.83,35.77
             33.89,34.58 35.34,32.16 35.47,29.75
             35.47,29.75 35.47,12.77 35.47,12.77
             35.47,12.77 25.06,12.77 25.06,12.77
             24.80,12.77 24.59,12.55 24.59,12.25
             24.59,11.95 24.80,11.72 25.06,11.72
             25.06,11.72 35.94,11.72 35.94,11.72
             36.20,11.72 36.41,11.95 36.41,12.25
             36.41,12.25 36.41,29.77 36.41,29.77
             36.41,29.78 36.41,29.78 36.41,29.80
             36.27,32.57 34.61,35.35 32.27,36.70
             32.27,36.70 25.23,40.98 25.23,40.98
             25.16,41.02 25.08,41.04 25.00,41.04 Z"/>
        <path fill="white" d="M 14.06,22.63
           C 13.80,22.63 13.59,22.40 13.59,22.10
             13.59,22.10 13.59,21.35 13.59,21.35
             13.59,21.21 13.64,21.07 13.75,20.96
             13.75,20.96 22.52,11.85 22.52,11.85
             22.61,11.76 22.72,11.71 22.83,11.71
             22.83,11.71 25.06,11.71 25.06,11.71
             25.33,11.71 25.53,11.94 25.53,12.23
             25.53,12.53 25.33,12.76 25.06,12.76
             25.06,12.76 23.02,12.76 23.02,12.76
             23.02,12.76 14.53,21.59 14.53,21.59
             14.53,21.59 14.53,22.12 14.53,22.12
             14.53,22.40 14.33,22.63 14.06,22.63 Z" />
        <path fill="white" d="M 23.66,22.61
           C 23.66,22.61 17.03,22.61 17.03,22.61
             16.77,22.61 16.56,22.38 16.56,22.08
             16.56,21.79 16.77,21.56 17.03,21.56
             17.03,21.56 23.19,21.56 23.19,21.56
             23.19,21.56 23.19,15.43 23.19,15.43
             23.19,15.14 23.39,14.91 23.66,14.91
             23.92,14.91 24.12,15.14 24.12,15.43
             24.12,15.43 24.12,22.10 24.12,22.10
             24.12,22.38 23.91,22.61 23.66,22.61 Z" />
    </svg>
</template>

<script>
    export default {
        name: "LogoMFCWhite"
    }
</script>

<style scoped>

</style>