<template>
  <div class="date-input__container date-input">
    <div class="date-input__label" @click="isMenuShown = !isMenuShown">
      <div class="icon">
        <CalendarIcon />
      </div>
      <div class="name">{{ getDate }}</div>
    </div>
    <div class="options" v-if="isMenuShown">
      <DatePicker class="date-picker" v-model="selectedDate" />
    </div>
  </div>
</template>

<script>
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'

export default {
  name: 'DateInput',
  components: {
    CalendarIcon,
    DatePicker
  },
  props: {
    value: String,
    titlePrefix: String,
  },
  data() {
    return {
      isMenuShown: false,
      selectedDate: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
    }
  },
  computed: {
    getDate() {
      const selectedDate = this.value
        ? moment(this.value, 'YYYY-MM-DD').format('DD.MM.YYYY')
        : ''
      return this.titlePrefix + selectedDate
    }
  },
  watch: {
    selectedDate(value) {
      this.$emit('update:value', moment(value).format('YYYY-MM-DD'))
      this.isMenuShown = false
    }
  },
  methods: {
    closeMenu(e) {
      if (!e.target.closest('.date-input')) this.isMenuShown = false
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
.date-input__container {
  margin-bottom: 16px;
}
.date-input__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px 0 12px;
  border: 1px solid #d0c7c2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
  width: 100%;
  font-size: 14px;
  color: rgba(44, 42, 41, 0.7);
  cursor: pointer;
  transition: 300ms;
}
.options {
  position: relative;
}
.date-picker {
  z-index: 10;
  position: fixed;
}
</style>
