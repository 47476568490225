import moment from 'moment'

export default function (timestamp) {
    if (moment().diff(moment(timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') < 1) {
        return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('Сегодня, HH:mm')
    } else if (moment().diff(moment(timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') === 1) {
        return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('Вчера, HH:mm')
    } else {
        return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm')
    }
}