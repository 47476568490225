<template>
  <div>
    <div v-for="subPage in subPages" :key="subPage.type" @click="setCurrentSubPage(subPage)">
      <a :class="{ active: isActive(subPage) }" class="link">{{ subPage.name }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SubPageNav',
  computed: {
    ...mapGetters('practices', [
      'currentSubPage',
      'subPages'
    ])
  },
  methods: {
    ...mapMutations('practices', [
      'setCurrentSubPage',
    ]),
    isActive(subPage) {
      return this.currentSubPage?.type === subPage.type
    }
  },
  created() {
    if (!this.currentSubPage) this.setCurrentSubPage(this.subPages[0])
  }
}
</script>

<style lang="scss" scoped>
.link {
  font-size: 1vw;
  margin-bottom: 1vw;
  color: #e04e39;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &.active {
    color: #2c2a29;
    cursor: pointer;
  }
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}
</style>
