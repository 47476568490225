<template>
  <div class="types">
    <router-link :to="{ name: 'NewsIndex' }" class="link" v-if="!bottom">
      <ArrowLeft />
      <span>К списку новостей</span>
    </router-link>
    <div class="groups">
<!--        <a class="group" v-for="type in types" :key="type" :href="type" target="_blank"  >{{ type }}</a>-->
            <div class="group" v-for="type in types" :key="type" @click="hashtagClickHandler(type)">{{ type }}</div>
    </div>
    <router-link :to="{ name: 'NewsIndex'}" class="link bottom" v-if="bottom">
      <ArrowLeft />
      <span>К списку новостей</span>
    </router-link>
  </div>
</template>

<script>
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import {mapActions} from "vuex";

export default {
  name: 'Types',
  components: {
    ArrowLeft
  },
  props: {
    bottom: Boolean,
    types: Array
  },
  methods: {
    ...mapActions('search', [
      'setSearch',
      'setPage',
      'setType',
    ]),
    async hashtagClickHandler(type) {
      const value = type.replace('#', '');
      this.setSearch(value);
      this.setType('news');
      this.setPage(1);
      await this.$router.push({ name: 'Search', params: { type: 'news' }, query: { page: '1', search: value } });
    },
  },
}
</script>

<style lang="scss" scoped>
.types{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .link{
    @include flex(left, center, row);
    font-size: 1.1vw;
    color: #E04E39;
    span{
      padding-left: 1vw;
    }
  }
  .groups{
    @include flex(left, top, column);
    margin-top: 1vw;
    .group{
      @include box(auto, auto, relative, null, null, null, null);
      margin-top: 0.5vw;
      border: 0.04vw solid #E9D7C1;
      padding: 0.3vw 0.7vw;
      border-radius: 0.4vw;
      font-size: 0.8vw;
      color: rgba(44, 42, 41, 0.7);
      cursor: pointer;
      &:hover {
        color: #333;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .types{
    padding: 0;
    padding-bottom: 2vw;
    .link{
      font-size: 2vw;
      span{
        padding-left: 1vw;
      }
      &.bottom{
        margin-top: 2vw;
      }
    }
    .groups{
      margin-top: 1vw;
      .group{
        margin-bottom: 2vw;
        border: 0.08vw solid #E9D7C1;
        padding: 1vw 2vw;
        border-radius: 0.4vw;
        font-size: 2vw;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .types{
    padding: 2vw 0;
    .link{
      font-size: 4vw;
      span{
        padding-left: 4vw;
      }
      &.bottom{
        margin-top: 6vw;
      }
    }
    .groups{
      margin-top: 3vw;
      .group{
        margin-bottom: 4vw;
        border: 0.04vw solid #E9D7C1;
        padding: 3vw 4vw;
        border-radius: 0.8vw;
        font-size: 4vw;
      }
    }
  }
}
</style>
