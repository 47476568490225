import {addPaginationFilters} from "./common";

export function getNewsQuery(region, dateFrom, dateTo, page, size) {
  const filter = addPaginationFilters([], page, size);
  filter.push('sort=-id&')
  if (region) {
    filter.push(`filter[region]=${region}&`);
  }
  if (dateFrom && dateTo) {
    //filter.push(`filter[date][lte]=${(dateFrom.getTime() / 1000) + 6*60*60}` + `&filter[date][gte]=${(dateTo.getTime() / 1000) - 6*60*60}`);
    filter.push(`filter[date][gte]=${dateFrom}` + `&filter[date][lte]=${dateTo}`)
  }
  return '/news?' + filter.join('&');
}
