<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="calendar">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2V0H5V2H13V0H15V2H16C17.1 2 18 2.9 18 4V18C18 19.1 17.1 20 16 20H2C0.9 20 0 19.1 0 18V4C0 2.9 0.9 2 2 2H3ZM16 7H2V18H16V7Z" fill="#2C2A29"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon',
}
</script>

<style lang="scss" scoped>
.calendar{
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .calendar{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .calendar{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
