<template>
  <div :class="['practice-button', {active}]" @mouseenter="showTooltip" @mouseleave="closeAppTooltip" @click="onClick">
      <slot/>
  </div>
</template>

<script>
export default {
    name: 'PracticeButton',
    props: {
        active: {
            type: Boolean,
            required: true
        },
        tooltip: {
            type: [String, null],
            default: null
        }
    },
    inject: ['showAppTooltip', 'closeAppTooltip'],
    methods: {
        showTooltip (e) {
            if (this.active && this.tooltip) {
                this.showAppTooltip(this.tooltip, e)
            }
        },
        onClick () {
            this.closeAppTooltip()
            this.$emit('onClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.practice-button {
    cursor: pointer;
    width: 44px;
    height: 44px;
    border: 2px solid #E9E5E3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    transition-property: background-color, transform;

    svg {
        width: 28px;
        height: 28px;

        path {
            transition: fill 0.3s;
        }
    }

    &:hover {
        transform: scale(0.95);
    }

    &.active {
        background-color: #E9E5E3;

        svg {
            path {
                fill: #E04E39;
            }
        }
    }
}
</style>
