<template>
  <div class="find-mfc">
    <select-bar
      :cities="cities"
      :regions="regions"
      :search="search"
      @setregion="setRegion"
      @setcity="setCity"
    />
    <Map :points="points" :regions="regions" @geolocation-success="setRegion" />
    <List v-if="true && changeBtn" />
  </div>
</template>

<script>
import SelectBar from "@/components/views/mfc/index/SelectBar";
import Map from "@/components/views/mfc/index/FindMfcMap";
import List from "@/components/views/mfc/index/List";
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: "MfcIndex",
  components: {
    Map,
    List,
    SelectBar
  },
  computed: {
    ...mapState('mfc', [
      'search',
      'cities',
      'regions',
      'points',
      'changeBtn',
      'defaultRegion',
    ]),
    ...mapGetters('mfc', [
      'getActiveRegion',
      'getActiveCity',
      'getPoints',
    ]),
  },
  methods: {
    ...mapActions('mfc', [
      'setCity',
      'setRegion',
      'getApi',
      'getApiRegion'
    ]),
  },
  mounted() {
    this.getApi({})
    this.setRegion({value: this.getActiveRegion?.type});
    if (this.regions.length < 1) {
      this.getApiRegion()
    }
  }
}
</script>

<style lang="scss" scoped>
.find-mfc {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, center, column);
  margin: auto;
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}
</style>
