<template>
  <div class="sections">
    <div class="container">
      <div class="rows">
        <div class="row">
          <ModelIcon1 class="icon" />
          <div class="name">Социальнозначимый проект</div>
        </div>
        <div class="row">
          <ModelIcon2 class="icon" />
          <div class="name">Электронное правительство</div>
        </div>
        <div class="row">
          <ModelIcon3 class="icon" />
          <div class="name">Устойчивое развитие</div>
        </div>
        <div class="row">
          <ModelIcon4 class="icon" />
          <div class="name">Большие перспективы</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelIcon1 from '@/components/icons/ModelIcon1.vue'
import ModelIcon2 from '@/components/icons/ModelIcon2.vue'
import ModelIcon3 from '@/components/icons/ModelIcon3.vue'
import ModelIcon4 from '@/components/icons/ModelIcon4.vue'

export default {
  name: 'Sections',
  components: {
    ModelIcon1,
    ModelIcon2,
    ModelIcon3,
    ModelIcon4
  }
}
</script>

<style lang="scss" scoped>
.sections{
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  padding: 2vw 0;
  .rows{
    @include box(100%, auto, relative, null, null, null, null);
    @include flex(left, top, row);
    padding: 2vw 0;
    .row{
      @include box(25%, auto, relative, null, null, null, null);
      @include flex(center, center, column);
      .name{
        font-size: 1.1vw;
        font-weight: 700;
        padding-top: 1.5vw;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .sections{
    padding: 2vw 0;
    .rows{
      padding: 2vw 2vw;
      flex-wrap: wrap;
      .row{
        @include box(50%, auto, relative, null, null, null, null);
        @include flex(center, center, column);
        padding: 3vw 0;
        .name{
          font-size: 2vw;
          padding-top: 2vw;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sections{
    padding: 4vw 0;
    .rows{
      padding: 0;
      .row{
        @include box(100%, auto, relative, null, null, null, null);
        padding: 4vw 0;
        .name{
          font-size: 5vw;
          padding-top: 4vw;
        }
      }
    }
  }
}
</style>
