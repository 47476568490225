<template>
<div ref="scroller">
  <slot></slot>
  <template v-if="!stop">
    <div class="spinner">
      <BaseSpinner/>
    </div>
  </template>
  <Observer @intersect="loadMore"/>
</div>
</template>

<script>
import BaseSpinner from '@/assets/icons/BaseSpinner.vue'
import Observer from './Observer.vue'

export default {
  name: 'InfiniteScrolling',
  components: {
    BaseSpinner,
    Observer
  },
  props: {
    stop: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async loadMore() {
      if (!this.stop) {
        await this.$emit('loadMore')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    margin-top: 40px;
  }
}
</style>
