<template>
  <div class="actions">
    <div class="line">
      <div class="statistic">
        <PracticeStatistic
          :approve="approve"
          :like="like"
          :comment="comment"
          :favorite="favorite"
          :isSelected="isSelected"
          :isLiked="isLiked"
          :split="deviceSize.mobile"
          size="normal"
        />
      </div>
      <div v-if="isAuthorized" class="group">
        <div class="button">
          <Button
            label="Внедрить практику"
            size="normal"
            background
            class="button"
            :active="!isEmbedded"
            @click="introducePractice"
          />
        </div>
        <div class="like">
          <PracticeButton :active="isLiked" tooltip="Вам нравится" @onClick="$emit('like')">
            <LikeIcon />
          </PracticeButton>
        </div>
        <div class="mark">
          <PracticeButton
            :active="isSelected"
            tooltip="В избранном"
            @onClick="$emit('toggleFavorite')"
          >
            <FavoriteIcon />
          </PracticeButton>
        </div>
      </div>
    </div>
    <div class="region">
      <PracticeRegion>{{ subject }}</PracticeRegion>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button.vue'
import PracticeStatistic from '@/components/views/practices/index/PracticeStatistic.vue'
import PracticeRegion from '@/components/views/practices/view/PracticeRegion.vue'
import PracticeButton from '@/components/views/practices/view/PracticeButton.vue'
import LikeIcon from '@/assets/icons/LikeIcon.vue'
import FavoriteIcon from '@/assets/icons/FavoriteIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PracticeActions',
  components: {
    PracticeStatistic,
    PracticeRegion,
    PracticeButton,
    LikeIcon,
    FavoriteIcon,
    Button
  },
  props: {
    approve: {
      type: [Number, null],
      default: null
    },
    like: {
      type: [Number, null],
      default: null
    },
    comment: {
      type: [Number, null],
      default: null
    },
    favorite: {
      type: [Number, null],
      default: null
    },
    likeMe: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isLiked: Boolean,
    subject: {
      type: [String, null],
      default: null
    },
    isEmbedded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('mfcCabinet', ['isAuthorized']),
  },
  inject: ['deviceSize'],
  methods: {
    introducePractice() {
      this.$emit('introducePractice')
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  .statistic {
    margin-bottom: 16px;
  }

  .group {
    margin-bottom: 24px;
  }

  @media (max-width: 899px) {
    &,
    .group,
    .statistic {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    position: relative;
    height: 240px;
    flex-direction: column-reverse;

    .line {
      display: flex;
      flex-direction: column-reverse;
    }

    .button {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }
  }

  @media (min-width: 900px) {
    .group {
      display: grid;
      grid-template-columns: 192px 44px 44px;
      grid-gap: 20px;
    }
  }
}
</style>
