<template>
  <div class="social">
    <a v-if="!instagram" href="instagram_link" class="link" target="_blank"><instagram class="icon"/></a>
    <a :href="vkontakte_link" class="link" target="_blank"><v-kontakte class="icon"/></a>
    <a v-if="!facebook" :href="facebook_link" class="link" target="_blank"><facebook class="icon"/></a>
    <a v-if="!twitter" :href="twitter_link" class="link" target="_blank"><twitter class="icon"/></a>
    <a v-if="!telegram" :href="telegram_link" class="link" target="_blank"><telegram class="icon"/></a>
    <a :href="youtube_link" class="link" target="_blank"><youtube class="icon"/></a>
  </div>
</template>

<script>
  import Instagram from "./icons/Instagram";
  import VKontakte from "./icons/VKontakte";
  import Facebook from "./icons/Facebook";
  import Twitter from "./icons/Twitter";
  import Youtube from "./icons/Youtube";
  import Telegram from "./icons/Telegram";
  export default {
    name: "Social",
    props: {
      instagram: Boolean,
      instagram_link: String,
      vkontakte: Boolean,
      vkontakte_link: String,
      facebook: Boolean,
      facebook_link: String,
      twitter: Boolean,
      twitter_link: String,
      youtube: Boolean,
      youtube_link: String,
      telegram: Boolean,
      telegram_link: String,
    },
    components: {
        Instagram,
        VKontakte,
        Facebook,
        Twitter,
        Youtube,
        Telegram
    }
  }
</script>

<style lang="scss" scoped>
  .social {
    @include box(95%, auto, relative, null, null, null, null);
    @include flex(left, center, row);
    .link {
      margin-right: .5vw;
    }
  }
  @media only screen and (max-width: 900px) {
    .social {
      @include flex(left, center, row);
      .link {
        .icon{
          @include box(3vw, auto, relative, null, null, null, null);
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .social {
      .link {
        margin-right: 2.5vw;
        .icon{
          @include box(10vw, auto, relative, null, null, null, null);
        }
      }
    }
  }
</style>
