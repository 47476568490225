<template>
  <div class="template">
    <Menu />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

import { mapActions } from 'vuex';

export default {
  name: 'Template',
  components: {
    Menu,
    Footer
  },
  methods: {
    ...mapActions([
      'setRoutes'
    ])
  },
  created() {
    this.setRoutes(this.$router.options.routes[0].children)
  }
}
</script>

<style lang="scss">
.template{
    min-height: 100vh;
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
  .content{
    @include box(100%, auto, relative, null, null, null, null);
  }
  .container{
    @include box(75%, auto, relative, null, null, null, null);
    margin: 0 auto;
  }
}
@media only screen and (max-width: 900px) {
  .template{
    .container{
      @include box(100%, auto, relative, null, null, null, null);
      margin: 0 auto;
    }
  }
}
</style>
