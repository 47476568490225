<template>
  <div class="replication__content" data-link-content>
    <div class="replication__item">
      <div class="replication__header">
        <span>{{ mfcs.length }}</span>
        <p>Внедрили практику</p>
      </div>
      <div class="replication__block">
        <input v-model="search" type="text" placeholder="Поиск региона" />
        <SortSelect :options="sortOptions" @change="changeSortSelect" />
      </div>
      <div v-for="item in filteredMfcs" :key="item.nick" class="replication__item-block">
        <div class="replication__item-image">
          <img :src="item.imgSrc" alt />
        </div>
        <div class="replication__item-info">
          <p>{{ item.nick }}</p>
          <span>
            Внедрена
            {{ item.date }}
          </span>
        </div>
      </div>

      <Pagination
        v-if="mfcs.total > pagination.perPage"
        @setpage="setPage"
        :pages="mfcs.total"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import SortSelect from './SortSelect.vue'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'AlreadyReplicated',
  components: {
    Pagination,
    SortSelect
  },
  props: {
    practice: Object
  },
  data: () => ({
    mfcs: [],
    search: '',
    sortType: {
      label: 'Названию МФЦ',
      value: 'nick',
      id: 1
    },
    sortOptions: [
      {
        label: 'Названию МФЦ',
        value: 'nick',
        id: 1
      },
      {
        label: 'Дате внедрения',
        value: 'date',
        id: 2
      },
    ],
    pagination: {
      currentPage: 1,
      perPage: 10
    }
  }),
  computed: {
    filteredMfcs() {
      return this.mfcs.filter(el => el.nick.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => {
          if (this.sortType.value === 'nick') {
            return a.nick < b.nick ? -1 : 1
          } else if (this.sortType.value === 'date') {
            return moment(a.date, 'DD.MM.YYYY').format('x') - moment(b.date, 'DD.MM.YYYY').format('x')
          }
        })
    }
  },
  methods: {
    setPage(page) {
      this.pagination.currentPage = page
      this.getMfcs()
    },
    async getMfcs() {
      const body = { id: this.$route.params.id }
      const { data } = await axios.post('/practice/embedded', body)
      this.mfcs = data
    },
    changeSortSelect(sortType) {
      this.sortType = sortType
    }
  },
  created() {
    this.getMfcs()
  }
}
</script>

<style lang="scss" scoped>
</style>