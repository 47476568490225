<template>
    <div class="container">
        <div class="title">Куда и как подать заявление о внесудебном банкротстве?</div>
        <div class="sub__title">В МФЦ по месту жительства или месту пребывания</div>
        <div class="sections">
            <div class="rows">
                <div class="row__name">
                    <div class="name__table">
                        В МФЦ по месту жительства или месту пребывания
                    </div>
                </div>
                <div class="row__description">
                    <div class="name__table">
                        Нужно предоставить заявление в <span class="red__text">письменном виде</span> и список всех известных кредиторов, составленный
                        по <span class="red__text">определенной форме.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DeliveryPlace"
    }
</script>

<style lang="scss" scoped>
    .red__text {
        color: #E04E39!important;
        border-bottom: 1px dashed #E04E39;
        cursor: pointer;
    }
    .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        padding-top: 80px;
        padding-bottom: 40px;
    }
    .sub__title {
        display: none;
    }
    .sections {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding-bottom: 80px;
        .rows {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .row__name {
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
            .row__description {
                @include box(75%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .name__table {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .title {
            font-weight: 700;
            font-size: 19px;
            line-height: 36px;
            padding-top: 80px;
            padding-left: 15px;
            padding-bottom: 0;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 15px;
            display: block;
        }
        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-bottom: 46px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__name {
                    @include box(25%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    display: none!important;
                    padding-left: 15px;
                    padding-right: 15px;
                    .name__table {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__description {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-left: 15px;
                    padding-right: 15px;
                    .name__table {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .title {
            font-weight: 700;
            font-size: 19px;
            line-height: 36px;
            padding-top: 80px;
            padding-left: 15px;
            padding-bottom: 0;
        }
        .sub__title {
            font-weight: 700;
            font-size: 19px;
            line-height: 28px;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 15px;
            display: block;
        }
        .sections {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, column);
            padding-bottom: 46px;
            .rows {
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__name {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    display: none!important;
                    padding-left: 15px;
                    padding-right: 15px;
                    .name__table {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .row__description {
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-left: 15px;
                    padding-right: 15px;
                    .name__table {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
            }
        }
    }
</style>
