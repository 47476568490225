<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="modelicon">
    <path d="M3 0V2H6.5625L0.28125 8.28125L1.71875 9.71875L8 3.4375V7H10V0H3ZM22 0V24H24V0H22ZM18 4V24H20V4H18ZM14 8V24H16V8H14ZM10 12V24H12V12H10ZM6 16V24H8V16H6ZM2 20V24H4V20H2Z" fill="#623B2A"/>
  </svg>
</template>

<script>
export default {
  name: "ModelIcon3"
}
</script>

<style lang="scss" scoped>
.modelicon {
  @include box(1.3vw, 1.3vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .modelicon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .modelicon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
