<template>
  <div class="modelsIndex">
    <Header />
    <Items :items="items" />
  </div>
</template>

<script>
import Header from '@/components/views/models/index/Header.vue'
import Items from '@/components/views/models/index/Items.vue'

import {mapActions, mapState} from 'vuex';

export default {
  name: 'ModelsIndex',
  components: {
    Header,
    Items
  },
  computed: {
    ...mapState('models', [
      'items',
    ])
  },
  methods: {
    ...mapActions('models', [
      'getApi',
        'setPage'
    ]),
  },
  mounted() {
    this.setPage(this.$route.query.page > 0 ? this.$route.query.page - 1 : 0)
    this.getApi({});
  }
}
</script>

<style lang="scss" scoped>
.modelsIndex {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, column);
}
</style>
