<template>
  <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrowRightWhiteIcon">
    <path d="M4 0V3H22V5H4V8L0 4L4 0Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightWhiteIcon',
}
</script>

<style lang="scss" scoped>
.arrowRightWhiteIcon{
  background-color: rgba(0,0,0,0) !important;
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .arrowRightWhiteIcon{
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .arrowRightWhiteIcon{
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
