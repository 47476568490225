<template>
    <div class="container">
        <div class="sections">
            <div class="sub__title">Последствия завершения процедуры внесудебного банкротства</div>
            <div class="rows">
                <div class="row row__title__none">
                    <div class="title">
                        Последствия завершения процедуры внесудебного банкротства
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <div class="icon"><Help class="icon" /></div>
                        <div class="title">
                            5 лет
                        </div>
                        <div class="row__description">
                            Нельзя брать кредит без указания на факт своего банкротства
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <div class="icon"><Bisnessman class="icon" /></div>
                        <div class="title">
                            3 года
                        </div>
                        <div class="row__description">
                            Нельзя участвовать в управлении юрлицом
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text">
                        <div class="icon"><Bug class="icon" /></div>
                        <div class="title">
                            10 лет
                        </div>
                        <div class="row__description">
                            Нельзя участвовать в управлении кредитной организацией
                        </div>
                    </div>
                </div>
            </div>
            <div class="rows">
                <div class="row row__title__none">
                    <div class="title">
                    </div>
                </div>
                <div class="row_two">
                    <div class="text">
                        <div class="icon"><Chart class="icon" /></div>
                        <div class="title">
                            5 лет
                        </div>
                        <div class="row__description">
                            Нельзя занимать должности в органах управления страховой организации, негосударственного пенсионного фонда, управляющей компании инвестиционного фонда, паевого инвестиционного фонда и негосударственного пенсионного фонда или микрофинансовой компании, иным образом участвовать в управлении такими организациями
                        </div>
                    </div>
                </div>
                <div class="row_two">
                    <div class="text">
                        <div class="icon"><Passport /></div>
                        <div class="title">
                            5 лет
                        </div>
                        <div class="row__description">
                            Нельзя зарегистрироваться как ИП, осуществлять предприниматель- скую деятельность, а также участвовать в управлении юрлицом, если был статус ИП менее чем за один год до подачи заявления о внесудебном банкротстве.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Help from "../../icons/bankrutcy/Help";
    import Bisnessman from "../../icons/bankrutcy/Bisnessman";
    import Bug from "../../icons/bankrutcy/Bug";
    import Chart from "../../icons/bankrutcy/Chart";
    import Passport from "../../icons/bankrutcy/Passport";
    export default {
        name: "FinishBankruptcy",
        components: {
            Passport,
            Chart,
            Bug,
            Bisnessman,
            Help,
        }
    }
</script>

<style lang="scss" scoped>
    .sub__title {
        display: none;
    }
    .icon {
        margin-bottom: 3px;
    }
    .sections{
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        margin-top: 80px;
        margin-bottom: 60px;
        background-color: #E04E39;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 40px 0;
        .rows{
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
            .row{
                @include box(25%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-left: 15px;
                padding-right: 15px;
                .title {
                    text-align: left;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
                .text{
                    text-align: left;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
            .row_two{
                @include box(38%, auto, relative, null, null, null, null);
                @include flex(center, center, column);
                padding-top: 46px;
                padding-left: 15px;
                padding-right: 15px;
                .title {
                    text-align: left;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }
                .text{
                    text-align: left;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .sub__title {
            font-size: 19px;
            font-weight: 700;
            line-height: 28px;
            padding-left: 15px;
            padding-bottom: 40px;
            display: block;
        }
        .sections{
            padding: 40px 0;
            .rows{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                .row__title__none {
                    display: none!important;
                }
                .row{
                    @include box(33%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-left: 15px;
                    padding-right: 15px;
                    .title {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .text{
                        text-align: left;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
                .row_two{
                    @include box(50%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-top: 46px;
                    padding-left: 15px;
                    padding-right: 15px;
                    .title {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .text{
                        text-align: left;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .sections{
            padding: 4vw 0;
            .rows{
                @include box(100%, auto, relative, null, null, null, null);
                @include flex(left, top, row);
                display: block!important;
                .row{
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-left: 15px;
                    padding-right: 15px;
                    .title {
                        text-align: left;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .text{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
                .row_two{
                    @include box(100%, auto, relative, null, null, null, null);
                    @include flex(center, center, column);
                    padding-top: 46px;
                    padding-left: 15px;
                    padding-right: 15px;
                    .title {
                        text-align: left;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .text{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }
    }
</style>
