<template>
  <div @click="toggleMenu" class="sort-select content__item-select">
    <div class="content__item-block">
      <p class="content__item-sort">Сортировать по</p>
      <p class="content__item-name">{{ selectedType.label }}</p>
    </div>
    <div class="content__item-icons">
      <img class="sort-icon" src="@/assets/searchBar/sort.svg" alt />
      <img src="@/assets/undecomposed/arrow-down.svg" alt />
    </div>
    <ul v-if="isMenuShown" class="content__item-drop active">
      <li v-for="type in sortTypes" :key="type.id" :class="selectedTypeClasses(type)">
        <div @click="changeSortType(type)">{{ type.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'SortSelect',
  computed: {
    selectedType() {
      return this.sortTypes.find(el => el.id === this.sortTypeId)
    }
  },
  data: () => ({
    sortTypeId: 1,
    isMenuShown: false,
    sortTypes: [
      {
        id: 1,
        label: 'Названию практик',
        type: 'title'
      },
      {
        id: 2,
        label: 'Пользовательским оценкам',
        type: 'like'
      },
      {
        id: 3,
        label: 'Внедрениям',
        type: 'embedded_count'
      },
      {
        id: 4,
        label: 'Комментариям',
        type: 'comment_count'
      },
      {
        id: 5,
        label: 'Дате внедрения',
        type: 'date'
      },
      {
        id: 6,
        label: 'Стоимости внедрения',
        type: 'price'
      },
      {
        id: 7,
        label: 'Добавлению в закладки',
        type: 'selected_count'
      },
    ]
  }),
  methods: {
    ...mapActions('practices', [
      'getApi',
    ]),
    ...mapMutations('practices', [
      'setSortType',
    ]),
    toggleMenu() {
      this.isMenuShown = !this.isMenuShown
    },
    closeMenu(e) {
      if (!e.target.closest('.sort-select')) this.isMenuShown = false
    },
    changeSortType(value) {
      this.sortTypeId = value.id
      const type = {
        [value.type]: 'DESC' // mb not ASC only?
      }
      this.setSortType(type)
      this.getApi({})
    },
    selectedTypeClasses(type) {
      return { 'content__item-drop--active': type.id === this.sortTypeId }
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style>
.sort-icon {
  display: block;
  margin-right: 10px;
}
</style>