<template>
  <div class="sidebar">
    <transition name="fide">
      <div class="wrapper" v-if="menuSidebar"></div>
    </transition>
    <transition name="slide">
      <div class="block" v-if="menuSidebar">
        <div class="cont">
          <div class="close" @click="$emit('close', false)">
            <CloseIcon />
          </div>
          <router-link to="/">
            <MfcBigLogo class="logo" />
            <LogoIcon class="logo mobile" />
          </router-link>
          <div class="links">
            <router-link
              :to="route.route"
              class="link"
              v-for="route in routes"
              :key="route.route"
              @click.native="setMenuSidebar(false)"
            >{{ route.description }}</router-link>
          </div>
          <div class="divider"></div>
          <div class="links">
            <router-link to="/models" class="link">Целевые модели МФЦ 2.0</router-link>
            <router-link v-show="false" to="/experience" class="link">Международный опыт</router-link>
            <router-link to="/journal" class="link">Журнал «Мои документы»</router-link>
            <router-link to="/forums" class="link">Всероссийский форум МФЦ</router-link>
            <router-link v-if="isAuthorized" to="/expert-platforms" class="link">Экспертные площадки</router-link>
            <router-link to="/standardization" class="link">Стандартизация</router-link>
            <router-link to="/contest" class="link">Конкурс «Лучший МФЦ»</router-link>
            <router-link v-show="false" to="/" class="link">Рейтинг МФЦ РФ</router-link>
            <router-link to="/brand" class="link">Бренд</router-link>
          </div>
          <template v-if="isAuthorized">
            <div class="divider"></div>
            <div class="links">
              <router-link to="/mfc-cabinet" class="link">Личный кабинет</router-link>
              <div class="link" @click="logout">Выход</div>
            </div>
          </template>
          <social
            class="social"
            :telegram="false"
            :instagram="true"
            :facebook="true"
            instagram_link="https://www.instagram.com/minec_russia/"
            vkontakte_link="https://vk.com/ministryeconomy"
            facebook_link="https://www.facebook.com/MinistryEconomy"
            twitter_link="https://twitter.com/MinistryEconomy"
            youtube_link="https://www.youtube.com/user/Mineconomrazvitia"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MfcBigLogo from '@/components/icons/MfcBigLogo'
import LogoIcon from '@/components/icons/LogoIcon'
import CloseIcon from '@/components/icons/CloseIcon'
import Social from '@/components/Social'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    routes: Array,
    menuSidebar: Boolean,
  },
  components: {
    MfcBigLogo,
    CloseIcon,
    Social,
    LogoIcon,
  },
  computed: {
    ...mapGetters('mfcCabinet', ['isAuthorized']),
  },
  methods: {
    ...mapActions(['setMenuSidebar']),
    ...mapActions('mfcCabinet', ['logout']),
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  z-index: 999;
  .wrapper {
    @include box(100%, 100%, fixed, 0, 0, null, null);
    background-color: rgba(0, 0, 0, 0.3);
  }
  .block {
    @include box(20%, 100%, fixed, 0, 0, null, null);
    background-color: #623b2a;
    z-index: 999;
    .cont {
      @include box(100%, auto, relative, null, null, null, null);
      @include flex(left, center, column);
      padding: 1vw 0;
      padding-top: 0;
      .close {
        @include box(null, null, absolute, 1vw, 1vw, null, null);
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          opacity: 0.6;
        }
      }
      .logo {
        @include box(7vw, 6vw, relative, null, null, null, null);
        margin-top: 2vw;
        &.mobile {
          display: none;
        }
      }
      .links {
        @include box(100%, auto, relative, null, null, null, null);
        @include flex(left, top, column);
        padding: 2vw 3vw;
        .link {
          cursor: pointer;
          margin: 0.5vw 0;
          font-size: 0.85vw;
          color: rgba(255, 255, 255, 0.7);
          transition: 0.2s;
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .divider {
        @include box(calc(100% - 6vw), 0.1vw, relative, null, null, null, null);
        background-color: rgba(255, 255, 255, 0.2);
      }
      .social {
        margin-top: 1vw;
        @include flex(center, center, row);
      }
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.5s;
  opacity: 1;
  transform: translateX(0);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.fide-enter-active,
.fide-leave-active {
  transition: opacity 0.5s;
}
.fide-enter,
.fide-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 900px) {
  .sidebar {
    .block {
      @include box(50%, 100%, fixed, 0, 0, null, null);
      overflow-y: scroll;
      .cont {
        padding: 4vw 0;
        padding-top: 4vw;
        .close {
          @include box(null, null, absolute, 3vw, 3vw, null, null);
        }
        .logo {
          @include box(15vw, 10vw, relative, null, null, null, null);
        }
        .links {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          padding: 3vw 4vw;
          .link {
            margin: 1.2vw 0;
            font-size: 2vw;
          }
        }
        .divider {
          @include box(
            calc(100% - 6vw),
            0.1vw,
            relative,
            null,
            null,
            null,
            null
          );
        }
        .social {
          margin-top: 2vw;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sidebar {
    .block {
      @include box(100%, 100%, fixed, 0, 0, null, null);
      overflow-y: scroll;
      .cont {
        @include flex(left, top, column);
        padding: 6vw 6vw;
        padding-top: 3vw;
        .close {
          @include box(null, null, absolute, auto, 5vw, 5vw, null);
        }
        .logo {
          @include box(30vw, auto, relative, null, null, null, null);
          display: none;
          &.mobile {
            display: flex;
            margin-bottom: 2vw;
          }
        }
        .links {
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);
          padding: 4vw 0;
          .link {
            margin: 2.5vw 0;
            font-size: 4vw;
          }
        }
        .divider {
          @include box(100%, 0.4vw, relative, null, null, null, null);
        }
        .social {
          margin-top: 4vw;
          @include flex(left, center, row);
        }
      }
    }
  }
}
</style>
