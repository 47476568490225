<template>
  <div class="banner-practice">
    <div class="container">
      <div class="text">
        <Breadcrumbs
          class="breadcrumbs"
          :pages="[{ name: 'Портал Идей', route: '/practices' }]"
          :white="deviceSize.desktop"
        />
        <div class="title">{{ practice.title }}</div>
        <div class="category">{{ practice.category }}</div>
      </div>
      <template v-if="deviceSize.desktop">
        <PracticeActions
          :approve="practice.embedded_count"
          :like="practice.like"
          :isLiked="practice.is_liked"
          :comment="practice.comment_count"
          :favorite="practice.selected_count"
          :isSelected="practice.is_selected"
          :subject="practice.subject"
          @like="like"
          @toggleFavorite="toggleFavorite"
          @introducePractice="introducePractice"
        />
      </template>
    </div>
    <div class="image" :style="{background: `url(${practice.banner})`}">
      <div class="container">
        <div class="achieve">
          <Achievements
            :approve="!!practice.expert_review"
            :best="!!practice.is_best"
            :top="deviceSize.desktop ? 4 : -14"
            :right="0"
          />
        </div>
        <template v-if="!deviceSize.desktop">
          <PracticeActions
            :approve="practice.embedded_count"
            :like="practice.like"
            :isLiked="practice.is_liked"
            :comment="practice.comment_count"
            :favorite="practice.selected_count"
            :isSelected="practice.is_selected"
            :subject="practice.subject"
            :isEmbedded="practice.is_embedded"
            @like="like"
            @toggleFavorite="toggleFavorite"
            @introducePractice="introducePractice"
          />
        </template>
      </div>
    </div>
    <AgreementModal
      :visible="isAgreementModalShown"
      title="Внедрить практику"
      lead="Настоящим, даю свое согласие на: "
      :checkboxes="modalCheckboxes"
      @closeModal="$router.push('/')"
      @accept="acceptAgreement"
    />
  </div>
</template>

<script>
import Achievements from '@/components/elements/Achievements.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import PracticeActions from '@/components/views/practices/view/PracticeActions.vue'
import AgreementModal from '@/components/elements/AgreementModal.vue'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Header',
  components: {
    Achievements,
    Breadcrumbs,
    PracticeActions,
    AgreementModal
  },
  props: {
    practice: Object
  },
  inject: ['deviceSize'],
  data() {
    return {
      modalCheckboxes: [
        {
          name: 'размещение информации и материалов о внедрении готового решения или практики МФЦ на информационном ресурсе',
          required: true,
          checked: false
        },
        {
          name: 'получение методического сопровождения и консультационной поддержки от «автора практики» при внедрении готового решения',
          required: true,
          checked: false
        },
      ],
      isAgreementModalShown: false
    }
  },
  computed: {
    ...mapGetters('mfcCabinet', ['practiceAgreement', 'isAuthorized']),
  },
  methods: {
    ...mapActions('practices', ['embedPractice']),
    ...mapActions('mfcCabinet', ['acceptPracticeAgreement']),
    async like() {
      const body = { id: +this.$route.params.id }
      const { data } = await axios.post('/practice/like', body)
      this.practice.is_liked = data?.is_liked
      if (this.practice.is_liked){
        this.practice.like += 1;
      } else {
        this.practice.like -= 1;
      }
    },
    async toggleFavorite() {
      const body = { id: +this.$route.params.id }
      const { data } = await axios.post('/practice/select', body)
      this.practice.is_selected = data?.is_selected
      if (this.practice.is_selected) {
        this.practice.selected_count += 1;
      } else {
        this.practice.selected_count -= 1;
      }
    },
    async introducePractice() {
      if (!this.practiceAgreement && this.isAuthorized) {
        this.isAgreementModalShown = true
      } else if (this.isAuthorized) {
        const status = await this.embedPractice()
        if (status === true) {
          this.$router.push({ name: 'implemented-practice', params: { id: this.practice.id } })
        }
      }
    },
    async acceptAgreement() {
      this.isAgreementModalShown = !(await this.acceptPracticeAgreement())
      const status = await this.embedPractice()
      if (status === true) {
        this.$router.push({ name: 'implemented-practice', params: { id: this.practice.id } })
      }
    }
  }
}
</script>

<style lang="scss">
.banner-practice {
  margin: 0 !important;
  padding: 20px 0 26px !important;
  position: relative;
  width: 100%;

  .breadcrumbs {
    position: relative;
    z-index: 1;
    margin-bottom: 24px;

    .container {
      padding: 0;

      @media (min-width: 1170px) {
        margin: 0;
      }
    }
  }

  .title {
    font-size: 29px;
    line-height: 38px;
    max-width: 730px;
    margin-bottom: 16px;
  }

  .category {
    font-size: 18px;
    line-height: 28px;
  }

  .image {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .achieve {
    position: relative;
  }

  @media (max-width: 899px) {
    .text {
      padding-bottom: 24px;
    }

    .image {
      padding: 12px 0 16px;
      height: 240px;
    }
  }

  @media (min-width: 900px) {
    padding: 40px 0 24px !important;
    overflow: hidden;

    .breadcrumbs {
      margin-bottom: 60px;
    }

    .text {
      color: white;
      margin-bottom: 16px;
    }

    .title {
      font-size: 44px;
      line-height: 52px;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      min-height: 500px;
        height: 100%;
    }
  }

  .container {
    position: relative;
    z-index: 1;

    @media (max-width: 600px) {
      padding: 0 6vw;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      padding: 0 4vw;
    }
  }

    .image {
        background-position: right !important;
        background-size: cover !important;
    }
}
</style>
