<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path d="M8.512 3.977C8.527 4.607 8.564 5.4 8.652 5.901C8.748 6.443 8.934 7.095 9.077 7.554C9.189 7.914 9.09 8.306 8.824 8.573L6.62 10.79C7.427 12.322 8.367 13.549 9.412 14.588C10.451 15.633 11.678 16.573 13.21 17.38L15.428 15.176C15.696 14.91 16.087 14.811 16.447 14.923C16.905 15.065 17.558 15.252 18.1 15.348C18.601 15.436 19.393 15.473 20.024 15.488C20.568 15.501 21 15.945 21 16.49V20C21 20.438 20.672 21 20 21C15.461 21 11.126 19.16 7.966 16.034C4.84 12.874 3 8.539 3 4C3 3.328 3.562 3 4 3H7.51C8.055 3 8.499 3.432 8.512 3.977Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>
</template>

<script>
  export default {
    name: "Phone"
  }
</script>

<style lang="scss" scoped>
  .icon{
    @include box(1.5vw, 1.5vw, relative, null, null, null, null);
  }
  @media only screen and (max-width: 900px) {
    .icon{
      @include box(3.1vw, 3.1vw, relative, null, null, null, null);
    }
  }
  @media only screen and (max-width: 600px) {
    .icon {
      @include box(6.4vw, 6.4vw, relative, null, null, null, null);
    }
  }
</style>
