<template>
  <div class="footer">
    <div class="wrapper">
      <div class="footer-container">
        <ul>
          <li>
            <router-link to="/news">Новости</router-link>
          </li>
          <li>
            <router-link to="/mfc">Интерактивная карта</router-link>
          </li>
          <li>
            <router-link to="/practices">Портал Идей</router-link>
          </li>
          <li v-show="false">
            <router-link to="/experience">Международный опыт</router-link>
          </li>
          <li>
            <router-link to="/journal">Журнал «Мои документы»</router-link>
          </li>
          <li>
            <router-link to="/poster">Афиша</router-link>
          </li>
          <li>
            <router-link to="/forums">Всероссийский форум МФЦ</router-link>
          </li>
        </ul>
        <ul>
          <li v-if="isAuthorized">
            <a href="/expert-platforms" style="display: none">Экспертные площадки</a>
          </li>
          <li>
            <a href="/standardization">Стандартизация</a>
          </li>
          <li>
            <router-link to="/documents">Нормативные правовые документы</router-link>
          </li>
          <li>
            <router-link to="/models">Целевые модели МФЦ 2.0</router-link>
          </li>
          <li>
            <a href="/contest">Конкурс «Лучший МФЦ»</a>
          </li>
          <li v-show="false">
            <a href="#">Рейтинг МФЦ РФ</a>
          </li>
          <li>
            <a href="/brand">Бренд</a>
          </li>
        </ul>
        <div class="links">
          <div class="links_box">
            <a href="https://www.economy.gov.ru/" class="big_link" target="_blank">
              <arms class="planchet-economy" />
              <div class="link_text">
                Министерство
                <br />экономического развития
                <br />Российской Федерации
              </div>
            </a>
          </div>
          <div class="links_box">
            <a href="https://www.gosuslugi.ru/" class="big_link" target="_blank">
              <logo-color />
              <div class="link_text">Портал государственных услуг</div>
            </a>
          </div>
        </div>
        <div class="social_wrapper">
          <div class="social_bar">
            <span>
              Министерство
              <br />экономического развития
              <br />Российской Федерации
            </span>
            <social
              instagram_link="https://www.instagram.com/minec_russia/"
              vkontakte_link="https://vk.com/ministryeconomy"
              facebook_link="https://www.facebook.com/MinistryEconomy"
              twitter_link="https://twitter.com/MinistryEconomy"
              :telegram="true"
              :instagram="true"
              :facebook="true"
              youtube_link="https://www.youtube.com/user/Mineconomrazvitia"
            />
          </div>
          <div class="social_bar">
            <span>Институт развития МФЦ ЦСР</span>
            <social
              class="planshet"
              instagram_link="https://www.instagram.com/mfc_csr/"
              vkontakte_link="https://vk.com/mfccsr"
              facebook_link="https://www.facebook.com/mfccsr"
              telegram_link="https://t.me/mfccsr"
              :twitter="true"
              :instagram="true"
              :facebook="true"
              youtube_link="http://www.youtube.com/c/ИнститутразвитияМФЦ"
            />
          </div>
          <div class="counter_box" v-show="false">
            <counter />
          </div>
        </div>
      </div>
      <hr />
      <div class="footer-container footer_reverse">
        <div class="footer_description">
          {{ footer }}
          <br />
          <br />© 2020 «Многофункциональный центр предоставления государственных и муниципальных услуг»
          <br />
          <span v-if="isAuthorized" class="footer__mfc-entrance" @click="logout">Выход</span>
          <span
            v-else
            class="footer__mfc-entrance"
            @click="openMfcCabinetEntranceModal"
          >Вход для МФЦ</span>
        </div>
        <div class="footer_logo">
          <mfc-big-logo />
        </div>
      </div>
    </div>
    <MfcCabinetEntranceModal :visible.sync="isMfcCabinetEntranceModalVisible" />
  </div>
</template>

<script>
import Arms from './icons/Arms'
import LogoColor from './icons/LogoColor'
import Social from './Social'
import Counter from './icons/Counter'
import MfcBigLogo from './icons/MfcBigLogo'
import { mapActions, mapState, mapGetters } from 'vuex'
import MfcCabinetEntranceModal from './views/mfcCabinet/entranceModal/MfcCabinetEntranceModal'
export default {
  name: 'Footer',
  components: {
    MfcCabinetEntranceModal,
    Arms,
    LogoColor,
    Social,
    Counter,
    MfcBigLogo,
  },
  props: {
    groups: Array,
  },
  computed: {
    ...mapState('home', ['footer']),
    ...mapGetters('mfcCabinet', ['isAuthorized']),
  },
  data() {
    return {
      isMfcCabinetEntranceModalVisible: false,
    }
  },
  methods: {
    ...mapActions('home', ['getApiOnce']),
    ...mapActions('mfcCabinet', ['logout']),
    openMfcCabinetEntranceModal() {
      this.isMfcCabinetEntranceModalVisible = true
    },
  },
  mounted() {
    this.getApiOnce({})
  },
}
</script>

<style lang="scss" scoped>
.footer {
  @include box(100%, auto, relative, null, null, null, null);
  @include flex(left, top, row);
  background-color: #623b2a;
  user-select: none;
  margin-top: auto;

  .wrapper {
    @include box(77%, auto, relative, null, null, null, null);
    @include flex(left, top, column);

    hr {
      background-color: rgba(255, 255, 255, 0.2);
      width: 100%;
    }

    margin: auto;

    .footer-container {
      @include flex(left, top, row);
      margin-top: 3.2vw;
      margin-bottom: 3.2vw;

      li {
        list-style-type: none;
        font-style: normal;
        font-weight: normal;
        font-size: 1vw;
        line-height: 1.65vw;
        margin-bottom: 1vw;
      }

      ul {
        @include box(25%, auto, relative, null, null, null, null);
        margin: 0 2.2vw 0 0;
        padding-left: 0;
      }

      a {
        color: rgba(255, 255, 255, 0.7);
        transition: 0.2s;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }

      .footer_description {
        color: rgba(255, 255, 255, 0.7);
        font-style: normal;
        font-weight: normal;
        font-size: 0.9vw;
        line-height: 1.4vw;
      }

      .footer_logo {
        svg {
          padding-left: 11vw;
        }
      }

      .links {
        @include box(25%, auto, relative, null, null, null, null);
        margin-right: 2.2vw;

        .links_box {
          .big_link {
            @include flex(left, center, row);
            margin-bottom: 2vw;

            .link_text {
              margin-left: 1vw;
              font-style: normal;
              font-weight: normal;
              font-size: 0.9vw;
              line-height: 1.4vw;
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }

      .social_wrapper {
        @include box(25%, auto, relative, null, null, null, null);
        @include flex(left, bottom, column);
        align-items: flex-start;
        margin-left: 1.5vw;

        .social_bar {
          margin-bottom: 2.4vw;

          .social {
            width: 100%;
            justify-content: flex-start;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 0.9vw;
            line-height: 1.4vw;
            margin-bottom: 0.9vw;
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .counter_box {
          @include flex(right, bottom, row);
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .wrapper {
      @include box(95%, auto, relative, null, null, null, null);

      .footer-container {
        flex-wrap: wrap;

        ul {
          @include box(30%, auto, relative, null, null, null, null);
        }

        li {
          font-size: 1.5vw;
        }

        .footer_description {
          font-size: 1.8vw;
          line-height: 2.5vw;
        }

        .footer_logo {
          svg {
            padding-left: 8vw;
          }
        }

        .links {
          @include box(33%, auto, relative, null, null, null, null);

          .links_box {
            .big_link {
              @include flex(left, center, row);

              .link_text {
                font-size: 1.5vw;
              }
            }
          }
        }

        .social_wrapper {
          padding-top: 2vw;
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, row);

          .social_bar {
            @include box(100%, auto, relative, null, null, null, null);

            .social {
              @include flex(left, top, row);
              padding-top: 1.5vw;
            }

            span {
              float: left;
              font-size: 1.5vw;
              line-height: 2.4vw;
            }

            .planshet {
              margin-top: 60px;
            }
          }

          .counter_box {
            @include box(100%, auto, relative, null, null, null, null);
            @include flex(left, top, row);
          }
        }

        .footer_description {
          font-size: 1.7vw;
        }

        &.footer_reverse {
          flex-wrap: nowrap;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .wrapper {
      @include box(100%, auto, relative, null, null, null, null);
      padding: 10vw 19vw 10vw 5vw;

      .footer-container {
        @include flex(left, top, column);

        ul {
          @include box(100%, auto, relative, null, null, null, null);
        }

        li {
          font-size: 5vw;
          margin-bottom: 6vw;
          line-height: 6vw;
        }

        .footer_description {
          font-size: 4vw;
          line-height: 6vw;
        }

        .footer_logo {
          svg {
            padding-left: 0;
            margin-bottom: 20vw;
            margin-top: 13vw;
          }
        }

        .links {
          @include box(100%, auto, relative, null, null, null, null);
          margin-right: 2.2vw;

          .links_box {
            .big_link {
              @include flex(left, center, row);
              margin-bottom: 9vw;

              .link_text {
                font-size: 4.4vw;
                margin-left: 3vw;
                line-height: 5.4vw;
                color: rgba(255, 255, 255, 0.7);
              }
            }
          }
        }

        .social_wrapper {
          padding-top: 0;
          @include box(100%, auto, relative, null, null, null, null);
          @include flex(left, top, column);

          .social_bar {
            margin-bottom: 6vw;

            .social {
              width: 100%;
              justify-content: flex-start;
            }

            span {
              float: left;
              font-style: normal;
              font-weight: normal;
              font-size: 4vw;
              line-height: 5.4vw;
              margin-bottom: 3vw;
              color: rgba(255, 255, 255, 0.7);
            }
          }

          .counter_box {
            @include flex(left, top, row);
          }
        }
      }

      .footer_reverse {
        flex-direction: column-reverse;
      }
    }
  }
}

.footer__mfc-entrance {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
</style>
