<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="timeIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C14.4301 2 18 5.56988 18 10C18 14.4301 14.4301 18 10 18C5.56988 18 2 14.4301 2 10C2 5.56988 5.56988 2 10 2ZM9 10.4141V4H11V9.58594L14.707 13.293L13.293 14.707L9 10.4141Z" fill="#2C2A29" fill-opacity="0.7"/>
  </svg>
</template>

<script>
  export default {
    name: "List"
  }
</script>

<style lang="scss" scoped>
.timeIcon {
  @include box(1vw, 1vw, relative, null, null, null, null);
}
@media only screen and (max-width: 900px) {
  .timeIcon {
    @include box(2vw, 2vw, relative, null, null, null, null);
  }
}
@media only screen and (max-width: 600px) {
  .timeIcon {
    @include box(4vw, 4vw, relative, null, null, null, null);
  }
}
</style>
